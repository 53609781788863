import Vue from 'vue'
import App from './App.vue'
// 饿了吗ui
import ElementUI from 'element-ui'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
//导入Echarts
// import echarts from 'echarts' // 引入echarts
// Vue.prototype.$echarts = echarts // 引入组件（将echarts注册为全局）
// 导入路由
import router from './router'
//导入jQuery
import $ from 'jquery'

// import { socket } from './libs/WebSocketService';


//导入预览图片的方法
import VuePreview from 'vue-preview';
Vue.use(VuePreview);
// 设置axios到原型上 方便共享
import axios from 'axios'
Vue.prototype.$axios = axios;


// 设置axios的基地址
// axios.defaults.baseURL = 'XXXXX'; //上线版
// axios.defaults.baseURL = 'XXXXX'; //本地版
// axios.defaults.baseURL = 'http://119.23.236.11:8080'; //外销版基地址

//本地
//export const $url = 'http://localhost:8097/bjs';
//export const $url = 'http://47.121.140.121:8097/bjs';

//线上
export const $url = '/bjs';
// export const $url = `http://192.168.10.53:58097/bjs`
let ws = null
let socket = (e) => {
  if (!ws) {
    // let url = $url.split("//")[1];
    let url = window.location.hostname;
    ws = new WebSocket(
      `ws://${url}/bjs/ws/${sessionStorage.getItem("id")}`
    )
    ws.onclose = () => {
    }
  }
  if (e) {
    ws = null
  }
  return ws;
};

Vue.prototype.$socket = socket;
Vue.prototype.$url = $url;



axios.defaults.baseURL = $url;

Vue.config.productionTip = false

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  render: h => h(App),
  // 挂在到vue实例上
  router,
  store,
  socket,
  $,
  VuePreview
}).$mount('#app')
