import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import webSocket from './modules/webSocket'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  getters,
  modules: {
    common,
    webSocket
  }
})

export default store
