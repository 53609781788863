<template>
  <div class="rechargeRecord-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
        <el-form-item prop="searchType" label="经营属性">
          <el-select v-model="formLabelAlign.searchType" id="time" @change="holderSK" placeholder="默认自营" >
            <el-option label="自营" value="zy" ></el-option>
            <el-option label="加盟" value="jm" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="timeType" label="录入时间">
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="state" label="收款状态" v-if="ShowSK">
          <el-select v-model="formLabelAlign.state" id="time" placeholder="请选择收款状态" >
            <el-option label="全部" value="" ></el-option>
            <el-option label="未收款" value="未收款" ></el-option>
            <el-option label="已收款" value="已收款" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="BTN">
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check">查询</el-button>
        <el-button style="background-color:	#00CDCD;color:#fff" @click="dialogFormVisible = true"   class="check">新增充值</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <el-table   :data="tableData" style="width: 100%" >
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <!-- 自营显示部分 -->
      <el-table-column prop="sourceName1" label="渠道" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column prop="sourceName2" label="平台" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column prop="payType" label="支付方式" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column prop="upMoney" label="充值金额" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column prop="accountMoney" label="到账金额" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column prop="rebates" label="返点" v-if="ZiYingShowCheck" :key="Math.random()"></el-table-column>
      <el-table-column  label="充值时间" v-if="ZiYingShowCheck" :key="Math.random()">
        <template slot-scope="scope">{{ scope.row.entryTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="录入时间" v-if="ZiYingShowCheck" :key="Math.random()">
        <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template>
      </el-table-column>
      <!-- 加盟显示部分 -->
      <el-table-column prop="departmentName" label="分公司" v-if="!ZiYingShowCheck" :key="Math.random()" ></el-table-column>
      <el-table-column prop="payType" label="支付方式" v-if="!ZiYingShowCheck" :key="Math.random()" ></el-table-column>
      <el-table-column prop="upMoney" label="充值金额" v-if="!ZiYingShowCheck" :key="Math.random()" ></el-table-column>
      <el-table-column prop="skBank" label="收款银行" v-if="!ZiYingShowCheck" :key="Math.random()" ></el-table-column>
      <el-table-column prop="state" label="收款状态" v-if="!ZiYingShowCheck" :key="Math.random()" >
        <template slot-scope="scope">
            <span v-if="scope.row.state == '已收款' " style='color:#00A06B'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '未收款' " style='color:#D7962F'>{{ scope.row.state}}</span>
            <span v-else style='color:grey'>{{ scope.row.state}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="entryTime" label="充值日期" v-if="!ZiYingShowCheck" :key="Math.random()" >
        <template slot-scope="scope">{{ scope.row.entryTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="录入日期" v-if="!ZiYingShowCheck" :key="Math.random()" >
        <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <!-- 弹出框 -->
    <el-dialog title="新增充值" :visible.sync="dialogFormVisible">
      <el-form :model="chongZhiForm" label-position="right">
        <div>
        <el-radio-group v-model="chongZhiForm.typeZH" @change="ChongZhiStyle">
          <el-radio-button label="自营"></el-radio-button>
          <el-radio-button label="加盟"></el-radio-button>
        </el-radio-group>
      </div><br><br>
        <el-form-item label="平台" :label-width="formLabelWidth" v-show="ZiYingShow">
          <el-select v-model="chongZhiForm.sourceId" placeholder="请选择平台" >
            <el-option
              v-for="item in pingTaiList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 还没有对接口 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
        <el-form-item label="分公司" :label-width="formLabelWidth" v-show="!ZiYingShow">
          <el-select v-model="chongZhiForm.departmentId" placeholder="请选择分公司" >
            <el-option
              v-for="item in FenGongSiList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" :label-width="formLabelWidth" >
          <el-select v-model="chongZhiForm.payType" placeholder="请选择支付方式" >
            <el-option label="公账" value="公账"></el-option>
            <el-option label="私账" value="私账"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值金额" :label-width="formLabelWidth" >
          <el-input v-model="chongZhiForm.upMoney" autocomplete="off" type="number"><i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item label="到账金额" :label-width="formLabelWidth" v-show="ZiYingShow">
          <el-input v-model="chongZhiForm.accountMoney" autocomplete="off" type="number"><i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item label="返点" :label-width="formLabelWidth" v-show="ZiYingShow">
          <el-input v-model="chongZhiForm.rebates" autocomplete="off" disabled><span class="jine">{{countNum}} </span><i  slot="suffix">%</i></el-input>
        </el-form-item>
        <el-form-item label="充值日期" :label-width="formLabelWidth" >
          <div class="block" id="dateOfBirth">
            <el-date-picker
              v-model="chongZhiForm.entryTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="收款银行" v-show="!ZiYingShow">
          <el-select v-model="chongZhiForm.skBank" placeholder disabled>
            <el-option label="不限" value=""></el-option>
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bankName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addChongZhi">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "rechargeRecord",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentDepartmentId:"",
        currentPhone:""
      },
      pingTaiList: [],
      showTimeKind:true,
      ZiYingShowCheck:true,  //查询条件控制
      ZiYingShow:true,  //新增弹窗选项控制
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      kindtimeList:[
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
        ],
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
        ],
      //筛选区域数据
      formLabelAlign: {
        searchType : "zy", 	//	经营属性
        state : "", 	//	收款状态
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      dialogFormVisible: false,
      //新增弹窗数据
      chongZhiForm: {
        upMoney:0,  //	充值金额
        accountMoney:0,  //	到账金额
        sourceId:"", 	//平台id
        rebates:"", 	//	返点
        payType:"", 	//	支付方式
        entryTime:"", 	//录入时间
        jinJianBankId:"", 	//进件银行
        departmentId:"", 	//分公司
        skBank:"浦发银行", 	//收款银行名称
        typeZH:"自营", //充值类型中文
        type:'0'   // 充值类型
      },
      //收款银行动态生成内容
      bankList: [],
      //是否隐藏收款选择框
      ShowSK:false,
      //分公司List
      FenGongSiList: [],
      formLabelWidth: '180px',
      tableData: []
    };
  },
  methods: {
    //获得充值记录列表
    getCusList(){
      this.$axios
      .get(`/source/getRechargeLogList?state=${this.formLabelAlign.state}&searchType=${this.formLabelAlign.searchType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //获取平台的下拉列表
    getPingTaiList(){
        this.$axios
      .get(`/source/getSourceByType?type=2`)
      .then(res => {
        // console.log(res);
        this.pingTaiList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      });
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";
        }
        this.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId){
      let obj = {};
      obj = this.kindtimeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true
      }else{
        this.showTimeKind = false
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
        this.formLabelAlign.dayType  = "customize";
      }  else {
        this.isShow = false;
        this.value1 = "";
      }
    },
    //判断收款状态是否隐藏
    holderSK(val){
      if (val == "jm") {
        this.ShowSK = true;
      }else{
        this.ShowSK = false;
        this.formLabelAlign.state = ""
      }
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getCusList()
      }else{
        this.pageConfig.pageSize = val;
         this.getCusList()
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getCusList()
    },
    //获取充值类型
    ChongZhiStyle(val){
      // console.log(val);
      if (val == "自营") {
        this.ZiYingShow = true
      }else {
        this.ZiYingShow = false
      }
    },
    // eslint-disable-next-line no-dupe-keys,vue/no-dupe-keys
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getCusList()
    },
    //查询
    checkCus(){
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      //收款状态这个字段暂时没有加入搜索条件！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
      this.$axios
      .post(`/source/getRechargeLogList?state=${this.formLabelAlign.state}&searchType=${this.formLabelAlign.searchType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&pageSize=10&currentPage=1`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          if (this.formLabelAlign.searchType == "zy") {
            this.ZiYingShowCheck = true
          }else{
            this.ZiYingShowCheck = false
          }
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合搜索条件的记录');
             this.clearAllCheck();
             this.tableData = []
             this.pageConfig.total = 0;
             return false;
          }
          this.$message.success("根据您的搜索条件，已为您找到以下记录(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          // this.clearAllCheck();
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.clearAllCheck();
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //添加充值
    addChongZhi(){
      if (this.chongZhiForm.typeZH == "自营") {
        this.chongZhiForm.type = "0"
        if (this.chongZhiForm.sourceId == "") {
          this.$message.error("请选择平台");
          return false
        }else if (this.chongZhiForm.payType == "") {
          this.$message.error("请选择支付方式");
          return false
        }else if (this.chongZhiForm.upMoney == "") {
          this.$message.error("请输入充值金额");
          return false
        }else if (this.chongZhiForm.accountMoney == "") {
          this.$message.error("请输入到账金额");
          return false
        }
        else if (this.chongZhiForm.entryTime == "") {
          this.$message.error("请选择充值日期");
          return false
        }
      }else{
        //this.chongZhiForm.typeZH为加盟的情况
        this.chongZhiForm.type = "1"
        if (this.chongZhiForm.departmentId == "") {
          this.$message.error("请选择加盟分公司");
          return false
        }else if (this.chongZhiForm.payType == "") {
          this.$message.error("请选择支付方式");
          return false
        }else if (this.chongZhiForm.upMoney == "") {
          this.$message.error("请输入充值金额");
          return false
        }else if (this.chongZhiForm.entryTime == "") {
          this.$message.error("请选择充值日期");
          return false
        }
      }

      this.$axios
      .get(`/source/addRechargeLog?state=未收款&type=${this.chongZhiForm.type}&upMoney=${this.chongZhiForm.upMoney}&departmentId=${this.chongZhiForm.departmentId}&accountMoney=${this.chongZhiForm.accountMoney}&sourceId=${this.chongZhiForm.sourceId}&rebates=${this.chongZhiForm.rebates}&payType=${this.chongZhiForm.payType}&skBank=${this.chongZhiForm.skBank}&entryTime=${this.chongZhiForm.entryTime}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.$message({  message: res.data.messageModel.messageText,type: 'success'});
          this.dialogFormVisible = false
          this.getCusList()
          //还原弹窗值
          this.chongZhiForm.upMoney = 0
          this.chongZhiForm.accountMoney = 0
          this.chongZhiForm.sourceId = ""
          this.chongZhiForm.rebates = ""
          this.chongZhiForm.payType = ""
          this.chongZhiForm.entryTime = ""
          this.chongZhiForm.jinJianBankId = ""
          this.chongZhiForm.departmentId = ""
          this.chongZhiForm.skBank = "浦发银行"
          this.chongZhiForm.typeZH = "自营"
          this.chongZhiForm.type = '0'
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    //获取银行列表的请求
    getBankList(){
      this.$axios
      .get(`/bill/getBankList`)
      .then(res => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.bankList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //拿到整个组织架构并挑出分公司组织新的数组（包含数据类型是对象）
    //请求和渲染组织架构
    getAllFenGongSi(){
      this.$axios
        .get(`/department/getJMDepartment`)
        .then(res => {
          // console.log(res);
          this.FenGongSiList = res.data.dataModel
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YYYY-MM-DD");
    },
  },
  created () {
    //存放操作人信息
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentDepartmentId = window.sessionStorage.getItem("departmentId")
    this.getCusList()
    //请求平台可选
    this.getPingTaiList()
    //进件银行可选
    this.getBankList()
    //分公司列表
    this.getAllFenGongSi()
  },
  computed: {
    //计算返点
    countNum:function(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.chongZhiForm.rebates = Math.round(((Number(this.chongZhiForm.accountMoney) - Number(this.chongZhiForm.upMoney))/Number(this.chongZhiForm.upMoney))*10000)/100
      return  Number(this.chongZhiForm.rebates)
      }
  }
};
</script>

<style  lang='scss'>
.rechargeRecord-container {
  .el-dialog {
    #dateOfBirth {
        /deep/.el-input__inner {
            padding-left: 30px!important;
        }
    }
    .el-form-item {
      // margin-top: 0;
      /deep/.el-form-item__label {
        width: 150px!important;
      }
      /deep/.el-form-item__content {
        margin-left: 150px!important;
      }
    }
    .el-dialog__body {
      padding: 0 20px 30px;
    }

  }
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}

//突出隔行的颜色差异
.el-table {
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important;
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
