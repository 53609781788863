import router from '../../router'
const common = {
  state: {
    editableTabs: [],
    zfpCount: "",
    yslCount: "",
    title:"",
  },

  mutations: {

    Add_Tab: (state, route) => {
      // console.log(route); //例如{name: "客户列表", path: "/customerList"}
      const whitelist =['/login']  
      if(whitelist.includes(route.path)) return
      const flag = state.editableTabs.findIndex(val => val.path === route.path)
      // if (route.path == "/myCusEdit") {
      //   state.editableTabs.push(route)
      // }
      // 不存在则插入
      if (flag === -1) {
        state.editableTabs.push(route)
      }
    },
    Remove_Tab: (state, path) => {
      const newTabs = state.editableTabs.filter(val => val.path !== path)
      if (!newTabs.length) {
      router.push('./')
      } else {
      router.push(newTabs[newTabs.length - 1].path)
      }
      state.editableTabs = newTabs
    },
    clearEditableTabs(state, val){
      state.editableTabs = []
    },
    getUnread(state,value){
      // console.log(value);
      if (value.zfpCount > 0) {
        state.zfpCount = "(" + value.zfpCount.toString() + ")"
      }else{
        state.zfpCount = ""
      }
      if (value.yslCount > 0) {
        state.yslCount = "(" +  value.yslCount.toString()  + ")"
      }else{
        state.yslCount = ""
      }
    },
    add(state){
      state.zfpCount++
    }
  },

  actions: {
    GetTabList ({ commit }, res) {
      commit('SET_TabList', res)
    }
  }
}

export default common;
