<template>
  <div class="examineAdd-container">
    <!-- 人事信息 -->
    <el-row class="filterCondition upOne">
      <span class="cusAddTit">添加审件</span>
    </el-row>
    <!-- 档案信息 -->
    <el-row class="filterCondition downOne">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item class="dangan" label="客户ID" >
          <el-input type="number" v-model="formLabelAlign.customerId" placeholder="请输入客户ID"></el-input>
        </el-form-item>
        <el-button type="danger" id="addnote" @click="checkCus">查询</el-button><span style="margin-left:10px">客户姓名：</span><span class="redSpan">{{this.formLabelAlign.name}}；</span><span>贷款顾问：</span><span class="redSpan">{{this.formLabelAlign.guwen}}；</span>
        <!-- <el-form-item class="dangan" label="客户姓名">
          <el-input v-model="formLabelAlign.name" placeholder="" disabled></el-input>
        </el-form-item> -->
        <br>
        <el-form-item class="dangan" label="备注记录">
          <div id="noteList">
          <ul>
        <li v-for="(item,index) in formLabelAlign.noteList" :key="index">
          {{++index}},{{ item.userName}},{{ item.createDate | formatTime}},{{ item.content}}
        </li>
          </ul>
          </div>
        </el-form-item><br>
        <el-form-item class="dangan" label="进件日期">
          <div class="block" >
            <el-date-picker
            class="examineTine"
              v-model="formLabelAlign.jinJianTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="费率">
          <el-input type="number" v-model="formLabelAlign.rate" placeholder="" class="yuan"><i  slot="suffix">%</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="合同号">
          <el-input type="number" v-model="formLabelAlign.contractNo" placeholder="" ></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="进件银行">
          <el-select v-model="formLabelAlign.jinJianBankId" placeholder>
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bankName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进件额度">
          <el-input type="number" v-model="formLabelAlign.jinJianRate" placeholder="" class="yuan"><i  slot="suffix" >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan " id="backManger" label="银行经理">
          <el-input v-model="formLabelAlign.bankUser" placeholder=""></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="诚意金">
          <el-input v-model="formLabelAlign.depositMoney" type="number" placeholder="" class="yuan">  <i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="单件类型">
          <el-select v-model="formLabelAlign.danJianType" placeholder>
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="信用贷" value="信用贷"></el-option>
            <el-option label="车抵贷" value="车抵贷"></el-option>
            <el-option label="房抵贷" value="房抵贷"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进度" >
          <el-select v-model="formLabelAlign.state" @change="getjindu" disabled placeholder="审核">
            <el-option
              v-for="item in jinduList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="放款额度" v-show="jiekuanShow">
          <el-input type="number" v-model="formLabelAlign.fangKuanRate" placeholder="" class="yuan">  <i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="放款日期" v-show="jiekuanShow">
          <div class="block" >
            <el-date-picker
            class="examineTine"
              v-model="formLabelAlign.fangKuanTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="收款方式" v-show="weiyueShow">
          <el-select v-model="formLabelAlign.thePayee" placeholder>
            <el-option label="POS" value="POS"></el-option>
            <el-option label="转账" value="转账"></el-option>
            <el-option label="现金" value="现金"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="收款日期" v-show="weiyueShow">
          <div class="block" >
            <el-date-picker
            class="examineTine"
              v-model="formLabelAlign.thePayeeTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="渠道费"  v-show="weiyueShow">
          <el-input type="number" v-model="formLabelAlign.channelMoney" placeholder="" class="yuan">  <i slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="收款额度"  v-show="jiekuanShow">
          <el-input type="number" v-model="formLabelAlign.thePayeeRate" placeholder="" class="yuan">  <i slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="收款银行" v-show="weiyueShow">
          <el-input  v-model="formLabelAlign.shouKuangBank" placeholder="" class="yuan"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="进件助理" v-show="weiyueShow">
          <el-select v-model="formLabelAlign.assistantId" placeholder>
            <el-option label="无" value= null></el-option>
            <el-option
              v-for="item in assistantList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <br/>
        <el-form-item class="jingshouru" label="净收入" v-show="ggShow">
          <span class="jine">{{countNum}} </span><span> 元 </span> <span class="gongshi">(净收入 = 诚意金 + 收款额度 - 渠道费)</span>
        </el-form-item><br>
        <el-form-item class="dangan" id="describe" label="新增备注">
          <el-input
            id="nianxian"
            type="textarea"
            :rows="5"
            placeholder="请输入备注内容"
            v-model="formLabelAlign.content"
          ></el-input>
        </el-form-item>

        <el-button type="primary" @click="submit" id="submit">提交审件</el-button>
      </el-form>
    </el-row>
  </div>
</template>

<script>
// 导入时间戳转换方法
import moment from 'moment'
export default {
  name: "examineAdd",
  // 数据
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        departmentName:""
      },
      //收款银行动态生成内容
      bankList: [{ id: 0, name: "中国银行" }],
      //判断选择框是否显示
      jiekuanShow:false,
      weiyueShow:false,
      ggShow:false,
      user: "", //表单要提交的参数
      //备注内容
      textarea: "",
      //备忘
      beiwang: "",
      //备忘时间
      beiwangdate: "",
      //助理列表
      assistantList: [],
      //进度动态生成内容
      jinduList: [
        { id: "审核", name: "审核" },
        { id: "拒批", name: "拒批" },
        { id: "收款", name: "收款" },
        { id: "违约", name: "违约" },
        ],
      //messageText为首次加载时的返回状态
      messageText: "",
      //客户筛选区域数据
      formLabelAlign: {
        jingShouRu:0,
        jiSuanjingShouRu:0,
        thePayeeRate:0,
        depositMoney:0,
        channelMoney:0,
        suoSGuWenid:0,
        hasSure:"",
        state:"审核"
      },
    };
  },
  methods: {
    //查询客户
    checkCus(){
      this.$axios
        .get(`/bill/getCustomerById?customerId=${this.formLabelAlign.customerId}`)
        .then(res => {
            if (res.data.messageModel.messageText == "成功!") {
              this.formLabelAlign.name = res.data.dataModel.name
              this.formLabelAlign.guwen = res.data.dataModel.user.name
              this.formLabelAlign.customerId = res.data.dataModel.id
              this.formLabelAlign.departmentName = res.data.dataModel.user.departmentName
              this.formLabelAlign.suoSGuWenid = res.data.dataModel.user.id
            }
            })
        .catch(error => {
                console.log(error);
            });
      this.checkCusNote()
    },
    //查询客户备注
    checkCusNote(){
      this.$axios
            .get(`/bill/getThreeNotes?customerId=${this.formLabelAlign.customerId}`)
            .then(res => {
                if (res.data.messageModel.messageText == "成功!") {
                  this.formLabelAlign.noteList = res.data.dataModel
                }
                })
            .catch(error => {
                console.log(error);
            });
      },
    //提交新增件
    submit() {
      if (this.formLabelAlign.name == "") {
        this.$message.error("请先点击查询，确认该客户真实存在");
        return false
      }

      if ( this.formLabelAlign.jinJianRate === undefined || this.formLabelAlign.jinJianRate <= 1000) {
        this.$message.error("进件额度不少于1000元，请检查");
        return false;
      }
      if (this.formLabelAlign.name != "") {
        this.formLabelAlign.fangKuanTime = moment(this.formLabelAlign.fangKuanTime).format("YYYY-MM-DD  HH:mm:ss")
        this.formLabelAlign.jinJianTime = moment(this.formLabelAlign.jinJianTime).format("YYYY-MM-DD  HH:mm:ss")
        this.formLabelAlign.thePayeeTime = moment(this.formLabelAlign.thePayeeTime).format("YYYY-MM-DD  HH:mm:ss")
      }
      // if (this.formLabelAlign.state == "收款" || this.formLabelAlign.state == "违约") {
      //   this.formLabelAlign.jingShouRu = this.formLabelAlign.jiSuanjingShouRu
      // }else{
      //   this.formLabelAlign.jingShouRu = 0
      // }
      // this.formLabelAlign.state == "审核"
        this.$axios
          .post(`/bill/addBill?userId=${this.formLabelAlign.suoSGuWenid}&dName=${this.currentUser.departmentName}`,toQueryString(this.formLabelAlign), {headers:{'Content-type':'application/x-www-form-urlencoded'}})
          .then(res => {
              // console.log(res);
              if (res.data.messageModel.code == 0){
                this.$message({  message: '恭喜你，添加成功',type: 'success'});
                this.checkCus()
                this.checkCusNote()
              }else if (res.data.messageModel.code == 104) {
                this.$message.error("已有相似记录，是否确认添加");
                this.$confirm('已有相似记录，是否确认添加?', '提示', {  confirmButtonText: '确定',  cancelButtonText: '取消',  type: 'warning'  }).then(() => {
                  this.formLabelAlign.hasSure = true
                  this.$axios
                  .post(`/bill/addBill?userId=${this.formLabelAlign.suoSGuWenid}`,toQueryString(this.formLabelAlign), {headers:{'Content-type':'application/x-www-form-urlencoded'}})
                  .then(res => {
                      // console.log(res);
                      if (res.data.messageModel.code == 0){
                        this.$message({  message: '恭喜你，添加成功',type: 'success'});
                        this.checkCus()
                        this.checkCusNote() }
                })
          .catch(error => {
            console.log(error);
          });
                  this.$message({  type: 'success',  message: '取消成功!'  });
                }).catch(() => {  this.$message({  type: 'info',  message: '已取消重复添加'  });
                });
              }
              else{
                  this.$message.error(res.data.messageModel.messageText);
                  // this.$router.go(0);
              }
              })
          .catch(error => {
              console.log(error);
          });
    },
    //获取进度 控制隐藏和现实
    getjindu(vId) {
      let obj = {};
      obj = this.jinduList.find(item => {
        //这里的erjiselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      if (obj.name == "收款") {
        this.jiekuanShow = true;
        this.weiyueShow = true;
        this.ggShow = true;
      }else if(obj.name == "违约"){
        this.weiyueShow = true;
        this.ggShow = true;
        this.jiekuanShow = false;
      }else {
        this.jiekuanShow = false;
        this.weiyueShow = false;
        this.ggShow = false;
      }

    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null || value == "") {
        return ""
      }
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.departmentName = window.sessionStorage.getItem("departmentName")
    //获取银行列表的请求
    this.$axios
      .get(`/bill/getBankList`)
      .then(res => {
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.bankList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
    //获取助理列表的请求
    this.$axios
      .get(`/bill/getAssistantList?userId=${this.currentUser.currentid}`)
      .then(res => {
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.assistantList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
  },
  computed: {
    //计算净收入
    countNum:function(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formLabelAlign.jiSuanjingShouRu = Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)
      return Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)
      }
  }
};
</script>

<style  lang='scss'>
.examineAdd-container {
  .el-input {
    width: auto;
  }
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      line-height: 20px;
      width: 100%;
      color: 	#708090;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
    //labal文字居左
  }
  .el-form-item__label {
    display: flex;
  }
  .el-button--danger {
    line-height: 6px;
  }
  // 时间分类框样式
  .examineAdd-container input.el-input__inner#againkinds,
  .examineAdd-container input.el-input__inner#againtime {
    width: 85px;
    padding-left: 7px;
  }

  // 日期选择器
  // daterange.el-input,
  // .el-date-editor--daterange.el-input__inner,
  // .el-date-editor--timerange.el-input,
  // .el-date-editor--timerange.el-input__inner {
  //   width: 240px;
  //   height: 36px;
  //   vertical-align: middle;
  //   .el-date-editor .el-range__icon {
  //     line-height: 27px;
  //   }
  //   .el-date-editor .el-range-separator {
  //     width: 5%;
  //   }
  // }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
    width: 300px;
  }
  .el-form-item.jingshouru {
    width: 100%;
    .jine {
      color: #C73E3A;
    }
    .gongshi {
      color:#666666;
    }
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
    #submit {
      margin-left: 20px;
    }
    .cusAddTit {
      display: inline-block;
      font-size: 17px;
      color: #c73e3a;
      margin-left: 20px;
      line-height: 66px;
    }
  }
  .filterCondition.upOne {
    border-bottom: 1px solid #f3f3f3;
    margin: 0;
  }
  .filterCondition.downOne {
      padding-top: 30px;
      padding-left: 20px;
      .redSpan {
        color: #c73e3a;
      }
      //文本域
      .el-textarea {
          width: 430px;
      }
      #momecontent {
        /deep/.el-form-item__content {
          margin-left: 30px !important;
          .el-input__inner {
            width: 485px;
          }
        }
      }
      #noteList {
      width: 700px;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      border: 1px solid #DCDFE6;
      background-color: #F5F7FA;
    }
    // #backManger {
    //   /deep/.el-form-item__label {
    //     width: 96px !important;
    //   }
    // }
    #submit {
      margin-left: 230px;
    }
    .examineTine {
      input{
         padding-left: 27px;
      }
    }
  }
  .zaifenCheck {
    margin-left: 20px;
  }
  .zaifenBth.el-button {
    padding: 10px 20px;
  }
  #dateOfBirth {
    width: 170px;
    input.el-input__inner {
      width: 170px;
      height: 32px;
      padding-left: 30px;
    }
  }
  .redistribution-container input.el-input__inner {
    width: 182px;
    height: 32px;
    padding-left: 10px;
  }
  #name .el-form-item__label {
    width: 43px;
  }
  .benhangye {
    display: inline-block;
    vertical-align: top;
    /deep/ .el-form-item__content {
      margin-left: 10px !important;
    }
    #hangyejingyan {
      display: flex;
      margin: 0;

      .el-form-item__label {
        width: 115px;
      }
    }
  }
  // 从业年限
  .nianxian {
    margin-left: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 182px;
  }
  //图片区域
  .picArea {
    //图片展示框的大小
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .picChild {
      float: left;
      width: 25%;
      display: flex;
      margin-top: 20px;
      span {
        width: 20%;
        font-size: 14px;
        color: #606266;
        margin-right: 20px;
        margin-left: 20px;
      }
      .avatar {
        width: 268px;
        height: 162px;
      }
      i {
        width: 268px;
        height: 162px;
      }
    }
  }
}
</style>
