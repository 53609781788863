<template>
    <el-container class="popularizeAnalysis-container">
        <div class="left">
            <!-- Ecahrts -->
            <el-row class="filterCondition">
                <!-- <h3>推广数据统计分部图</h3> -->
                <div id="main" ref="chart" style="width: 100%;height: 400px;"></div>
            </el-row>
            <!-- 表格 -->
            <el-table :data="tableDataA"  border style="width: 100%">
                <el-table-column align="center"  label="今日推广数据">
                <el-table-column prop="date" label="数据量" ></el-table-column>
                <el-table-column prop="name" label="上海自营" ></el-table-column>
                <el-table-column prop="address" label="上海加盟"></el-table-column>
                <el-table-column prop="address1" label="南京加盟"></el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <div class="right">
            <!-- 表格 -->
            <el-table :data="tableDataB"  border style="width: 100%">
                <el-table-column align="center"  label="今日推广数据">
                <el-table-column prop="date" label="日期" ></el-table-column>
                <el-table-column prop="name" label="上海自营" ></el-table-column>
                <el-table-column prop="address" label="上海加盟"></el-table-column>
                <el-table-column prop="address1" label="南京加盟"></el-table-column>
                </el-table-column>
            </el-table>
        </div>
    </el-container>
</template>

<script>
import echarts from 'echarts'
export default {
    name: "index",
    data () {
        return {
            tableDataA: [{date: '数据接收上限',name: '800',address: '800',address1: '800'}, 
            {date: '今日推广量',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'
            }, {date: '昨日推广量',name: '30',address: '30',address1: '30'
            }],
            tableDataB: [{date: '2019-10-30',name: '800',address: '800',address1: '800'}, 
            {date: '2019-10-29',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-28',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-27',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-26',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-25',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-24',name: '530（已受理500）',address: '530（已受理500）',address1: '530（已受理500）'}, 
            {date: '2019-10-23',name: '30',address: '30',address1: '30'
            }],
        }
    },
    methods: {
        //Echats部分
    drawPie(id){
        this.charts = echarts.init(document.getElementById(id))
        this.charts.clear()
        this.charts.setOption({
        title: {
        //   text: '推广数据统计分部图'
        },
        tooltip : {
            trigger: 'axis',
            axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        legend: {
            data:['接收数据上限','昨日待分配','今日推广量','已受理']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis : [
            {
                type : 'category',
                data : ['上海自营','上海加盟','南京加盟']
            }
        ],
        yAxis : [
            {
                type : 'value'
            }
        ],
        series : [
        {
            name:'接收数据上限',
            type:'bar',
            data:[320, 332, 301]
        },
        {
            name:'昨日待分配',
            type:'bar',
            stack: '分推数据',
            data:[120, 132, 101]
        },
        {
            name:'今日推广量',
            type:'bar',
            stack: '分推数据',
            data:[220, 182, 191]
        },
        {
            name:'已受理',
            type:'bar',
            data:[862, 1018, 964],
            // markLine : {
            //     lineStyle: {
            //         normal: {
            //             type: 'dashed'
            //         }
            //     },
            //     data : [
            //         [{type : 'min'}, {type : 'max'}]
            //     ]
            // }
        }]  
        })
    }
    },
mounted(){
    this.drawPie('main')
},
created () {
    this.drawPie('main')
}
}
</script>

<style lang='scss'>
    .popularizeAnalysis-container{
        .left{
            height: 500px;
            width: 50%;
            margin: 10px;
            // background-color: rgb(121, 73, 73);
        }
        .right{
            height: 500px;
            width: 50%;
            margin: 10px;
            // background-color: rgb(65, 138, 156);
        }
    }
</style>