<template>
  <div class="login-container">
    <div class="center-box">
      <!--      <h2 class="my-h3" v-show="this.isShow">破峰-业务系统登录</h2>-->
      <h2 class="my-h3" v-show="this.isShow">CRM-业务系统登录</h2>
      <!--      <h2 class="my-h3" v-show="this.isShow">佑米云-业务系统登录</h2>-->
      <h2 class="my-h3" v-show="!this.isShow">后勤系统登录</h2>
      <el-form
        :model="loginForm"
        ref="loginForm"
        label-position="top"
        label-width="100px"
      >
        <el-form-item v-show="this.isShow" prop="phone" label="用户手机号">
          <el-input
            v-model.number="loginForm.phone"
            id="phone"
            @keyup.13="submitForm"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="!this.isShow" prop="userName" label="用户名">
          <el-input
            v-model.number="loginForm.userName"
            id="phone"
            @keyup.13="submitForm"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="!this.isShow" prop="moblie" label="所属手机号">
          <el-input
            v-model.number="loginForm.moblie"
            id="phone"
            @keyup.13="submitForm"
            placeholder="输入用户名点击获取验证码即可得到所属手机号"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item v-show="this.isShow" prop="code" label="手机验证码">
          <el-input v-model="loginForm.code"></el-input>
          <el-button
            type="danger"
            class="getmodBtn"
            id="getCode"
            @click="getCode()"
            :disabled="!login.canGet"
          >
            <span v-show="!login.canGet">{{
              login.waitTime + "s后重新获取"
            }}</span>
            <span v-show="login.canGet">获取手机验证码</span>
          </el-button>
        </el-form-item>
        <el-form-item
          v-show="!this.isShow"
          prop="CodeByName"
          label="用户名验证码"
        >
          <el-input v-model="loginForm.CodeByName"></el-input>
          <el-button
            type="danger"
            class="getmodBtn"
            id="getCode"
            @click="getCodeByName()"
            :disabled="!login.canGet"
          >
            <span v-show="!login.canGet">{{
              login.waitTime + "s后重新获取"
            }}</span>
            <span v-show="login.canGet">获取用户验证码</span>
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            class="cuozuoBth"
            type="primary"
            @click="submitForm('loginForm')"
            >登录</el-button
          >
          <el-button
            class="cuozuoBth"
            type="info"
            @click="resetForm('loginForm')"
            >重置</el-button
          >
        </el-form-item>
        <el-tooltip
          class="item"
          effect="dark"
          content="适用于财务、人事等后勤人员使用"
          placement="left"
        >
          <a style="font-size: 12px" href="javascript:void(0)" @click="UserShow"
            >后勤系统登录</a
          >
        </el-tooltip>
        &nbsp;&nbsp;
        <el-tooltip
          class="item"
          effect="dark"
          content="适用于运营、及各级业务负责人、顾问使用"
          placement="right"
        >
          <a
            style="font-size: 12px"
            href="javascript:void(0)"
            @click="YanZhengShow"
            >业务系统登录</a
          >
        </el-tooltip>
      </el-form>
    </div>
  </div>
</template>

<script>
function timeCountdown(obj) {
  const TIME_COUNT = 60; //倒计时秒数
  if (!obj.timer) {
    obj.waitTime = TIME_COUNT;
    obj.canGet = false;
    obj.timer = setInterval(() => {
      if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
        obj.waitTime--;
      } else {
        obj.canGet = true;
        clearInterval(obj.timer); //清空定时器
        obj.timer = null;
      }
    }, 1000);
  }
  return {
    timer: obj.timer,
    canGet: obj.canGet,
    waitTime: obj.waitTime,
  };
}
// import qs from "qs";
import { ifError } from "assert";
export default {
  name: "login",
  data() {
    return {
      key: "",
      // 手机验证码倒计时
      tempLogin: {
        //定义一个临时对象
        canGet: true,
        timer: null,
        waitTime: 60,
      },
      isShow: true,
      // 表单数据
      loginForm: {
        phone: "",
        moblie: "", //通过用户名获取的手机号
        code: "",
        userName: "",
        CodeByName: "",
        passWord: "",
      },
    };
  },
  // 方法
  methods: {
    // 提交表单
    submitForm() {
      if (this.isShow) {
        // 简单判断电话号码和验证码是否为空
        if (this.loginForm.phone == "") {
          this.$message.error("您忘记填写电话号码了");
          return false;
        }
        // else if (this.loginForm.phone == 13592323065) {
        //   this.$axios  .get(  `/login/login?phone=${this.loginForm.phone}`)
        //   .then(res => {
        //     // console.log(res);
        //     this.$message.success("登陆成功！^_^");
        //     for(let key  in res.data){
        //       this.key = key
        //       window.sessionStorage.setItem(`${this.key}`, res.data[key]);
        //     }
        //     // 编程式导航
        //     this.$router.push("myCustomer");
        //   })
        // }
        else if (this.loginForm.code == "") {
          this.$message.error("请您填写验证码后点击登陆");
          return false;
        } else {
          this.$axios
            .get(
              `/login/smsQuery?phone=${this.loginForm.phone}&code=${this.loginForm.code}`
            )
            .then((res) => {
              // console.log(res);
              if (res.data.messageModel.code == 0) {
                localStorage.setItem("ws", null);
                this.$message.success("登陆成功！^_^");
                for (let key in res.data.dataModel) {
                  this.key = key;
                  window.sessionStorage.setItem(
                    `${this.key}`,
                    res.data.dataModel[key]
                  );
                }
                // 编程式导航
                this.$router.push("myCustomer");
              } else {
                this.$message.error(res.data.messageModel.messageText);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.loginForm.userName == "") {
          this.$message.error("用户名不能为空");
          return false;
        } else if (this.loginForm.moblie == "") {
          this.$message.error("请先通过用户名获取验证后登陆");
          return false;
        } else if (this.loginForm.CodeByName == "") {
          this.$message.error("验证码不能为空");
          return false;
        } else {
          this.$axios
            .get(
              `/login/smsQuery?phone=${this.loginForm.moblie}&code=${this.loginForm.CodeByName}&nickName=${this.loginForm.userName}`
            )
            .then((res) => {
              // console.log(res);
              if (res.data.messageModel.code == 0) {
                localStorage.setItem("ws", null);
                this.$message.success("登陆成功！^_^");
                for (let key in res.data.dataModel) {
                  this.key = key;
                  window.sessionStorage.setItem(
                    `${this.key}`,
                    res.data.dataModel[key]
                  );
                }
                // 编程式导航
                this.$router.push("myCustomer");
              } else {
                this.$message.error(res.data.messageModel.messageText);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //验证码
    getCode() {
      //判断手机是否为空
      if (this.loginForm.phone == "") {
        this.$message.error("登陆手机号码不可为空");
        return false;
      }
      //验证手机号是否正确
      this.$axios
        .get(`/user/getUserByPhone?phone=${this.loginForm.phone}`)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            //发送验证码
            this.$axios
              .get(`/login/smsSend?phone=${this.loginForm.phone}`)
              .then((res) => {
                // console.log(res);
                if (res.data.messageModel.code != 0) {
                  this.$message.error(res.data.messageModel.messageText);
                }
              })
              .catch((error) => {
                /*console.log(error);*/
              });
            //倒计时开始
            timeCountdown(this.login);
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //通过用户名获取验证码
    getCodeByName() {
      //判断手机是否为空
      if (this.loginForm.userName == "") {
        this.$message.error("用户名不可为空");
        return false;
      }
      //验证手机号是否正确
      this.$axios
        .get(`/login/selNickName?nickName=${this.loginForm.userName}`)
        .then((res) => {
          if (res.data.messageModel.code == 0) {
            this.$message.success(
              "已发送验证码至--" + res.data.dataModel + ", 请注意查收。"
            );
            this.loginForm.moblie = res.data.dataModel;
            //发送验证码
            this.$axios
              .get(`/login/smsSend?phone=${this.loginForm.moblie}`)
              .then((res) => {
                // console.log(res);
              })
              .catch((error) => {
                /*console.log(error)*/
              });
            //倒计时开始
            timeCountdown(this.login);
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //键盘事件
    keyupEnter() {
      document.onkeydown = (e) => {
        let body = document.getElementsByTagName("body")[0];
        if (
          e.keyCode === 13 &&
          e.target.baseURI.match(/login/) &&
          e.target === body
        ) {
          this.submitForm();
        }
      };
    },
    UserShow() {
      this.isShow = false;
      this.loginForm.phone = "";
      this.loginForm.code = "";
    },
    YanZhengShow() {
      this.isShow = true;
      this.loginForm.userName = "";
      // this.loginForm.passWord = "";
      this.loginForm.code = "";
    },
  },
  computed: {
    //手机验证码按键倒计时
    login() {
      //最终对象
      if (!this.tempLogin.canGet) {
        return timeCountdown(this.tempLogin);
      } else {
        return this.tempLogin;
      }
    },
  },
  created() {
    this.keyupEnter();
    // this.setting_Height()
    let component = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key === 13) {
        component.submitForm();
      }
    };
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  .login-container {
    height: 100%;
    background-color: #324152;
    display: flex;
    align-items: center;
    justify-content: center;
    .center-box {
      width: 580px;
      // height: 410px;
      background: white;
      border-radius: 10px;
      padding: 35px 35px;
      box-sizing: border-box;
      .my-h3 {
        margin: 0;
        padding: 0;
        text-align: center;
      }
      form {
        margin-top: 50px;
        .el-form-item {
          display: flex;
        }
        .el-form-item__content {
          display: flex;
          .cuozuoBth {
            width: 232px;
            margin-top: 30px;
          }
        }

        .el-form-item__label {
          width: 110px;
        }
        .el-input {
          flex: 1;
        }
        #yanzhengma {
          width: 200px;
        }
        #phone {
          width: 362px;
        }
      }
      #getCode {
        padding: 6px 20px;
        height: 40px;
        margin-left: 20px;
      }
    }
  }
}
</style>
