<template>
  <div class="SourceStatistics-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
          <!-- <span class="xinZeng">新增平台：</span> -->
        <el-form-item label="渠道">
          <el-select v-model="formLabelAlign.laiYuan" placeholder="渠道">
            <el-option
              v-for="item in laiYuanList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台名称">
          <el-input v-model="formLabelAlign.pingtaiName" ></el-input>
        </el-form-item>
        <el-button style="background-color:#00CDCD;color:#fff" @click="addPingTai" class="check" :disabled="disTBN">新增平台</el-button>
        <el-button style="background-color:#E4A715;color:#fff" @click="delPingTai" class="check" :disabled="disTBN">删除平台</el-button>
        <!-- <el-button style="background-color:#49AEEF;color:#fff" @click="addLaiYuan" class="check" :disabled="disTBN">新增来源</el-button> -->
        <el-button style="background-color:#49AEEF;color:#fff" @click="centerDialogVisible = true" class="check" :disabled="disTBN">新增来源</el-button>
        <el-button style="background-color:#C73E3A;color:#fff" @click="ShowPingTai" class="check" v-show="IsShowPL" :disabled="disTBN">显示平台</el-button>
        <el-button style="background-color:#008C5E;color:#fff" @click="Showlaiyuan" class="check" v-show="!IsShowPL" :disabled="disTBN">显示来源</el-button><br>
      </el-form>
    </el-row>
    <el-table ref="tableData" tooltip-effect="dark" style="width: 100%"  :data="tableData"  >
      <!-- <el-table-column type="index" label="排名" align="center" width="55" ></el-table-column> -->
      <el-table-column prop="id"  label="ID"  width="60"></el-table-column>
      <el-table-column prop="sourceName1" label="渠道" width="120"></el-table-column>
      <el-table-column prop="sourceName2" label="平台" width="150"></el-table-column>
      <el-table-column prop="name" label="来源" width="150"></el-table-column>
      <el-table-column prop="type" label="来源英文名" ></el-table-column>
      <el-table-column  fixed="right"  label="操作"  width="120"><template slot-scope="scope"><el-button  @click.native.prevent="deleteRow(scope.$index, tableData)"  type="text"  size="small">删除</el-button>  </template></el-table-column>  
    </el-table>
    
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[20 ,50]"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <el-dialog
      title="新增来源"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <el-form>
        <el-form-item class="tanchuang" label="平台">
          <el-select v-model="formLabelAlign.pingTaiId" placeholder="平台">
            <el-option  v-for="item in pingTaiList"  :key="item.id"  :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="tanchuang" label="来源中文名">
          <el-input v-model="formLabelAlign.chineseName" ></el-input>
        </el-form-item><br><br>
        <el-switch
          style="display: block;magin-left:10px"
          v-model="ZiYing"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="打开自营供量"
          inactive-text="关闭自营供量"
          disabled>
        </el-switch><br>
        <!-- <el-switch
          style="display: block"
          v-model="feiZiYing"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="打开非自营供量"
          inactive-text="关闭非自营供量">
        </el-switch> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addLaiYuan">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { setTimeout } from 'timers';
export default {
  name: "SourceStatistics",
  // 数据
  data() {
    return {
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:20,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      zongjiList:{
          chuangShou:"",
          junChuang:"",
          todayZengChuang:"",
          huanBi:"",
          riBiYueShang:"",
          riBiYueJin:"",
          riBiYueShou:""
      },
      //开关
      ZiYing:false,
      feiZiYing:false,
      centerDialogVisible:false,
      //来源新增区域数据
      formLabelAlign: {
        laiYuan : "", 	//关键字
        pingtaiName : "", 	//	星级
        scopeOf : "", 	//	状态
        EnglishName : "", 	//备注内容
        chineseName : "",	//备注条数
        pingTaiId : ""	//平台
      },
      laiYuanList: [],
      pingTaiList: {},
      hasValue: "",
      disTBN:false,
      IsShowPL:true,
      // 表格依赖于数据没有数据  会一行都没有
      tableData: []
    };
  },
  methods: {
    //获取渠道的下拉列表
    getQudaoList(){
        this.$axios
      .get(`/source/getSourceByType?type=1`)
      .then(res => {
        this.laiYuanList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      }); 
    },
    //获取平台的下拉列表
    getPingTaiList(){
        this.$axios
      .get(`/source/getSourceByType?type=2`)
      .then(res => {
        // console.log(res);
        this.pingTaiList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      });
    },
    //获取来源
    getSourceList(){
      this.$axios
      .get(`/source/getSourceList?pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}&hasValue=${this.hasValue}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
        }else{
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = "";
            this.pageConfig.total = 0
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getSourceList()
      }else{
        this.pageConfig.pageSize = val;
        this.getSourceList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getSourceList()
      // }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //删除
    deleteRow(index, rows) {
        this.$confirm('此操作将永久删除该来源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.$axios.get(`/source/delById?id=${rows[index].id}`).then(res => {
            // console.log(res);
            if (res.data.messageModel.code == 0) {
                this.pageConfig.currentPage = 1
                this.pageConfig.pageSize = 20
                this.getSourceList()
            }else{
                this.$message.error(res.data.messageModel.messageText);
            }
      }).catch(error => {
        console.log(error);
      });
          this.$message({    type: 'success',    message: '删除成功!'  });
        }).catch(() => {
          this.$message({    type: 'info',    message: '已取消删除'  });          
        });
        
      },
    //添加平台
    addPingTai(){
        this.disButton()
        // console.log(this.formLabelAlign.laiYuan);
        if (this.formLabelAlign.laiYuan == "") {
            this.$message.error("渠道不可以为空，请检查");
            return false
        }
        if (this.formLabelAlign.pingtaiName == "") {
            this.$message.error("请填写要添加的平台名称");
            return false
        }
      this.$axios
        .get(`/source/addOrDelSource?addType=pingtai&name=${this.formLabelAlign.pingtaiName}&parentId=${this.formLabelAlign.laiYuan}`)
        .then(res => {
        //   console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({  message: '添加成功',type: 'success'});
            this.pageConfig.pageSize =  20
            this.pageConfig.currentPage =  1
            // this.getSourceList()
            this.getPingTaiList()
            this.getQudaoList()
          }else{
            this.$message.error(res.data.messageModel.messageText);
            // this.tableData = null
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //删除平台
    delPingTai(){
        if (this.formLabelAlign.laiYuan == "") {
            this.$message.error("渠道不可以为空，请检查");
            return false
        }
        if (this.formLabelAlign.pingtaiName == "") {
            this.$message.error("请填写要删除的平台名称");
            return false
        }
        this.$confirm('此操作将永久删除该平台, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.get(`/source/addOrDelSource?addType=del&name=${this.formLabelAlign.pingtaiName}&parentId=${this.formLabelAlign.laiYuan}`)
          .then(res => {
          //   console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({  message: "删除成功",type: 'success'});
            this.pageConfig.pageSize =  20
            this.pageConfig.currentPage =  1
            // this.getSourceList()
            this.getPingTaiList()
            this.getQudaoList()
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = null
          }
        })
        .catch(error => {
          console.log(error);
        });
          this.$message({    type: 'success',    message: '删除成功!'  });
        }).catch(() => {
          this.$message({    type: 'info',    message: '已取消删除'  });          
        });
    },
    //添加来源
    addLaiYuan(){
      this.disButton()
      if (this.formLabelAlign.pingTaiId == "") {
          this.$message.error("平台不可以为空，请检查");
          return false
      }
      if (this.formLabelAlign.chineseName == "") {
          this.$message.error("请填写要添加的来源名称");
          return false
      }
      this.$axios
        .get(`/source/addOrDelSource?addType=laiyuan&name=${this.formLabelAlign.chineseName}&parentId=${this.formLabelAlign.pingTaiId}`)
        .then(res => {
        //   console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({  message: '添加成功',type: 'success'});
            this.pageConfig.pageSize =  20
            this.pageConfig.currentPage =  1
            this.getSourceList()
            this.getPingTaiList()
            this.getQudaoList()
            this.centerDialogVisible = false
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.centerDialogVisible = false
            // this.tableData = null
          }
        })
        .catch(error => {
          console.log(error);
          this.centerDialogVisible = false
        });
    },
    //显示平台
    ShowPingTai(){
      this.IsShowPL = false
      this.hasValue = "ABC"
      this.pageConfig.pageSize = 20
      this.pageConfig.currentPage = 1
      this.getSourceList()
    },
    //显示来源
    Showlaiyuan(){
      this.IsShowPL = true
      this.hasValue = ""
      this.pageConfig.pageSize = 20
      this.pageConfig.currentPage = 1
      this.getSourceList()
    }
  },
  created () {
    //获取来源
    this.$axios
      .get(`/source/getSourceList?hasValue=${this.hasValue}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.messageText == "成功!") {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
        }else{
            this.$message.error("获取数据失败，请联系管理员");
            this.tableData = {};
            this.pageConfig.total = 0
        }
      })
      .catch(error => {
        console.log(error);
      });
    //请求渠道可选
    this.getQudaoList()  
    //请求平台可选
    this.getPingTaiList()  
    
  },
  computed: {
    //计算净收入
    // countNum:function(){
    //   // this.zongjiList.chuangShou = Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)
    //   for (let i = 1; i < this.tableData.length; i++) {
    //     // const element = array[i];
    //     this.zongjiList.chuangShou += this.tableData[i].sumMoney
    //   }
    //   console.log(this.zongjiList.chuangShou);    
    //   // return Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)   
    //   }
  }
};
</script>

<style  lang='scss'>
.SourceStatistics-container {
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
  .el-table {
    font-size: 12px;
    th {
      padding: 0;
    }
    .warning-row {
      background: #fff;
    }
     .success-row {
      background: #D3D3D3;
    }
    td {//取消单元格的上下padding
      padding: 0;
      border-bottom: 1px solid rgb(211, 205, 205);
    }
     .el-button--mini{
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255,255,255,0.01);
    }
    .toEdit:hover {
      color:skyblue;
    }
  }
// 时间分类框样式
.SourceStatistics-container input.el-input__inner#kinds,
.SourceStatistics-container input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
  .xinZeng {
      padding-left: 10px;
      color: #49AEEF;
  }
}
.check {
  margin-left: 27px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  .el-pagination input.el-input__inner {
    width: 120px;
  }
  //弹窗
  .el-dialog__body{
    .tanchuang{
      width: 100%;
      .el-input{
        width: 82%;
      }
      .el-form-item__label{
        width: 18%;
      }
    }
    .el-switch{
      margin-left: 20px;
    }
  }
}

</style>