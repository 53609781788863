// 导入vue
import Vue from 'vue';
// 导入VueRouter
import VueRouter from 'vue-router';
// use一下
Vue.use(VueRouter);

// 导入组件
import login from './components/login.vue';
import index from './components/index.vue';
//嵌套路由的组件
//客户管理相关页面
import myCustomer from './components/Customer/myCustomer.vue';
import UnhandleM from './components/Customer/UnhandleM.vue';
import customerList from './components/Customer/customerList.vue';
import redistribution from './components/Customer/redistribution.vue';
import UnhandleR from './components/Customer/UnhandleR.vue';
import teamCus from './components/Customer/teamCus.vue';
import UnhandleT from './components/Customer/UnhandleT.vue';
import customerAdd from './components/Customer/customerAdd.vue';
import myCusEdit from './components/Customer/myCusEdit.vue';
//排行榜
import Leaderboard from './components/Leaderboard/Leaderboard.vue';
//数据统计
import dataStatistics from './components/Statistical Analysis/dataStatistics.vue';
import ResEfficiency from './components/Statistical Analysis/ResEfficiency.vue';
import BankAnalysis from './components/Statistical Analysis/BankAnalysis.vue';
import PerformanceAnalysis from './components/Statistical Analysis/PerformanceAnalysis.vue';
//财务管理
import examine from './components/Financial Management/examine.vue';
import examineAdd from './components/Financial Management/examineAdd.vue';
import examineEdit from './components/Financial Management/examineEdit.vue';
import FinExamine from './components/Financial Management/FinExamine.vue';
//运营管理
import SourceStatistics from './components/Operation Management/SourceStatistics.vue';
import SupplyConfiguration from './components/Operation Management/SupplyConfiguration.vue';
import rechargeDeplete from './components/Operation Management/rechargeDeplete.vue';
import rechargeRecord from './components/Operation Management/rechargeRecord.vue';
import PromotionAnalysis from './components/Operation Management/PromotionAnalysis.vue';
import toBReport from './components/Operation Management/toBReport.vue';
import popularizeAnalysis from './components/Operation Management/popularizeAnalysis.vue';
//部门管理
import department from './components/Department Management/department.vue';
import employeeAdd from './components/Department Management/employeeAdd.vue';
import employeeEdit from './components/Department Management/employeeEdit.vue';
import HRemployeeEdit from './components/Department Management/HRemployeeEdit.vue';
//财务用加盟充值记录
import FinRechargeRecord from './components/FinRechargeRecord.vue';
import dataAnalysis from './components/dataAnalysis.vue';
//日志管理
import MyLog from './components/LogManagement/MyLog.vue';
import TeamLog from './components/LogManagement/TeamLog.vue';
import EditLog from './components/LogManagement/EditLog.vue';
//小电话通话记录录音表
import CallRecords from './components/CallRecordManagement/CallRecords.vue';
//小电话通话联系明细表
import CallConcats from './components/CallRecordManagement/CallConcats.vue';
//话机通话记录录音表
import PhoneRecords from './components/CallRecordManagement/PhoneRecords.vue';
//话机通话联系明细表
import PhoneConcats from './components/CallRecordManagement/PhoneConcats.vue';
// 路由规则
const routes = [
  {
    path: '/login',
    component: login,
    name: 'login',
        meta: {
          // title: '登陆'
        }
  },
  {
    path: '/',
    component: index,
    children: [
      {
        path: '', // 重定向
        redirect: '/myCustomer',
        meta: {
          title: '我的客户'
        }
      },
      {
        path: 'myCustomer', // 客户
        component: myCustomer,
        name: ' myCustomer',
        title: '我的客户',meta: {name: '我的客户'}
      },
      {
        path: 'customerList', // 客户列表
        component: customerList,
        name: ' customerList',
        title: '客户列表',meta: {name: '客户列表'}
      },
      {
        path: 'teamCus', // 团队客户
        component: teamCus,
        name: ' teamCus',
        title: '团队客户',meta: {name: '团队客户'}
      },
      {
        path: 'redistribution', // 再分配客户
        component: redistribution,
        name: ' redistribution',
        title: '再分配客户',meta: {name: '再分配客户'}
      },
      {
        path: 'dataAnalysis', // 数据分析
        component: dataAnalysis,
        name: ' dataAnalysis',
        title: '数据分析',meta: {name: '数据分析'}
      },
      {
        path: 'Leaderboard', // 业绩排行
        component: Leaderboard,
        name: ' Leaderboard',
        title: '业绩排行',meta: {name: '业绩排行'}
      },
      {
        path: 'examine', // 在审件管理
        component: examine,
        name: ' examine ',
        title: '在审件管理',meta: {name: '在审件管理'}
      },
      {
        path: 'examineAdd', // 添加在审件页面
        component: examineAdd,
        name: ' examineAdd',
        title: '添加在审件',meta: {name: '添加在审件'}
      },
      {
        path: 'examineEdit', // 编辑在审件页面
        component: examineEdit,
        name: ' examineEdit',
        title: '编辑在审件',meta: {name: '编辑在审件'}
      },
      {
        path: 'dataStatistics', // 数据统计
        component: dataStatistics,
        name: ' dataStatistics',
        title: '数据统计',meta: {name: '数据统计'}
      },
      {
        path: 'department', // 部门管理
        component: department,
        name: ' department',
        title: '部门管理',meta: {name: '部门管理'}
      },
      {
        path: 'employeeEdit', // 人员编辑页面
        component: employeeEdit,
        name: ' employeeEdit',
        title: '人员编辑',meta: {name: '人员编辑'}
      },
      {
        path: 'employeeAdd', // 人员编辑页面
        component: employeeAdd,
        name: ' employeeAdd',
        title: '人员新增',meta: {name: '人员新增'}
      },
      {
        path: 'myCusEdit', // 团队客户编辑页面
        component: myCusEdit,
        name: ' myCusEdit',
        title: '编辑客户',meta: {name: '编辑客户',
          // noTabe
        }
      },
      {
        path: 'customerAdd', // 添加客户页面
        component: customerAdd,
        name: ' customerAdd',
        title: '添加客户',meta: {name: '添加客户'}
      },
      {
        path: 'SourceStatistics', // 来源管理
        component: SourceStatistics,
        name: ' SourceStatistics',
        title: '来源管理',meta: {name: '来源管理'}
      },
      {
        path: 'rechargeDeplete', // 充值消耗
        component: rechargeDeplete,
        name: ' rechargeDeplete',
        title: '充值消耗',meta: {name: '充值消耗'}
      },
      {
        path: 'rechargeRecord', // 数据充值记录
        component: rechargeRecord,
        name: ' rechargeRecord',
        title: '数据充值记录',meta: {name: '数据充值记录'}
      },
      {
        path: 'toBReport', // 对公数据报表
        component: toBReport,
        name: ' toBReport',
        title: '对公数据报表',meta: {name: '对公数据报表'}
      },
      {
        path: 'ResEfficiency', // 数据处理效率
        component: ResEfficiency,
        name: ' ResEfficiency',
        title: '数据处理效率',meta: {name: '数据处理效率'}
      },
      {
        path: 'BankAnalysis', // 进件银行分析
        component: BankAnalysis,
        name: ' BankAnalysis',
        title: '进件银行分析',meta: {name: '进件银行分析'}
      },
      {
        path: 'FinExamine', // 业绩管理
        component: FinExamine,
        name: ' FinExamine',
        title: '业绩管理',meta: {name: '业绩管理'}
      },
      {
        path: 'SupplyConfiguration', // 供量配置
        component: SupplyConfiguration,
        name: ' SupplyConfiguration',
        title: '供量配置',meta: {name: '供量配置'}
      },
      {
        path: 'PromotionAnalysis', // 申请跑量分析
        component: PromotionAnalysis,
        name: ' PromotionAnalysis',
        title: '申请跑量分析',meta: {name: '申请跑量分析'}
      },
      {
        path: 'PerformanceAnalysis', // 业绩数据分析
        component: PerformanceAnalysis,
        name: ' PerformanceAnalysis',
        title: '业绩数据分析',meta: {name: '业绩数据分析'}
      },
      {
        path: 'FinRechargeRecord', // 加盟充值记录
        component: FinRechargeRecord,
        name: ' FinRechargeRecord',
        title: '加盟充值记录',meta: {name: '加盟充值记录'}
      },
      {
        path: 'UnhandleM', // 待处理客户
        component: UnhandleM,
        name: ' UnhandleM',
        title: '待处理客户',meta: {name: '待处理客户'}
      },
      {
        path: 'UnhandleR', // 再分配待处理客户
        component: UnhandleR,
        name: ' UnhandleR',
        title: '再分配待处理客户',meta: {name: '再分配待处理客户'}
      },
      {
        path: 'UnhandleT', // 团队待处理客户
        component: UnhandleT,
        name: ' UnhandleT',
        title: '团队待处理客户',meta: {name: '团队待处理客户'}
      },
      {
        path: 'MyLog', // 我的日志
        component: MyLog,
        name: ' MyLog',
        title: '我的日志',meta: {name: '我的日志'}
      },
      {
        path: 'TeamLog', // 团队日志
        component: TeamLog,
        name: ' TeamLog',
        title: '团队日志',meta: {name: '团队日志'}
      },
      {
        path: 'EditLog', // 编辑日志
        component: EditLog,
        name: ' EditLog',
        title: '编辑日志',meta: {name: '编辑日志'}
      },
      {
        path: 'popularizeAnalysis', // 推广分析
        component: popularizeAnalysis,
        name: ' popularizeAnalysis',
        title: '推广分析',meta: {name: '推广分析'}
      },
      {
        path: 'CallRecords', // 通话记录录音表
        component: CallRecords,
        name: 'CallRecords',
        title: '小电话通话记录',meta: {name: '小电话通话记录'}
      },
      {
        path: 'CallConcats', // 通话联系明细表
        component: CallConcats,
        name: 'CallConcats',
        title: '小电话通话数据',meta: {name: '小电话通话数据'}
      },
      {
        path: 'PhoneRecords', // 话机通话记录录音表
        component: PhoneRecords,
        name: 'PhoneRecords',
        title: '话机通话记录',meta: {name: '话机通话记录'}
      },
      {
        path: 'PhoneConcats', // 话机通话联系明细表
        component: PhoneConcats,
        name: 'PhoneConcats',
        title: '话机通话数据',meta: {name: '话机通话数据'}
      },
      {
        path: 'HRemployeeEdit', // HR专用编辑页面
        component: HRemployeeEdit,
        name: ' HRemployeeEdit',
        title: 'HR专用编辑页面',meta: {name: 'HR专用编辑页面'}
      }
    ]
  }
]

//路由拦截器
// router.beforeEach((to, from, next) => {
//   //根据本地信息是否被更改
//   if (window.sessionStorage.getItem("currentName") == null) {
//     this.$message.warning("请先进行登录操作！");
//     this.$router.push("login");
//   } else {
//       next()//若点击的是不需要验证的页面,则进行正常的路由跳转
//   }
// });
// 实例化路由对象
const router = new VueRouter({
  base:"/CRM/",
  //mode:"history",
  mode:"hash",
  routes
})

//解决elementui 导航栏中的vue-router在3.0版本以上重复点菜单报错问题
//push
/*const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}*/

// 暴露出去
export default router
