<template>
  <div class="UnhandleR-container" style="margin-top: 0;" v-cloak>
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-if="this.formLabelAlign.zfpSearchLevel == 'level0'">0星客户超过6h未联系</p>
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-else-if="this.formLabelAlign.zfpSearchLevel == 'level2'">2星及2星+客户超过6天未联系</p>
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-else-if="this.formLabelAlign.zfpSearchLevel == 'level3'">3星及4星客户超过4天未联系</p>
        <p v-else></p>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table :data="tableData" style="width: 100%" v-loading="loading" v-cloak>
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
      <el-table-column  label="姓名" width="70" align="center">
        <template slot-scope="scope"><a class="toEdit" size="mini" @click="handleEdit(scope.$index, scope.row)">{{ scope.row.name}}</a></template>
      </el-table-column>
      <el-table-column  label="状态" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '资质不符' " style='color:#C73E3A'>{{ scope.row.state}}</span>
          <span v-else-if=" scope.row.state == 'B上门'||scope.row.state == '已上门'  || scope.row.state == '待签约'|| scope.row.state == '已放款'|| scope.row.state == '审核中'" style='color:#5BBD2B'>{{ scope.row.state}}</span>
          <span v-else-if="scope.row.state == '已受理' || scope.row.state == '未接通' || scope.row.state == '待跟进'" style='color:#3390ff'>{{ scope.row.state}}</span>
          <span v-else-if="scope.row.state == '捣乱申请' || scope.row.state == '已拒批' || scope.row.state == '外地申请'||  scope.row.state == '重复申请'" style='color:#D7962F'>{{ scope.row.state}}</span>
          <span v-else style='color:grey'>{{ scope.row.state}}</span>
          </template>
      </el-table-column>
      <el-table-column  label="星级" width="50" align="center">
        <template slot-scope="scope">{{ scope.row.level| levelKind }}</template>
      </el-table-column>
      <el-table-column label="备注" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.noteList" :key="index">
            <p class="ell" v-if="index < 3"
               v-bind:style="{ color: item.otherUser == 1 ? 'red' : '#606266' }">
              &nbsp;<span v-bind:style="{
									color: item.otherUser == 1 ? '#898989' : '#898989',
								}"
            >{{ ++index }}--{{ item.createDate | formatTime }}--{{
                item.userName
              }}</span
            >--{{ item.content }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="资质" width="200" align="center">
        <template slot-scope="scope"><span>{{ scope.row.remarks | remarksPanKong}}</span></template>
      </el-table-column>
      <el-table-column  label="再分配时间" width="150" align="center" sortable>
        <!-- <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template> -->
        <template slot-scope="scope">{{ scope.row.zfpTime | formatTime }}</template>
        <!-- <template slot-scope="scope">{{ scope.row.distributionTime | formatTime }}</template> -->
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
import { mapMutations } from 'vuex';
export default {
  name: "UnhandleR",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      loading:true,
      showTimeKind:true,
      //提交表格的对象
      form:"",
      //时间区间隐藏状态
      isShow:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      //进度动态生成内容
      statusList: [
        { id: "所有", name: "" },
        { id: "已受理", name: "已受理" },
        { id: "未接通", name: "未接通" },
        { id: "待跟进", name: "待跟进" },
        { id: "资质不符", name: "资质不符" },
        { id: "待签约", name: "待签约" },
        { id: "已上门", name: "已上门" },
        { id: "B上门", name: "B上门" },
        { id: "审核中", name: "审核中" },
        { id: "已放款", name: "已放款" },
        { id: "已拒批", name: "已拒批" },
        { id: "捣乱申请", name: "捣乱申请" },
        { id: "重复申请", name: "重复申请" },
        { id: "外地申请", name: "外地申请" },
      ],
      daiGenJinNum:{
        dgjCount:0,
        level3count:0,
        level2count:0
      },
      //加入公共池的客户id数组
      customerId:[],
      kindtimeList:[
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
      ],
      //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      //客户筛选区域数据
      formLabelAlign: {
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "", //	当前页码
        zfpSearchLevel : "" //	星级条件
      },
      // 表格依赖于数据没有数据  会一行都没有
      tableData: [],
      //暂存当前页面的星级条件 判断是否需要变更currentPage
      currentSearchLevel:""
    };
  },
  methods: {
    //表格各行变色的方法
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
        return 'warning-row';
      }else{
        return 'success-row';
      }
    },
    //获取客户 List的方法
    getCusList(){
      //打开遮罩层
      this.loading = true
      this.$axios.post(`/customer/getDclCustomerList?userId=${this.currentUser.currentid}&type=zfp&searchLevel=${this.formLabelAlign.zfpSearchLevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`).then(res => {
        // console.log(res);
        let code = res.data.messageModel.code;
        if (code == 0) {
          this.loading = false
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合条件的客户');
             this.tableData = []
             this.pageConfig.total = res.data.dataModel.total;
             return false;
          }
          this.$message.success("已为您找到以下客户(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.loading = false
          this.$message.error(res.data.messageModel.messageText);
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getCusList()
      }else{
        this.pageConfig.pageSize = val;
        this.getCusList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getCusList()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //编辑
    handleEdit(index, row) {
      let CusId = row.id;
      // 编程式导航
      this.$router.push({ path: '/myCusEdit', query: { "CusId": CusId ,"addatbn" :"true" } })
      window.sessionStorage.setItem("CusId", CusId);
    },
    //查询
    checkCus(){
      this.pageConfig.currentPage = 1
      this.getCusList()
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    remarksPanKong(value) {
      if (value == "null") {
        return ""
      }
      return value;
    },
    //判断星级状态
    levelKind(val) {
      if (val == "0") {  return  "0星" }
      else if(val == "1"){  return  "1星" }
      else if(val == "2"){  return  "2星" }
      else if(val == "3"){  return "2星+" }
      else if(val == "4"){  return  "3星" }
      else if(val == "5"){  return "4星" }
      else{  return ""
      }
    }
  },
  created () {
    if (window.sessionStorage.getItem("prohibitLogin") == "true") {
        if (this.$route.query.zfpSearchLevel) {
            this.formLabelAlign.zfpSearchLevel = this.$route.query.zfpSearchLevel
            this.currentSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
        }else{
            this.formLabelAlign.zfpSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
            this.currentSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
        }
      //创建存放表格的对象
      this.form = new FormData()
      this.currentUser.currentName = window.sessionStorage.getItem("name")
      this.currentUser.currentid = window.sessionStorage.getItem("id")
      this.$axios.post(`/customer/getDclCustomerList?userId=${this.currentUser.currentid}&type=zfp&searchLevel=${this.formLabelAlign.zfpSearchLevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`).then(res => {
        if (res.data.messageModel.code == 0) {
          this.loading = false;
          if (res.data.dataModel == null) {
            this.$message.error("抱歉，没有找到符合搜索条件的客户");
            this.tableData = {};
          }else{  this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
          }
        }else{
          this.loading = false;
          this.$message.error(res.data.messageModel.messageText);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      this.$message.warning("请先进行登录操作！");
      this.$router.push("login");
    }
    let component = this
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key === 13) {
        component.checkCus()
      }
    }
  },
  activated () {
    if (this.$route.query.zfpSearchLevel) {
        this.formLabelAlign.zfpSearchLevel = this.$route.query.zfpSearchLevel
        if (this.currentSearchLevel != window.sessionStorage.getItem("zfpSearchLevel")) {
          this.currentSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
          this.pageConfig.currentPage = 1
        }
    }else{
        this.formLabelAlign.zfpSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
        if (this.currentSearchLevel != window.sessionStorage.getItem("zfpSearchLevel")) {
          this.currentSearchLevel = window.sessionStorage.getItem("zfpSearchLevel")
          this.pageConfig.currentPage = 1
        }
    }
    this.getCusList()
  }
};
</script>

<style  lang='scss'>
// 在填充数据之前隐藏
[v-cloak] {
    display: none;
}
.UnhandleR-container {

//突出隔行的颜色差异
.el-table {
  font-size: 12px;
  .cell {
      padding: 2px 2px;
      line-height: 16px;
    }
  th {
    padding: 0;
  }
  th.is-sortable {
    .cell {
      text-align: center;
    }
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 2px 0;
    border-bottom: 1px solid rgb(211, 205, 205);
    border-right: 1px dashed #d3cdcd;
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
  .el-form-item#btnList {
    /deep/.el-form-item__content{
      margin-left: 20px!important;
    }
  }
}
.check {
  margin-left: 22px;
  padding: 10px 20px;
}
.ell {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  word-break:normal;
  text-indent: -2em;
  margin: 0 0 0 2em;
  line-height: 1.2;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
