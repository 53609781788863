<template>
  <div class="examine-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="115px" label-position="right" :model="formLabelAlign">
        <el-form-item label=" 客户">
          <el-input v-model="formLabelAlign.keywords" placeholder="姓名/ID/手机号均可"></el-input>
        </el-form-item>
        <!-- <el-form-item label="顾问">
          <el-input v-model="formLabelAlign.userName" placeholder="请输入顾问姓名" ></el-input>
        </el-form-item> -->
        <el-form-item label="层级">
          <el-select v-model="formLabelAlign.otherScopeOf" id="otherThing" placeholder>
            <el-option label="不限" value=""></el-option>
            <el-option label="城市" value="city"></el-option>
            <el-option label="分公司" value="fenGS"></el-option>
            <el-option label="大区" value="quYu"></el-option>
            <el-option label="部门" value="buMen"></el-option>
            <el-option label="顾问" value="user"></el-option>
          </el-select>
          <el-input v-model="formLabelAlign.otherName" class="yaosile" id="addThing"></el-input>
        </el-form-item>
        <el-form-item label="进度">
          <el-select v-model="formLabelAlign.state" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="审核" value="审核"></el-option>
            <el-option label="拒批" value="拒批"></el-option>
            <el-option label="收款" value="收款"></el-option>
            <el-option label="违约" value="违约"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进件银行">
          <el-select v-model="formLabelAlign.jinJianBankId" placeholder>
            <el-option label="不限" value=""></el-option>
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bankName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="收款方式">
          <el-select v-model="formLabelAlign.thePayee" placeholder>
            <el-option label="POS" value="POS"></el-option>
            <el-option label="刷卡" value="刷卡"></el-option>
            <el-option label="现金" value="现金"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="进件类型">
          <el-select v-model="formLabelAlign.danJianType" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="信用贷" value="信用贷"></el-option>
            <el-option label="车抵贷" value="车抵贷"></el-option>
            <el-option label="房抵贷" value="房抵贷"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进件助理">
          <el-select v-model="formLabelAlign.assistantId" placeholder>
            <el-option label="无" value=""></el-option>
            <el-option
              v-for="item in assistantList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台" v-if="showPingTai">
          <el-select v-model="formLabelAlign.pingTaiId" placeholder="平台">
            <el-option label="所有" value=""></el-option>
            <el-option
              v-for="item in pingTaiList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-select v-model="formLabelAlign.timeType" id="kinds" @change="timeKind" placeholder="分类">
            <el-option
              v-for="item in timeKindList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" :disabled="this.showTimeKind">
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check">查询</el-button>
        <el-button style="background-color:#3a8ac7;color:#fff" @click="addExamine" class="check" v-if="showAddBth">添加在审件</el-button>
        <el-button style="background-color:#25c58b;color:#fff" @click="addBank" class="check" v-if="showAddRights">添加进件银行</el-button>
        <el-button type="warning" @click="addAssistant" class="check" v-if="showAddRights">添加进件助理</el-button>
      </el-form>
    </el-row>
    <!-- table -->
    <el-table id="zaishenjiantable"  :data="tableData" style="width: 100%" :row-style="headerColor" stripe>
      <el-table-column  label="ID" width="60"  fixed="left">
        <template slot-scope="scope">{{ scope.row.id | isZongJi }}</template>
      </el-table-column>
      <el-table-column  label="进件日期" width="65"  fixed="left">
        <template slot-scope="scope">{{ scope.row.jinJianTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="userName" label="顾问" width="60"  fixed="left"></el-table-column>
      <el-table-column prop="departmentName" label="部门" width="65"  fixed="left"></el-table-column>
      <el-table-column prop="customerName" label="客户" width="50"  fixed="left">
        <template slot-scope="scope"><a type="primary" class="miniBth" plain size="mini" @click="handleEdit(scope.$index, scope.row)">{{ scope.row.customerName}}</a></template>
      </el-table-column>
      <el-table-column prop="jinJianBankName" label="进件银行" width="60"></el-table-column>
      <el-table-column prop="rate" label="费率(%)" width="50">
        <template slot-scope="scope">{{ scope.row.rate | feilv }}</template>
      </el-table-column>
      <el-table-column prop="jinJianRate" label="进件额度" width="80"></el-table-column>
      <el-table-column prop="state" label="进度" width="35">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '拒批'  " style='color:#C73E3A'>{{ scope.row.state}}</span>
          <span v-else-if=" scope.row.state == '收款'||scope.row.state == '违约'" style='color:#5BBD2B'>{{ scope.row.state}}</span>
          <span v-else-if=" scope.row.state == '审核' " style='color:#409eff'>{{ scope.row.state}}</span>
          <span v-else style='color:grey'>{{ scope.row.state}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="fangKuanRate" label="批款额度" width="70"></el-table-column>
      <el-table-column  label="批款时间" width="65">
        <template slot-scope="scope">{{ scope.row.fangKuanTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="thePayeeRate" label="收款额" width="70"></el-table-column>
      <el-table-column  label="收款时间" width="65">
        <template slot-scope="scope">{{ scope.row.thePayeeTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="channelMoney" label="渠道费" width="65"></el-table-column>
      <el-table-column prop="depositMoney" label="诚意金" width="65"></el-table-column>
      <el-table-column prop="jingShouRu" label="净收入" width="70"></el-table-column>
      <el-table-column prop="shouKuangBank" label="收款银行" width="58"></el-table-column>
      <el-table-column prop="danJianType" label="单件类型" width="60"></el-table-column>
      <el-table-column label="申请日期" width="65">
        <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="sourceName" label="平台" width="70" v-if="showPingTai"></el-table-column>
      <el-table-column  label="录入日期" width="65">
        <template slot-scope="scope">{{ scope.row.entryTime | formatTime }}</template>
      </el-table-column>
      <el-table-column label="操作"  >
        <template slot-scope="scope">
          <el-button  @click.native.prevent="delExamine(scope.$index, scope.row)"  type="text"  size="small" v-if="scope.$index != 0">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10 , 20 , 50 ,100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "examine",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      showTimeKind:true,
      showPingTai:false,
      showAddBth:true,
      showAddRights:false,
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      //助理列表
      assistantList: [],
      jinjianBack:"",//进件行
      jinjianAssistant:"",//进件助理
       //时间类型生成内容
      timeKindList: [
        // { id: "createTime", name: "申请时间" },
        { id: "entryTime", name: "录入时间" },
        { id: "thePayeeTime", name: "收款时间" },
        { id: "fangKuanTime", name: "放款时间" },
        { id: "jinJianTime", name: "进件时间" }
      ],
      //收款银行动态生成内容
      bankList: [],
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" }
        ],
      //审件筛选区域数据
      formLabelAlign: {
        keywords : "", 	//关键字
        userName : "",
        shouKuanStyle : "", 	//	收款方式
        otherScopeOf : "", 	//	其他条件
        otherName:"",  //查询条件的值
        state : "", 	//	状态
        content : "", 	//备注内容
        thePayee : "", 	//收款人
        jinJianBankId : "", 	//进件银行
        danJianType : "", 	//审件类型
        assistantId : "", 	//进件助理
        timeType : "",      //今日
        dayType : "",
        beginTime : "", //开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        pingTaiId : "", 	//平台
        currentPage : "" //	当前页码
      },
      pingTaiList: {},
      tableData: []
    };
  },
  methods: {
    //首行总计为红色
    headerColor({row,rowIndex}){
      if (rowIndex == 0) {
        return 'background-color:#55C7B0;color:#184785'
      }
    },
    //获取银行列表的请求
    getBankList(){
      this.$axios
      .get(`/bill/getBankList`)
      .then(res => {
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.bankList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //添加助理
    addAssistant(){
      this.$prompt('请输入新增进件助理', '添加进件助理', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.jinjianAssistant = value
          if (this.jinjianAssistant != null) {
            this.$axios.get(`/bill/addAssistant?userId=${this.currentUser.currentid}&name=${this.jinjianAssistant}`)
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.messageText == "添加成功") {
                  this.$message({type: 'success',  message: '您已成功添加'+ value +'为进件助理' });
                  this.getAssistantList()
                  this.jinjianAssistant = ""
                }else{
                  this.$message({type: 'warning',  message: res.data.messageModel.messageText });
                  this.jinjianAssistant = ""
                }
                })
              .catch(error => {/*console.log(error); */ this.jinjianAssistant = ""});
          }else{
            this.$message({type: 'warning',  message: '填写内容不可以为空，请检查' });
          }
        }).catch(() => {
          this.$message({  type: 'info',  message: '取消输入'  });
        });
    },
    //获取助理
    getAssistantList(){
      this.$axios
      .get(`/bill/getAssistantList?userId=${this.currentUser.currentid}`)
      .then(res => {
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.assistantList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    addBank(){
      // console.log(this.jinjianBack);
      this.$prompt('请输入新增进件银行名称', '添加进件银行', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // inputPattern: /@"^[\u4E00-\u9FA5]{2,4}$"/,
          // inputErrorMessage: '仅限输入汉字'
        }).then(({ value }) => {
          this.jinjianBack = value
          if (this.jinjianBack != null) {
            this.$axios.get(`/bill/addBank?bankName=${this.jinjianBack}`)
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.messageText == "添加成功") {
                  this.$message({type: 'success',  message: '您已成功添加'+ value +'为进件银行' });
                  this.jinjianBack = ""
                  this.getBankList()
                }else if(res.data.messageModel.messageText == "请检查是否重名"){
                  this.$message({  message: '该银行已被添加' });
                  this.jinjianBack = ""
                }else{
                  this.$message({type: 'warning',  message: '网络异常请稍后再试' });
                  this.jinjianBack = ""
                }
                })
              .catch(error => {/*console.log(error);  */this.jinjianBack = ""});
          }else{
            this.$message({type: 'warning',  message: '填写内容不可以为空，请检查' });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    },
    //获取客户 List的方法
    getShenJianList(){
      this.$axios
      .get(`/bill/billList?userId=${this.currentUser.currentid}&sourceParentId=${this.formLabelAlign.pingTaiId}&keywords=${this.formLabelAlign.keywords}&userName=${this.formLabelAlign.userName}&state=${this.formLabelAlign.state}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&jinJianBankId=${this.formLabelAlign.jinJianBankId}&danJianType=${this.formLabelAlign.danJianType}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        let code = res.data.messageModel.code;
        if (code == 0) {
          if (res.data.dataModel.total == 0) {
            this.$message('抱歉，没有找到符合搜索条件的审件');
            this.tableData = [];
            this.pageConfig.total = 0;
            return false;
          }
          this.$message.success("已为您找到以下审件(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //判断时间类型
    timeKind(vId){
      let obj = {};
      obj = this.timeKindList.find(item => {
        return item.id === vId; //筛选出匹配数据
      });
      if (obj.name == "备注时间") {  this.formLabelAlign.timeType1 = true;
      }else if(obj.name == "入库时间"){  this.formLabelAlign.timeType2 = true;
      }
      if (obj.name == "") {
        this.showTimeKind = true
      }else{
        this.showTimeKind = false
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.name == "自定义") {
        this.isShow = true;
        this.formLabelAlign.dayType  = "customize";
      }else if(obj.name == "今日"){
        this.isShow = false;
        this.value1 ="";
        this.formLabelAlign.today  = true;
        this.formLabelAlign.yestoday  = "";
        this.formLabelAlign.sevenDay  = "";
        this.formLabelAlign.tomonth  = "";
        this.formLabelAlign.yesmonth  = "";
      }else if(obj.name == "昨日"){
        this.isShow = false;
        this.value1 ="";
        this.formLabelAlign.today  = "";
        this.formLabelAlign.yestoday  = true;
        this.formLabelAlign.sevenDay  = "";
        this.formLabelAlign.tomonth  = "";
        this.formLabelAlign.yesmonth  = "";
      }else if(obj.name == "最近七天"){
        this.isShow = false;
        this.value1 ="";
        this.formLabelAlign.today  = "";
        this.formLabelAlign.yestoday  = "";
        this.formLabelAlign.sevenDay  = true;
        this.formLabelAlign.tomonth  = "";
        this.formLabelAlign.yesmonth  = "";
      }else if(obj.name == "本月"){
        this.isShow = false;
        this.value1 ="";
        this.formLabelAlign.today  = "";
        this.formLabelAlign.yestoday  = "";
        this.formLabelAlign.sevenDay  = "";
        this.formLabelAlign.tomonth  = true;
        this.formLabelAlign.yesmonth  = "";
      }else if(obj.name == "上月"){
        this.isShow = false;
        this.value1 ="";
        this.formLabelAlign.today  = "";
        this.formLabelAlign.yestoday  = "";
        this.formLabelAlign.sevenDay  = "";
        this.formLabelAlign.tomonth  = "";
        this.formLabelAlign.yesmonth  = true;
      }
      else {
        this.isShow = false;
        this.value1 ="";
      }
    },
      //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getShenJianList()
      }else{
        this.pageConfig.pageSize = val;
         this.getShenJianList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
         this.getShenJianList()
      // }
    },
    //获取平台的下拉列表
    getPingTaiList(){
        this.$axios
      .get(`/source/getSourceByType?type=2`)
      .then(res => {
        this.pingTaiList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      });
    },
    //添加在审件
    addExamine(){
      this.$router.push("examineAdd");
    },
    //编辑
    handleEdit(index, row) {
      this.$router.push({ path: '/examineEdit', query: { "billId": row.id ,"customerId" :row.customerId} })
      window.sessionStorage.setItem("billId", row.id);
      //非财务跳转判断参数
      window.sessionStorage.setItem("confirm", false);
    },
    //删除审件
    delExamine(index, row) {
      this.$confirm('此操作将删除该审件, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
      this.$axios
      .get(`/bill/del?id=${row.id}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.messageText == "删除成功") {
          this.$message({type: 'success',message: '删除成功!'});
          this.pageConfig.currentPage = 1
          this.pageConfig.pageSize = 20
          this.getShenJianList()
        }else{
          this.$message.error("删除失败，请与管理员联系/(ㄒoㄒ)/~~");
        }
        })
      .catch(error => {
        console.log(error);
      });
        }).catch(() => {
          this.$message({  type: 'info',  message: '已取消删除'});
        });
    },
    //查询
    checkCus(){
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        }
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      //后端暂不接收对象 故采用拼接传值
        this.getShenJianList()
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YY-MM-DD");
    },
    feilv(feilv){
      if (feilv != null) {
        return feilv + "%"
      }else{
        return ""
      }
    },
    isZongJi(val){
      if (val == 0) {
        return "总计"
      }else{
        return val
      }
    },
    //判断客户状态
    stateKind(val) {
      // console.log(val);
      if (val == "dgj") {
        return  "待跟进"
      }else if(val == "zzbf"){
        return  "资质不符合"
      }else if(val == "dqy"){
        return  "待签约"
      }else if(val =="yqy"){
        return "B上门"
      }else if(val == "shz"){
        return  "审核中"
      }else if(val == "dlsq"){
        return "捣乱申请"
      }
    }
  },
  created () {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    if (this.currentUser.currentLevel == 0 || this.currentUser.currentLevel == 10) {
      this.showPingTai = true
    }
    if (this.currentUser.currentLevel <= 3) {
      this.showAddRights = true
    }
    if (this.currentUser.currentLevel == 11) {
      this.showAddBth = false
      this.showAddRights = false
    }
    //应该先拿当前用户的ID后 再发请求
    this.$axios.get(`/bill/billList?userId=${this.currentUser.currentid}&pageSize=10&currentPage=1`)
      .then(res => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
          this.tableData = [];
          this.pageConfig.total = 0;
        }else{
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }
        })
      .catch(error => {
        console.log(error);
      });
    this.getAssistantList()
    this.getBankList()
    //请求平台可选
    this.getPingTaiList()
    //注册键盘事件
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key === 13) {
        component.checkCus()
      }
    }
  },
  activated () {
    this.$axios
      .get(`/bill/billList?userId=${this.currentUser.currentid}&sourceParentId=${this.formLabelAlign.pingTaiId}&keywords=${this.formLabelAlign.keywords}&userName=${this.formLabelAlign.userName}&state=${this.formLabelAlign.state}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&jinJianBankId=${this.formLabelAlign.jinJianBankId}&danJianType=${this.formLabelAlign.danJianType}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        let code = res.data.messageModel.code;
        if (code == 0) {
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合搜索条件的审件');
             return false;
          }
          this.$message.success("已为您找到以下审件(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
        }
        })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style  lang='scss'>
.examine-container {
  #zaishenjiantable .cell {
    font-size: 12px;
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
//突出隔行的颜色差异
.el-table {
  th {
    padding: 0;
  }
  //单元格内文字margin值
  .cell {
    padding: 0 2px;
    .miniBth {
      padding: 5px 15px;
      margin: 3px 0;
    }
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
  /deep/a {
    display: inline-block;
    width: 100%;
    padding: 0!important;
  }
  a:hover{
    color: aqua;
  }
}
// 时间分类框样式
 input.el-input__inner#addThing,
 input.el-input__inner#otherThing,
 /deep/input.el-input__inner#time  {
    width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
.yaosile {
  width: 85px;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important;
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 5px 0;
}
.check {
  margin-left: 27px;
  padding: 10px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
