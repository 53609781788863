<template>
  <div class="examineEdit-container">
    <img v-show="showAudited" id="Audited" src="../../assets/Audited.png" alt="">
    <!-- 人事信息 -->
    <el-row class="filterCondition upOne">
      <span class="cusAddTit">编辑审件</span>
    </el-row>
    <!-- 档案信息 -->
    <el-row class="filterCondition downOne">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item class="dangan" label="客户ID" >
          <el-input v-model="formLabelAlign.customerId" placeholder="仅支持Id或手机号查询" :disabled="confirm"></el-input>
        </el-form-item>
        <el-button type="danger" id="addnote" @click="checkCus" :disabled="confirm">查询</el-button><span style="margin-left:10px">客户姓名：</span><span class="redSpan">{{this.formLabelAlign.name}}；</span><span>贷款顾问：</span><span class="redSpan">{{this.formLabelAlign.guwen}}；</span>
        <!-- <el-form-item class="dangan" label="客户姓名">
          <el-input v-model="formLabelAlign.name" placeholder="" disabled></el-input>
        </el-form-item> --><br>
        <el-form-item class="dangan" label="备注记录">
          <div id="noteList">
          <ul>
            <li v-for="(item,index) in this.formLabelAlign.noteList" :key="index">
              {{++index}},{{ item.userName}},{{ item.createDate | formatTime}},{{ item.content}}
            </li>
          </ul>
          </div>
        </el-form-item><br>
        <!-- <el-form-item class="dangan" label="贷款顾问">
          <el-input v-model="formLabelAlign.guwen" placeholder="" disabled></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="部门">
          <el-input v-model="formLabelAlign.departmentName" placeholder="" disabled></el-input>
        </el-form-item> -->
        <el-form-item class="dangan" label="进件日期">
          <div class="block" >
            <el-date-picker
              class="examineTine"
              v-model="formLabelAlign.jinJianTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              :disabled="confirm"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="费率">
          <el-input v-model="formLabelAlign.rate" placeholder="" class="yuan" :disabled="confirm"><i  slot="suffix">%</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="合同号">
          <el-input v-model="formLabelAlign.contractNo" placeholder="" :disabled="confirm"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="进件银行">
          <el-select v-model="formLabelAlign.jinJianBankId" :disabled="confirm">
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bankName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进件额度">
          <el-input v-model="formLabelAlign.jinJianRate" placeholder="" class="yuan" :disabled="confirm"><i  slot="suffix" >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan " id="backManger" label="银行经理">
          <el-input v-model="formLabelAlign.bankUser" placeholder="" :disabled="confirm"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="诚意金">
          <el-input v-model="formLabelAlign.depositMoney" type="number" placeholder="" class="yuan" :disabled="confirm">  <i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="单件类型">
          <el-select v-model="formLabelAlign.danJianType" placeholder :disabled="confirm">
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="信用贷" value="信用贷"></el-option>
            <el-option label="车抵贷" value="车抵贷"></el-option>
            <el-option label="房抵贷" value="房抵贷"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="进度" >
          <el-select v-model="formLabelAlign.state" placeholder @change="getjindu" :disabled="confirm">
            <el-option
              v-for="item in jinduList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="违约金"  v-show="weiyueJingShow">
          <el-input type="number" v-model="formLabelAlign.weiyueMoney" placeholder="" class="yuan" :disabled="confirm">  <i slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="放款额度" v-show="jiekuanShow">
          <el-input v-model="formLabelAlign.fangKuanRate" placeholder="" type="number" class="yuan" :disabled="confirm">  <i  slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="放款日期" v-show="jiekuanShow">
          <div class="block" >
            <el-date-picker
            class="examineTine"
              v-model="formLabelAlign.fangKuanTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              :disabled="confirm"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="收款方式" v-show="weiyueShow">
          <el-select v-model="formLabelAlign.thePayee" placeholder :disabled="confirm">
            <el-option label="POS" value="POS"></el-option>
            <el-option label="转账" value="转账"></el-option>
            <el-option label="现金" value="现金"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="收款日期" v-show="weiyueShow">
          <div class="block" >
            <el-date-picker
            class="examineTine"
              v-model="formLabelAlign.thePayeeTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              :disabled="confirm"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="渠道费"  v-show="weiyueShow">
          <el-input type="number" v-model="formLabelAlign.channelMoney" placeholder="" class="yuan" :disabled="confirm">  <i slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="收款额度"  v-show="jiekuanShow">
          <el-input type="number" v-model="formLabelAlign.thePayeeRate" placeholder="" class="yuan" :disabled="confirm">  <i slot="suffix"  >/元</i></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="收款银行" v-show="weiyueShow">
          <el-input  v-model="formLabelAlign.shouKuangBank" placeholder="" class="yuan" :disabled="confirm"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="进件助理" v-show="weiyueShow">
          <el-select v-model="formLabelAlign.assistantId" placeholder :disabled="confirm">
            <el-option label="无" value= 0></el-option>
            <el-option
              v-for="item in assistantList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <br/>
        <el-form-item class="jingshouru" label="净收入" v-show="ggShow">
          <span class="jine">{{countNum}} </span><span> 元 </span> <span class="gongshi">(净收入 = 诚意金 + 收款额度 - 渠道费)</span>
        </el-form-item><br>
        <el-form-item class="jingshouru" label="净收入" v-show="weiyueJingShow">
          <span class="jine">{{countNum1}} </span><span> 元 </span> <span class="gongshi">(净收入 = 诚意金 + 违约金)</span>
        </el-form-item><br>
        <el-form-item class="dangan" id="describe" label="新增备注">
          <el-input
            id="nianxian"
            type="textarea"
            :rows="5"
            placeholder="请输入备注内容"
            v-model="formLabelAlign.note"
            :disabled="confirm"
          ></el-input>
        </el-form-item>

        <el-button type="primary" @click="submit" id="submit" v-show="!confirm" :disabled="showAudited">修改审件</el-button>
        <el-button type="primary" style="background-color:#c73e3a;color:#fff" @click="Approved" id="submit" v-show="confirm">确认核实</el-button>
        <el-button type="primary"  @click="DelApproved"  v-show="confirm">撤销核实</el-button>
      </el-form>
    </el-row>
  </div>
</template>

<script>
// 导入时间戳转换方法
import $ from "jquery";
import moment from 'moment'
export default {
  name: "examineEdit",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      billId:"", //在审件ID
      confirm:"", //判断是否从财务权限跳转而来
      //收款银行动态生成内容
      bankList: [],
      //判断选择框是否显示
      jiekuanShow:false,
      weiyueShow:false,
      ggShow:false,
      weiyueJingShow:false,
      user: "", //表单要提交的参数
      //备注内容
      textarea: "",
      //控制已审核图片是否显示
      showAudited:false,
      //审件ID
      customerId:"",
      //备忘
      beiwang: "",
      //备忘时间
      beiwangdate: "",
      //助理列表
      assistantList: [],
      //进度动态生成内容
      jinduList: [
        { id: "审核", name: "审核" },
        { id: "拒批", name: "拒批" },
        { id: "收款", name: "收款" },
        { id: "违约", name: "违约" },
        ],
      //messageText为首次加载时的返回状态
      messageText: "",
      //客户筛选区域数据
      formLabelAlign: {
        name:"", //客户姓名
        guwen:"", //顾问姓名
        departmentName:"", //顾问部门
        customerId:"",	//客户ID
        userId:"",	//	顾问ID
        jinJianTime:"",	//进件日期
        rate:0,	//	费率
        contractNo:"",	//合同号
        jinJianBankId:"", //  进件银行id
        jinJianRate:0,	//进件额度
        bankUser:"",	//银行客户经理
        depositMoney:0,	//诚意金
        danJianType:"",	//单件类型
        state:"",	     //进度
        fangKuanRate:0,	  //放款额度
        fangKuanTime:"",	//放款时间
        thePayee:"",	    //收款人
        thePayeeRate:0,	  //收款额度
        thePayeeTime:"",	//收款时间
        channelMoney:0,	  //渠道费
        shouKuangBank:"",	//收款银行id
        assistantId:"",   //助理ID
        jingShouRu:0,     //净收入
        suoSGuWenid:"",   //所属顾问ID
        confirm:"",       //是否为财务提交
        weiyueMoney:0,       //违约金
        note:""
      },
      //净收入
      jingShouRu1:0,
      jingShouRu2:0
    };
  },
  methods: {
    //查询客户
    checkCus(){
      this.$axios
        .get(`/bill/getCustomerById?customerId=${this.formLabelAlign.customerId}`)
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.messageText == "成功!") {
            this.formLabelAlign.name = res.data.dataModel.name
            this.formLabelAlign.guwen = res.data.dataModel.user.name
            this.formLabelAlign.departmentName = res.data.dataModel.user.departmentName
            this.formLabelAlign.suoSGuWenid = res.data.dataModel.user.id
          }
          })
        .catch(error => {
            console.log(error);
        });
    },
    //查询客户备注
    checkCusNote(){
      this.$axios
        .get(`/bill/getThreeNotes?customerId=${this.formLabelAlign.customerId}`)
        .then(res => {
          if (res.data.messageModel.messageText == "成功!") {
            this.formLabelAlign.noteList = res.data.dataModel
          }
          })
        .catch(error => {
            console.log(error);
        });
    },
    //提交之后重新获取审件信息并渲染
    getBilldetail(){
      //获取编辑在审件详情
      this.$axios
      .get(`/bill/getBillById?billId=${this.billId}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.formLabelAlign = res.data.dataModel
          this.formLabelAlign.guwen = res.data.dataModel.userName
          this.formLabelAlign.name = res.data.dataModel.customerName
          this.formLabelAlign.assistantId = +res.data.dataModel.assistantId //id有时会变为字符串 需转数字
          if (this.formLabelAlign.state == "收款") {
            this.jiekuanShow = true;
            this.weiyueShow = true;
            this.ggShow = true;
            this.weiyueJingShow = false;
          }else if(this.formLabelAlign.state == "违约"){
            this.weiyueShow = true;
            this.ggShow = false;
            this.jiekuanShow = false;
            this.weiyueJingShow = true;
          }else {
            this.jiekuanShow = false;
            this.weiyueShow = false;
            this.ggShow = false;
            this.weiyueJingShow = false;
          }
          if (this.formLabelAlign.confirm == true) {
            this.showAudited = true;
          }else{
            this.showAudited = false;
          }
          this.checkCusNote()
          // this.getBankList()
          // this.getAssistantList()
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //清空隐藏输入框的值
    clearHidden(){
      this.formLabelAlign.fangKuanRate = 0,	//放款额度
      this.formLabelAlign.fangKuanTime = "",	//放款时间
      this.formLabelAlign.thePayee = "",	//收款人
      this.formLabelAlign.thePayeeRate = 0,	//收款额度
      this.formLabelAlign.thePayeeTime = "",	//收款时间
      this.formLabelAlign.channelMoney = 0,	//渠道费
      this.formLabelAlign.shouKuangBank = "",	//收款银行id
      this.formLabelAlign.assistantId = "", //助理ID
      this.formLabelAlign.jingShouRu = 0     //净收入
    },
    //提交新修改
    submit() {
      // 判断收款、诚意金、渠道费是否为数字
      if (isNaN(this.formLabelAlign.depositMoney)) {
        this.formLabelAlign.depositMoney = 0;
      }
      if (this.formLabelAlign.jinJianRate <= 1000) {
        this.$message.error("进件额度不少于1000元，请检查");
        return false;
      }
      //判断如果手动删除 则变为字符串类型但又不等于字符串空（因此只能通过数据类型判断）
      if (typeof(this.formLabelAlign.depositMoney) == "string" && this.formLabelAlign.depositMoney.length == 0) {
        this.formLabelAlign.depositMoney = 0;
      }
      if (isNaN(this.formLabelAlign.thePayeeRate)) {
        this.formLabelAlign.thePayeeRate = 0;
      }
      if (typeof(this.formLabelAlign.thePayeeRate) == "string" && this.formLabelAlign.thePayeeRate.length == 0) {
        this.formLabelAlign.thePayeeRate = 0;
      }
      if (isNaN(this.formLabelAlign.channelMoney)) {
        this.formLabelAlign.channelMoney = 0;
      }
      if (typeof(this.formLabelAlign.channelMoney) == "string" && this.formLabelAlign.channelMoney.length == 0) {
        this.formLabelAlign.channelMoney = 0;
      }
      if (isNaN(this.formLabelAlign.weiyueMoney)) {
        this.formLabelAlign.weiyueMoney = 0;
      }
      if (typeof(this.formLabelAlign.weiyueMoney) == "string" && this.formLabelAlign.weiyueMoney.length == 0) {
        this.formLabelAlign.weiyueMoney = 0;
      }
      if (isNaN(this.formLabelAlign.jingShouRu)) {
        if (this.formLabelAlign.state == "违约") {
          this.formLabelAlign.jingShouRu = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.weiyueMoney)
        }else if(this.formLabelAlign.state == "收款"){
          this.formLabelAlign.jingShouRu = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.thePayeeRate) - Number(this.formLabelAlign.channelMoney)
        }else{
          this.formLabelAlign.jingShouRu = 0
        }
      }else{
        if (this.formLabelAlign.state == "违约") {
          this.formLabelAlign.jingShouRu = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.weiyueMoney)
        }else if(this.formLabelAlign.state == "收款"){
          this.formLabelAlign.jingShouRu = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.thePayeeRate) - Number(this.formLabelAlign.channelMoney)
        }else{
          this.formLabelAlign.jingShouRu = 0
        }
      }
      if (this.currentUser.currentLevel == 0 || this.currentUser.currentLevel == 1 || this.currentUser.currentLevel == 2 || this.currentUser.currentLevel == 3 || this.currentUser.currentLevel == 4) {
        if (this.formLabelAlign.name == "") {
        this.$message.error("请先点击查询，确认该客户真实存在");
        return false
        }
        if (this.formLabelAlign.name != "") {
          this.formLabelAlign.thePayeeTime = moment(this.formLabelAlign.thePayeeTime).format("YYYY-MM-DD  HH:mm:ss")
          this.formLabelAlign.fangKuanTime = moment(this.formLabelAlign.fangKuanTime).format("YYYY-MM-DD  HH:mm:ss")
        }
        // if(typeof this.formLabelAlign.fangKuanRate !== 'number'){
        //   this.formLabelAlign.fangKuanRate = 0
        // }
        // if(typeof this.formLabelAlign.thePayeeRate !== 'number'){
        //   this.formLabelAlign.thePayeeRate = 0
        // }
        if(this.formLabelAlign.state == '审核' || this.formLabelAlign.state == '拒批'){
          // this.clearHidden()
        }
        this.$axios
        .post(`/bill/updateBill`,toQueryString(this.formLabelAlign), {headers:{'Content-type':'application/x-www-form-urlencoded'}})
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.code == 0){
            this.$message({  message: '恭喜你，修改成功',type: 'success'});
            this.formLabelAlign.note = ""
            this.checkCusNote()
            this.getBilldetail()
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.getBilldetail()
          }
        })
        .catch(error => {
            console.log(error);
        });
      }else{
        this.$message.error("抱歉，您的权限被限制修改审件，如需修改请与上级联系。");
      }
    },
    //通过审核
    Approved() {
        // if (this.formLabelAlign.name == "") {
        // this.$message.error("请先点击查询，确认该客户真实存在");
        // return false
        // }
        if (this.formLabelAlign.name != "") {
          this.formLabelAlign.thePayeeTime = moment(this.formLabelAlign.thePayeeTime).format("YYYY-MM-DD  HH:mm:ss")
          this.formLabelAlign.fangKuanTime = moment(this.formLabelAlign.fangKuanTime).format("YYYY-MM-DD  HH:mm:ss")
        }
        if(typeof this.formLabelAlign.fangKuanRate !== 'number'){
          this.formLabelAlign.fangKuanRate = 0;
        }
        if(typeof this.formLabelAlign.thePayee !== 'number'){
          this.formLabelAlign.thePayee = 0;
        }
        if(this.formLabelAlign.state == '审核' || this.formLabelAlign.state == '拒批'){
          this.clearHidden();
        }
        this.formLabelAlign.confirm = this.confirm
        this.$axios
        .post(`/bill/updateBill`,toQueryString(this.formLabelAlign), {headers:{'Content-type':'application/x-www-form-urlencoded'}})
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.code == 0){
            this.$message({  message: '成功通过审核',type: 'success'});
            this.formLabelAlign.note = ""
            this.getBilldetail()
            this.checkCus()
            this.checkCusNote()
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
            console.log(error);
        });
    },
    //撤销审核
    DelApproved() {
        // if (this.formLabelAlign.name == "") {
        // this.$message.error("请先点击查询，确认该客户真实存在");
        // return false
        // }
        if (this.formLabelAlign.name != "") {
          this.formLabelAlign.thePayeeTime = moment(this.formLabelAlign.thePayeeTime).format("YYYY-MM-DD  HH:mm:ss")
          this.formLabelAlign.fangKuanTime = moment(this.formLabelAlign.fangKuanTime).format("YYYY-MM-DD  HH:mm:ss")
        }
        if(typeof this.formLabelAlign.fangKuanRate !== 'number'){
          this.formLabelAlign.fangKuanRate = 0
        }
        if(typeof this.formLabelAlign.thePayee !== 'number'){
          this.formLabelAlign.thePayee = 0
        }
        if(this.formLabelAlign.state == '审核' || this.formLabelAlign.state == '拒批'){
          this.clearHidden()
        }
        this.formLabelAlign.confirm = false
        this.$axios
        .post(`/bill/updateBill`,toQueryString(this.formLabelAlign), {headers:{'Content-type':'application/x-www-form-urlencoded'}})
        .then(res => {
          //console.log(res);
          if (res.data.messageModel.code == 0){
            this.$message({  message: '成功撤销审核',type: 'success'});
            this.formLabelAlign.note = ""
            this.checkCus()
            this.checkCusNote()
            this.getBilldetail()
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
            console.log(error);
        });
    },
    getBankList(){
      //获取银行列表的请求
      this.$axios
      .get(`/bill/getBankList`)
      .then(res => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
        }else{
          this.bankList = res.data.dataModel;
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    getAssistantList(){
      //获取助理列表的请求
      this.$axios
        .get(`/bill/getAssistantList?userId=${this.currentUser.currentid}`)
        .then(res => {
          let dataModel = res.data.dataModel;
          if (dataModel == null) {
            this.$message.error("网络异常，请稍后再试/(ㄒoㄒ)/~~");
          }else{
            this.assistantList = res.data.dataModel;
          }
          })
        .catch(error => {
          console.log(error);
        });
    },
    //获取进度 控制隐藏和现实
    getjindu(vId) {
      // console.log(typeof (this.formLabelAlign.weiyueMoney)　== "undefined");
      let obj = {};
      obj = this.jinduList.find(item => {
        return item.id === vId; //筛选出匹配数据
      });
      if (obj.name == "收款") {
        this.jiekuanShow = true;
        this.weiyueShow = true;
        this.ggShow = true;
      } else if(obj.name == "违约"){
        this.weiyueShow = true;
        this.ggShow = false;
        this.jiekuanShow = false;
        this.weiyueJingShow = true;
      } else {
        this.weiyueJingShow = false;
        this.jiekuanShow = false;
        this.weiyueShow = false;
        this.ggShow = false;
      }
    },
    countjingShouRu1(){
      this.jingShouRu1 = Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney);
    },
    countjingShouRu2(){
      this.jingShouRu2 = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.weiyueMoney);
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    // this.billId = this.$route.query.billId
    this.billId = window.sessionStorage.getItem("billId")
    var confirm = window.sessionStorage.getItem("confirm")
    if (confirm == true || confirm == "true") {
      this.confirm = true
    }else{
      this.confirm = false
    }
    //获取编辑在审件详情
    this.$axios
      .get(`/bill/getBillById?billId=${this.billId}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.formLabelAlign = res.data.dataModel
          if (typeof (this.formLabelAlign.weiyueMoney)　== "undefined") {
            this.formLabelAlign.weiyueMoney = 0;
          }
          this.formLabelAlign.guwen = res.data.dataModel.userName
          this.formLabelAlign.name = res.data.dataModel.customerName
          this.formLabelAlign.assistantId = +res.data.dataModel.assistantId //id有时会变为字符串
          if (this.formLabelAlign.state == "收款") {
            this.jiekuanShow = true;
            this.weiyueShow = true;
            this.ggShow = true;
            this.weiyueJingShow = false;
          }else if(this.formLabelAlign.state == "违约"){
            this.weiyueShow = true;
            this.ggShow = false;
            this.jiekuanShow = false;
            this.weiyueJingShow = true;
          }else {
            this.weiyueJingShow = false;
            this.jiekuanShow = false;
            this.weiyueShow = false;
            this.ggShow = false;
            // this.clearHidden();
          }
          if (this.formLabelAlign.confirm == true) {
            this.showAudited = true;
          }else{
            this.showAudited = false;
          }
          this.checkCusNote()
          this.getBankList()
          this.getAssistantList()
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
        })
    .catch(error => {
      console.log(error);
    });
    if (typeof (this.formLabelAlign.weiyueMoney)　== "undefined") {
      this.formLabelAlign.weiyueMoney = 0;
    }
  },
  activated () {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    // this.billId = this.$route.query.billId
    this.billId = window.sessionStorage.getItem("billId")
    var confirm = window.sessionStorage.getItem("confirm")
    if (confirm == true || confirm == "true") {
      this.confirm = true
    }else{
      this.confirm = false
    }
    //获取编辑在审件详情
    this.$axios
      .get(`/bill/getBillById?billId=${this.billId}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.formLabelAlign = res.data.dataModel
          if (typeof (this.formLabelAlign.weiyueMoney)　== "undefined") {
            this.formLabelAlign.weiyueMoney = 0;
          }
          this.formLabelAlign.guwen = res.data.dataModel.userName
          this.formLabelAlign.name = res.data.dataModel.customerName
          this.formLabelAlign.assistantId = +res.data.dataModel.assistantId //id有时会变为字符串
          if (this.formLabelAlign.state == "收款") {
            this.jiekuanShow = true;
            this.weiyueShow = true;
            this.ggShow = true;
            this.weiyueJingShow = false;
          }else if(this.formLabelAlign.state == "违约"){
            this.weiyueShow = true;
            this.ggShow = false;
            this.jiekuanShow = false;
            this.weiyueJingShow = true;
          }else {
            this.weiyueJingShow = false;
            this.jiekuanShow = false;
            this.weiyueShow = false;
            this.ggShow = false;
            // this.clearHidden();
          }
          if (this.formLabelAlign.confirm == true) {
            this.showAudited = true;
          }else{
            this.showAudited = false;
          }
          this.checkCusNote()
          this.getBankList()
          this.getAssistantList()
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
        })
    .catch(error => {
      console.log(error);
    });
    if (typeof (this.formLabelAlign.weiyueMoney)　== "undefined") {
      this.formLabelAlign.weiyueMoney = 0;
    }
  },
  computed: {
    //计算净收入
    countNum:function(){
      this.countjingShouRu1();
      // this.jingShouRu1 = Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)
      // console.log(this.formLabelAlign.jingShouRu);
      return Number(this.formLabelAlign.thePayeeRate) + Number(this.formLabelAlign.depositMoney)  - Number(this.formLabelAlign.channelMoney)
      },
    //违约情况下计算净收入
    countNum1:function(){
      this.countjingShouRu2();
      // this.jingShouRu2 = Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.weiyueMoney)
      // console.log(this.formLabelAlign.jingShouRu);
      return Number(this.formLabelAlign.depositMoney) + Number(this.formLabelAlign.weiyueMoney)
      }
  }
};
</script>

<style  lang='scss'>
.examineEdit-container {
  position: relative;
  #Audited {
    position:absolute;
    z-index: 888;
    top: 45%;
    right: 45%;
  }
  .el-input {
    width: auto;
  }
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      line-height: 20px;
      width: 100%;
      color: 	#708090;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
    //labal文字居左
  }
  .el-form-item__label {
    display: flex;
  }
  .el-button--danger {
    line-height: 6px;
  }
  // 时间分类框样式
  .examineEdit-container input.el-input__inner#againkinds,
  .examineEdit-container input.el-input__inner#againtime {
    width: 85px;
    padding-left: 7px;
  }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
    width: 300px;
  }
  .el-form-item.jingshouru {
    width: 100%;
    .jine {
      color: #C73E3A;
    }
    .gongshi {
      color:#666666;
    }
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
    #submit {
      margin-left: 20px;
    }
    .cusAddTit {
      display: inline-block;
      font-size: 17px;
      color: #c73e3a;
      margin-left: 20px;
      line-height: 36px;
    }
  }
  .filterCondition.upOne {
    border-bottom: 1px solid #f3f3f3;
    margin: 0;
  }
  .filterCondition.downOne {
    padding-top: 10px;
    padding-left: 20px;
    .redSpan {
      color: #c73e3a;
    }
    //文本域
    .el-textarea {
        width: 430px;
    }
    #momecontent {
      /deep/.el-form-item__content {
        margin-left: 30px !important;
        .el-input__inner {
          width: 485px;
        }
      }
    }
    #noteList {
      width: 700px;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      border: 1px solid #DCDFE6;
      background-color: #F5F7FA;
    }
    // #backManger {
    //   /deep/.el-form-item__label {
    //     width: 96px !important;
    //   }
    // }
    #submit {
      margin-left: 230px;
    }
    .examineTine {
      input{
         padding-left: 27px;
      }
    }
  }
  .zaifenCheck {
    margin-left: 20px;
  }
  .zaifenBth.el-button {
    padding: 10px 20px;
  }
  #dateOfBirth {
    width: 170px;
    input.el-input__inner {
      width: 170px;
      height: 32px;
      padding-left: 30px;
    }
  }
  .redistribution-container input.el-input__inner {
    width: 182px;
    height: 32px;
    padding-left: 10px;
  }
  #name .el-form-item__label {
    width: 43px;
  }
  .benhangye {
    display: inline-block;
    vertical-align: top;
    /deep/ .el-form-item__content {
      margin-left: 10px !important;
    }
    #hangyejingyan {
      display: flex;
      margin: 0;

      .el-form-item__label {
        width: 115px;
      }
    }
  }
  // 从业年限
  .nianxian {
    margin-left: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 182px;
  }
  //图片区域
  .picArea {
    //图片展示框的大小
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .picChild {
      float: left;
      width: 25%;
      display: flex;
      margin-top: 20px;
      span {
        width: 20%;
        font-size: 14px;
        color: #606266;
        margin-right: 20px;
        margin-left: 20px;
      }
      .avatar {
        width: 268px;
        height: 162px;
      }
      i {
        width: 268px;
        height: 162px;
      }
    }
  }
}
</style>
