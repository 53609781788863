<template>
  <div class="PromotionAnalysis-container" >
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
        <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
            <el-form-item prop="city" label="城市" v-if="ShowCity">
            <el-select v-model="formLabelAlign.city" placeholder="请选择城市" >
                <el-option label="所有" value="all"></el-option>
                <el-option
                    v-for="item in cityselectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="scopeOf" label="平台">
            <el-select v-model="formLabelAlign.scopeOf" placeholder="请选择层级">
                <el-option label="忽略层级" value = ""  ></el-option>
                <el-option label="城市" value="city" v-if="ShowCity" ></el-option>
                <el-option
                    v-for="item in cengJiList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
            </el-select>
            </el-form-item>
            <el-form-item prop="timeType" label="时间">
            <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
                <el-option label="不限时间" value = ""  ></el-option>
                <el-option
                v-for="item in timeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                ></el-option>
            </el-select>
            </el-form-item>
            <el-form-item prop="value1" label="时间区间" v-show="isShow">
            <div class="block" >
                <el-date-picker
                v-model="value1"
                type="daterange"
                format="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                ></el-date-picker>
            </div>
            </el-form-item>
            <el-form-item class="BTN">
            <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check" :disabled="disTBN">查询</el-button>
            <!-- <el-button style="background-color:	#00CDCD;color:#fff" @click="resetForm('formLabelAlign')"   class="check">重置筛选条件</el-button> -->
            </el-form-item>
        </el-form>
    </el-row>
    <!-- Ecahrts -->
    <el-row class="filterCondition" v-loading="loading">
        <!-- <h3>推广数据统计分部图</h3> -->
        <div id="main" style="width: 100%;height: 400px;"></div>
    </el-row>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
import echarts from 'echarts'
export default {
  name: "PromotionAnalysis",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
      ShowCity:false,
      loading:true,
      showTimeKind:true,
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      //禁用按钮的状态值
      disTBN:false,
      cityselectList: [{ id: 0, name: "请先选择城市" }],
      cengJiList: [
        { id: "fenGS", name: "分公司" },
        { id: "quyu", name: "大区" },
        { id: "bumen", name: "部门" },
        { id: "guwen", name: "顾问" }
        ],
       //时间选择生成内容
      timeList: [
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
        ],
      //客户筛选区域数据
      formLabelAlign: {
        scopeOf : "", 	//	层级
        city : "", 	//	状态
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        value1 : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      //echarts
      timeData:['0时','1时','2时','3时','4时','5时','6时','7时','8时','9时','10时','11时','12时','13时','14时','15时','16时','17时','18时','19时','20时','21时','22时','23时'],
      BenYueData:[310, 220, 182, 191, 234, 290, 330, 392, 301, 334, 390, 330, 320, 610, 298, 358, 182, 491, 234, 290, 330, 392, 301, 334],
      ShangYueData:[258, 320, 332, 301, 334, 390, 330, 320, 1294, 290, 300, 332, 301, 332, 301, 334, 332, 301, 334, 390, 330, 320, 1294, 290]
    };
  },
  methods: {
    hiddenCengJi(){
      for(let key  in this.tableDepartmentShows){
        this.tableDepartmentShows[key] = false
      }
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";  
        }
        this.formLabelAlign.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.name == "自定义") {
        this.isShow = true;
        this.formLabelAlign.dayType  = "customize";
      } 
      else if (obj.name == "昨日") {
        this.isShow = false;
        this.formLabelAlign.dayType  = "yestoday";
      } 
      else if (obj.name == "最近七天") {
        this.isShow = false;
        this.formLabelAlign.dayType  = "sevenDay";
      } 
      else if (obj.name == "本月") {
        this.isShow = false;
        this.formLabelAlign.dayType  = "tomonth";
      } 
      else if (obj.name == "上月") {
        this.isShow = false;
        this.formLabelAlign.dayType  = "yesmonth";
      } 
      else if (obj.name == "今日") {
        this.isShow = false;
        this.formLabelAlign.dayType  = "today";
      } 
      else {
        this.isShow = false;
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //查询
    checkCus(){
      this.disButton()
      this.loading = true
      if (this.currentUser.currentcity == null) {
        if (this.ShowCity == true && this.formLabelAlign.city == "") {
        this.$message.error("请选择城市");
        return false
        }
      }else if(this.formLabelAlign.scopeOf == "city"){
        this.hiddenCengJi()
        this.tableDepartmentShows.cityShow = true
      }else if(this.formLabelAlign.scopeOf == "fenGS"){
        this.hiddenCengJi()
        this.tableDepartmentShows.fenShow = true
      }else if(this.formLabelAlign.scopeOf == "quyu"){
        this.hiddenCengJi()
        this.tableDepartmentShows.quyuShow = true
      }else if(this.formLabelAlign.scopeOf == "bumen"){
        this.hiddenCengJi()
        this.tableDepartmentShows.bumenShow = true
      }else if(this.formLabelAlign.scopeOf == "guwen"){
        this.hiddenCengJi()
        this.tableDepartmentShows.bumenShow = true
        this.tableDepartmentShows.guWenShow = true
      }else if(this.formLabelAlign.scopeOf == ""){
        this.hiddenCengJi()
        this.tableDepartmentShows.dateShow = true
      }
      
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        }
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }    
    },
    drawPie(id){
      this.charts = echarts.init(document.getElementById(id))
      this.charts.setOption({
      title: {
        text: '业绩数据统计分部图'
    },
    tooltip : {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    legend: {
        data:['本月',
        '上月',
        // '对比数值'
        ]
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis : [
        {
            type : 'category',
            boundaryGap : false,
            data : this.timeData
        }
    ],
    yAxis : [
        {
            type : 'value'
        }
    ],
    series : [
        {
            name:'本月',
            type:'line',
            stack: '总量1',
            areaStyle: {
                normal: {
                color:'#DF3F46',  
                    lineStyle:{  
                        color:'#DF3F46'  
                    }  
                }
            },
            data:this.BenYueData
        },
        {
            name:'上月',
            type:'line',
            stack: '总量2',
            areaStyle: {normal: {
                color:'#1DA563',  
                lineStyle:{  
                    color:'#1DA563'  
                } 
            }},
            data:this.ShangYueData
        },
        // {
        //     name:'对比数值',
        //     type:'line',
        //     stack: '总量3',
        //     label: {
        //         normal: {
        //             // show: true,
        //             // position: 'top'
        //         }
        //     },
        //     areaStyle: {normal: {}},
        //     data:[999, 820, 932, 901, 934, 1290, 1330, 1320, 258, 1593, 1111, 2589, 369 ,689, 1425, 2631, 3932, 901, 934, 1290, 1330, 1320, 258, 1593, 1111]
        // }
    ]
      })
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YY-MM-DD");
    },
  },
  created () {
    this.loading = true
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
      this.currentUser.currentName = window.sessionStorage.getItem("name")
      this.currentUser.currentid = window.sessionStorage.getItem("id")
      this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
      this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
      if (this.currentUser.currentLevel == 0 && this.currentUser.currentcity == "null") {
        this.formLabelAlign.city = "all"
        this.ShowCity = true
        //获取所有城市
          this.$axios
            .get(`/department/getAll`)
            .then(res => {
              // console.log(res);
              this.cityselectList = res.data;
              this.loading = false
            })
            .catch(error => {
              console.log(error);
              this.loading = false
            });
      }else{
        this.formLabelAlign.city = this.currentUser.currentcity
      }
  },
  // methods:{
    
  // },
  mounted(){
    this.$nextTick(function() {
        this.drawPie('main')
    })
  },
  //抽取到vuex中试一试
  computed:{
    //  Echartsdata(){
    //    return this.$store.state.common.Echartsdata;
    //  },
    //  EchartsListdata(){
    //    return this.$store.state.common.EchartsListdata;
    //  }
  },
};
</script>

<style  lang='scss'>
.PromotionAnalysis-container {
    //echarts的样式
  * {
        // margin: 0;
        // padding: 0;
        list-style: none;
    } 
 
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important; 
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>