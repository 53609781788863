<template>
  <div class="employeeAdd-container">
    <!-- 人事信息 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item label="当前岗位">
          <!-- <el-select v-model="formLabelAlign.companyPosition" @change="levelLimited" placeholder> -->
          <el-select v-model="formLabelAlign.companyPosition" placeholder>
            <el-option v-if="ShowManger.showCity" label="城市负责人" value="城市负责人"></el-option>
            <el-option v-if="ShowManger.showCity" label="城市负责人(储备)" value="城市负责人(储备)"></el-option>
            <el-option v-if="ShowManger.showFen" label="分公司负责人" value="分公司负责人"></el-option>
            <el-option v-if="ShowManger.showFen" label="分公司负责人(储备)" value="分公司负责人(储备)"></el-option>
            <el-option v-if="ShowManger.showQu" label="区长" value="区长"></el-option>
            <el-option v-if="ShowManger.showQu" label="区长(储备)" value="区长(储备)"></el-option>
            <el-option v-if="ShowManger.showBu" label="部长" value="部长"></el-option>
            <el-option v-if="ShowManger.showBu" label="部长(储备)" value="部长(储备)"></el-option>
            <el-option v-if="ShowManger.showGuWen" label="顾问" value="顾问"></el-option>
            <el-option v-if="ShowManger.showGuWen" label="学徒" value="学徒"></el-option>
            <!-- <el-option v-for="item in partList" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            @change="selectGetFen"
            v-model="formLabelAlign.city"
            :placeholder="holderList.cityHolder"
            :disabled="disabled.citydisabled"
          >
            <el-option
              v-for="item in cityselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分公司">
          <el-select
            @change="selectGetYi"
            v-model="formLabelAlign.fengongsi"
            :placeholder="holderList.fengongsiHolder"
            :disabled="disabled.fendisabled"
          >
            <el-option
              v-for="item in fenselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一级部门">
          <el-select
            @change="selectGetEr"
            v-model="formLabelAlign.yiji"
            :placeholder="holderList.yijiHolder"
            :disabled="disabled.yijidisabled"
          >
            <el-option
              v-for="item in yijiselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级部门">
          <el-select
            @change="putDepartmentId"
            v-model="formLabelAlign.erji"
            :placeholder="holderList.erjiHolder"
            :disabled="disabled.erjidisabled"
          >
            <el-option
              v-for="item in erjiselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="公司手机">
          <el-input v-model="formLabelAlign.phone"></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 档案信息 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item class="dangan" label="*姓名">
          <el-input v-model="formLabelAlign.name" placeholder></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="出生日期">
          <div class="block" id="dateOfBirth">
            <el-date-picker
              v-model="formLabelAlign.birthday"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan" label="性别">
          <el-select v-model="formLabelAlign.sex" placeholder>
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="籍贯">
          <el-input v-model="formLabelAlign.address" placeholder="例如：河北 天津"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="入职时间">
          <div class="block" id="dateOfBirth">
            <el-date-picker
              v-model="formLabelAlign.inTheTime"
              type="date"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item class="dangan" label="学历">
          <el-select v-model="formLabelAlign.schooling" placeholder>
            <el-option label="初中及以下" value="初中及以下"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="研究生及以上" value="研究生及以上"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="dangan" label="毕业院校">
          <el-input v-model="formLabelAlign.schoolName"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="身份证号">
          <el-input v-model="formLabelAlign.idCard"></el-input>
        </el-form-item>
        <el-form-item class="mobile" label="个人电话">
          <el-input v-model="formLabelAlign.mobile"></el-input>
        </el-form-item>
        <!--<el-form-item class="mobile" label="分机号">
          <el-input v-model="formLabelAlign.fenjiNo"></el-input>
        </el-form-item>-->
        <el-form-item class="dangan" label="前公司名">
          <el-input v-model="formLabelAlign.oldCompanyName"></el-input>
        </el-form-item>
        <el-form-item class="dangan" label="曾任职务">
          <el-input v-model="formLabelAlign.oldCompanyPosition"></el-input>
        </el-form-item>
        <div class="benhangye">
          <el-form-item class="dangan hangyejingyan" id="hangyejingyan" label="从业年限">
            <el-input v-model="formLabelAlign.workingTime" id="nianxian"></el-input>
          </el-form-item>
        </div>
        <br />
        <el-form-item class="dangan" id="describe" label="个人简介">
          <el-input v-model="formLabelAlign.describe" id="nianxian" ></el-input>
        </el-form-item>
        <div class="picArea">
          <div class="headPortrait picChild">
            <span>头像</span>
            <el-upload
              class="avatar-uploader headPortrait"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload"
              :on-change="onchange1"
              style="display:inline-block"
            >
              <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="frontCard picChild">
            <span>身份证(正面)</span>
            <el-upload
              class="avatar-uploader frontCard"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload2"
              :on-change="onchange2"
              style="display:inline-block"
            >
              <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="reverseCard picChild">
            <span>身份证(反面)</span>
            <el-upload
              class="avatar-uploader reverseCard"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload3"
              :on-change="onchange3"
              style="display:inline-block"
            >
              <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="creditReport picChild">
            <span>征信报告</span>
            <el-upload
              class="avatar-uploader creditReport"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload4"
              :on-change="onchange4"
              style="display:inline-block"
            >
              <img v-if="imageUrl4" :src="imageUrl4" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="laborContract picChild">
            <span>劳务合同</span>
            <el-upload
              class="avatar-uploader laborContract"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload5"
              :on-change="onchange5"
              style="display:inline-block"
            >
              <img v-if="imageUrl5" :src="imageUrl5" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="graduationCard picChild">
            <span>毕业证</span>
            <el-upload
              class="avatar-uploader graduationCard"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload6"
              :on-change="onchange6"
              style="display:inline-block"
            >
              <img v-if="imageUrl6" :src="imageUrl6" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="medicalReport picChild">
            <span>体检报告</span>
            <el-upload
              class="avatar-uploader medicalReport"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload7"
              :on-change="onchange7"
              style="display:inline-block"
            >
              <img v-if="imageUrl7" :src="imageUrl7" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="bankCard picChild">
            <span>银行卡</span>
            <el-upload
              class="avatar-uploader bankCard"
              action
              :show-file-list="false"
              :auto-upload="false"
              :before-upload="beforeAvatarUpload8"
              :on-change="onchange8"
              style="display:inline-block"
            >
              <img v-if="imageUrl8" :src="imageUrl8" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <el-button type="primary" @click="submit" id="submit" :disabled="disTBN">提交按钮</el-button>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "employeeAdd",
  // 数据
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      user: "", //表单要提交的参数
      //图片
      imageUrl1: "", //头像
      imageUrl2: "", //身份证正面
      imageUrl3: "", //身份证反面
      imageUrl4: "", //征信报告
      imageUrl5: "", //劳务合同
      imageUrl6: "", //毕业证
      imageUrl7: "", //体检报告
      imageUrl8: "", //银行卡
      //层级部门是否限制选择
      disabled: {
        citydisabled: false,
        fendisabled: false,
        yijidisabled: false,
        erjidisabled: false
      },
      ShowManger:{
        showCity : true,
        showFen : true,
        showQu : true,
        showBu : true,
        showGuWen : true
      },
      //层级部门holder内容
      holderList: {
        cityHolder: "请选择城市",
        fengongsiHolder: "请选择分公司",
        yijiHolder: "请选择一级部门",
        erjiHolder: "请选择二级部门"
      },
      //职位可选项
      partList: [
        { id: 0, name: "城市负责人" },
        { id: 1, name: "城市负责人(储备)" },
        { id: 2, name: "分公司负责人" },
        { id: 3, name: "分公司负责人(储备)" },
        { id: 4, name: "区长" },
        { id: 5, name: "区长(储备)" },
        { id: 6, name: "部长" },
        { id: 7, name: "部长(储备)" },
        { id: 8, name: "顾问" },
        { id: 9, name: "学徒" }
      ],
      disTBN:false,
      //城市动态生成内容
      cityselectList: [{ id: 0, name: "请先选择城市" }],
      //分公司动态生成内容
      fenselectList: [{ id: 0, name: "请先选择上一级" }],
      //一级部门动态生成内容
      yijiselectList: [{ id: 0, name: "请先选择上一级" }],
      //二级部门动态生成内容
      erjiselectList: [{ id: 0, name: "请先选择上一级" }],
      //当前登陆人员level
      level: "",
      //messageText为首次加载时的返回状态
      messageText: "",
      //图片
      imageUrl: "",
      //时间选择框数据
      value1: "",
      //客户筛选区域数据
      formLabelAlign: {
        name: "",
        city: "", //城市
        fengongsi: "", //分公司
        yiji: "", //一级部门
        erji: "", //二级部门
        departmentId: "", //departmentId
        address: "",
        inTheTime: "",
        companyPosition: "",
        sex: "",
        phone: "", //分配手机号
        mobile: "",
        fenjiNo:"",//分机号
        schooling: "",
        schoolName: "",
        idCard: "",
        oldCompanyName: "",
        oldCompanyPosition: "",
        noteList: [{content:"还没有给该客户添加任何备注"}],
        workingTime: "",
        describe: "",
        birthday: ""
      }
    };
  },
  methods: {
    //点击岗位限制选择的选项
    levelLimited(vId) {
      // let obj = {};
      // obj = this.partList.find(item => {
      //   //这里的cityselectList就是上面遍历的数据源
      //   return item.id === vId; //筛选出匹配数据
      // });
      // this.formLabelAlign.companyPosition = obj.name;
      // // console.log(obj.name);//name就是对应label的
      // if (obj.name == "城市负责人" || obj.name == "城市负责人(储备)") {
      //   //禁用城市一下部门选择
      //   this.disabled.fendisabled = true;
      //   this.disabled.yijidisabled = true;
      //   this.disabled.erjidisabled = true;
      // } else if (
      //   obj.name == "分公司负责人" ||
      //   obj.name == "分公司负责人(储备)"
      // ) {
      //   this.disabled.fendisabled = false;
      //   this.disabled.yijidisabled = true;
      //   this.disabled.erjidisabled = true;
      // } else if (obj.name == "区长" || obj.name == "区长(储备)") {
      //   this.disabled.fendisabled = false;
      //   this.disabled.yijidisabled = false;
      //   this.disabled.erjidisabled = true;
      // } else if (obj.name == "部长" || obj.name == "部长(储备)") {
      //   this.disabled.fendisabled = false;
      //   this.disabled.yijidisabled = false;
      //   this.disabled.erjidisabled = false;
      // } else {
      //   this.disabled.fendisabled = false;
      //   this.disabled.yijidisabled = false;
      //   this.disabled.erjidisabled = false;
      // }
    },
    //城市获取分公司数据
    selectGetFen(vId) {
      let obj = {};
      obj = this.cityselectList.find(item => {
        //这里的cityselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //保存departmentId
      this.formLabelAlign.departmentId = obj.id;
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          // console.log(res.data.dataModel);
          this.fenselectList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //分公司获取一级部门数据
    selectGetYi(vId) {
      let obj = {};
      obj = this.fenselectList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //保存departmentId
      this.formLabelAlign.departmentId = obj.id;
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          this.yijiselectList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //一级部门获取二级部门数据
    selectGetEr(vId) {
      let obj = {};
      obj = this.yijiselectList.find(item => {
        //这里的erjiselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //保存departmentId
      this.formLabelAlign.departmentId = obj.id;
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          this.erjiselectList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //提交departmentId
    putDepartmentId(vId) {
      let obj = {};
      obj = this.erjiselectList.find(item => {
        //这里的erjiselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //保存departmentId
      this.formLabelAlign.departmentId = obj.id;
    },
    //判断图片类型和大小
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //清空所有值的方法
    clearData(){
      this.user = new FormData();
      this.imageUrl1 =  "" ;
      this.imageUrl2 =  "" ;
      this.imageUrl3 =  "" ;
      this.imageUrl4 =  "" ;
      this.imageUrl5 =  "" ;
      this.imageUrl6 =  "" ;
      this.imageUrl7 =  "" ;
      this.imageUrl8 =  "" ;
      for(let key  in this.formLabelAlign){
        this.formLabelAlign[key] = ""
      }
    },
    //判断图片类型和大小
    // eslint-disable-next-line no-dupe-keys,vue/no-dupe-keys
    beforeAvatarUpload(file) {
      const isJPG = file1.type === "image/jpeg/JPG";
      const isLt2M = file1.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange1(file, file1List) {
      if (this.imageUrl1 == "") {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl1 = URL.createObjectURL(file.raw);
        this.user.append("headPortrait", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }

        this.imageUrl1 = URL.createObjectURL(file.raw);
        this.user.headPortrait = "";
        this.user.append("headPortrait", file.raw, file.name);
      }
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange2(file, filesList) {
      if (this.imageUrl2 == "") {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          return isLt2M;
        }
        this.imageUrl2 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("frontCard", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl2 = URL.createObjectURL(file.raw);
        this.user.frontCard = "";
        this.user.append("frontCard", file.raw, file.name);
      }
    },
    beforeAvatarUpload3(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange3(file, filesList) {
      if (this.imageUrl3 == "") {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          return isLt2M;
        }
        this.imageUrl3 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("reverseCard", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }

        this.imageUrl3 = URL.createObjectURL(file.raw);
        this.user.reverseCard = "";
        //新表单已经产生 无需再次创建
        this.user.append("reverseCard", file.raw, file.name);
      }
    },
    beforeAvatarUpload4(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange4(file, filesList) {
      if (this.imageUrl4 == "") {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl4 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("creditReport", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl4 = URL.createObjectURL(file.raw);
        this.user.creditReport = "";
        //新表单已经产生 无需再次创建
        this.user.append("creditReport", file.raw, file.name);
      }
    },
    beforeAvatarUpload5(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange5(file, filesList) {
      if (this.imageUrl5 == "") {
        //劳务合同
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl5 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("laborContract", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl5 = URL.createObjectURL(file.raw);
        this.user.laborContract = "";
        //新表单已经产生 无需再次创建
        this.user.append("laborContract", file.raw, file.name);
      }
    },
    beforeAvatarUpload6(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange6(file, filesList) {
      if (this.imageUrl6 == "") {
        //劳务合同
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl6 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("graduationCard", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl6 = URL.createObjectURL(file.raw);
        this.user.graduationCard = "";
        //新表单已经产生 无需再次创建
        this.user.append("graduationCard", file.raw, file.name);
      }
    },
    beforeAvatarUpload7(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange7(file, filesList) {
      if (this.imageUrl7 == "") {
        //体检报告
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl7 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("medicalReport", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl7 = URL.createObjectURL(file.raw);
        this.user.medicalReport = "";
        //新表单已经产生 无需再次创建
        this.user.append("medicalReport", file.raw, file.name);
      }
    },
    beforeAvatarUpload8(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onchange8(file, filesList) {
      if (this.imageUrl8 == "") {
        //体检报告
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl8 = URL.createObjectURL(file.raw);
        //新表单已经产生 无需再次创建
        this.user.append("bankCard", file.raw, file.name);
      } else {
        // const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {this.$message.error("上传头像图片只能是 image/jpeg 格式!");}
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
          // return isJPG && isLt2M;
          return isLt2M;
        }
        this.imageUrl8 = URL.createObjectURL(file.raw);
        this.user.bankCard = "";
        //新表单已经产生 无需再次创建
        this.user.append("bankCard", file.raw, file.name);
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    // onSubmit(){//表单提交的事件
    submit() {
      this.disButton()
      // var reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
      if (this.formLabelAlign.name == "") {
        this.$message.error("姓名不能为空");
        return false
      // }else if(reg.test(this.formLabelAlign.name)) {
      //   this.$message.error("姓名只能为纯中文字符");
      //   return false
      }else if (this.formLabelAlign.phone == "") {
        this.$message.error("公司分配号码不能为空");
        return false
      }
     /* else if (this.formLabelAlign.fenjiNo == "") {
        this.$message.error("分机号不能为空");
        return false
      }*/
      //判断手机号是否有效
      if (!/^1[3456789]\d{9}$/.test(this.formLabelAlign.phone)) {
        this.$message.error("分配手机号码填写有误，请检查┭┮﹏┭┮");
        return false;
      } else {
        //请求验证分配号码是否已被注册
        this.$axios
          .post(
            "/user/getUserByPhone?phone=" + this.formLabelAlign.phone
          )
          .then(res => {
            // console.log(res);
            if (res.data.dataModel == null) {
              //说明该号码没有被注册 继续下一步提交
              if (!/^1[3456789]\d{9}$/.test(this.formLabelAlign.mobile)) {
                this.$message.error("个人手机号码填写有误，请检查┭┮﹏┭┮");
                return false;
              }
              //下面append的东西就会到form表单数据的fields中；
              this.user.append("name", this.formLabelAlign.name);
              this.user.append("fenjiNo", this.formLabelAlign.fenjiNo);//分机号
              this.user.append("companyPosition",this.formLabelAlign.companyPosition);
              this.user.append("departmentId",this.formLabelAlign.departmentId);
              this.user.append("birthday", this.formLabelAlign.birthday);
              this.user.append("sex", this.formLabelAlign.sex);
              this.user.append("address", this.formLabelAlign.address);
              this.user.append("inTheTime", this.formLabelAlign.inTheTime);
              this.user.append("mobile", this.formLabelAlign.mobile);
              this.user.append("phone", this.formLabelAlign.phone);
              this.user.append("schooling", this.formLabelAlign.schooling);
              this.user.append("schoolName", this.formLabelAlign.schoolName);
              this.user.append("idCard", this.formLabelAlign.idCard);
              this.user.append("oldCompanyName",this.formLabelAlign.oldCompanyName);
              this.user.append("oldCompanyPosition",this.formLabelAlign.oldCompanyPosition);
              this.user.append("workingTime", this.formLabelAlign.workingTime);
              this.user.append("describe", this.formLabelAlign.describe);
              this.user.append("userId", this.currentUser.currentid);
              //  console.log(this.user);

              let config = {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              };
              //提交注册信息
              this.$axios
                .post("/user/addOrUpdateUser", this.user, config)
                .then(res => {
                  // console.log(res);
                  if (res.data.messageModel.code == 0) {
                    this.$message.success(  "已为您提交新增人员信息"  );
                    this.clearData()
                  } else {
                    this.$message.error(res.data.messageModel.messageText);
                    this.clearData()
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            } else {
              this.$message.error(
                "该分配手机号码已经被注册，请检查后重新输入(⊙o⊙)…"
              );
              return false;
            }
          });
      }
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentDepartmentId = window.sessionStorage.getItem("departmentId");
    if (this.currentUser.currentLevel == 0) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
    }else if (this.currentUser.currentLevel == 1) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
    }else if (this.currentUser.currentLevel == 2) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
    }else if (this.currentUser.currentLevel == 3) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
      this.ShowManger.showQu = false
    }else if (this.currentUser.currentLevel == 4) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
      this.ShowManger.showGuWen = true
    }else {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
    }
    //将user创建为一个表单上传的方法
    this.user = new FormData();
    //获取组织架构数据的请求
    this.$axios
      .get(`/department/getDepartmentById?departmentId=`+this.currentUser.currentDepartmentId)
      .then(res => {
        // console.log(res);
        this.messageText = res.data.messageModel.messageText;
        this.total = res.data.total;
        // if (this.total == 0) {
          //通过level的值来判断登陆者的权限
        this.level =0;
        if(null != res.data.dataModel){
          this.level = res.data.dataModel.level;
        }
          if (this.level == 1) {
            //分公司负责人入口 限制city选择并给city赋值
            this.disabled.citydisabled = true;
            this.holderList.cityHolder = res.data.dataModel.name;
            //渲染分公司选项
            this.fenselectList = res.data.dataModel.departmentList;
          } else if (this.level == 2) {
            //分公司负责人入口 限制city选择并给city赋值
            this.disabled.citydisabled = true;
            this.holderList.cityHolder = res.data.dataModel.parentName1;
            //限制分公司并赋值
            this.disabled.fendisabled = true;
            this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
            //渲染区域选项
            this.yijiselectList = res.data.dataModel.departmentList;
          } else if (this.level == 3) {
            //区域负责人入口 限制city选择并给city赋值
            this.disabled.citydisabled = true;
            this.holderList.cityHolder = res.data.dataModel.parentName1;
            //限制分公司选择并赋值
            this.disabled.fendisabled = true;
            this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
            //限制区域选择并赋值
            this.disabled.yijidisabled = true;
            this.holderList.yijiHolder = res.data.dataModel.name;
            //渲染二级部门选项
            this.erjiselectList = res.data.dataModel.departmentList;
          } else if (this.level == 4) {
            //区域负责人入口 限制city选择并给city赋值
            this.disabled.citydisabled = true;
            this.holderList.cityHolder = res.data.dataModel.parentName1;
            //限制分公司选择并赋值
            this.disabled.fendisabled = true;
            this.holderList.fengongsiHolder = res.data.dataModel.parentName2;
            //限制区域选择并赋值
            this.disabled.yijidisabled = true;
            this.holderList.yijiHolder = res.data.dataModel.parentName3;
            //限制部门限制区域选择并赋值
            this.disabled.erjidisabled = true;
            this.holderList.erjiHolder = res.data.dataModel.name;
          } else {
            //权限最高的管理员入口  获取所有城市
            this.$axios
                    .get(`/department/getAll`)
                    .then(res => {
                      // console.log(res);
                      this.cityselectList = res.data;
                    })
                    .catch(error => {
                      console.log(error);
                    });
            //直接渲染分城市选项
            // this.cityselectList =  res.data.dataModel.departmentList;
          }
        // }
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style  lang='scss'>
.employeeAdd-container {
  /deep/.el-form-item__label {
    display: inline-block!important;
  }
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      line-height: 20px;
      width: 100%;
      color: 	#708090;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
    //labal文字居左
  }
  .el-form-item__label {
    display: flex;
  }
  // 时间分类框样式
  input.el-input__inner#againkinds,
  input.el-input__inner#againtime {
    width: 85px;
    padding-left: 7px;
  }
  //历史职位状态选择器
  #block {
    width: 431px;
    vertical-align: middle;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 12px;
    .blockp {
      padding: 0;
    }
  }
  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
    .el-date-editor .el-range-separator {
      width: 5%;
    }
  }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
    #submit {
      margin: 50px 0 0 20px;
    }
    //个人简介
    #describe {
      .el-input__inner {
        width: 527px;
      }
    }
    #noteList {
    width: 692px;
    // height: 160px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    border: 1px solid #DCDFE6;
    background-color: #F5F7FA;
  }
  }
  .zaifenCheck {
    margin-left: 20px;
  }
  .zaifenBth.el-button {
    padding: 10px 20px;
  }
  #dateOfBirth {
    width: 170px;
    // padding-left: 30px;

    input.el-input__inner {
      width: 170px;
      height: 32px;
      padding-left: 30px;
    }
  }
  .redistribution-container input.el-input__inner {
    width: 182px;
    height: 32px;
    padding-left: 10px;
  }
  #name .el-form-item__label {
    width: 43px;
  }
  .benhangye {
    display: inline-block;
    vertical-align: top;
    /deep/ .el-form-item__content {
      margin-left: 10px !important;
    }
    #hangyejingyan {
      display: flex;
      margin: 0;

      .el-form-item__label {
        width: 115px;
      }
    }
  }
  // 从业年限
  .nianxian {
    margin-left: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 182px;
  }
  //图片区域
  .picArea {
    //图片展示框的大小
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .picChild {
      float: left;
      width: 25%;
      display: flex;
      margin-top: 20px;
      span {
        width: 20%;
        font-size: 14px;
        color: #606266;
        margin-right: 20px;
        margin-left: 20px;
      }
      .avatar {
        width: 268px;
        height: 162px;
      }
      i {
        width: 268px;
        height: 162px;
      }
    }
  }
}
</style>
