<template>
  <div class="department-container">
    <div class="left" v-loading="loading">
      <!-- 循环生成ul -->
      <div class="tree well">
        <ul id="bigUl" class="tree">
          <li>
            <span @click="getAll">组织结构</span>
            <span style="display:none" class="parentId">0</span>
            <a class="add" href="javascript:void(0)" @click="open">+</a>
            <span  style="display:none"  class="level">0,,,,</span>
            <ul>
              <!-- 城市 -->
              <li v-for="(item,index) in nav" :key="index" style="color:	#800000">
                <a class="add zhankai" href="javascript:void(0)"><i class="el-icon-d-caret"></i></a>
                <span @click="getThisLevelem">{{item.name}}</span>
                <span style="display:none" class="parentId">{{item.id}}</span>
                <a class="add" href="javascript:void(0)" @click="open">+</a>
                <span style="display:none"  class="level">{{item.level}},{{item.parentName1}},{{item.parentName2}},{{item.parentName3}},{{item.parentName4}}</span>
                <span style="display:none" class="parentId">{{item.id}}</span>
                <a class="add" href="javascript:void(0)" @click="delDepartment"><i class="el-icon-close"></i></a>
                <ul v-if="item.departmentList">
                  <!-- 分公司 -->
                  <li v-for="(value,index) in item.departmentList" :key="index" style="color:#00CED1">
                    <a class="add zhankai" href="javascript:void(0)"><i class="el-icon-d-caret"></i></a>
                    <span  @click="getThisLevelem">{{value.name}}</span>
                    <span style="display:none" class="parentId">{{value.id}}</span>
                    <a class="add" href="javascript:void(0)" @click="open">+</a>
                    <span  style="display:none"  class="level">{{value.level}},{{value.parentName1}},{{value.parentName2}},{{value.parentName3}},{{value.parentName4}}</span>
                    <span style="display:none" class="parentId">{{value.id}}</span>
                    <a class="add" href="javascript:void(0)" @click="delDepartment"><i class="el-icon-close"></i></a>
                    <ul v-if="value.departmentList">
                      <!-- 区域 -->
                      <li v-for="(value1,index) in value.departmentList" :key="index" style="color:#FF4500">
                        <a class="add zhankai" href="javascript:void(0)"><i class="el-icon-d-caret"></i></a>
                        <span  @click="getThisLevelem">{{value1.name}}</span>
                        <span style="display:none" class="parentId">{{value1.id}}</span>
                        <a class="add" href="javascript:void(0)" @click="open">+</a>
                        <span  style="display:none"  class="level">{{value1.level}},{{value1.parentName1}},{{value1.parentName2}},{{value1.parentName3}},{{value1.parentName4}}</span>
                        <span style="display:none" class="parentId">{{value1.id}}</span>
                        <a class="add" href="javascript:void(0)" @click="delDepartment"><i class="el-icon-close"></i></a>
                        <ul v-if="value1.departmentList">
                          <!-- 部门 -->
                          <li class="lowestbumen" v-for="(value2,index) in value1.departmentList" :key="index" style="color:#3CB371">
                            <span  @click="getThisLevelem">{{value2.name}}</span>
                            <span style="display:none" class="parentId">{{value2.id}}</span>
                            <a class="add" href="javascript:void(0)" style="display:none">占位的a</a>
                            <span  style="display:none"  class="level">{{value2.level}}</span>
                            <span style="display:none" class="parentId">{{value2.id}}</span>
                            <a class="add" href="javascript:void(0)" @click="delDepartment"><i class="el-icon-close"></i></a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="right" v-loading="loading">
      <!-- 栅格 输入框 和按钮 -->
      <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item prop="userName" class="CheckGuWen" label="顾问">
          <el-input v-model="formLabelAlign.userName" placeholder="姓名/ID/手机号均可"></el-input>
        </el-form-item>
        <el-button style="background-color:#C73E3A;color:#fff" class="emCheck" :disabled="disTBN" @click="CheckGuWen">查询</el-button>
        <el-button style="background-color:#3a8ac7;color:#fff" class="emCheck" :disabled="disTBN" @click="addguwen">添加顾问</el-button>
        <el-button style="background-color:#25c58b;color:#fff" class="emCheck" :disabled="disTBN" @click="showLizhi" v-show="ifShowLiZhi">显示离职人员</el-button>
        <el-button style="background-color:#CD9110;color:#fff" class="emCheck" :disabled="disTBN" @click="showZaizhi" v-show="!ifShowLiZhi">显示在职人员</el-button>
      </el-form>
    </el-row>
    <div id="unread">
      <span class="unread">当日上限<span class="unreadNuM">{{this.daiGenJinNum.distributionCount}}</span>条；已开上限<span class="unreadNuM">{{this.daiGenJinNum.realDistributionCount}}</span>条；日已分配<span class="unreadNuM">{{this.daiGenJinNum.shijiDis}}</span>条；月已分配<span class="unreadNuM">{{this.daiGenJinNum.dangyueDis}}</span>条；余量<span class="unreadNuM">{{this.daiGenJinNum.yuLiang}}</span>条</span>
    </div>
      <el-table :data="tableData" @selection-change="changeCus" style="width: 100%"  stripe>
        <el-table-column type="selection" width="25"></el-table-column>
        <el-table-column  label="姓名" width="80" align="center">
          <template slot-scope="scope"><a  size="mini" @click="handleEdit(scope.$index, scope.row)">{{ scope.row.name}}-{{ scope.row.id}}</a></template>
        </el-table-column>
        <el-table-column prop="department.parentName2" label="分公司" width="70" align="center"></el-table-column>
        <el-table-column prop="department.parentName3" label="大区" width="70" align="center"></el-table-column>
        <el-table-column prop="department.parentName4" label="部门" width="120" align="center"></el-table-column>
        <el-table-column prop="companyPosition" label="岗位" width="80" align="center"></el-table-column>
        <el-table-column prop="distributionCount" label="当日上限" width="50" align="center"></el-table-column>
        <el-table-column prop="shijiDis" label="日已分配" width="50" align="center" ></el-table-column>
        <el-table-column prop="dangyueDis" label="月已分配" width="60" align="center"></el-table-column>
        <el-table-column label="数据开关" width="70" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isNeedData == false " style='color:#C73E3A'>{{ scope.row.isNeedData|getDataRights }}</span>
              <span v-else-if="scope.row.isNeedData == true " style='color:#5BBD2B'>{{ scope.row.isNeedData|getDataRights }}</span>
              <span v-else style='color:grey'>{{ scope.row.isNeedData|getDataRights }}</span>
            </template>
          </el-table-column>
        <el-table-column  label="登陆开关" width="70" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.prohibitLogin == false " style='color:#C73E3A'>{{ scope.row.prohibitLogin | getLoginRights }}</span>
              <span v-else-if="scope.row.prohibitLogin == true " style='color:#5BBD2B'>{{ scope.row.prohibitLogin | getLoginRights }}</span>
              <span v-else style='color:grey'>{{ scope.row.prohibitLogin | getLoginRights }}</span>
            </template>
          </el-table-column>
    </el-table>

      <!-- 批量处理数据 -->
      <el-form :inline="true" :model="formOnline" id="piliangarea" class="demo-form-online">
        <el-form-item id="shangxianbavel" label="批量设置日接收上限（条）">
          <el-input v-model="formOnline.num"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="background-color:#C73E3A;color:#fff" class="piliangbth" type="danger"  :disabled="disTBN"  @click="limitPeie">确认</el-button>
          <el-button style="background-color:#31C1B2;color:#fff" class="piliangbth" type="success" :disabled="disTBN"  @click="allowedLogin">批量恢复登陆</el-button>
          <el-button style="background-color:#3A8AC7;color:#fff" class="piliangbth" type="primary" :disabled="disTBN"  @click="prohibitLogin">批量禁止登陆</el-button>
          <el-button style="background-color:#31C1B2;color:#fff" class="piliangbth" type="success" :disabled="disTBN"  @click="allowedAccept">批量开启</el-button>
          <el-button style="background-color:#3A8AC7;color:#fff" class="piliangbth" type="primary" :disabled="disTBN"  @click="prohibitAccept">批量关闭</el-button>
        </el-form-item>
      </el-form>
      <!-- 分页 -->
      <el-pagination  background @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50 , 100 ,200]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    </div>
  </div>
</template>


<script>
import $ from "jquery";
export default {
  name: "department",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentDepartmentId:"",
        currentPhone:""
      },
      flowSwitch:false,
      ifShowLiZhi:true,
      loading:true,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:100,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      guwenListId:[],//被选中顾问id的数组
      //添加组织架构的数据
      zuzhiData: {
        name: "",
        level: "",
        parentId: "0",
        parentName1: "",
        parentName2: "",
        parentName3: "",
        parentName4: ""
      },
      //循环生成ul的例子数据
      nav: [],
      //禁用按钮的状态值
      disTBN:false,
      //批量操作数据
      formOnline: {
        num: ""
      },
      //时间选择框数据
      value1: "",
      //客户筛选区域数据
      formLabelAlign: {
        cengji: "",
        userName: ""
      },
      daiGenJinNum:{
        distributionCount:0,
        realDistributionCount:0,
        shijiDis:0,
        dangyueDis:0,
        yuLiang:0
      },
      // 表格数据
      tableData: [],
      delDepartmentId:"",
      searchDid:"",
      searchDlevel:""
    }
  },
  methods: {
    //获取顾问List的方法
    getEmployeeList(){
      this.$axios
      .get(`/user/getUserList?userId=${this.currentUser.currentid}&keywords=&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`  )
      .then(res => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
        }else{
          this.tableData = res.data.dataModel.list.list;
          if (res.data.dataModel.listSum == null) {
            for(let key  in this.daiGenJinNum){
              this.daiGenJinNum[key] = 0;
            }
          }else{
            this.daiGenJinNum = res.data.dataModel.listSum;
          }
          this.pageConfig.total = res.data.dataModel.list.total;
          this.ifShowLiZhi = true
        }
        this.loading = false;
        this.$axios
                .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&keywords=&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
                .then(res => {
                  let dataModel = res.data.dataModel;
                  if (dataModel == null) {
                    this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                  }else{
                    this.tableData = res.data.dataModel.list.list;
                    if (res.data.dataModel.listSum == null) {
                      for(let key  in this.daiGenJinNum){
                        this.daiGenJinNum[key] = 0;
                      }
                    }else{
                      this.daiGenJinNum = res.data.dataModel.listSum;
                    }
                    this.pageConfig.total = res.data.dataModel.list.total;
                    this.ifShowLiZhi = true
                  }
                });
        })
      .catch(error => {
        console.log(error);
      });
    },
    //请求和渲染组织架构
    getAllbumen(){
      this.$axios
        .get(`/department/getDepartmentList?departmentId=${this.currentUser.currentDepartmentId}`)
        .then(res => {
          this.nav = res.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 添加顾问
    addguwen() {
      this.$router.push("employeeAdd");
    },
    //查找顾问
    CheckGuWen(){
      this.$axios
      .get(`/user/getUserList?userId=${this.currentUser.currentid}&keywords=${this.formLabelAlign.userName}&pageSize=${this.pageConfig.pageSize}&currentPage=1`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0 || res.data.dataModel.total != 0) {
          this.tableData = res.data.dataModel.list.list;
          this.pageConfig.total = res.data.dataModel.list.total;
        }else if (res.data.messageModel.code == 0 || res.data.dataModel.total == 0) {
          this.$message("当前没有处于离职状态的员工");
          this.tableData = []
          this.pageConfig.total = 0
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.tableData = []
          this.pageConfig.total = 0
        }
        if (res.data.dataModel.listSum == null) {
          for(let key  in this.daiGenJinNum){
            this.daiGenJinNum[key] = 0;
          }
        }else{
          this.daiGenJinNum = res.data.dataModel.listSum;
        }
        this.$axios
                .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&keywords=${this.formLabelAlign.userName}&pageSize=${this.pageConfig.pageSize}&currentPage=1`)
                .then(res => {
                  let dataModel = res.data.dataModel;
                  if (dataModel == null) {
                    this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                  }else{
                    this.tableData = res.data.dataModel.list.list;
                    if (res.data.dataModel.listSum == null) {
                      for(let key  in this.daiGenJinNum){
                        this.daiGenJinNum[key] = 0;
                      }
                    }else{
                      this.daiGenJinNum = res.data.dataModel.listSum;
                    }
                    this.pageConfig.total = res.data.dataModel.list.total;
                    this.ifShowLiZhi = true
                  }
                });
      })
      .catch(error => {
        console.log(error);
      });
    },
    //请求离职人员的方法
    getLiZhi(){
      this.$axios
      .get(`/user/getUserList?userId=${this.currentUser.currentid}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}&departure=true`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0 || res.data.dataModel.total != 0) {
          this.tableData = res.data.dataModel.list.list;
          if (res.data.dataModel.listSum == null) {
            for(let key  in this.daiGenJinNum){
              this.daiGenJinNum[key] = 0;
            }
          }else{
            this.daiGenJinNum = res.data.dataModel.listSum;
          }
          this.pageConfig.total = res.data.dataModel.list.total;
          this.ifShowLiZhi = false
        }else if (res.data.messageModel.code == 0 || res.data.dataModel.total == 0) {
          this.$message("当前没有处于离职状态的员工");
          this.tableData = []
          this.pageConfig.total = 0
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.tableData = []
          this.pageConfig.total = 0
        }
        this.$axios
                .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}&departure=true`)
                .then(res => {
                  let dataModel = res.data.dataModel;
                  if (dataModel == null) {
                    this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                  }else{
                    this.tableData = res.data.dataModel.list.list;
                    if (res.data.dataModel.listSum == null) {
                      for(let key  in this.daiGenJinNum){
                        this.daiGenJinNum[key] = 0;
                      }
                    }else{
                      this.daiGenJinNum = res.data.dataModel.listSum;
                    }
                    this.pageConfig.total = res.data.dataModel.list.total;
                    this.ifShowLiZhi = true
                  }
                });
      })
      .catch(error => {
        console.log(error);
      });
    },
    // 显示离职人员
    showLizhi() {
      this.pageConfig.pageSize = 100
      this.pageConfig.currentPage = 1
      this.getLiZhi()
    },
    // 显示离职人员分页
    FenYeshowLizhi() {
      this.disButton()
      this.getLiZhi()
    },
    // 显示在职人员
    showZaizhi() {
      this.disButton()
      this.pageConfig.currentPage=1
      this.pageConfig.pageSize=100
      this.getEmployeeList()
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //分页的方法 pageSize 改变时会触发
    handleSizeChange(val) {
      this.loading = true
      if (this.ifShowLiZhi == true) {
        if (this.pageConfig.currentPage != 1) {
          this.pageConfig.currentPage = 1;
          this.pageConfig.pageSize = val;
           this.getEmployeeList()
          }else{
          this.pageConfig.pageSize = val;
           this.getEmployeeList()
        }
      }else if (this.ifShowLiZhi == false) {
        if (this.pageConfig.currentPage != 1) {
          this.pageConfig.currentPage = 1;
          this.pageConfig.pageSize = val;
           this.FenYeshowLizhi()
        }else{
          this.pageConfig.pageSize = val;
           this.FenYeshowLizhi()
      }
      }
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.loading = true
        this.pageConfig.currentPage = val;
        if (this.ifShowLiZhi == true) {
          this.getEmployeeList()
        }else if (this.ifShowLiZhi == false) {
          this.FenYeshowLizhi()
        }
    },
    //编辑
    handleEdit(index, row) {
        let EditEmId = row.id;
        this.$router.push({ path: '/employeeEdit', query: { "EditEmId": EditEmId } })
        window.sessionStorage.setItem("EditEmId", EditEmId);
      },
    //限制日分配上线
    limitPeie() {
      this.disButton()
      if (this.guwenListId == "") {
        this.$message({  type: 'info',  message: '请至少选中一个顾问'  });
        return false
      }else{
        // console.log(this.formOnline.num);
        if (this.formOnline.num == "") {
          // console.log("数字为空");
          this.$message({  type: 'info',  message: '请填写日接收上限的条数'  });
          return false
        }else{
          this.$axios
              .post(
                `/user/bachUpdate?id=${this.guwenListId}&userId=${this.currentUser.currentid}&type=distributionCount&count=${this.formOnline.num}`
              )
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.$message({  type: "success",  message: res.data.messageModel.messageText  });
                  // this.getEmployeeList()   原本调用的方法 操作完毕后查询所有结果集
                  this.getThisLevelem(this.departmentNode)
                }else{
                  this.$message({  type: 'info',  message: res.data.messageModel.messageText  });
                }
              })
              .catch(error => {
                console.log(error);
              });
        }
      }
    },
    //批量允许登陆
    allowedLogin(){
      this.disButton()
      if (this.guwenListId == "") {
        this.$message({  type: 'info',  message: '请至少选中一个顾问'  });
        return false
      }else{
          this.$axios
              .post(
                `/user/bachUpdate?id=${this.guwenListId}&userId=${this.currentUser.currentid}&type=prohibitLogin&bool=true`
              )
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.$message({  type: "success",  message: res.data.messageModel.messageText  });
                  // this.getEmployeeList()
                  this.getThisLevelem(this.departmentNode)
                }else{
                  this.$message({  type: 'info',  message: res.data.messageModel.messageText  });
                }
              })
              .catch(error => {
                console.log(error);
              });
      }
    },
    //批量禁止登陆
    prohibitLogin(){
      this.disButton()
      if (this.guwenListId == "") {
        this.$message({  type: 'info',  message: '请至少选中一个顾问'  });
        return false
      }else{
        // console.log(this.formOnline.num);
          this.$axios
              .post(
                `/user/bachUpdate?id=${this.guwenListId}&userId=${this.currentUser.currentid}&type=prohibitLogin&bool=false`
              )
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.$message({  type: "success",  message: res.data.messageModel.messageText  });
                  // this.getEmployeeList()
                  this.getThisLevelem(this.departmentNode)
                }else{
                  this.$message({  type: 'info',  message: res.data.messageModel.messageText  });
                }
              })
              .catch(error => {
                console.log(error);
              });
      }
    },
    //批量允许接收信息
    allowedAccept(){
      this.disButton()
      if (this.guwenListId == "") {
        this.$message({  type: 'info',  message: '请至少选中一个顾问'  });
        return false
      }else{
          this.$axios
              .post(
                `/user/bachUpdate?id=${this.guwenListId}&userId=${this.currentUser.currentid}&type=isNeedData&bool=true`
              )
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.$message({  type: "success",  message: res.data.messageModel.messageText  });
                  // this.getEmployeeList()
                  this.getThisLevelem(this.departmentNode)
                }else{
                  this.$message({  type: 'info',  message: res.data.messageModel.messageText  });
                }
              })
              .catch(error => {
                console.log(error);
              });
      }
    },
    //批量禁止接收信息
    prohibitAccept(){
      this.disButton()
      if (this.guwenListId == "") {
        this.$message({  type: 'info',  message: '请至少选中一个顾问'  });
        return false
      }else{
        // console.log(this.formOnline.num);
        this.$axios
        .post(`/user/bachUpdate?id=${this.guwenListId}&userId=${this.currentUser.currentid}&type=isNeedData&bool=false`)
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({  type: "success",  message: res.data.messageModel.messageText  });
            // this.getEmployeeList()
            this.getThisLevelem(this.departmentNode)
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    //获取所有的信息
    getAll(){
      this.$axios
      .get(`/user/getUserList?userId=${this.currentUser.currentid}&keywords=&pageSize=100&currentPage=1`)
      .then(res => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("数据为空/(ㄒoㄒ)/~~");
        }else{
          this.tableData = res.data.dataModel.list.list;
          if (res.data.dataModel.listSum == null) {
            for(let key  in this.daiGenJinNum){
              this.daiGenJinNum[key] = 0;
            }
          }else{
            this.daiGenJinNum = res.data.dataModel.listSum;
          }
          this.pageConfig.total = res.data.dataModel.list.total;
        }
        this.$axios
                .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&keywords=&pageSize=100&currentPage=1`)
                .then(res => {
                  let dataModel = res.data.dataModel;
                  if (dataModel == null) {
                    this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                  }else{
                    this.tableData = res.data.dataModel.list.list;
                    if (res.data.dataModel.listSum == null) {
                      for(let key  in this.daiGenJinNum){
                        this.daiGenJinNum[key] = 0;
                      }
                    }else{
                      this.daiGenJinNum = res.data.dataModel.listSum;
                    }
                    this.pageConfig.total = res.data.dataModel.list.total;
                    this.ifShowLiZhi = true
                  }
                });
        })
      .catch(error => {
        console.log(error);
      });
    },
    //请求该组织节点的顾问
    getThisLevelem(e){
      this.loading = true
      this.pageConfig.currentPage = 1
      this.pageConfig.pageSize = 100
      if (undefined != e && e.currentTarget != null) {
        this.searchDid = e.currentTarget.nextElementSibling.innerHTML
        this.searchDlevel = e.currentTarget.nextElementSibling.nextElementSibling.nextElementSibling.innerHTML[0]
      }
      this.$axios.post(  `/user/getUserList?userId=${this.currentUser.currentid}&searchDid=${this.searchDid}&searchDlevel=${this.searchDlevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize} `)
        .then(res => {
            // console.log(res);
          if (res.data.messageModel.code == 0) {
              this.tableData = res.data.dataModel.list.list
              if (res.data.dataModel.listSum == null) {
                for(let key  in this.daiGenJinNum){
                  this.daiGenJinNum[key] = 0;
                }
              }else{
                this.daiGenJinNum = res.data.dataModel.listSum;
              }
              this.pageConfig.total = res.data.dataModel.list.total;
              this.loading = false
            }else{
              this.$message({  type: 'info',  message: res.data.messageModel.messageText  });
              this.loading = false
            }
          this.$axios
                  .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&searchDid=${this.searchDid}&searchDlevel=${this.searchDlevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize} `)
                  .then(res => {
                    let dataModel = res.data.dataModel;
                    if (dataModel == null) {
                      this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                    }else{
                      this.tableData = res.data.dataModel.list.list;
                      if (res.data.dataModel.listSum == null) {
                        for(let key  in this.daiGenJinNum){
                          this.daiGenJinNum[key] = 0;
                        }
                      }else{
                        this.daiGenJinNum = res.data.dataModel.listSum;
                      }
                      this.pageConfig.total = res.data.dataModel.list.total;
                      this.ifShowLiZhi = true
                    }
                  });
        })
        .catch(error => {  /*console.log(error); */ });
    },
    //删除阻止节点的方法
    delDepartment(e){
      this.loading = true
      this.delDepartmentId = e.currentTarget.previousElementSibling.innerHTML
      this.$confirm('此操作将删除该选中的单位, 是否继续?', '提示', {  confirmButtonText: '确定',  cancelButtonText: '取消',  type: 'warning'
        }).then(() => {
          // console.log(this.delDepartmentId);
          this.$axios
              .post(
                `/department/delDepartment?delDepartmentId=${this.delDepartmentId}`
              )
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.$message.error(res.data.messageModel.messageText);
                  // this.loading = false;
                  window.location.reload();
                }else{
                  this.$message.error(res.data.messageModel.messageText);
                  this.loading = false
                }
              })
              .catch(error => {
                console.log(error);
              });
        }).catch(() => {
          this.$message({  type: 'info',  message: '已取消删除'  });
          this.loading = false
        });

    },
    //添加组织架构弹框
    open(e) {
      // console.log(e.target);//获取点击元素
      // console.log(e.currentTarget.parentElement);//获取父元素
      let city = e.currentTarget.previousElementSibling.previousElementSibling.innerHTML;
      // console.log(city);
      let parentId = e.currentTarget.previousElementSibling.innerHTML; //获取前一个元素
      let level = e.currentTarget.nextElementSibling.innerHTML; //获取父元素的ID
      this.zuzhiData.parentId = parentId;
      let parentNameList = level.split(",");
      // console.log(parentNameList);
      this.zuzhiData.parentId = parentId;
      this.zuzhiData.level = parentNameList[0];
      if (this.zuzhiData.level == 0) {
      this.zuzhiData.parentName1 = parentNameList[1];
      this.zuzhiData.parentName2 = parentNameList[2];
      this.zuzhiData.parentName3 = parentNameList[3];
      this.zuzhiData.parentName4 = parentNameList[4];
        ++this.zuzhiData.level
      }else if (this.zuzhiData.level == 1) {
        this.zuzhiData.parentName1 = city;
        this.zuzhiData.parentName2 = parentNameList[2];
        this.zuzhiData.parentName3 = parentNameList[3];
        this.zuzhiData.parentName4 = parentNameList[4];
        ++this.zuzhiData.level
      }else if (this.zuzhiData.level == 2) {
        this.zuzhiData.parentName2 = city;
        this.zuzhiData.parentName1 = parentNameList[1];
        this.zuzhiData.parentName3 = parentNameList[3];
        this.zuzhiData.parentName4 = parentNameList[4];
         ++this.zuzhiData.level
      }else if (this.zuzhiData.level == 3) {
        this.zuzhiData.parentName3 = city;
        this.zuzhiData.parentName1 = parentNameList[1];
        this.zuzhiData.parentName2 = parentNameList[2];
        this.zuzhiData.parentName4 = parentNameList[4];
         ++this.zuzhiData.level
      }else  {
        this.$message({
          type: "error",
          message: "添加程序不合法"
        });
      }
      this.$prompt("", "请输入添加的组织架构（添加下一级）", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          let that = this;
          if (value === null) {
            this.$message({
              type: "error",
              message: "您没有输入任何字符哦"
            });
            return false;
          } else {
            this.zuzhiData.name = value;
            this.$axios
              .post(
                `/department/addDepartment?name=${this.zuzhiData.name}&level=${this.zuzhiData.level}&parentId=${this.zuzhiData.parentId}&parentName1=${this.zuzhiData.parentName1}&parentName2=${this.zuzhiData.parentName2}&parentName3=${this.zuzhiData.parentName3}&parentName4=${this.zuzhiData.parentName4}`
              )
              .then(res => {
                // console.log(res);
                this.$message({  type: "success",  message: "您添加了: " + value  });
                //重新渲染组织架构数据
                this.getAllbumen()
              })
              .catch(error => {
                console.log(error);
              });
          }
          return false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消添加"
          });
        });
    },
    //获取选中客户并返回数组
    changeCus (val) {
      this.guwenListId = []
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.guwenListId.push(val[i].id);
      }
      // console.log(this.guwenListId);
    }
  },
  // 本地过滤器
  filters: {
    getDataRights(value) {
      if (value == true) {
        return "已允许"
      }else if(value == false){
        return "已禁止"
      }else{
        return "";
      }
    },
    getLoginRights(value) {
      if (value == false) {
        return "已禁止"
      }else if(value == true){
        return "已允许"
      }else{
        return "";
      }
    }
  },
  beforeCreate() {},
  created() {
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentDepartmentId = window.sessionStorage.getItem("departmentId")

    //非管理员，不可控制流量开关
    let level = this.currentUser.currentLevel
    if(level > 2){
      this.flowSwitch = false
    } else {
      this.flowSwitch = true
    }
    //获取组织架构数据的请求
    this.getAllbumen()
    //获取顾问信息请求
    this.$axios
      .get(`/user/getUserList?userId=${this.currentUser.currentid}&keywords=&pageSize=100&currentPage=1`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
            this.tableData = res.data.dataModel.list.list;
            if (res.data.dataModel.listSum == null) {
              for(let key  in this.daiGenJinNum){
                this.daiGenJinNum[key] = 0;
              }
            }else{
              this.daiGenJinNum = res.data.dataModel.listSum;
            }
            this.pageConfig.total = res.data.dataModel.list.total;
            this.loading = false
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = []
            this.pageConfig.total = 0;
            this.loading = false
          }
        this.$axios
                .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&keywords=&pageSize=100&currentPage=1`)
                .then(res => {
                  let dataModel = res.data.dataModel;
                  if (dataModel == null) {
                    this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                  }else{
                    this.tableData = res.data.dataModel.list.list;
                    if (res.data.dataModel.listSum == null) {
                      for(let key  in this.daiGenJinNum){
                        this.daiGenJinNum[key] = 0;
                      }
                    }else{
                      this.daiGenJinNum = res.data.dataModel.listSum;
                    }
                    this.pageConfig.total = res.data.dataModel.list.total;
                    this.ifShowLiZhi = true
                  }
                });
        })
      .catch(error => {
        console.log(error);
      });
    // }else{
    //   this.$message.warning("请先进行登录操作！");
    //   this.$router.push("login");
    // } ;
  },
  activated () {
    // this.loading = true
    // this.$axios
    //   .get(`/user/getUserListForSum?userId=${this.currentUser.currentid}&userName=${this.formLabelAlign.userName}&pageSize=${this.pageConfig.pageSize}&currentPage=1`)
    //   .then(res => {
    //     console.log(res);
    //     if (res.data.messageModel.code == 0 || res.data.dataModel.total != 0) {
    //       this.tableData = res.data.dataModel.list.list;
    //       this.daiGenJinNum = res.data.dataModel.listSum;
    //       this.pageConfig.total = res.data.dataModel.list.total;
    //     }else if (res.data.messageModel.code == 0 || res.data.dataModel.total == 0) {
    //       this.$message("当前没有处于离职状态的员工");
    //       this.tableData = []
    //       this.pageConfig.total = 0
    //     }else{
    //       this.$message.error(res.data.messageModel.messageText);
    //       this.tableData = []
    //       this.pageConfig.total = 0
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // this.loading = false
  },
  mounted() {
    //组织结构展开收缩功能代码
      // $(function() {
      // // $(".tree li:has(ul)").addClass("parent_li").find(" > span").attr("title", "Expand this branch");
      // $(".tree li:has(ul)").addClass("parent_li").find(" > span").attr("title", "Collapse this branch");
      // // var children = $(this).parent('li.parent_li').find(' > ul > li');
			// // if (children.is(":visible")) {
      // //   children.hide('fast');
			// // 	$(this).attr('title', 'Collapse this branch').find(' > i').addClass('icon-minus-sign').removeClass(	'icon-plus-sign');
			// // } else {
			// // 	children.show('fast');
			// // 	$(this).attr('title', 'Expand this branch').find(' > i').addClass('icon-plus-sign').removeClass('icon-minus-sign');
      // // }

      //   $(".tree li.parent_li > .zhankai").on("click", function(e) {
      //     var children = $(this).parent('li.parent_li').find(' > ul > li');
      //     // console.log(children.is(":visible"));
      //     if (children.is(":visible")) {
      //       children.hide("fast");
      //       $(this).attr("title", "Collapse this branch").find(" > i").addClass("icon-minus-sign").removeClass("icon-plus-sign");
      //       return false;
      //     } else {
      //       children.show("fast");
      //       $(this).attr("title", "Expand this branch").find(" > i").addClass("icon-plus-sign").removeClass("icon-minus-sign");
      //       return false;
      //     }
      //     e.stopPropagation();   //表示阻止向父元素冒泡
      //     e.preventDefault();    //阻止 方法阻止元素发生默认的行为（例如，当点击提交按钮时阻止对表单的提交或者a标签）
      //   });
      // });
  },
  updated () {
    //组织结构展开收缩功能代码
      // $(function() {
      // // $(".tree li:has(ul)").addClass("parent_li").find(" > span").attr("title", "Expand this branch");
      // $(".tree li:has(ul)").addClass("parent_li").find(" > span").attr("title", "Collapse this branch");
      // // var children = $(this).parent('li.parent_li').find(' > ul > li');
			// // if (children.is(":visible")) {
      // //   children.hide('fast');
			// // 	$(this).attr('title', 'Collapse this branch').find(' > i').addClass('icon-minus-sign').removeClass(	'icon-plus-sign');
			// // } else {
			// // 	children.show('fast');
			// // 	$(this).attr('title', 'Expand this branch').find(' > i').addClass('icon-plus-sign').removeClass('icon-minus-sign');
      // // }

      //   $(".tree li.parent_li > .zhankai").on("click", function(e) {
      //     var children = $(this).parent('li.parent_li').find(' > ul > li');
      //     // console.log(children.is(":visible"));
      //     if (children.is(":visible")) {
      //       children.hide("fast");
      //       $(this).attr("title", "Collapse this branch").find(" > i").addClass("icon-minus-sign").removeClass("icon-plus-sign");
      //       return false;
      //     } else {
      //       children.show("fast");
      //       $(this).attr("title", "Expand this branch").find(" > i").addClass("icon-plus-sign").removeClass("icon-minus-sign");
      //       return false;
      //     }
      //     e.stopPropagation();   //表示阻止向父元素冒泡
      //     e.preventDefault();    //阻止 方法阻止元素发生默认的行为（例如，当点击提交按钮时阻止对表单的提交或者a标签）
      //   });
      // });
  }
};
</script>

<style  lang='scss'>
  .department-container {
    display: flex;
    height: 100%;
    margin-top: 0;
    /deep/.left {
      width: 320px !important;
      height: 100%;
      background-color: #fff;
      margin-right: 10px;
      // float: left;
      //树状图的样式

    }

    .right {
      flex: 1;
      // float: left;
      a:hover {
        color: aqua;
      }
      .filterCondition {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 10px 0;
        .emCheck {
          margin-left: 20px;
          padding: 8px 20px;
        }
        .cengjispan {
          margin-left: 20px;
          margin-right: 20px;
          line-height: 80px;
          font-weight: 700;
        }
        .CheckGuWen {
          margin: 0;
          display: inline-block;
        }
      }
      .departmentCheck {
        padding: 8px 20px;
        margin-left: 27px;
      }
      .ell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .el-table {
        font-size: 12px;
        padding: 0 20px;
        margin-bottom: 20px;
        .cell {
          padding-left: 1px;
          padding-right: 1px;
        }
        td {
          padding: 1px 0;
          border-right: 1px dashed #d3cdcd;
        }
        .el-button--mini {
          font-size: 12px;
          border: 0;
          padding: 5px 15px;
          margin: 5px 0;
          background: rgba(255, 255, 255, 0.01);
        }
        th {
          padding: 0;
        }
      }
      //分页框样式设置（避免页面被覆盖）
      .el-pagination .el-input__inner {
        width: 100px;
      }
      #piliangarea {
        .el-form {
          .el-button.piliangbth {
            padding: 8px 20px!important;
          }
        }
        //批量labal的样式
        .el-form-item__label {
          float: none;
          display: inline-block;
          width: 180px;
        }
        .el-button {
          padding: 7px 20px;
        }
      }
      #unread {
        margin: -15px 0 5px 0;
        .unread {
          font-size: 14px;
          color: #000000;
          margin-left: 120px;
        }
        .unreadNuM {
          font-size: 18px;
          color: #c73e3a;
        }
      }
    }
  }
  .department-container input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
  .tree {
    min-height: 20px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .tree a {
    text-decoration: none;
    font-size: 12px;
    color: #000;
  }
  #bigUl {
    width: 300px;
    padding-left: 8px;
  }
  #bigUl .add {
    text-decoration: none;
    color: #fff;
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #c73e3a;
    border-radius: 50%;
    margin-left: 2px;
    line-height: 10px;
  }
  .tree li {
    list-style-type: none;
    margin: 0;
    padding: 10px 5px 0 5px;
    position: relative;
  }
  .tree li::before,
  .tree li::after {
    content: "";
    left: -20px;
    position: absolute;
    right: auto;
  }
  .tree li::before {
    border-left: 1px solid #999;
    bottom: 50px;
    height: 100%;
    top: 0;
    width: 1px;
  }
  .tree li::after {
    border-top: 1px solid #999;
    height: 20px;
    top: 25px;
    width: 25px;
  }
  .tree li span {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid #999;
    border-radius: 5px;
    display: inline-block;
    padding: 2px 8px;
    text-decoration: none;
    font-size: 12px;
  }
  .tree li.parent_li > span {
    cursor: pointer;
  }
  .tree > ul > li::before,
  .tree > ul > li::after {
    border: 0;
  }
  .tree li:last-child::before {
    height: 25px;
  }
  .tree li.parent_li > span:hover,
  .tree li.parent_li > span:hover + ul li span {
    background: #eee;
    border: 1px solid #94a0b4;
    color: #000;
  }
  .tree .lowestbumen span:hover {
    background: #eee;
    border: 1px solid #94a0b4;
    color: #000;
  }

  [class^="el-icon-"] {
    line-height: 5px;
    // background-color: grey;
  }
</style>
