<template>
  <div class="FinRechargeRecord-container">
    <!-- 财务用充值记录页面 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
        <!-- <el-form-item prop="searchType" label="经营属性">
          <el-select v-model="formLabelAlign.searchType" id="time" @change="holderQuJian" placeholder="默认自营" >
            <el-option label="自营" value="zy" ></el-option>
            <el-option label="加盟" value="jm" ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="timeType" label="录入时间">
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="state" label="收款状态">
          <el-select v-model="formLabelAlign.state" id="time" @change="holderQuJian" placeholder="请选择收款状态" >
            <el-option label="全部" value="" ></el-option>
            <el-option label="未收款" value="未收款" ></el-option>
            <el-option label="已收款" value="已收款" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="BTN">
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check">查询</el-button>
        <el-button style="background-color:	#00CDCD;color:#fff" @click="PiLiangQueRen"   class="check">批量确认收款</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <el-table   :data="tableData" @selection-change="changeJiLu" style="width: 100%" stripe>
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- 加盟显示部分 -->
      <el-table-column prop="departmentName" label="分公司"></el-table-column>
      <el-table-column prop="payType" label="支付方式"></el-table-column>
      <el-table-column prop="upMoney" label="充值金额"></el-table-column>
      <el-table-column prop="skBank" label="收款银行"></el-table-column>
      <el-table-column prop="state" label="收款状态">
        <template slot-scope="scope">
            <span v-if="scope.row.state == '已收款' " style='color:#00A06B'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '未收款' " style='color:#D7962F'>{{ scope.row.state}}</span>
            <span v-else style='color:grey'>{{ scope.row.state}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="充值日期">
        <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template>
      </el-table-column>
      <el-table-column prop="entryTime" label="录入日期">
        <template slot-scope="scope">{{ scope.row.entryTime | formatTime }}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "FinRechargeRecord",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentDepartmentId:"",
        currentPhone:""
      },
      showTimeKind:true,
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      kindtimeList:[
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
        ],
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
        ],
      //筛选区域数据
      formLabelAlign: {
        searchType : "jm", 	//	经营属性
        state : "", 	//	收款状态
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      formLabelWidth: '180px',
      tableData: [],
      JiLuId:[]  //选中的记录
    };
  },
  methods: {
    //获取选中记录并返回数组
    changeJiLu (val) {
      this.JiLuId = []
    //   console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.JiLuId.push(val[i].id);
      }
    },
    //批量确充值录
    PiLiangQueRen () {
        // console.log(this.JiLuId);
        if (this.JiLuId.length == 0) {
            this.$message.error("请至少选择一条记录");
            return false
        }
        this.$axios
        .get(`/source/updateState?id=${this.JiLuId}&state=已收款`)
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success("确认成功！");
            this.getJiLuList()
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
          })
        .catch(error => {
          console.log(error);
        });
    },
    //获得充值记录列表
    getJiLuList(){
        this.$axios
        .get(`/source/getRechargeLogList?state=${this.formLabelAlign.state}&searchType=${this.formLabelAlign.searchType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
        .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合搜索条件的记录');
             this.clearAllCheck();
             this.tableData = []
             this.pageConfig.total = 0;
             return false;
          }
          this.$message.success("根据您的搜索条件，已为您找到以下记录(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          // this.clearAllCheck();
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.clearAllCheck();
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";
        }
        this.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId){
      let obj = {};
      obj = this.kindtimeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true
      }else{
        this.showTimeKind = false
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
        this.formLabelAlign.dayType  = "customize";
      }  else {
        this.isShow = false;
        this.value1 = "";
      }
    },
      //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getJiLuList()
      }else{
        this.pageConfig.pageSize = val;
         this.getJiLuList()
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getJiLuList()
    },
    // eslint-disable-next-line no-dupe-keys,vue/no-dupe-keys
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getJiLuList()
    },
    //查询
    checkCus(){
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      //收款状态这个字段暂时没有加入搜索条件！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
      this.$axios
      .post(`/source/getRechargeLogList?state=${this.formLabelAlign.state}&searchType=${this.formLabelAlign.searchType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&pageSize=10&currentPage=1`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合搜索条件的记录');
             this.clearAllCheck();
             this.tableData = []
             this.pageConfig.total = 0;
             return false;
          }
          this.$message.success("根据您的搜索条件，已为您找到以下记录(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          // this.clearAllCheck();
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.clearAllCheck();
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YYYY-MM-DD");
    },
  },
  created () {
    //存放操作人信息
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentDepartmentId = window.sessionStorage.getItem("departmentId")
    this.getJiLuList()
  },
  computed: {}
};
</script>

<style  lang='scss'>
.FinRechargeRecord-container {
  .el-dialog {
    #dateOfBirth {
        /deep/.el-input__inner {
            padding-left: 30px!important;
        }
    }
    .el-form-item {
      // margin-top: 0;
      /deep/.el-form-item__label {
        width: 150px!important;
      }
      /deep/.el-form-item__content {
        margin-left: 150px!important;
      }
    }
    .el-dialog__body {
      padding: 0 20px 30px;
    }

  }
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}

//突出隔行的颜色差异
.el-table {
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important;
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
