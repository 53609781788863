<template>
  <div class="MyLog-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
        <el-form-item prop="timeType" label="时间">
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="completeTime" label="选择区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="completeTime"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="BTN">
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check" :disabled="disTBN">查询</el-button>
        <el-button style="background-color:	#00CDCD;color:#fff" @click="EditLog()" class="check">编辑日志</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table  :data="tableData" style="width: 100%" >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="todayCount" label="当日上门"  ></el-table-column>
        <el-table-column prop="singingCount" label="当日签约"  ></el-table-column>
        <el-table-column label="当日进件"  >
          <template slot-scope="scope">{{scope.row.jinJianCount}}/{{scope.row.jinJianSumMoney}}</template>
        </el-table-column>
        <el-table-column label="当日放款"  >
          <template slot-scope="scope">{{scope.row.fkCount}}/{{scope.row.fkSumMoney}}</template>
        </el-table-column>
        <el-table-column label="当日收款"  >
          <template slot-scope="scope">{{scope.row.thePayeeCount}}/{{scope.row.thePayeeSum}}</template>
        </el-table-column>
        <el-table-column prop="tomorrowCount" label="次日上门" ></el-table-column>
        <el-table-column prop="days" label="时间" ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text"  @click="checkDetail(scope.row)" size="small"><i class="el-icon-search"></i>查看详情</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <!-- 弹出框 -->
    <el-dialog title="日志详情" style="text-align:center" :visible.sync="dialogFormVisible">
        <div>
        <el-radio-group v-model="myLogForm.typeZH" @change="TCTableStyle">
          <el-radio-button label="当日上门"></el-radio-button>
          <el-radio-button label="当日进件"></el-radio-button>
          <el-radio-button label="当日收款"></el-radio-button>
          <el-radio-button label="次日上门"></el-radio-button>
          <el-radio-button label="当前在审件"></el-radio-button>
        </el-radio-group>
      </div><br>
      <!-- 当日上门表单 -->
      <el-table  :data="DStableData" style="width: 100%" height="380" v-loading="loading" v-show="showTanKuangTable.todaySMShow">
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <!-- <el-table-column prop="id" label="日志ID" ></el-table-column> -->
        <el-table-column prop="name" label="姓名" ></el-table-column>
        <el-table-column  label="星级" width="40" align="center">
          <template slot-scope="scope">{{ scope.row.level| levelKind }}</template>
        </el-table-column>
        <el-table-column  label="状态" width="55" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '资质不符' " style='color:#C73E3A'>{{ scope.row.state}}</span>
            <span v-else-if=" scope.row.state == 'B上门'||scope.row.state == '已上门'  || scope.row.state == '待签约'|| scope.row.state == '已放款'|| scope.row.state == '审核中'" style='color:#5BBD2B'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '已受理' || scope.row.state == '未接通' || scope.row.state == '待跟进'" style='color:#3390ff'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '捣乱申请' || scope.row.state == '外地申请'|| scope.row.state == '已拒批'|| scope.row.state == '重复申请'" style='color:#D7962F'>{{ scope.row.state}}</span>
            <span v-else style='color:grey'>{{ scope.row.state}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="资质" width="300">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark"  placement="top">
            <div style="width:200px;" slot="content">{{scope.row.remarks |panKong}}</div>
              <p class="ell"   >{{ scope.row.remarks |panKong}}</p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="上门时间" >
          <template slot-scope="scope">
            <p class="ell">{{ scope.row.createTime | detailFormatTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="signing" label="是否签约" >
          <template slot-scope="scope">
            <p class="ell">{{ scope.row.signing | IsSingning }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="说明" ></el-table-column>
      </el-table>
      <!-- 当日上门分页 -->
      <el-pagination v-show="showTanKuangTable.todaySMShow" @size-change="detailhandleSizeChange"  @current-change="detailhandleCurrentChange"  :current-page="DSpageConfig.DScurrentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="DSpageConfig.DSpageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="DSpageConfig.DStotal">
      </el-pagination>
      <!-- 当日进件表单 新增-->
      <el-table  :data="DJtableData" style="width: 100%" height="380" v-loading="loading" v-show="showTanKuangTable.todayDSShow">
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <el-table-column prop="name" label="姓名" ></el-table-column>
        <el-table-column prop="jinJianBankName" label="进件银行" ></el-table-column>
        <el-table-column prop="state" label="状态" ></el-table-column>
        <el-table-column prop="rate" label="费率" ></el-table-column>
        <el-table-column prop="fangKuanRate" label="批款额度" ></el-table-column>
        <el-table-column prop="jingShouRu" label="净收入" ></el-table-column>
        <el-table-column prop="state" label="进度" ></el-table-column>
      </el-table>
      <!-- 当日进件分页 -->
      <!-- <el-pagination v-show="showTanKuangTable.todayDSShow" @size-change="detailhandleSizeChange"  @current-change="detailhandleCurrentChange"  :current-page="DJpageConfig.DJcurrentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="DJpageConfig.DJpageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="DJpageConfig.DJtotal">
      </el-pagination> -->
      <!-- 当日收款表单 -->
      <el-table  :data="DFtableData" style="width: 100%" height="380" v-loading="loading" v-show="showTanKuangTable.todayFKShow">
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <el-table-column prop="customerName" label="姓名" ></el-table-column>
        <el-table-column prop="jinJianBankName" label="进件银行" ></el-table-column>
        <el-table-column prop="rate" label="费率" ></el-table-column>
        <el-table-column prop="fangKuanRate" label="批款额度" ></el-table-column>
        <el-table-column prop="jingShouRu" label="净收入" ></el-table-column>
      </el-table>
      <!-- 当日收款分页 -->
      <!-- <el-pagination v-loading="loading" v-show="showTanKuangTable.todayFKShow"  @size-change="detailhandleSizeChange"  @current-change="detailhandleCurrentChange"  :current-page="DFpageConfig.DFcurrentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="DFpageConfig.DFpageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="DFpageConfig.DFtotal">
      </el-pagination> -->
      <!-- 次日上门表单 -->
      <el-table  :data="CStableData" style="width: 100%" height="380" v-loading="loading" v-show="showTanKuangTable.tomorrowSMShow">
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <el-table-column prop="name" label="姓名" ></el-table-column>
        <el-table-column  label="星级" align="center">
          <template slot-scope="scope">{{ scope.row.level| levelKind }}</template>
        </el-table-column>
        <el-table-column  label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '资质不符' " style='color:#C73E3A'>{{ scope.row.state}}</span>
            <span v-else-if=" scope.row.state == 'B上门'||scope.row.state == '已上门'  || scope.row.state == '待签约'|| scope.row.state == '已放款'|| scope.row.state == '审核中'" style='color:#5BBD2B'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '已受理' || scope.row.state == '未接通' || scope.row.state == '待跟进'" style='color:#3390ff'>{{ scope.row.state}}</span>
            <span v-else-if="scope.row.state == '捣乱申请' || scope.row.state == '外地申请'|| scope.row.state == '已拒批'|| scope.row.state == '重复申请'" style='color:#D7962F'>{{ scope.row.state}}</span>
            <span v-else style='color:grey'>{{ scope.row.state}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="资质" width="300">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark"  placement="top">
            <div style="width:200px;" slot="content">{{scope.row.remarks |panKong}}</div>
              <p class="ell"   >{{ scope.row.remarks |panKong}}</p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="distributionTime" label="分配时间" >
          <template slot-scope="scope">
            <p class="ell">{{ scope.row.createTime | formatTime }}</p>
          </template>
        </el-table-column>
      </el-table>
      <!-- 次日上门分页 -->
      <el-pagination v-show="showTanKuangTable.tomorrowSMShow"  @size-change="detailhandleSizeChange"  @current-change="detailhandleCurrentChange"  :current-page="CSpageConfig.CScurrentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="CSpageConfig.CSpageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="CSpageConfig.CStotal">
      </el-pagination>
      <!-- 当前在审件表单 新增-->
      <el-table  :data="SJtableData" style="width: 100%" height="380" v-loading="loading" v-show="showTanKuangTable.todaySJShow">
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <el-table-column prop="name" label="姓名" ></el-table-column>
        <el-table-column prop="jinJianBankName" label="进件银行" ></el-table-column>
        <el-table-column prop="rate" label="费率" ></el-table-column>
        <el-table-column prop="jinJianRate" label="进件额度" ></el-table-column>
        <el-table-column prop="depositMoney" label="诚意金" ></el-table-column>
        <el-table-column prop="state" label="进度" ></el-table-column>
        <el-table-column prop="entryTime" label="进件日期" >
          <template slot-scope="scope">
            <p class="ell">{{ scope.row.entryTime | formatTime }}</p>
          </template>
        </el-table-column>
      </el-table>
      <!-- 当前在审件分页 -->
      <!-- <el-pagination v-show="showTanKuangTable.todaySJShow"  @size-change="detailhandleSizeChange"  @current-change="detailhandleCurrentChange"  :current-page="SJpageConfig.SJcurrentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="SJpageConfig.SJpageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="SJpageConfig.SJtotal">
      </el-pagination> -->
    </el-dialog>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "MyLog",
  // 数据
  data() {
    return {
      //时间区间
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogFormVisible: false,
      //正常数据
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
      //弹窗隐藏显示
      showTanKuangTable:{
        todaySMShow:true,
        todayFKShow:false,
        todayDSShow:false, //当日进件
        tomorrowSMShow:false,
        todaySJShow:false //当前在审件
      },
      showTimeKind:true,
      //未分割的完整版时间区间
      completeTime: "",
      loading:true,
      //时间区间隐藏状态
      isShow:false,
      //禁用按钮的状态值
      disTBN:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      McurrentPage:1, //临时存储当前页
      MpageSize:10, //临时存页容量
      //当日上门页码参数
      DSpageConfig:{
        DScurrentPage:1,//当前页
        DSpageSize:10,//当前单页数据条数
        DStotal:0,//请求到的数据总条数
      },
      //当日进件页码参数  （新）
      DJpageConfig:{
        DJcurrentPage:1,//当前页
        DJpageSize:10,//当前单页数据条数
        DJtotal:0,//请求到的数据总条数
      },
      //当日收款页码参数
      DFpageConfig:{
        DFcurrentPage:1,//当前页
        DFpageSize:10,//当前单页数据条数
        DFtotal:0,//请求到的数据总条数
      },
      //次日上门页码参数
      CSpageConfig:{
        CScurrentPage:1,//当前页
        CSpageSize:10,//当前单页数据条数
        CStotal:0,//请求到的数据总条数
      },
      //当前审件页码参数 （新）
      SJpageConfig:{
        SJcurrentPage:1,//当前页
        SJpageSize:10,//当前单页数据条数
        SJtotal:0,//请求到的数据总条数
      },
      //新增弹窗数据
      myLogForm: {
        typeZH:"当日上门", //充值类型中文
        time:"", //按时间搜索
        type:"today", //值：today，todayJinJian ，tomorrow，todayFk，danQian 默认当日
      },
      //时间选择生成内容
      timeList: [
        { id: "today", name: "今日" },
        { id: "JinLiangDays", name: "近两天" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "customize", name: "自定义" },
      ],
      //客户筛选区域数据
      formLabelAlign: {
        dayType: "JinLiangDays", 	//日期类型
        timeType: "", 	//时间类型
        searchType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      // 六个表格
      tableData: [], //页面表格
      DStableData: [], //弹窗当日上门
      DFtableData: [], //弹窗当日收款
      DJtableData: [], //弹窗当日进件
      CStableData: [], //弹窗次日上门
      SJtableData: [], //弹窗当前审件
    };
  },
  methods: {
    //默认第一页
    chooseFirstPage(){
      this.DSpageConfig.DScurrentPage = 1;
      this.DFpageConfig.DFcurrentPage = 1;
      this.CSpageConfig.CScurrentPage = 1;
      this.SJpageConfig.SJcurrentPage = 1;
      this.DJpageConfig.DJcurrentPage = 1;
    },
    //获取充值类型
    TCTableStyle(val){
      this.chooseFirstPage()
      if (val == "当日上门") {
        this.myLogForm.type = "today";
        this.showTanKuangTable.todaySMShow = true
        this.showTanKuangTable.todayFKShow = false
        this.showTanKuangTable.todayDSShow = false
        this.showTanKuangTable.tomorrowSMShow = false
        this.showTanKuangTable.todaySJShow = false
        const currentPage = this.DSpageConfig.DScurrentPage
        const pageSize = this.DSpageConfig.DSpageSize
        this.getDetailList(currentPage,pageSize)
      }
      if (val == "当日进件") {
        this.myLogForm.type = "todayJinJian";
        this.showTanKuangTable.todaySMShow = false
        this.showTanKuangTable.todayDSShow = true
        this.showTanKuangTable.todayFKShow = false
        this.showTanKuangTable.tomorrowSMShow = false
        this.showTanKuangTable.todaySJShow = false
        const currentPage = this.DJpageConfig.DJcurrentPage;
        const pageSize = this.DJpageConfig.DJpageSize;
        this.getDetailList(currentPage,pageSize)
      }
      if (val == "当日收款") {
        this.myLogForm.type = "todayFk";
        this.showTanKuangTable.todaySMShow = false
        this.showTanKuangTable.todayDSShow = false
        this.showTanKuangTable.todayFKShow = true
        this.showTanKuangTable.tomorrowSMShow = false
        this.showTanKuangTable.todaySJShow = false
        const currentPage = this.DFpageConfig.DFcurrentPage
        const pageSize = this.DFpageConfig.DFpageSize
        this.getDetailList(currentPage,pageSize)
      }
      if (val == "次日上门") {
        this.myLogForm.type = "tomorrow";
        this.showTanKuangTable.todaySMShow = false
        this.showTanKuangTable.todayDSShow = false
        this.showTanKuangTable.todayFKShow = false
        this.showTanKuangTable.tomorrowSMShow = true
        this.showTanKuangTable.todaySJShow = false
        const currentPage = this.CSpageConfig.CScurrentPage
        const pageSize = this.CSpageConfig.CSpageSize
        this.getDetailList(currentPage,pageSize)
      }
      if (val == "当前在审件") {
        this.myLogForm.type = "danQian";
        this.showTanKuangTable.todaySMShow = false
        this.showTanKuangTable.todayDSShow = false
        this.showTanKuangTable.todayFKShow = false
        this.showTanKuangTable.tomorrowSMShow = false
        this.showTanKuangTable.todaySJShow = true
        const currentPage = this.SJpageConfig.SJcurrentPage
        const pageSize = this.SJpageConfig.SJpageSize
        this.getDetailList(currentPage,pageSize)
      }
    },
    //获取我的日志List的方法
    getLogList(){
      this.$axios
      .get(`/theDoor/getTheDoorList?userId=${this.currentUser.currentid}&searchType=${this.formLabelAlign.searchType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.tableData = [];
          this.pageConfig.total = 0;
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //获取详情当日上门数据的方法
    getDetailList(currentPage,pageSize){
      this.loading = true
      this.$axios
      .get(`/theDoor/getTheDoorListById?userId=${this.currentUser.currentid}&searchType=${this.formLabelAlign.searchType}&time=${this.myLogForm.time}&type=${this.myLogForm.type}&currentPage=` + currentPage +`&pageSize=` + pageSize)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          //判断应该渲染哪个列表
          if (this.myLogForm.type == "today") {
            //今日上门
            this.DStableData = res.data.dataModel.list;
            this.DSpageConfig.DStotal = res.data.dataModel.total;
          }else if (this.myLogForm.type == "todayJinJian") {
            //当日进件
            this.DJtableData = res.data.dataModel;
            this.DJpageConfig.DJtotal = res.data.length;
          }else if (this.myLogForm.type == "todayFk") {
            //当日收款（因前期是放款 字段未更改 注意！！）
            this.DFtableData = res.data.dataModel;
            this.DFpageConfig.DFtotal = res.data.dataModel.total;
          }else if (this.myLogForm.type == "tomorrow") {
            //次日上门
            this.CStableData = res.data.dataModel.list;
            this.CSpageConfig.CStotal = res.data.dataModel.total;
          }else if (this.myLogForm.type == "danQian") {
            //当前审件
            this.SJtableData = res.data.dataModel;
            this.SJpageConfig.SJtotal = res.data.total;
          }
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.DStableData = [];
          this.DFtableData = [];
          this.CStableData = [];
          this.DSpageConfig.DStotal = 0;
          this.DFpageConfig.DFtotal = 0;
          this.CSpageConfig.CStotal = 0;
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
        this.loading = false
      });
    },
    //查看详情
    checkDetail(val){
      // console.log(val);
      this.myLogForm.time = val.days;
      //默认为当日上门
      this.myLogForm.typeZH = "当日上门" ;
      this.dialogFormVisible = true ;
      this.myLogForm.type = "today";
        this.showTanKuangTable.todaySMShow = true
        this.showTanKuangTable.todayFKShow = false
        this.showTanKuangTable.tomorrowSMShow = false
        const currentPage = this.DSpageConfig.DScurrentPage
        const pageSize = this.DSpageConfig.DSpageSize
        this.getDetailList(currentPage,pageSize)
    },
    //单行数据的编辑和查看详情
    handleClick(val){
        // 编程式导航
        this.$router.push({ path: '/EditLog'})
        // this.$router.push({ path: '/myCusEdit', query: { "CusId": CusId } })
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";
        }
        this.completeTime = "" 	//未分割的完整版时间区间
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      } else if (obj.id == "today") {
        this.isShow = false;
        this.formLabelAlign.beginTime = moment(new Date()).format("YYYY-MM-DD ") + " " + "00:00:00";
        this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD ")+ " " + "23:59:59";
        this.completeTime = "";
      } else if (obj.id == "JinLiangDays") {
        this.isShow = false;
        this.formLabelAlign.beginTime = moment(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1)).format("YYYY-MM-DD ") + " " + "00:00:00";
        this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD ")+ " " + "23:59:59";
        this.completeTime = "";
      } else if (obj.id == "sevenDay") {
        this.isShow = false;
        this.formLabelAlign.beginTime = moment(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7)).format("YYYY-MM-DD ") + " " + "00:00:00";
        this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD ")+ " " + "23:59:59";
        this.completeTime = "";
      } else if (obj.id == "tomonth") {
        this.isShow = false;
        this.formLabelAlign.beginTime = moment(new Date()).format("YYYY-MM-01 ") + " " + "00:00:00";
        this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD ")+ " " + "23:59:59";
        this.completeTime = "";
      } else {
        this.isShow = false;
        this.completeTime = "";
      }
    },
    //分页的方法
    handleSizeChange(val) {
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getLogList()
      }else{
        this.pageConfig.pageSize = val;
         this.getLogList()
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
        this.getLogList()
    },
    //详情内容分页的方法
    detailhandleSizeChange(val) {
      //判断应该渲染哪个列表
      if (this.myLogForm.type == "today") {
        //今日上门
        const currentPage = this.DSpageConfig.DScurrentPage
        const pageSize = this.DSpageConfig.DSpageSize
        if (currentPage != 1) {
          currentPage = 1;
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }else{
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }
      }else if (this.myLogForm.type == "todayJinJian") {
        //当日进件
        const currentPage = this.DJpageConfig.DJcurrentPage
        const pageSize = this.DJpageConfig.DJpageSize
        if (currentPage != 1) {
          currentPage = 1;
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }else{
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }
      }else if (this.myLogForm.type == "todayFk") {
        //当日放款
        const currentPage = this.DFpageConfig.DFcurrentPage
        const pageSize = this.DFpageConfig.DFpageSize
        if (currentPage != 1) {
          currentPage = 1;
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }else{
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }
      }else if (this.myLogForm.type == "tomorrow") {
        //次日上门
        const currentPage = this.CSpageConfig.CScurrentPage
        const pageSize = this.CSpageConfig.CSpageSize
        if (currentPage != 1) {
          currentPage = 1;
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }else{
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }
      }else if (this.myLogForm.type == "danQian") {
        //当前审件
        const currentPage = this.SJpageConfig.SJcurrentPage
        const pageSize = this.SJpageConfig.SJpageSize
        if (currentPage != 1) {
          currentPage = 1;
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }else{
          pageSize = val;
          this.getDetailList(currentPage,pageSize)
        }
      }
    },
    detailhandleCurrentChange(val) {
      let pageSize = val;
      //判断应该改变哪个页面的页码变量
      if (this.myLogForm.type == "today") {
        //今日上门
        let currentPage = this.DSpageConfig.DScurrentPage
      }else if (this.myLogForm.type == "todayJinJian") {
        //当日进件
        let currentPage = this.DJpageConfig.DJcurrentPage
      }else if (this.myLogForm.type == "todayFk") {
        //当日放款
        let currentPage = this.DFpageConfig.DFcurrentPage
      }else if (this.myLogForm.type == "tomorrow") {
        //次日上门
        let currentPage = this.CSpageConfig.DScurrentPage
      }else if (this.myLogForm.type == "danQian") {
        //当前审件
        let currentPage = this.SJpageConfig.SJcurrentPage
      }
      this.getDetailList(currentPage,pageSize)
    },
    EditLog() {
      // 编程式导航
        this.$router.push({ path: '/EditLog'})
        // this.$router.push({ path: '/myCusEdit', query: { "CusId": CusId } })
    },
    //查询
    checkCus(){
      if (this.completeTime != undefined && this.completeTime != "" ) {
        this.formLabelAlign.beginTime = (this.completeTime)[0];
        this.formLabelAlign.endTime = (this.completeTime)[1];
        // if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
        this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
        this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        // }
      }else{
        this.formLabelAlign.beginTime = this.formLabelAlign.beginTime;
        this.formLabelAlign.endTime = this.formLabelAlign.endTime;
      }
      // console.log(this.formLabelAlign.beginTime);
      // console.log(this.formLabelAlign.endTime );
      // this.disButton()
      this.loading = true
      this.getLogList()
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YYYY-MM-DD");
    },
    detailFormatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    IsSingning(val) {
      if (val == true) {
        return "B上门"
      }else{
        return "未签约";
      }
    },
    panKong(val) {
      if (val == null || val == "null") {
        return ""
      }else{
        return val
      }
    },
    //判断星级状态
    levelKind(val) {
      // console.log(val);
      if (val == "0") {
        return  "0星"
      }else if(val == "1"){
        return  "1星"
      }else if(val == "2"){
        return  "2星"
      }else if(val == "3"){
        return "2星+"
      }else if(val == "4"){
        return  "3星"
      }else if(val == "5"){
        return "4星"
      }else{
        return ""
      }
    },
  },
  created () {
    // 我的日志
    this.formLabelAlign.searchType = "my"
    const beginTime = new Date();
    // 默认时间 今日
    // this.formLabelAlign.beginTime = beginTime.setTime(beginTime.getTime() - 3600 * 1000 * 24 * 7);
    // this.formLabelAlign.beginTime = moment(this.formLabelAlign.beginTime).format("YYYY-MM-DD HH:mm:ss");
    // this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    this.formLabelAlign.beginTime = moment(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1)).format("YYYY-MM-DD ") + " " + "00:00:00";
    this.formLabelAlign.endTime = moment(new Date()).format("YYYY-MM-DD ")+ " " + "23:59:59";
    // console.log(this.formLabelAlign.beginTime );
    // console.log(this.formLabelAlign.endTime );
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
    this.getLogList()
  },
  activated () {
    this.getLogList()
  }
};
</script>

<style  lang='scss'>
.MyLog-container {
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}
//突出隔行的颜色差异
.el-table {
  font-size: 12px;
  .cell {
    padding-left: 1px;
    padding-right: 1px;
    text-align: center;
  }
  th {
    padding: 0;
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important;
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
    .el-dialog__header {
      padding: 10px 20px 10px;
    }
  .el-dialog__body{
    height: 75%;
    padding: 0px 10px;
    /deep/.el-table{
        height: 82%!important;
      }
  }
  .el-dialog{
    width: 76%;
    height: 68%;
  }
}
</style>
