<template>
  <div class="HRemployeeEdit-container">
    <!-- 人事信息 -->
    <el-row class="filterCondition hrMessage">
      <el-form label-width="110px" label-position="inside" :model="formLabelAlign">
        <el-form-item label="当前岗位">
          <!-- <el-select v-model="formLabelAlign.companyPosition" @change="levelLimited" placeholder> -->
          <el-select v-model="formLabelAlign.companyPosition" placeholder disabled>
            <el-option v-if="ShowManger.showCity" label="城市负责人" value="城市负责人"></el-option>
            <el-option v-if="ShowManger.showCity" label="城市负责人(储备)" value="城市负责人(储备)"></el-option>
            <el-option v-if="ShowManger.showFen" label="分公司负责人" value="分公司负责人"></el-option>
            <el-option v-if="ShowManger.showFen" label="分公司负责人(储备)" value="分公司负责人(储备)"></el-option>
            <el-option v-if="ShowManger.showQu" label="区长" value="区长"></el-option>
            <el-option v-if="ShowManger.showQu" label="区长(储备)" value="区长(储备)"></el-option>
            <el-option v-if="ShowManger.showBu" label="部长" value="部长"></el-option>
            <el-option v-if="ShowManger.showBu" label="部长(储备)" value="部长(储备)"></el-option>
            <el-option v-if="ShowManger.showGuWen" label="顾问" value="顾问"></el-option>
            <el-option v-if="ShowManger.showGuWen" label="学徒" value="学徒"></el-option>
            <!-- <el-option v-for="item in partList" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <el-select
            @change="selectGetFen"
            v-model="formLabelAlign.city"
            :placeholder="holderList.cityHolder"
            disabled
          >
            <el-option
              v-for="item in cityselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分公司">
          <el-select
            @change="selectGetYi"
            v-model="formLabelAlign.fengongsi"
            :placeholder="holderList.fengongsiHolder"
            disabled
          >
            <el-option
              v-for="item in fenselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一级部门">
          <el-select
            @change="selectGetEr"
            v-model="formLabelAlign.yiji"
            :placeholder="holderList.yijiHolder"
            disabled
          >
            <el-option
              v-for="item in yijiselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级部门">
          <el-select
            @change="putDepartmentId"
            v-model="formLabelAlign.erji"
            :placeholder="holderList.erjiHolder"
            disabled
          >
            <el-option
              v-for="item in erjiselectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="分配手机号">
          <el-input v-model="formLabelAlign.phone" disabled ></el-input>
        </el-form-item>
        <el-form-item label="就职状态">
          <el-select v-model="formLabelAlign.departure" :placeholder="rightsList.departureHolder" disabled>
            <el-option label="在职" value=false></el-option>
            <el-option label="离职" value=true></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="HR"  label="日接受数据上限">
          <el-input type="number" v-model="formLabelAlign.distributionCount" disabled></el-input>
        </el-form-item>
        <el-form-item class="HR" label="是否禁止登陆">
          <el-select v-model="formLabelAlign.prohibitLogin" :placeholder="rightsList.prohibitLoginHolder" disabled>
            <el-option label="禁止" value=false></el-option>
            <el-option label="不禁止" value=true></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="HR" label="是否接受新数据">
          <el-select v-model="formLabelAlign.isNeedData" :placeholder="rightsList.isNeedDataHolder" disabled>
            <el-option label="允许" value=true></el-option>
            <el-option label="不允许" value=false></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="历史岗位">
          <div  id="noteList">
            <ul>
              <template v-for="(item,index) in this.formLabelAlign.noteList">
                <li  :key='index' v-if="index<6">
                  <p class="liShiGW">{{++index}},{{item.historyTime}} 至 {{item.createTime}},{{item.name}}</p>
                </li>
              </template>
            </ul>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 档案信息 -->
    <el-row class="filterCondition" >
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item  label="姓名" >
          <el-input v-model="formLabelAlign.name" placeholder="" disabled></el-input>
        </el-form-item>
        <el-form-item  label="出生日期">
          <div class="block" id="dateOfBirth">
            <el-date-picker v-model="formLabelAlign.birthday" type="date" format="yyyy/MM/dd"  value-format="yyyy/MM/dd" disabled></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item  label="性别">
          <el-select v-model="formLabelAlign.sex" placeholder disabled>
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="籍贯">
          <el-input v-model="formLabelAlign.address" placeholder="例如：河北 天津" disabled></el-input>
        </el-form-item>
        <el-form-item  label="入职时间">
          <div class="block" id="dateOfBirth">
            <el-date-picker v-model="formLabelAlign.inTheTime" type="date" format="yyyy/MM/dd"  value-format="yyyy/MM/dd" disabled></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item  label="学历">
          <el-select v-model="formLabelAlign.schooling" placeholder disabled>
            <el-option label="初中及以下" value="初中及以下"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="研究生及以上" value="研究生及以上"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="毕业院校">
          <el-input v-model="formLabelAlign.schoolName" disabled></el-input>
        </el-form-item>
        <el-form-item  label="身份证号码">
          <el-input v-model="formLabelAlign.idCard" disabled></el-input>
        </el-form-item>
        <el-form-item  label="个人电话">
          <el-input v-model="formLabelAlign.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item  label="前公司名称">
          <el-input v-model="formLabelAlign.oldCompanyName" disabled></el-input>
        </el-form-item>
        <el-form-item  label="前公司职务">
          <el-input v-model="formLabelAlign.oldCompanyPosition" disabled></el-input>
        </el-form-item>
        <div class="benhangye">
          <el-form-item  id="hangyejingyan" label="从业年限">
            <el-input v-model="formLabelAlign.workingTime" id="nianxian" disabled></el-input>
          </el-form-item>
        </div><br>
        <el-form-item class="dangan" id="describe" label="个人简介">
          <el-input v-model="formLabelAlign.describe" id="nianxian" disabled></el-input>
        </el-form-item>
        <div class="picArea">
          <div class="headPortrait picChild">
            <span>头像</span>
            <img v-if="imageUrl1" @click="YuLan(imageUrl1)" :src="imageUrl1" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="frontCard picChild">
            <span>身份证(正面)</span>
            <img v-if="imageUrl2" @click="YuLan(imageUrl2)" :src="imageUrl2" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="reverseCard picChild">
            <span>身份证(反面)</span>
            <img v-if="imageUrl3" :src="imageUrl3" @click="YuLan(imageUrl3)" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="creditReport picChild">
            <span>征信报告</span>
              <img v-if="imageUrl4" :src="imageUrl4" @click="YuLan(imageUrl4)" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="laborContract picChild">
            <span>劳务合同</span>
              <img v-if="imageUrl5" :src="imageUrl5" @click="YuLan(imageUrl5)" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="graduationCard picChild">
            <span>毕业证</span>
              <img v-if="imageUrl6" :src="imageUrl6" @click="YuLan(imageUrl6)" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="medicalReport picChild">
            <span>体检报告</span>
              <img v-if="imageUrl7" :src="imageUrl7" @click="YuLan(imageUrl7)" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="bankCard picChild">
            <span>银行卡</span>
              <img v-if="imageUrl8" :src="imageUrl8" @click="YuLan(imageUrl8)" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </div>
        <el-button type="primary" @click="submit" id="submit">通过核准</el-button>
      </el-form>
    </el-row>
    <!-- 图片预览功能 -->
    <el-dialog
      id="el-dialog"
      title=""
      :visible.sync="centerDialogVisible"
      width="90%"
      center>
      <img class="ShowImg"  :src="url" alt="">
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "HRemployeeEdit",
  data() {
    return {
     currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      currentEmployeelevel:"",//该员工更改前的level
      changedEmployeelevel:"",//该员工更改后的level
      departmentId:"",
      departmentIdList:{
        cityId : "",
        fenGongSiId : "",
        quYuId : "",
        buMenId : ""
      },
      ShowManger:{
        showCity : true,
        showFen : true,
        showQu : true,
        showBu : true,
        showGuWen : true
      },
      EditEmId:"",
      user:{},//表单要提交的参数
      //权限开关的holder值
      rightsList:{
        departureHolder:"默认在职",
        prohibitLoginHolder:"默认不允许",
        isNeedDataHolder:"默认不允许接受",
      },
      centerDialogVisible: false,
      url: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      //图片
      imageUrl1: "",//头像
      imageUrl2: "",//身份证正面
      imageUrl3: "",//身份证反面
      imageUrl4: "",//征信报告
      imageUrl5: "",//劳务合同
      imageUrl6: "",//毕业证
      imageUrl7: "",//体检报告
      imageUrl8: "",//银行卡
      //层级部门是否限制选择
      disabled: {
        citydisabled: false,
        fendisabled: false,
        yijidisabled: false,
        erjidisabled: false
      },
      //层级部门holder内容
      holderList: {
        cityHolder: "请选择城市",
        fengongsiHolder: "请选择分公司",
        yijiHolder: "请选择一级部门",
        erjiHolder: "请选择二级部门"
      },
      //职位可选项
      partList: [
        { id: 0, name: "城市负责人" ,level:1},
        { id: 1, name: "城市负责人(储备)" ,level:1},
        { id: 2, name: "分公司负责人" ,level:2},
        { id: 3, name: "分公司负责人(储备)",level:2},
        { id: 4, name: "区长",level:3 },
        { id: 5, name: "区长(储备)",level:3 },
        { id: 6, name: "部长",level:4 },
        { id: 7, name: "部长(储备)",level:4 },
        { id: 8, name: "顾问",level:5 },
        { id: 9, name: "学徒",level:5 }
        ],
      //城市动态生成内容
      cityselectList: [{ id: 0, name: "请先选择城市" }],
      //分公司动态生成内容
      fenselectList: [{ id: 0, name: "请先选择上一级" }],
      //一级部门动态生成内容
      yijiselectList: [{ id: 0, name: "请先选择上一级" }],
      //二级部门动态生成内容
      erjiselectList: [{ id: 0, name: "请先选择上一级" }],
      //当前登陆人员level
      level: "",
      //messageText为首次加载时的返回状态
      messageText: "",
      //图片
      imageUrl: "",
      //时间选择框数据
      value1: "",
      baseURL:'https://www.duodaiwang.com/files',
      formLabelAlign: {
        name: "",
        city: "", //城市
        fengongsi: "", //分公司
        yiji: "", //一级部门
        erji: "", //二级部门
        departmentId:"",//departmentId
        departure:"",//就职状态
        prohibitLogin:"",//是否禁止登陆
        isNeedData:"",//是否接收新数据
        distributionCount:"",//日接收数据上限
        address:"",
        inTheTime:"",
        companyPosition: "",
        sex: "",
        phone: "",//分配手机号
        mobile: "",
        schooling: "",
        schoolName: "",
        idCard: "",
        oldCompanyName: "",
        noteList: [{userNmae:"测试",createDate:"2019/09/20",content:"还没有给该顾问添加任何备注"}],
        oldCompanyPosition: "",
        workingTime: "",
        jianjie: "",
        birthday: ""
      }
    };
  },
  methods: {
    //预览图片
    YuLan(url){
        // console.log(url);
        if (url != "") {
            this.url =  url
        }else{
            this.url = "../../public/empty.jpg"
        }
        this.centerDialogVisible = true
    },
    //判断可选项
    PanDuanKeXuan(){
      if (this.currentUser.currentLevel == 0) {
          this.partList =  [
        { id: 0, name: "城市负责人" ,level:1},
        { id: 1, name: "城市负责人(储备)" ,level:1},
        { id: 2, name: "分公司负责人" ,level:2},
        { id: 3, name: "分公司负责人(储备)",level:2},
        { id: 4, name: "区长",level:3 },
        { id: 5, name: "区长(储备)",level:3 },
        { id: 6, name: "部长",level:4 },
        { id: 7, name: "部长(储备)",level:4 },
        { id: 8, name: "顾问",level:5 },
        { id: 9, name: "学徒",level:5 }
        ]
        }else if(this.currentUser.currentLevel == 1){
          this.partList =  [
            { id: 2, name: "分公司负责人" ,level:2},
            { id: 3, name: "分公司负责人(储备)",level:2},
            { id: 4, name: "区长",level:3 },
            { id: 5, name: "区长(储备)",level:3 },
            { id: 6, name: "部长",level:4 },
            { id: 7, name: "部长(储备)",level:4 },
            { id: 8, name: "顾问",level:5 },
            { id: 9, name: "学徒",level:5 }
          ]
        }else if(this.currentUser.currentLevel == 2){
          this.partList =  [
            { id: 4, name: "区长",level:3 },
            { id: 5, name: "区长(储备)",level:3 },
            { id: 6, name: "部长",level:4 },
            { id: 7, name: "部长(储备)",level:4 },
            { id: 8, name: "顾问",level:5 },
            { id: 9, name: "学徒",level:5 }
          ]
        }else if(this.currentUser.currentLevel == 3){
          this.partList =  [
            { id: 6, name: "部长",level:4 },
            { id: 7, name: "部长(储备)",level:4 },
            { id: 8, name: "顾问",level:5 },
            { id: 9, name: "学徒",level:5 }
          ]
        }else if(this.currentUser.currentLevel == 4){
          this.partList =  [
            { id: 8, name: "顾问",level:5 },
            { id: 9, name: "学徒",level:5 }
          ]
        }else {
          this.partList =  []
        }
    },
    settingURL(res){
      //渲染图片
      if (res.data.dataModel.headPortrait != null) {
        this.imageUrl1 = this.baseURL + res.data.dataModel.headPortrait;
      }
      if (res.data.dataModel.frontCard != null) {
        this.imageUrl2 = this.baseURL + res.data.dataModel.frontCard;
      }
      if (res.data.dataModel.reverseCard != null) {
        this.imageUrl3 = this.baseURL + res.data.dataModel.reverseCard;
      }
      if (res.data.dataModel.creditReport != null) {
        this.imageUrl4 = this.baseURL + res.data.dataModel.creditReport;
      }
      if (res.data.dataModel.laborContract != null) {
        this.imageUrl5 = this.baseURL + res.data.dataModel.laborContract;
      }
      if (res.data.dataModel.graduationCard != null) {
        this.imageUrl6 = this.baseURL + res.data.dataModel.graduationCard;
      }
      if (res.data.dataModel.medicalReport != null) {
        this.imageUrl7 = this.baseURL + res.data.dataModel.medicalReport;
      }
      if (res.data.dataModel.bankCard != null) {
        this.imageUrl8 = this.baseURL + res.data.dataModel.bankCard;
      }
    },
    //点击岗位限制选择的选项
    levelLimited(vId) {
      let obj = {};
      obj = this.partList.find(item => {
        //这里的cityselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      this.formLabelAlign.companyPosition = obj.name
      this.formLabelAlign.changedEmployeelevel = obj.level
      // console.log(obj.name);//name就是对应label的
      if (obj.name == "城市负责人" || obj.name == "城市负责人(储备)") {
        //禁用城市一下部门选择
        this.disabled.fendisabled = true;
        this.disabled.yijidisabled = true;
        this.disabled.erjidisabled = true;
      }else if(obj.name == "分公司负责人" || obj.name == "分公司负责人(储备)"){
        this.disabled.fendisabled = false;
        this.disabled.yijidisabled = true;
        this.disabled.erjidisabled = true;
      }else if(obj.name == "区长" || obj.name == "区长(储备)"){
        this.disabled.fendisabled = false;
        this.disabled.yijidisabled = false;
        this.disabled.erjidisabled = true;
      }else if(obj.name == "部长" || obj.name == "部长(储备)"){
        this.disabled.fendisabled = false;
        this.disabled.yijidisabled = false;
        this.disabled.erjidisabled = false;
      }else{
        this.disabled.fendisabled = false;
        this.disabled.yijidisabled = false;
        this.disabled.erjidisabled = false;
      }
    },
    //城市获取分公司数据
    selectGetFen(vId) {
      let obj = {};
      obj = this.cityselectList.find(item => {
        return item.id === vId; //筛选出匹配数据
      });
      //储存被选中的城市ID
      this.departmentIdList.cityId = obj.id
      // console.log(this.departmentIdList.cityId);
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          // console.log(res.data.dataModel);
          this.fenselectList = res.data.dataModel;})
        .catch(error => {
          console.log(error);
        });
    },
    //分公司获取一级部门数据
    selectGetYi(vId) {
      let obj = {};
      obj = this.fenselectList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          // console.log(res.data.dataModel);
          this.yijiselectList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //一级部门获取二级部门数据
    selectGetEr(vId) {
      let obj = {};
      obj = this.yijiselectList.find(item => {
        //这里的erjiselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      //获取下一级组织架构数据
      this.$axios
        .get(`/department/getDepartmentByParentId?parentId=` + obj.id)
        .then(res => {
          // console.log(res.data.dataModel);
          this.erjiselectList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //提交departmentId
    putDepartmentId(vId) {
      let obj = {};
      obj = this.erjiselectList.find(item => {
        //这里的erjiselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);//name就是对应label的
      // console.log(obj.id);
      //保存departmentId
      this.formLabelAlign.departmentId = obj.id;
      
    },
    //请求该顾问的历史岗位
    getPositionList(){
      this.$axios
        .get(`/user/getPositionList?userId=${this.EditEmId}`)
        .then(res => {
          // console.log(res);
          this.formLabelAlign.noteList = res.data.dataModel;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //表单提交的事件
    submit(){//表单提交的事件
      this.$message({  type: 'info',  message: '急什么，这个接口还没写呐！'  });
      //判断手机号是否有效
      // if(!(/^1[3456789]\d{9}$/.test(this.formLabelAlign.phone))){ 
      //     this.$message.error("分配手机号码填写有误，请检查");
      //     return false; 
      // } 
      // if(!(/^1[3456789]\d{9}$/.test(this.formLabelAlign.mobile))){ 
      //     this.$message.error("个人手机号码填写有误，请检查");
      //     return false; 
      //   } 
      //  //下面append的东西就会到form表单数据的fields中；
      //  if (this.formLabelAlign.departure == "true") {
      //    this.user.append('departure', true);//就职状态  
      //  }else if(this.formLabelAlign.departure == "false"){
      //    this.user.append('departure', false);
      // }
      // if (this.formLabelAlign.prohibitLogin == "true") {
      //    this.user.append('prohibitLogin', true);//登陆
      //  }else if(this.formLabelAlign.prohibitLogin == "false"){
      //    this.user.append('prohibitLogin', false);
      // }
      // if (this.formLabelAlign.isNeedData == "true") {
      //    this.user.append('isNeedData', true);//获取数据
      //  }else if(this.formLabelAlign.isNeedData == "false"){
      //    this.user.append('isNeedData', false);
      // }       
      // if (this.formLabelAlign.changedEmployeelevel > this.currentEmployeelevel) {
      //   //为降级 通过判断确保降级后的部门所属不为空
      //   if (this.formLabelAlign.changedEmployeelevel == 5 || this.formLabelAlign.changedEmployeelevel == 4 ) {
      //     if (this.formLabelAlign.erji == undefined || this.formLabelAlign.erji == "") {
      //       this.$message.error("对应的部门不能为空");
      //       return false
      //     }
      //   }else if (this.formLabelAlign.changedEmployeelevel == 3) {
      //     if (this.formLabelAlign.yiji == undefined || this.formLabelAlign.yiji == "") {
      //       this.$message.error("对应的区域不能为空");
      //       return false
      //     }    
      //   }else if (this.formLabelAlign.changedEmployeelevel == 2) {
      //     if (this.formLabelAlign.fengongsi == undefined || this.formLabelAlign.fengongsi == "") {
      //       this.$message.error("对应的分公司不能为空");
      //       return false
      //     }    
      //   }
      // }
      // //提交的时候有id就是编辑  没有id就是新增
      // this.user.append('id', this.EditEmId);
      // this.user.append('name', this.formLabelAlign.name);
      // this.user.append('companyPosition', this.formLabelAlign.companyPosition);//岗位
      // this.user.append('departmentId', this.formLabelAlign.departmentId);//组织架构
      // this.user.append('birthday', this.formLabelAlign.birthday);//生日
      // this.user.append('sex', this.formLabelAlign.sex);//性别
      // this.user.append('address', this.formLabelAlign.address);//籍贯
      // this.user.append('inTheTime', this.formLabelAlign.inTheTime);//入职
      // this.user.append('mobile', this.formLabelAlign.mobile);//个人手机号
      // this.user.append('phone', this.formLabelAlign.phone);//分配手机号
      // this.user.append('schooling', this.formLabelAlign.schooling);//学历
      // this.user.append('schoolName', this.formLabelAlign.schoolName);//院校
      // this.user.append('idCard', this.formLabelAlign.idCard);//身份证
      // this.user.append('oldCompanyName', this.formLabelAlign.oldCompanyName);//上家公司名称
      // this.user.append('oldCompanyPosition', this.formLabelAlign.oldCompanyPosition);//上家公司职位
      // this.user.append('workingTime', this.formLabelAlign.workingTime);//入行时间
      // this.user.append('jianjie', this.formLabelAlign.jianjie);//个人简介
      // this.user.append('distributionCount', this.formLabelAlign.distributionCount);//接收数据上限   
      // let config = {
      //   headers: {  'Content-Type': 'multipart/form-data'  }
      // };
      // //提交user
      // this.$axios.post("/user/addOrUpdateUser", this.user, config).then(res => {
      //    console.log(res);
      //    if (res.data.messageModel.code == 0) {
      //      this.$message.success("修改成功");
      //      this.user = new FormData();
      //    }else{
      //      this.$message.error(res.data.messageModel.messageText);
      //      this.user = new FormData();
      //    }
      //  })
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YYYY-MM-DD");
    }
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    if (this.currentUser.currentLevel == 0) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
    }else if (this.currentUser.currentLevel == 1) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
    }else if (this.currentUser.currentLevel == 2) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
    }else if (this.currentUser.currentLevel == 3) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
      this.ShowManger.showQu = false
    }else if (this.currentUser.currentLevel == 4) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
      this.ShowManger.showGuWen = true
    }else {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
    }
    this.EditEmId = window.sessionStorage.getItem("EditEmId")
    // this.user.id = this.$route.query.EditEmId
    //接受传过来的id
    // console.log(this.$route.query.EditEmId);
    //重新写一个表单上传的方法
    this.user = new FormData();
    //获取人员id并请求
    this.$axios
      .get(`/user/getUserById?id=${this.EditEmId}`)
      .then(res => {
        // console.log(res); 
        if (res.data.dataModel == null) {
          this.$message(res.data.messageModel.messageText);
          return false
        }
        this.departmentId = res.data.dataModel.departmentId 
        this.currentEmployeelevel = res.data.dataModel.userLevel;
        //因为状态回传为英文 所以需要手动转化为中文
        this.formLabelAlign = res.data.dataModel;
        //因为状态回传为英文 所以需要手动转化为中文
        let departure = res.data.dataModel.departure;//就职状态
        // console.log(departure);
        if (departure == true) {  this.formLabelAlign.departure = "离职"
        }else{  this.formLabelAlign.departure = "在职"
        }
        var prohibitLogin = res.data.dataModel.prohibitLogin;//是否限制登陆
        if (prohibitLogin == true) {  this.formLabelAlign.prohibitLogin = "不禁止"
        }else{  this.formLabelAlign.prohibitLogin = "禁止"
        }
        var isNeedData = res.data.dataModel.isNeedData;//是否接收数据
        if (isNeedData == true) {  this.formLabelAlign.isNeedData = "允许"
        }else{  this.formLabelAlign.isNeedData = "不允许"
        }
        this.holderList.cityHolder = res.data.dataModel.departmentName1;
        this.holderList.fengongsiHolder = res.data.dataModel.departmentName2;
        this.holderList.yijiHolder = res.data.dataModel.departmentName3;
        this.holderList.erjiHolder = res.data.dataModel.departmentName4;
        this.settingURL(res);//渲染图片
        // console.log(this.currentUser.currentLevel);
        //通过被编辑人的level决定其可以选择的的岗位
        this.PanDuanKeXuan()
      });
      //获取组织架构数据的请求（由于异步原因 这处动态操作人id不方便 可通过限制选择框禁用达到limited效果）
    this.$axios
      .get(`/department/getDepartmentById?departmentId=0`)
      .then(res => {
        // console.log(res);
        this.messageText = res.data.messageModel.messageText;
        // console.log(this.messageText);
        this.total = res.data.total;
        // console.log(this.total); 
        if (this.total == 0 ) {
          //权限最高的管理员入口  获取所有城市
          this.$axios
            .get(`/department/getAll`)
            .then(res => {
              // console.log(res);
              this.cityselectList = res.data;
            })
            .catch(error => {
              console.log(error);
            });
          //直接渲染分城市选项
          // this.cityselectList =  res.data.dataModel.departmentList;
        }  else {
          //通过level的值来判断登陆者的权限
          this.level = res.data.dataModel.level;
          if (this.level == 1) {
          //分公司负责人入口
          //限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.name;
          //渲染分公司选项
          this.fenselectList = res.data.dataModel.departmentList;
        } else if (this.level == 2) {
          //分公司负责人入口
          //限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
          //渲染区域选项
          this.yijiselectList = res.data.dataModel.departmentList;
        } else if (this.level == 3) {
          //区域负责人入口 限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司选择并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
          //限制区域选择并赋值
          this.disabled.yijidisabled = true;
          this.holderList.yijiHolder = res.data.dataModel.name;
          //渲染二级部门选项
          this.erjiselectList = res.data.dataModel.departmentList;
        } else if (this.level == 4) {
          //区域负责人入口 限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司选择并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder = res.data.dataModel.parentName2;
          //限制区域选择并赋值
          this.disabled.yijidisabled = true;
          this.holderList.yijiHolder = res.data.dataModel.parentName3;
          //限制部门限制区域选择并赋值
          this.disabled.erjidisabled = true;
          this.holderList.erjiHolder = res.data.dataModel.name;
        }else{
          // console.log("你还有情况没考虑到啊");
        }
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.getPositionList()
  },
  activated () {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    if (this.currentUser.currentLevel == 0) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
    }else if (this.currentUser.currentLevel == 1) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
    }else if (this.currentUser.currentLevel == 2) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
    }else if (this.currentUser.currentLevel == 3) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = true
      }
      this.ShowManger.showCity = false
      this.ShowManger.showFen = false
      this.ShowManger.showQu = false
    }else if (this.currentUser.currentLevel == 4) {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
      this.ShowManger.showGuWen = true
    }else {
      for(let key in this.ShowManger){
        this.ShowManger[key] = false
      }
    }
    this.EditEmId = window.sessionStorage.getItem("EditEmId")
    //接受传过来的id
    // console.log(this.$route.query.EditEmId);
    //重新写一个表单上传的方法
    this.user = new FormData();
    //获取人员id并请求
    this.$axios
      .get(`/user/getUserById?id=${this.EditEmId}`)
      .then(res => {
        // console.log(res); 
        if (res.data.dataModel == null) {
          this.$message(res.data.messageModel.messageText);
          return false
        }
        this.departmentId = res.data.dataModel.departmentId 
        this.currentEmployeelevel = res.data.dataModel.userLevel;
        //因为状态回传为英文 所以需要手动转化为中文
        this.formLabelAlign = res.data.dataModel;
        //因为状态回传为英文 所以需要手动转化为中文
        let departure = res.data.dataModel.departure;//就职状态
        if (departure == true) {  this.formLabelAlign.departure = "离职"
        }else{  this.formLabelAlign.departure = "在职"
        }
        var prohibitLogin = res.data.dataModel.prohibitLogin;//是否限制登陆
        if (prohibitLogin == true) {  this.formLabelAlign.prohibitLogin = "不禁止"
        }else{  this.formLabelAlign.prohibitLogin = "禁止"
        }
        var isNeedData = res.data.dataModel.isNeedData;//是否接收数据
        if (isNeedData == true) {  this.formLabelAlign.isNeedData = "允许"
        }else{  this.formLabelAlign.isNeedData = "不允许"
        }
        this.holderList.cityHolder = res.data.dataModel.departmentName1;
        this.holderList.fengongsiHolder = res.data.dataModel.departmentName2;
        this.holderList.yijiHolder = res.data.dataModel.departmentName3;
        this.holderList.erjiHolder = res.data.dataModel.departmentName4;
        this.settingURL(res);//渲染图片
        //通过被编辑人的level决定其可以选择的的岗位
        this.PanDuanKeXuan()
        
      });
      //获取组织架构数据的请求（由于异步原因 这处动态操作id不方便 可通过限制选择框禁用达到limited效果）
    this.$axios
      .get(`/department/getDepartmentById?departmentId=0`)
      .then(res => {
        // console.log(res);
        this.messageText = res.data.messageModel.messageText;
        this.total = res.data.total;
        if (this.total == 0 ) {
          //权限最高的管理员入口  获取所有城市
          this.$axios
            .get(`/department/getAll`)
            .then(res => {
              // console.log(res);
              this.cityselectList = res.data;
            })
            .catch(error => {
              console.log(error);
            });
          //直接渲染分城市选项
          // this.cityselectList =  res.data.dataModel.departmentList;
        }  else {
          //通过level的值来判断登陆者的权限
          this.level = res.data.dataModel.level;
          if (this.level == 1) {
          //分公司负责人入口
          //限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.name;
          //渲染分公司选项
          this.fenselectList = res.data.dataModel.departmentList;
        } else if (this.level == 2) {
          //分公司负责人入口
          //限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
          //渲染区域选项
          this.yijiselectList = res.data.dataModel.departmentList;
        } else if (this.level == 3) {
          //区域负责人入口 限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司选择并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder =
            res.data.dataModel.departmentList[0].parentName2;
          //限制区域选择并赋值
          this.disabled.yijidisabled = true;
          this.holderList.yijiHolder = res.data.dataModel.name;
          //渲染二级部门选项
          this.erjiselectList = res.data.dataModel.departmentList;
        } else if (this.level == 4) {
          //区域负责人入口 限制city选择并给city赋值
          this.disabled.citydisabled = true;
          this.holderList.cityHolder = res.data.dataModel.parentName1;
          //限制分公司选择并赋值
          this.disabled.fendisabled = true;
          this.holderList.fengongsiHolder = res.data.dataModel.parentName2;
          //限制区域选择并赋值
          this.disabled.yijidisabled = true;
          this.holderList.yijiHolder = res.data.dataModel.parentName3;
          //限制部门限制区域选择并赋值
          this.disabled.erjidisabled = true;
          this.holderList.erjiHolder = res.data.dataModel.name;
        }else{
          // console.log("你还有情况没考虑到啊");
        }
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.getPositionList()
  }
};
// text-align: right;
</script>

<style  lang='scss'>
.HRemployeeEdit-container {
  ul {
    list-style: none;
    padding-left: 20px;
  }
  .hrMessage {
    .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
    width: 300px;
    .el-form-item__label {
      width: 116px;
      display: inline-block;
      text-align: right;
    }
    //
    }
    #noteList {
      width: 478px;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      /deep/.el-form-item__content {
        line-height: 22px !important;
        position: relative;
        font-size: 14px;
      }
      ul {
        margin: 0 0 10px 0;
        padding-bottom: 5px;
      }
      ul li {
        height: 20px;
        .liShiGW {
          margin: 0;
        }
      }
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
    //labal文字居左
  }
  .el-form-item__label {
    display: inline-block;
    text-align: right;
  }
  // 时间分类框样式
  .HRemployeeEdit-container input.el-input__inner#againkinds,
  .HRemployeeEdit-container input.el-input__inner#againtime {
    width: 85px;
    padding-left: 7px;
  }
  //历史职位状态选择器
  #block {
    width: 469px;
    vertical-align: middle;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    color: #606266;
    background-color: #F1F1F1;
    display: inline-block;
    font-size: inherit;
    height: 90px;
    line-height: 12px;
    padding-left: 10px;
    .blockp {
      padding: 0;
    }
  }
  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
    .el-date-editor .el-range-separator {
      width: 5%;
    }
  }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
    #submit {
      margin: 50px 0 0 122px;
    }
    //个人简介
    #describe {
      .el-input__inner {
        width: 527px;
      }
    }
  }
  .zaifenCheck {
    margin-left: 20px;
  }
  .zaifenBth.el-button {
    padding: 10px 20px;
  }
  #dateOfBirth {
    width: 170px;
    // padding-left: 30px;
    input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 30px;
    }
  }
  .redistribution-container input.el-input__inner {
    width: 182px;
    height: 32px;
    padding-left: 10px;
  }
  #name .el-form-item__label {
    width: 43px;
    text-align: right;
  }
  .benhangye {
    display: inline-block;
    vertical-align: top;
    /deep/ .el-form-item__content {
      margin-left: 10px !important;
    }
    #hangyejingyan {
      display: flex;
      margin: 0;

      .el-form-item__label {
        width: 115px;
        text-align: right;
      }
    }
  }
  // 从业年限
  .nianxian {
    margin-left: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 182px;
  }
  //图片区域
  .picArea {
    display: flex;
    flex-wrap: wrap; 
    width: 100%;
    //图片展示框的大小
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    // .avatar {
    //   width: 178px;
    //   height: 178px;
    //   display: block;
    // }
    .picChild {
    //   float: left;
        // width: 25%;
        flex: 0 0 25%;
        display: flex;
        margin-top: 20px;
        img {
            border: 1px dashed #606266;
            border-radius: 6px;
        }
        span {
          width: 20%;
          font-size: 14px;
          color: #606266;
          margin-right: 10px;
          margin-left: 20px;
        }
        .avatar {
          width: 268px;
          height: 162px;
        }
        i {
            width: 268px;
            height: 162px;
        }
    }
  }
  .el-dialog {
      margin-top: 5vh;
      height: 90%;
      // background: url("https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg") no-repeat center top;
      text-align: center;
    }
    #el-dialog {
      /deep/.el-dialog--center {
        margin-top: 5vh!important;
        height: auto;
        width: auto;
        .ShowImg{
          height: 100%;
          width: 100%;
        }
      }
    }
}

</style>