<template>
  <div class="EditLog-container">
    <!-- <h2>编辑日志</h2> -->
    <div class="EditLogTables-container">
      <p>当日上门客户</p>
      <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
      <el-table  :data="todayData" style="width: 100%" v-loading="loading" highlight-current-row @row-click="handleCurrentChange">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="name" label="姓名"  ></el-table-column>
        <el-table-column prop="remarks" label="资质"  >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark"  placement="top">
            <div style="width:200px;" slot="content">{{scope.row.remarks | panKong}}</div>
            <p class="ell ziZhi">{{ scope.row.remarks | panKong}}</p>
            </el-tooltip> 
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="上门时间" >
          <template slot-scope="scope">
            <span class="ell">{{ scope.row.createTime | formatTime }}</span>
          </template> 
        </el-table-column>
        <el-table-column prop="signing" label="是否签约" >
          <template scope="scope">
            <el-switch v-model="scope.row.signing" active-text="已签" inactive-text="未签"  @change="handleEdit(scope.$index, scope.row)"></el-switch>
          </template>
        </el-table-column> 
        <!-- <el-table-column prop="signing" label="是否签约"  >
          <template slot-scope="scope">
            <el-select v-model="scope.row.signing" v-on:change="changeSigning($event)" placeholder>
              <el-option label="请选择" value= ""></el-option>
              <el-option label="未签约" value=0></el-option>
              <el-option label="已签约" value=1></el-option>
            </el-select>
          </template>
        </el-table-column> -->
        <el-table-column prop="creditReporting" label="客户征信" >
          <template scope="scope">
            <el-switch v-model="scope.row.creditReporting" active-text="良好" inactive-text="差"  @change="handleEdit(scope.$index, scope.row)"></el-switch>
          </template>
        </el-table-column> 
        <el-table-column label="说明" >
            <template scope="scope">
              <el-input size="small" type="textarea" :autosize="{ minRows: 2, maxRows: 3}" v-model="scope.row.content" placeholder="请输入未签约/跟进情况" @change="handleEdit(scope.$index, scope.row)"></el-input>
            </template>
        </el-table-column>
      </el-table>
      <p>当日进件客户</p>
      <el-table  :data="DJtableData" style="width: 100%" v-loading="loading" :row-style="headerColor">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <el-table-column prop="customerName" label="姓名"  ></el-table-column>
        <el-table-column prop="jinJianBankName" label="进件银行" ></el-table-column>
        <el-table-column prop="rate" label="费率" ></el-table-column>
        <el-table-column prop="jinJianRate" label="进件额度" ></el-table-column> 
        <el-table-column prop="fangKuanRate" label="批款额度" ></el-table-column>
        <el-table-column prop="jingShouRu" label="净收入" ></el-table-column>
        <el-table-column prop="state" label="进度" ></el-table-column>
      </el-table>
      <p>当日收款客户</p>
      <el-table  :data="DStableData" style="width: 100%" v-loading="loading">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="customerId" label="客户ID" ></el-table-column>
        <!-- <el-table-column prop="id" label="日志ID"   ></el-table-column> -->
        <el-table-column prop="customerName" label="姓名"  ></el-table-column>
        <el-table-column prop="jinJianBankName" label="进件银行" ></el-table-column>
        <el-table-column prop="rate" label="费率" ></el-table-column>
        <el-table-column prop="jinJianRate" label="批款额度" ></el-table-column> 
        <el-table-column prop="jingShouRu" label="净收入" ></el-table-column>
      </el-table>
      <p>次日上门客户</p>
        <el-input v-model="formLabelAlign.customerId" placeholder="请输入客户ID/手机号"></el-input>
        <el-button style="background-color:#c73e3a;color:#fff" :disabled="disTBN" @click="checkCus" class="check">查询</el-button> 
      <!-- table -->
      <el-table  :data="tomorrowData" style="width: 100%" v-loading="loading" @row-click="handleCurrentChange">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <!-- <el-table-column type="index" width="50"></el-table-column> -->
        <el-table-column prop="customerId" label="客户ID"  ></el-table-column>
        <!-- <el-table-column prop="id" label="日志ID"  ></el-table-column> -->
        <el-table-column prop="name" label="客户姓名"  >
          <template scope="scope">
            <el-input size="small" v-model="scope.row.name" placeholder="请输入客户姓名" @change="handleEdit(scope.$index, scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="客户手机号"  ></el-table-column>
        <el-table-column prop="remarks" label="资质"  >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark"  placement="top">
            <div style="width:200px;" slot="content">{{scope.row.remarks | panKong}}</div>
            <p class="ell ziZhi">{{ scope.row.remarks | panKong}}</p>
            </el-tooltip> 
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="所属顾问" ></el-table-column>
        <!-- <el-table-column prop="userPhone" label="所属顾问手机" ></el-table-column> -->
        <el-table-column label="操作">
          <template scope="scope">
            <!--<el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
            <el-button size="small" type="danger" @click.native.prevent="deleteRow(scope.$index, tomorrowData)" v-if="scope.row.id == 0 ">删除</el-button>
            <el-button size="small" type="danger" @click="deleteShouDongRow(scope.$index, scope.row)" v-else>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="btn" style="text-align: center;margin-top:66px">
        <el-button style="background-color:#c73e3a;color:#fff" :disabled="disTBN" @click="submit" >提交</el-button>
        <el-button style="background-color:#3a8ac7;color:#fff" :disabled="disTBN" @click="cancel"  >取消</el-button>
      </div>
      
    </div>
    
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
import qs from "qs";
export default {
  name: "EditLog",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
      showTimeKind:true,
      //未分割的完整版时间区间
      value1: "",
      loading:false,
      //时间区间隐藏状态
      isShow:false,
      //禁用按钮的状态值
      disTBN:false,
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      myLogForm: {
        typeZH:"当日上门", //充值类型中文
        time:"", //按时间搜索
        type:"today", //值：today，tomorrow，todayFk 默认当日
      },
      //客户筛选区域数据
      formLabelAlign: {
        timeType: "", 	//时间类型
        dayType:"tomonth",
        customerId : ""  //查询客户ID
      },
      todayData: [{
            shuoMing: '',
            name: '王小虎',
            signing: false ,
            creditReporting:false,
            remarks: '测试数据，仅在服务器连接失败时显示'
        }
      ],
      //提交数据下标的数组
      indexList:[],
      //真正提交的表格
      trueTodayData:[],
      DStableData: [],
      DJtableData: [],
      tomorrowData: [{
            id: 123,
            name: '测试',
            phone: '13888888888',
            userName: '销售王（仅在服务器连接失败时显示）',
      }],
      //传送对象
      demo:[]
    };
  },
  methods: {
    //首行总计为红色
    headerColor({row,rowIndex}){
      if (rowIndex == 0) {
        return 'color:#184785'
      }
    },
    //获取详情当日上门
    getTodaySM(){
      this.loading = true
      this.$axios
      .get(`/theDoor/getTheDoorListById?userId=${this.currentUser.currentid}&time=${this.myLogForm.time}&searchType=my&type=today&currentPage=1&pageSize=1000`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          for (let i = 0; i < res.data.dataModel.list.length; i++) {
            if(res.data.dataModel.list[i].signing == 1){
              res.data.dataModel.list[i].signing = true;
            }else{
              res.data.dataModel.list[i].signing = false;
            }
            if(res.data.dataModel.list[i].creditReporting == 1){
              res.data.dataModel.list[i].creditReporting = true;
            }else{
              res.data.dataModel.list[i].creditReporting = false;
            }
          }
          this.todayData = res.data.dataModel.list;
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.todayData = [];
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
        this.loading = false
      });
    },
    //获取详情当日进件
    getTodayJinJian(){
      this.loading = true
      this.$axios
      .get(`/bill/billList?userId=${this.currentUser.currentid}&time=${this.myLogForm.time}&otherScopeOf=user&otherName=${this.currentUser.currentName}&timeType=jinJianTime&dayType=today&currentPage=1&pageSize=1000`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          if (res.data.dataModel.list.length == 1 && res.data.dataModel.total == 0) {
            this.DJtableData = [];
          }else{
            res.data.dataModel.list[0].customerId = '总计';
            this.DJtableData = res.data.dataModel.list;
          }
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.DJtableData = [];
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
        this.loading = false
      });
    },
    //获取详情当日放款
    getTodayFk(){
      this.loading = true
      this.$axios
      .get(`/theDoor/getTheDoorListById?userId=${this.currentUser.currentid}&searchType=my&type=todayFk&time=${this.myLogForm.time}&currentPage=1&pageSize=1000`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          this.DStableData = res.data.dataModel;
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.DStableData = [];
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
        this.loading = false
      });
    },
    //获取次日上门数据
    getTomorrow(){
      this.$axios
      .get(`/theDoor/getTheDoorListById?userId=${this.currentUser.currentid}&searchType=my&type=tomorrow&time=${this.myLogForm.time}&currentPage=1&pageSize=1000`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          this.tomorrowData = res.data.dataModel.list;
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.tomorrowData = [];
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
        this.loading = false
      });
    },
    handleCurrentChange(row, event, column,index) {
      // console.log(row, event, column,index)
    },
    handleEdit(index, row) {
      // console.log(index, row);
      if (this.indexList.indexOf(index) == -1) {
        this.indexList.push(index)
      }
    },
    handleDelete(index, row) {
      // console.log(index, row);
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //删除客户
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //查询客户 contentType: "application/json;charset=UTF-8",
    checkCus(){
      this.disButton()
      if (this.formLabelAlign.customerId == "") {
        this.$message.error("请填写需要查询的客户ID");
        return false;
      }
      this.$axios
        .get(`/bill/getCustomerById?customerId=${this.formLabelAlign.customerId}`)
        .then(res => {      
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            const addDataOne = {id:0,name:"",phone:"",user:{name:0,phone:0}};
            addDataOne.id = 0
            addDataOne.customerId = res.data.dataModel.id
            addDataOne.name = res.data.dataModel.name
            addDataOne.phone = res.data.dataModel.phone
            addDataOne.remarks = res.data.dataModel.remarks
            addDataOne.userName = res.data.dataModel.user.name 
            addDataOne.userId = res.data.dataModel.user.id 
            addDataOne.userPhone = res.data.dataModel.user.phone
            this.tomorrowData.push(addDataOne)
          }else if (res.data.messageModel.code == 103) {
            const addData = {id:0,name:"",phone:"",userName:this.currentUser.currentName,userId:this.currentUser.currentid,userPhone:this.currentUser.currentPhone};
            if (this.formLabelAlign.customerId.length == 11) {
              addData.phone = this.formLabelAlign.customerId
              this.tomorrowData.push(addData)
            }else{
              this.$message.error("没有找到该客户");
            }
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //提交
    submit(){
      console.log(this.todayData);
      //判断是否存在客户姓名为空的情况
      if (this.todayData.length != 0) {
        for (let i = 0; i < this.todayData.length; i++) {
          console.log(this.todayData[i].content);
          if (this.todayData[i].content == "" || this.todayData[i].content == null) {
            this.$message.error("今日上门客户说明不能为空");
            return false;
          }
        }
      }
      if (this.tomorrowData.length != 0) {
        for (let i = 0; i < this.tomorrowData.length; i++) {
          if (this.tomorrowData[i].name == "") {
            this.$message.error("新增次日上门客户姓名不能为空");
            return false;
          }
        }
      }
      if (this.indexList.length != 0) {
        for (let i = 0; i < this.indexList.length; i++) {
          // const j = this.indexList[i]
          if(this.todayData[this.indexList[i]].signing){
            this.todayData[this.indexList[i]].signing = 1.;
          }else{
            this.todayData[this.indexList[i]].signing = 0;
          }
          if(this.todayData[this.indexList[i]].creditReporting){
            this.todayData[this.indexList[i]].creditReporting = 1.;
          }else{
            this.todayData[this.indexList[i]].creditReporting = 0;
          }
          this.trueTodayData.push(this.todayData[this.indexList[i]]);
        }
      }
      //传当日上门
      this.$axios
        .post(`/theDoor/updateTheDoor?userId=${this.currentUser.currentid}`,JSON.stringify(this.trueTodayData), {headers:{'Content-type':'application/json;charset=UTF-8'}})
        .then(res => {      
          // console.log(res);
          // if (res.data.messageModel.code == 0) {
          // }else{
          //   this.$message.error(res.data.messageModel.messageText);
          // }
        })
        .catch(error => {
          console.log(error);
        });
      //传次日上门
      this.$axios
        .post(`/theDoor/addTomrrowData?userId=${this.currentUser.currentid}&userPhone=${this.currentUser.currentPhone}`,JSON.stringify(this.tomorrowData), {headers:{'Content-type':'application/json;charset=UTF-8'}})
        .then(res => {      
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({type: 'success',  message: '您已成功提交编辑日志' });
            this.$router.push({ path: '/MyLog' })
          }else{
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //取消
    cancel(){
      this.$router.push({ path: '/MyLog'})
    },
    //删除次日上门客户弹窗
    open() {
      this.$confirm('此操作将删除该上门客户, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
      }).then(() => {
        this.$message({type: 'success',message: '删除成功!'});
      }).catch(() => {
        this.$message({type: 'info',message: '已取消删除'});          
      });
    },
    //删除手动添加的row
    deleteShouDongRow(index, row){
      // console.log(index, row);
      this.$axios
        .get(`/theDoor/delTheDoor?id=${row.id}`)
        .then(res => {     
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
      //物理删除
      this.tomorrowData.splice(index, 1);
    },
    //签约选择框
    changeSigning(event){
      console.log(event);
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YY-MM-DD");
    },
    panKong(val) {
      if (val == null || val == "null") {
        return ""
      }else{
        return val
      }
    },
  },
  created () {
    this.demo = new Object();
    // console.log(this.$store.state.admin);
    //当日日期
    // const todayTime = new Date();
    this.myLogForm.time = moment(new Date()).format("YYYY-MM-DD");
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
    this.currentUser.currentPhone = window.sessionStorage.getItem("phone")
    this.getTomorrow()
    this.getTodaySM()
    this.getTodayFk()
    this.getTodayJinJian()
  },
  activated () {
    // this.getTomorrow()
    this.getTodaySM()
    this.getTodayFk()
    this.getTodayJinJian()
  }
};
</script>

<style  lang='scss'>
.EditLog-container {
  .EditLogTables-container{
    margin: 10px;
    .el-input{
      display: inline-block;
      width: 180px;
      .el-input__inner {
        width: 160px;
        height: 30px;
      }
    }
    .check {
      padding: 7px 20px;
      margin-bottom: 20px;
    }
    p{
      color: #555;
      border-bottom: 1px solid #B7B7B7;
      font-weight: 500;
    }
    .ell.ziZhi {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // line-height: .9;
      border: none;
    }
    .el-table{
        font-size: 12px;
        .cell {
        padding-left: 1px;
        padding-right: 1px;
        text-align: center;
        }
        td{
            padding: 0;
        }
        th {
          padding: 0;
        }
        .shuoMing{
            width: 100%;
            border: 0;
            // outline: none;
            margin: 0;
            padding: 0 10px;
        }
        .el-button--danger {
          padding: 6px 15px;
        }
    }
  }
    
}
</style>