<template>
  <div class="Leaderboard-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
        <el-form-item label="城市" v-if="ShowCity">
          <el-select v-model="formLabelAlign.city" placeholder="请选择城市" >
            <el-option
              v-for="item in cityselectList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="层级">
          <el-select v-model="formLabelAlign.scopeOf" id="otherThing" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="分公司" value="fenGS"></el-option>
            <el-option label="区域" value="quyu"></el-option>
            <el-option label="部门" value="bumen"></el-option>
            <el-option label="顾问" value="guwen"></el-option>
          </el-select>
          <el-input v-model="formLabelAlign.scopeOfValue" class="yaosile" id="addThing"></el-input>
        </el-form-item>
        <el-form-item prop="timeType" label="时间">
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-button style="background-color:#c73e3a;color:#fff" @click="queryList" class="check" :disabled="disTBN">查询</el-button>
        <el-button style="background-color:#c73e3a;color:#fff" @click="exportClick" class="check" :disabled="disTBN" v-show="OnlyForCaiWu">导出数据</el-button>
      </el-form>
    </el-row>
    <!-- <el-table ref="tableData" tooltip-effect="dark" style="width: 100%" show-summary :summary-method="getSummaries" :data="tableData"  :span-method="arraySpanMethod"> -->
    <el-table ref="tableData" tooltip-effect="dark" style="width: 100%"  :data="tableData" :cell-style="cellStyle" v-loading="loading" :span-method="arraySpanMethod" :row-style="headerColor">
      <!-- <el-table-column type="index" label="排名" align="center" width="50" :index="indexFun"></el-table-column> -->
      <el-table-column label="排名" align="center" width="50" >
        <template slot-scope="scope">
          <img v-if="scope.row.rownum == 1" src="../../assets/jinpai.png" alt="">
          <img v-else-if="scope.row.rownum == 2" src="../../assets/yinpai.png" alt="">
          <img v-else-if="scope.row.rownum == 3" src="../../assets/tongpai.png" alt="">
          <span v-else>{{scope.row.rownum}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="departmentName"  label="名称"  width="70" ></el-table-column>
      <el-table-column label="归属" width="140" v-if="cengJiShow.guWenShow">  
        <template slot-scope="scope">
          <span v-if="scope.row.userLevel == 5 || scope.row.userLevel == 4">{{scope.row.departmentName3}}{{scope.row.departmentName4 | panKong}}</span>
          <span v-else-if="scope.row.userLevel == 3">{{scope.row.departmentName2}}{{scope.row.departmentName3 | panKong}}</span>
          <span v-else-if="scope.row.userLevel == 2">{{scope.row.departmentName1}}{{scope.row.departmentName2 | panKong}}</span>
          <span v-else-if="scope.row.userLevel == 1">{{scope.row.departmentName1}}</span>
          <span v-else-if="scope.row.userLevel == 0">顶级权限没有归属</span>
          <span v-else>--</span>
        </template>  
      </el-table-column>
      <el-table-column label="归属" width="140" v-if="cengJiShow.buMenShow">  <template slot-scope="scope">{{scope.row.departmentName2}}{{scope.row.departmentName3 | panKong}}</template>  </el-table-column>
      <el-table-column label="归属" width="140" v-if="cengJiShow.quYuShow">  <template slot-scope="scope">{{scope.row.departmentName1}}{{scope.row.departmentName2 | panKong}}</template>  </el-table-column>
      <el-table-column label="归属" width="90" v-if="cengJiShow.fenGSShow">  <template slot-scope="scope">{{scope.row.departmentName1}}</template>  </el-table-column>
      <el-table-column label="岗位" width="120">
        <template slot-scope="scope">{{scope.row.userName}}{{scope.row.positionName | panKong}}</template>
      </el-table-column>
      <el-table-column prop="sumMoney" label="创收" width="90"></el-table-column>
      <el-table-column prop="perIncome" label="人均创收" width="90"></el-table-column>
      <!-- <el-table-column prop="increase" label="今日新增创收" width="90"></el-table-column> -->
      <el-table-column prop="todayMoney" label="今日新增创收" width="90"></el-table-column>
      <el-table-column  label="环比增长" width="70" >
        <template slot-scope="scope">{{ scope.row.yesmonthMoney != 0?((scope.row.tomonthMoney - scope.row.yesmonthMoney)/scope.row.yesmonthMoney):"--" | Percentage}}</template>
      </el-table-column>
      <el-table-column  label="当日/当月分配" width="70" >
        <template slot-scope="scope">{{ scope.row.shijiDis + "/"  + scope.row.dangyueDis}}</template>
      </el-table-column>
      <el-table-column  label="当日/当月上门" width="70" >
        <template slot-scope="scope">{{ scope.row.todaySm + "/"  + scope.row.tomonthSm}}</template>
      </el-table-column>
      <el-table-column  label="当日/当月进件" width="70" >
        <template slot-scope="scope">{{ scope.row.todayJinJian + "/"  + scope.row.tomonthJinJian}}</template>
      </el-table-column>
      <el-table-column  label="当日/当月收款" width="100">
        <template slot-scope="scope">{{ scope.row.todayPayeeCount + "/"  + scope.row.tomonthPayeeCount}}</template>
      </el-table-column>     
      <el-table-column  label="分配数据量" width="100" v-if="OnlyForCaiWu">
        <template slot-scope="scope">{{ scope.row.realDis}}</template>
      </el-table-column>     
      <el-table-column  label="确认金额" width="100">
        <template slot-scope="scope">{{ scope.row.realMoney}}</template>
      </el-table-column>     
    </el-table>
  </div>
</template>
<script>
import { truncate } from 'fs';
import $ from "jquery";
import moment from 'moment';
export default {
  name: "Leaderboard",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
      //搜索不同层级 显示不同项
      cengJiShow:{
        guWenShow:false,
        buMenShow:false,
        quYuShow:false,
        fenGSShow:false
      },
      OnlyForCaiWu:false,
      onlyGuWenShow:false,
      loading:true,
      zongjiList:{
          chuangShou:"",
          junChuang:"",
          todayZengChuang:"",
          huanBi:"",
          riBiYueShang:"",
          riBiYueJin:"",
          riBiYueShou:""
      },
      //禁用按钮的状态值
      disTBN:false,
      //客户筛选区域数据
      formLabelAlign: {
        name: "",
        city: "",
        scopeOf: "",
        note: "",
        noteNum: "",
        withoutCon: "",
        fanwei: "",
        scopeOfValue: "",
        kinds: "",
        time: "",
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
      },
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      ShowCity:false,
      //时间选择生成内容
      timeList: [
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      cityselectList: [{ id: 0, name: "请先选择城市" }],
      // 表格依赖于数据没有数据  会一行都没有
      tableData: [],  
      //要导出的报表的list
      exportList:[],

    };
  },
  methods: {
    //首行总计为红色
    headerColor({row,rowIndex}){
      if (rowIndex == 0) {
        return 'color:red'
      }
    },
    //创收变红色
    cellStyle({row, column, rowIndex, columnIndex}){
      if(columnIndex === 4){ //指定坐标
          return 'color:red'
      }else{
          return ''
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    getSummaries(param) {
      // console.log(param);
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        });
      // this.tableData.unshift(sums); 
      // console.log(sums);
      return sums;
    },
    // 插入合计的数据
    summaryFun(){
      if (this.tableData == []) {
        return false;
      }else{
        let sumMoney = null
        let todayMoney = null
        let todayJinJian = null
        let tomonthJinJian = null
        let todayNewMoney = null
        let todayPayeeCount = null
        let tomonthPayeeCount = null
        let todaySm = null
        let tomonthSm = null
        let tomonthMoney = null
        let yesmonthMoney = null
        let shijiDis = null
        let dangyueDis = null
        let realMoney = null
        let realDis = null
        for (let i = 0; i < this.tableData.length; i++) {
          sumMoney += this.tableData[i].sumMoney; 
          todayMoney += this.tableData[i].todayMoney; 
          todayJinJian += this.tableData[i].todayJinJian; 
          tomonthJinJian += this.tableData[i].tomonthJinJian; 
          todayNewMoney += this.tableData[i].todayNewMoney; 
          todayPayeeCount += this.tableData[i].todayPayeeCount; 
          tomonthPayeeCount += this.tableData[i].tomonthPayeeCount; 
          todaySm += this.tableData[i].todaySm; 
          tomonthSm += this.tableData[i].tomonthSm; 
          tomonthMoney += this.tableData[i].tomonthMoney; 
          yesmonthMoney += this.tableData[i].yesmonthMoney; 
          shijiDis += this.tableData[i].shijiDis; 
          dangyueDis += this.tableData[i].dangyueDis; 
          realMoney += this.tableData[i].realMoney; 
          realDis += this.tableData[i].realDis; 
        }
        if (tomonthJinJian != null) {
          tomonthJinJian = tomonthJinJian.toString();
        } else {
          tomonthJinJian = "";
        }
        if (todayJinJian != null) {
          todayJinJian = todayJinJian.toString();
        } else {
          todayJinJian = "";
        }
        if (todayPayeeCount != null) {
          todayPayeeCount = todayPayeeCount.toString();
        } else {
          todayPayeeCount = "";
        }
        if (tomonthPayeeCount != null) {
          tomonthPayeeCount = tomonthPayeeCount.toString();
        } else {
          tomonthPayeeCount = "";
        }
        if (todaySm != null) {
          todaySm = todaySm.toString();
        } else {
          todaySm = "";
        }
        if (tomonthSm != null) {
          tomonthSm = tomonthSm.toString();
        } else {
          tomonthSm = "";
        }
        if (yesmonthMoney != null) {
          yesmonthMoney = yesmonthMoney.toString();
        } else {
          yesmonthMoney = "";
        }
        if (shijiDis != null) {
          shijiDis = shijiDis.toString();
        } else {
          shijiDis = "";
        }
        if (dangyueDis != null) {
          dangyueDis = dangyueDis.toString();
        } else {
          dangyueDis = "";
        }
        if (realDis != null) {
          realDis = realDis.toString();
        } else {
          realDis = "";
        }
        if (realMoney != null) {
          realMoney = realMoney.toString();
        } else {
          realMoney = "";
        }
        if (!isNaN(sumMoney)) {
          sumMoney = Math.ceil(sumMoney*100)/100;
        }
        if (!isNaN(realMoney)) {
          realMoney = Math.ceil(realMoney*100)/100;
        }
        if (!isNaN(todayMoney)) {
          todayMoney = Math.ceil(todayMoney*100)/100;
        }
        var obj = {  departmentName: '总计',  sumMoney: sumMoney,  perIncome: 0,todaySm:todaySm,shijiDis:shijiDis,dangyueDis:dangyueDis,realMoney:realMoney,realDis:realDis,yesmonthMoney:yesmonthMoney,tomonthMoney:tomonthMoney,tomonthSm:tomonthSm, todayPayeeCount: todayPayeeCount ,tomonthPayeeCount: tomonthPayeeCount , todayMoney: todayMoney  ,todayNewMoney:todayNewMoney,todayJinJian :todayJinJian, tomonthJinJian:tomonthJinJian,JinJianBi: tomonthJinJian, receipt:"0/0"}
        this.tableData.unshift(obj);  
      }     
    },
    // 合并合计第一行
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (rowIndex === 0) {
            if (columnIndex === 0) {
                return [0, 0];
          } else if (columnIndex === 1) {
                return [1, 2];
        }
      }
    },
    // 表格序号 除去合计从第一开始
    indexFun (index) { 
      return index;
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";  
        }
        this.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId){
      let obj = {};
      obj = this.kindtimeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true
      }else{
        this.showTimeKind = false
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.value1 = "";
      }
    },
    clearGuiShu(){
      for(var key in this.cengJiShow){
        this.cengJiShow[key] = false
      }
      // return this.cengJiShow
    },
    //查询
    queryList(){
      this.disButton()
      this.loading = true
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        }
      }else{
        this.value1 = "";
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }  
      if (this.formLabelAlign.scopeOf == "guwen") {
        this.onlyGuWenShow = true
      }else{
        this.onlyGuWenShow = false
      }
      if (this.formLabelAlign.scopeOf == "fenGS") {
        this.clearGuiShu()
        this.cengJiShow.fenGSShow = true
        this.OnlyForCaiWu = false
      }else if (this.formLabelAlign.scopeOf == "quyu") {
        this.clearGuiShu()
        this.cengJiShow.quYuShow = true
        this.OnlyForCaiWu = false
      }else if (this.formLabelAlign.scopeOf == "bumen") {
        this.clearGuiShu()
        this.cengJiShow.buMenShow = true
        if (this.currentUser.currentLevel == 11) {
          this.OnlyForCaiWu = true
        }
      }else if (this.formLabelAlign.scopeOf == "guwen") {
        this.clearGuiShu()
        this.cengJiShow.guWenShow = true
        if (this.currentUser.currentLevel == 11) {
          this.OnlyForCaiWu = true
        }
      }else {
        this.clearGuiShu()
        this.OnlyForCaiWu = false
      }
      this.$axios
        .get(`/bill/getResultsRanking?userId=${this.currentUser.currentid}&city=${this.formLabelAlign.city}&scopeOf=${this.formLabelAlign.scopeOf}&scopeOfValue=${this.formLabelAlign.scopeOfValue}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}`)
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.tableData =  res.data.dataModel
            this.summaryFun()
            this.loading = false
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = []
            this.loading = false
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //导出表格
    exportClick() {
        require.ensure([], () => {
          const { export_json_to_excel } = require('../../vendor/Export2Excel');
          const tHeader = ['名称', '所在部门','所在部门归属', '岗位','创收', '分配数据量' ];
          const filterVal = ['departmentName','departmentName4', 'departmentName3','positionName','sumMoney','realDis'];  
          const list = this.tableData;
          const data = this.formatJson(filterVal, list);       
          export_json_to_excel(tHeader, data, '数据分配统计表'+moment(new Date()).format('YYYYMMDDHHmmss'));
        })
     },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    }
  },
  filters: {
    toString(val){
      if (val != "null") {
        return val.toString()
      } else {
        return ""
      }
      
    },
    Percentage(val){
      if (isNaN(val)) {
        return val 
      }
      return Math.floor(val * 100) + "%"
    },
    panKong(val){
      if (val != undefined) {
        return "--" +  val
      }else{
        return val
      }
    }
  },
  created () {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
    if (this.currentUser.currentcity == "null") {
      this.cityShow = true
      this.formLabelAlign.city = "上海"
      this.cengJiShow.buMenShow =true
    }else{
      this.formLabelAlign.city = this.currentUser.currentcity
    }
    if (this.currentUser.currentLevel == 0) {
        // this.tableDepartmentShows.cityShow = true
        this.ShowCity = true
    }
    this.formLabelAlign.dayType = "tomonth" //默认查询本月排行
    //请求排行榜数据
    this.$axios
      .get(`/bill/getResultsRanking?userId=${this.currentUser.currentid}&dayType=${this.formLabelAlign.dayType}&city=${this.formLabelAlign.city}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.loading = false
          this.tableData =  res.data.dataModel
          this.summaryFun()
        }else{
          this.loading = false
          this.$message.error(res.data.messageModel.messageText );
        }
      })
      .catch(error => {
        console.log(error);
      });
    //请求城市可选
    this.$axios
      .get(`/department/getAll`)
      .then(res => {
        // console.log(res);
        this.cityselectList = res.data;
      })
      .catch(error => {
        console.log(error);
      });  
  },
  updated () {
  }
};
</script>

<style  lang='scss'>
.Leaderboard-container {
  .el-table {
    font-size: 12px;
    th {
      padding: 0;
      border-right: 1px dashed #d3cdcd;
    }
    td {
      padding: 0;
      border-bottom: 1px solid rgb(211, 205, 205);
      border-right: 1px dashed #d3cdcd;
    }
    .cell {
      padding:0 1px;
      text-align: center;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}
// 时间分类框样式
input.el-input__inner#kinds,
input.el-input__inner#otherThing,
input.el-input__inner#addThing,
input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
.yaosile {
  width: 85px;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
}
.el-form-item {
  display: inline-block;
  margin-left: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 10px;
}
/deep/.check {
  margin-left: 27px;
  padding: 8px 20px !important;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
}

</style>