<template>
  <el-tabs
    v-model="editableTabsValue"
    v-if="editableTabs.length!==0"
    type="card"
    closable
    @tab-remove="removeTab"
    @tab-click="handleClickTab"
  >
    <el-tab-pane v-for="item in editableTabs" :key="item.path" :label="item.name" :name="item.path"></el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      editableTabsValue: "",
    };
  },
  computed: {
    ...mapGetters(["editableTabs"])
  },
  methods: {
    ...mapMutations(["Add_Tab", "Remove_Tab"]),
    addTab(name, title) {
      // 不需要tab
      if (this.$route.meta.noTab) return;
      this.editableTabsValue = this.$route.path;
      this.Add_Tab({
        // name: this.$route.meta.title,
        name: this.$route.meta.name,
        // name: this.$store.state.common.title,
        path: this.$route.path
      });
      // console.log(this.editableTabsValue, this.editableTabs)
    },
    //tabs
    removeTab(targetName) {
      this.Remove_Tab(targetName);
      if (this.editableTabs.length >= 1) {
        this.editableTabsValue = this.editableTabs[
          this.editableTabs.length - 1
        ].path;
      } else {
        this.editableTabsValue = "";
      }
    },
    // 切换便签页
    handleClickTab(tab, event) {
      this.$router.push(this.editableTabsValue);
    }
  },
  watch: {
    // 通过检测路由变化，添加新的标签页
    $route(to, from) {
      this.addTab();
      // console.log("报告！发现路由变化！！！");
      //先清空定时器
      if ( this.timeOut ) {
        clearTimeout(this.timeOut);
      }
      // let _this = this
      var that = this
      this.timeOut = setTimeout(() => {
        window.sessionStorage.clear();
        that.$router.push("/login");
      }, 14400000);
      function refreshCount() {
        window.sessionStorage.clear();
        that.$router.push("/login");
        this.$message("长时间未操作，账号已被强制登出");
      }
    },
    tabslist() {
      this.addTab();
    }
  }
};
</script>

<style>
.el-tabs__item {
  padding: 0 10px;
  font-size: 12px;
}
</style>

