<template>
  <div class="customerList-container" style="margin-top: 20px">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <div id="bigBox" style="display: flex">
        <span id="give">城市:</span>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选
        </el-checkbox>
        <div class="duoxuankuang" style="display: inline">
          <el-checkbox-group
            v-model="checkedCity"
            @change="handleCheckedCityChange"
          >
            <el-checkbox
              v-for="(dange, index) in cityList"
              :label="dange"
              :key="index"
              >{{ dange.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-form
        label-width="82px"
        label-position="right"
        ref="formLabelAlign"
        :model="formLabelAlign"
      >
        <el-form-item prop="keywords" label=" 客户">
          <el-input
            v-model="formLabelAlign.keywords"
            placeholder="姓名/ID/手机号均可"
          ></el-input>
        </el-form-item>
        <el-form-item prop="level" label="星级">
          <el-select v-model="formLabelAlign.level" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="0星" value="0"></el-option>
            <el-option label="1星" value="1"></el-option>
            <el-option label="2星" value="2"></el-option>
            <el-option label="2星+" value="3"></el-option>
            <el-option label="3星" value="4"></el-option>
            <el-option label="4星" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="state" label="状态">
          <el-select v-model="formLabelAlign.state" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="待分配" value="待分配"></el-option>
            <el-option label="已受理" value="已受理"></el-option>
            <el-option label="未接通" value="未接通"></el-option>
            <el-option label="待跟进" value="待跟进"></el-option>
            <el-option label="资质不符" value="资质不符"></el-option>
            <el-option label="待签约" value="待签约"></el-option>
            <el-option label="已上门" value="已上门"></el-option>
            <el-option label="B上门" value="B上门"></el-option>
            <el-option label="已拒批" value="已拒批"></el-option>
            <el-option label="审核中" value="审核中"></el-option>
            <el-option label="已放款" value="已放款"></el-option>
            <el-option label="捣乱申请" value="捣乱申请"></el-option>
            <el-option label="重复申请" value="重复申请"></el-option>
            <el-option label="外地申请" value="外地申请"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="content" label="备注内容">
          <el-input v-model="formLabelAlign.content"></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-select v-model="formLabelAlign.sourceName2" placeholder="平台">
            <el-option label="所有" value=""></el-option>
            <el-option
              v-for="item in pingTaiList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="formLabelAlign.sourceName3"></el-input>
        </el-form-item>
        <el-form-item prop="dayType" label="申请时间">
          <el-select
            v-model="formLabelAlign.dayType"
            id="time"
            @change="holderQuJian"
            placeholder="时间"
          >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="otherScopeOf" label="其他条件">
          <el-select
            v-model="formLabelAlign.otherScopeOf"
            id="otherThing"
            placeholder
          >
            <el-option label="所有" value=""></el-option>
            <el-option label="分公司" value="fenGS"></el-option>
            <el-option label="区域" value="quYu"></el-option>
            <el-option label="部门" value="buMen"></el-option>
            <el-option label="顾问" value="user"></el-option>
          </el-select>
          <el-input
            v-model="formLabelAlign.otherName"
            class="yaosile"
            id="addThing"
          ></el-input>
        </el-form-item>

        <el-form-item id="btnList">
          <el-button
            style="background-color: #c73e3a; color: #fff"
            :disabled="disTBN"
            @click="checkCus"
            class="check"
            >查询
          </el-button>
          <el-button
            style="background-color: #3a8ac7; color: #fff"
            :disabled="disTBN"
            @click="showChongFu"
            class="check"
            v-show="ShowChongData"
            >显示重复数据
          </el-button>
          <el-button
            style="background-color: #c18c00; color: #fff"
            :disabled="disTBN"
            @click="showCreated"
            class="check"
            v-show="!ShowChongData"
            >取消重复限制
          </el-button>
          <el-button
            style="background-color: #007f54; color: #fff"
            @click="dialogFormVisible = true"
            class="check"
            >导入数据
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table
      @selection-change="changeCus"
      :data="tableData"
      style="width: 100%"
      v-loading="loading"
      stripe
    >
      <el-table-column type="selection" width="35"></el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column label="姓名" width="55" align="center">
        <template slot-scope="scope"
          ><a
            class="toEdit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            v-bind:class="{ 'newData':scope.row.noteList.length <= 0  }"
            >{{ scope.row.name }}{{ scope.row.repeatCount | chongFuCount }}</a
          ></template
        >
      </el-table-column>
      <!-- <el-table-column prop="phone" label="电话" width="30" align="center">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="top">
                        <div slot="content">{{scope.row.phone}}</div>
                        <span>显示</span>
                    </el-tooltip>
                </template>
            </el-table-column> -->
      <el-table-column prop="phone" label="电话" width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
          <i
            @click="tel(scope.row)"
            class="el-icon-phone"
            style="font-size: 18px; color: #3390ff; cursor: pointer"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        prop="city"
        label="城市"
        width="40"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="顾问"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column label="星级" width="40" align="center">
        <template slot-scope="scope">{{
          scope.row.level | levelKind
        }}</template>
      </el-table-column>
      <el-table-column label="状态" width="55" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '资质不符'" style="color: #c73e3a">{{
            scope.row.state
          }}</span>
          <span
            v-else-if="
              scope.row.state == 'B上门' ||
              scope.row.state == '已上门' ||
              scope.row.state == '待签约' ||
              scope.row.state == '已放款' ||
              scope.row.state == '审核中'
            "
            style="color: #5bbd2b"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '已受理' ||
              scope.row.state == '未接通' ||
              scope.row.state == '待跟进'
            "
            style="color: #3390ff"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '捣乱申请' ||
              scope.row.state == '外地申请' ||
              scope.row.state == '已拒批' ||
              scope.row.state == '重复申请'
            "
            style="color: #d7962f"
            >{{ scope.row.state }}</span
          >
          <span v-else style="color: grey">{{ scope.row.state }}</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="150" align="center">
        <template slot-scope="scope">
          <p class="ell">申请：{{ scope.row.updateRepeatTime | formatTime }}</p>
          <p class="ell">分配：{{ scope.row.distributionTime | formatTime }}</p>
        </template>
      </el-table-column>
      <el-table-column label="备注" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.noteList" :key="index">
            <p
              class="ell"
              v-if="index < 3"
              v-bind:style="{ color: item.otherUser == 1 ? 'red' : '#606266' }"
            >
              &nbsp;<span
                v-bind:style="{
                  color: item.otherUser == 1 ? '#898989' : '#898989',
                }"
                >{{ ++index }}--{{ item.createDate | formatTime }}--{{
                  item.userName
                }}</span
              >--{{ item.content }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="资质" width="200" align="center">
        <template slot-scope="scope"
          ><span>{{ scope.row.remarks | remarksPanKong }}</span></template
        >
      </el-table-column>
      <el-table-column
        prop="sourceName2"
        label="平台"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column label="来源" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sourceType == 1">******</span>
          <span v-else>{{ scope.row.sourceName3 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="城市详情" width="120" sortable>
        <template slot-scope="scope">
          <p class="ell">客填：{{ scope.row.originalCity }}</p>
          <p class="ell">手机：{{ scope.row.addressPhone }}</p>
          <p class="ell">IP所在：{{ scope.row.addressByIp }}</p>
          <p class="ell">IP：{{ scope.row.addressIp }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="handleEdits(scope.$index, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Details
      :dialogVisible="dialogVisible"
      @handleClose="dialogVisible = false"
    />
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageConfig.currentPage"
      :page-sizes="[20, 50, 100]"
      :page-size="pageConfig.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageConfig.total"
    >
    </el-pagination>
    <el-dialog title="导入数据" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-upload
          class="image-uploader"
          :multiple="false"
          :auto-upload="true"
          list-type="text"
          :show-file-list="true"
          :before-upload="beforeUpload"
          :drag="true"
          action
          :limit="1"
          :on-exceed="handleExceed"
          :http-request="uploadFile"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或 <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            一次只能上传一个文件，仅限excel格式，单文件不超过1MB
          </div>
        </el-upload>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit" :disabled="disTBN"
          >提 交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Details from "./details.vue";
// 导入时间戳
import moment from "moment";
// import { mapMutations } from 'vuex'
export default {
  name: "customerList",
  components: {
    Details,
  },
  // 数据
  data() {
    return {
      dialogVisible: false,
      currentUser: {
        currentName: "",
        currentId: "",
        currentLevel: "",
        currentPhone: "",
      },
      loading: true,
      ShowChongData: true,
      checkAll: false,
      isIndeterminate: false,
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      checkedCity: [],
      cityList: [], //城市列表
      cityId: [], //城市ID
      //未分割的完整版时间区间
      value1: "",
      //禁用按钮的状态值
      disTBN: false,
      //时间区间隐藏状态
      isShow: false,
      pingTaiList: [],
      //页码参数
      pageConfig: {
        currentPage: 1, //当前页
        pageSize: 20, //当前单页数据条数
        total: 0, //请求到的数据总条数
      },
      //加入公共池的客户id数组
      customerId: [],
      //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      formLabelAlign: {
        keywords: "", //关键字
        level: "", //	星级
        state: "", //	状态
        content: "", //备注内容
        pingTaiId: "", //平台
        sourceName2: "", //平台
        sourceName3: "", //来源
        dayType: "today",
        otherScopeOf: "",
        otherName: "",
        beginTime: "", //开始时间
        endTime: "", //	结束时间
        showRepeat: "",
      },
      tableData: [],
      //提交表格的对象
    };
  },
  methods: {
    tel(row) {
      let obj = {
        phone: row.phone,
      };
      this.$socket().send(JSON.stringify(obj));
    },
    //表格各行变色的方法
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    handleCheckAllChange(val) {
      this.checkedCity = val ? this.cityList : [];
      if (this.checkedCity != val) {
        this.cityList.forEach((item) => {
          this.cityId.push(item.name);
        });
      } else {
        this.checkAll = false;
        this.cityId = [];
      }
      this.isIndeterminated = false;
    },
    //获取选中城市
    handleCheckedCityChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cityList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cityList.length;
      this.cityId = [];
      for (let i = 0; i < value.length; i++) {
        this.cityId.push(value[i].name);
      }
    },
    changeCus(val) {
      this.customerId = [];
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.customerId.push(val[i].id);
      }
    },
    //获取客户 List的方法
    getCusList() {
      this.loading = true;
      this.$axios
        .post(
          `/customer/customerTheListOf?showRepeat=${this.formLabelAlign.showRepeat}&keywords=${this.formLabelAlign.keywords}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&endTime=${this.formLabelAlign.endTime}&sourceName2=${this.formLabelAlign.sourceName2}&sourceName3=${this.formLabelAlign.sourceName3}&city=${this.cityId}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}&userId=${this.currentUser.currentId}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.loading = false;
            if (res.data.dataModel.total == 0) {
              this.$message("抱歉，没有找到符合搜索条件的客户");
              // this.clearAllCheck();
              this.tableData = [];
              this.pageConfig.total = 0;
              return false;
            }
            this.$message.success("已为您找到以下客户(#^.^#)");
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
            // this.clearAllCheck();
          } else {
            this.loading = false;
            this.$message.error(res.data.messageModel.messageText);
            // this.clearAllCheck();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //清空所有筛选条件的方法
    clearAllCheck() {
      for (let i = 0; i < this.formLabelAlign.length; i++) {
        this.formLabelAlign.formLabelAlign[i] = "";
      }
      this.value1 = ""; //未分割的完整版时间区间
    },
    //判断时间区间是否隐藏
    holderQuJian(vId) {
      let obj = {};
      obj = this.timeList.find((item) => {
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.value1 = "";
      }
    },
    //将按钮禁用十秒 !!!!!!!
    disButton() {
      this.disTBN = true;
      setTimeout(() => {
        this.disTBN = false;
      }, 3000);
    },
    //分页的方法
    handleSizeChange(val) {
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getCusList();
      } else {
        this.pageConfig.pageSize = val;
        this.getCusList();
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getCusList();
    },
    //获取平台的下拉列表
    getPingTaiList() {
      this.$axios
        .get(`/source/getSourceByType?type=2`)
        .then((res) => {
          // console.log(res);
          this.pingTaiList = res.data.dataModel;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //编辑客户
    handleEdit(index, row) {
      let CusId = row.id;
      // console.log(CusId);
      // this.$store.state.common.title = row.name
      // 编程式导航
      this.$router.push({ path: "/myCusEdit", query: { CusId: CusId } });
      window.sessionStorage.setItem("CusId", CusId);
    },
    //编辑客户
    handleEdits(index, row) {
      let CusId = row.id;
      // console.log(CusId);
      // this.$store.state.common.title = row.name
      // 编程式导航
      window.sessionStorage.setItem("CusId", CusId);
      this.dialogVisible = true;
    },
    //查询
    checkCus() {
      // this.disButton()
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = this.value1[0];
        this.formLabelAlign.endTime = this.value1[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime =
            this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime =
            this.formLabelAlign.endTime + " " + "23:59:59";
        }
      } else {
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      // console.log(this.formLabelAlign.sourceName2);
      // console.log(this.formLabelAlign.sourceName3);
      // if (this.cityId.length == 0) {
      //   this.$message.error("请选择城市");
      //   return false
      // }
      // this.pageConfig.pageSize = 20
      this.pageConfig.currentPage = 1;
      this.formLabelAlign.showRepeat = ""; //重复数据
      this.getCusList();
    },
    showChongFu() {
      this.disButton();
      // this.pageConfig.pageSize = 20
      this.pageConfig.currentPage = 1;
      this.formLabelAlign.showRepeat = "true"; //重复数据
      this.getCusList();
      this.ShowChongData = false;
    },
    showCreated() {
      this.disButton();
      // this.pageConfig.pageSize = 20
      this.pageConfig.currentPage = 1;
      this.formLabelAlign.showRepeat = ""; //重复数据
      this.getCusList();
      this.ShowChongData = true;
    },
    // 上传文件之前的钩子
    beforeUpload(file) {
      const isXLS = file.type === "application/vnd.ms-excel";
      const isLt1M = file.size / 5120 / 5120 < 1;
      const isTextComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (!isXLS && !isTextComputer) {
        this.$message.error("上传表格只能是 XLS 格式!");
        return false;
      }
      if (!isLt1M) {
        this.$message.error("上传表格大小不能超过 5MB!");
        return false;
      }
      return isXLS | isTextComputer;
      // return isXLS && isLt1M;
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，请删除后继续上传`);
    },
    // 上传文件
    uploadFile(item) {
      const fileObj = item.file;
      // 文件对象
      this.form.append("file", fileObj);
      // form.append('comId', this.comId)
    },
    //点击上传
    submit() {
      this.disButton();
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      //提交注册信息
      this.$axios
        .post("/customer/importCustomer", this.form, config)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success("已为您提交新增人员信息");
            this.user = new FormData();
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.user = new FormData();
          }
          //清空存放表格的对象
          this.form = new FormData();
          this.dialogFormVisible = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return "";
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    remarksPanKong(value) {
      if (value == "null") {
        return "";
      }
      return value;
    },
    //判断星级状态
    levelKind(val) {
      // console.log(val);
      if (val == "0") {
        return "0星";
      } else if (val == "1") {
        return "1星";
      } else if (val == "2") {
        return "2星";
      } else if (val == "3") {
        return "2星+";
      } else if (val == "4") {
        return "3星";
      } else if (val == "5") {
        return "4星";
      } else {
        return "";
      }
    },
    chongFuCount(val) {
      if (val > 1) {
        return "(" + val.toString() + ")";
      } else {
        return "";
      }
    },
  },
  created() {
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentId = window.sessionStorage.getItem("id");
    //创建存放表格的对象
    this.form = new FormData();
    //拿到所有城市列表
    this.$axios
      .get(`/department/getAll`)
      .then((res) => {
        this.cityList = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    //请求平台可选
    this.getPingTaiList();
    this.$axios
      .get(
        `/customer/customerTheListOf?pageSize=${this.pageConfig.pageSize}&dayType=${this.formLabelAlign.dayType}&currentPage=${this.pageConfig.currentPage}&userId=${this.currentUser.currentId}`
      )
      .then((res) => {
        // console.log(res);
        let dataModel = res.data.dataModel;
        if (dataModel == null) {
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
          this.tableData = {};
          this.loading = false;
        } else {
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          this.loading = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }else{
    //   this.$message.warning("请先进行登录操作！");
    //   this.$router.push("login");
    // } ;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key === 13) {
        this.methods.checkCus();
      }
    };
  },
  computed: {
    title() {
      return this.$store.state.common.title;
    },
  },
  activated() {
    this.getCusList();
  },
};
</script>

<style lang="scss">
.customerList-container {
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }

  //突出隔行的颜色差异
  .el-table {
    font-size: 12px;

    .cell {
      padding-left: 1px;
      padding-right: 1px;
      line-height: 16px;
    }

    th {
      padding: 0;
    }

    th.is-sortable {
      .cell {
        text-align: center;
      }
    }

    .el-checkbox__inner {
      margin-left: 10px;
    }

    .warning-row {
      background: #fff;
    }

    .success-row {
      background: #d3d3d3;
    }

    td {
      //取消单元格的上下padding
      padding: 2px 0;
      border-bottom: 1px solid rgb(211, 205, 205);
      border-right: 1px dashed #d3cdcd;
    }

    .el-button--mini {
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255, 255, 255, 0.01);
    }

    .toEdit:hover {
      color: skyblue;
    }

    .newData {
      color: red;
      :hover {
        color: skyblue;
      }
    }
  }

  // 时间分类框样式
  input.el-input__inner#kinds,
  input.el-input__inner#otherThing,
  input.el-input__inner#addThing,
  /deep/ input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
    height: 32px !important;
  }

  .yaosile {
    width: 85px;
  }

  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;

    .el-date-editor .el-range__icon {
      line-height: 27px;
    }

    .el-date-editor .el-range-separator {
      width: 5%;
    }

    /deep/ .block,
    #block {
      border: none !important;

      /deep/ .el-date-editor--daterange {
        width: 240px !important;
      }
    }
  }

  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .el-select.time {
    margin-left: 20px;
  }

  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }

  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;

    .el-form-item#btnList {
      /deep/ .el-form-item__content {
        margin-left: 20px !important;
      }
    }
  }

  .check {
    margin-left: 22px;
    padding: 10px 20px;
  }

  .ell {
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    word-break: normal;
    text-indent: -2em;
    margin: 0 0 0 2em;
    line-height: 1.2;
  }

  //分页框样式设置（避免页面被覆盖）
  .el-pagination {
    display: inline-block;

    .el-input__inner {
      width: 100px;
    }
  }

  //多选框
  #bigBox {
    margin-top: 5px;
    margin-bottom: 5px;

    #give {
      width: 41px;
      margin-left: 51px;
      font-size: 14px;
    }

    .el-checkbox {
      margin-right: 8px;
    }

    .duoxuankuang {
      // padding: 10px;
      margin-right: 10px;
      // border: 1px solid #eeeeee;
      // border-bottom: 20px
    }
  }
}
</style>
