<template>
  <div class="SupplyConfiguration-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" :model="formLabelAlign">
          <!-- <span class="xinZeng">新增平台：</span> -->
        <el-form-item class="tanchuang" label="平台">
          <el-select v-model="formLabelAlign.pingTaiId" placeholder="平台">
            <el-option  v-for="item in pingTaiList"  :key="item.id"  :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="渠道">
          <el-select v-model="formLabelAlign.laiYuan" placeholder="渠道">
            <el-option
              v-for="item in laiYuanList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="供量类型">
          <el-select v-model="formLabelAlign.hzType" placeholder="默认全部">
            <el-option label="全部" value = "" ></el-option>
            <el-option label="自营" value = false ></el-option>
            <el-option label="加盟" value = true ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="非自营供量">
          <el-select v-model="formLabelAlign.FeihzType" placeholder="默认全部">
            <el-option label="全部" value=""></el-option>
            <el-option label="是" value="fenGS"></el-option>
            <el-option label="否" value="quyu"></el-option>
          </el-select>
        </el-form-item> -->
        <el-button style="background-color:#49AEEF;color:#fff" @click="check" class="check" :disabled="disTBN">查询</el-button>
        <el-button style="background-color:#67BF7F;color:#fff" @click="OpenZiGong" class="check" :disabled="disTBN">批量开启加盟供量</el-button>
        <el-button style="background-color:#E33539;color:#fff" @click="CloseZiGong" class="check" :disabled="disTBN">批量关闭加盟供量</el-button>
      </el-form>
    </el-row>
    <el-table ref="tableData" tooltip-effect="dark" @selection-change="changeLaiYuan" style="width: 100%"  :data="tableData"  >
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- <el-table-column prop="id"  label="ID"  width="60"></el-table-column> -->
      <!-- <el-table-column prop="sourceName1" label="渠道" width="120"></el-table-column> -->
      <el-table-column prop="sourceName2" label="平台" width="150"></el-table-column>
      <el-table-column prop="name" label="来源" width="150"></el-table-column>
      <el-table-column label="加盟供量开关" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.hzType == false " style='color:#C73E3A'>{{ scope.row.hzType|panduanKG}}</span>
          <span v-else-if="scope.row.hzType == true " style='color:#5BBD2B'>{{ scope.row.hzType|panduanKG}}</span>
          <span v-else style='color:grey'>{{ scope.row.state}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="type" label="非自营供量" width="150"></el-table-column> -->
      <el-table-column prop="userName" label="操作人" width="150"></el-table-column>
      <el-table-column label="操作时间" >
        <template slot-scope="scope"><p class="ell">{{ scope.row.updateTime | formatTime }}</p></template>
      </el-table-column>
      <!-- <el-table-column  fixed="right"  label="操作" ><template slot-scope="scope"><el-button  @click.native.prevent="EditRow(scope.$index, tableData)"  type="text"  size="small">编辑</el-button>  </template></el-table-column>   -->
    </el-table>
    
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[20 ,50]"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <el-dialog
      title="新增来源"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <el-form>
        <el-form-item class="tanchuang" label="平台">
          <el-input v-model="EditData.name" disabled></el-input>
        </el-form-item>
        <el-form-item class="tanchuang" label="来源">
          <el-select v-model="EditData.laiYuanId" placeholder="请选择来源">
            <el-option  v-for="item in laiYuanList"  :key="item.id"  :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item><br><br>
        <el-switch
          style="display: block"
          v-model="EditData.bool"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="打开自营供量"
          inactive-text="关闭自营供量">
        </el-switch><br>
        <!-- <el-switch
          style="display: block"
          v-model="feiZiYing"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="打开非自营供量"
          inactive-text="关闭非自营供量">
        </el-switch> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="EditLaiYuan">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { setTimeout } from 'timers';
import moment from 'moment'
export default {
  name: "SupplyConfiguration",
  // 数据
  data() {
    return {
      currentUser:{
        currentName: "",
        currentid: ""
      },
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:20,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      LaiYuanIdList:[],//被选中来源id的数组
      zongjiList:{
          chuangShou:"",
          junChuang:"",
          todayZengChuang:"",
          huanBi:"",
          riBiYueShang:"",
          riBiYueJin:"",
          riBiYueShou:""
      },
      //开关
      ZiYing:false,
      feiZiYing:false,
      centerDialogVisible:false,
      //来源新增区域数据
      formLabelAlign: {
        laiYuan : "", 	//关键字
        pingtaiName : "", 	//	星级
        scopeOf : "", 	//	状态
        EnglishName : "", 	//英文
        chineseName : "",	//中文
        hzType : "" ,	//非自营
        FeihzType : "",	//自营
        pingTaiId : ""	//平台
      },
      laiYuanList: {},
      pingTaiList: {},
      hasValue: "",
      disTBN:false,
      // 表格依赖于数据没有数据  会一行都没有
      tableData: [],
      EditData:{
        name:"",
        bool:false,
        laiYuanId:""
      }
    };
  },
  methods: {
    //获取渠道的下拉列表
    getQudaoList(){
        this.$axios
      .get(`/source/getSourceByType?type=1`)
      .then(res => {
        this.laiYuanList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      }); 
    },
    //获取平台的下拉列表
    getPingTaiList(){
        this.$axios
      .get(`/source/getSourceByType?type=2`)
      .then(res => {
        // console.log(res);
        this.pingTaiList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      });
    },
    //获取来源
    getSourceList(){
      this.$axios
      .get(`/source/getSourceList?pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}&hasValue=${this.hasValue}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
        }else{
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = "";
            this.pageConfig.total = 0
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getSourceList()
      }else{
        this.pageConfig.pageSize = val;
        this.getSourceList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getSourceList()
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //打开编辑弹窗
    EditRow(index, rows) {
      // console.log(rows[index]);
      this.EditData.name = rows[index].sourceName2
      //根据平台ID查来源
      this.$axios.get(`/source/getSourceList?userid=${this.currentUser.currentid}&id=${rows[index].id}`).then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.laiYuanList = res.data.dataModel.list;
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
      }).catch(error => {
        console.log(error);
      });
      this.centerDialogVisible = true; 
    },
    //查询
    check(){
      this.disButton()
      // if (this.formLabelAlign.pingTaiId == "") {
      //   this.$message.error("平台不可以为空，请检查");
      //   return false
      // }
      //获取来源
      this.pageConfig.currentPage = 1
      this.$axios
        .get(`/source/getSourceList?hasValue=${this.hasValue}&pinTaiId=${this.formLabelAlign.pingTaiId}&hzType=${this.formLabelAlign.hzType}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
        .then(res => {
          // console.log(res);
          if (res.data.messageModel.messageText == "成功!") {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
          }else{
            this.$message.error("获取数据失败，请联系管理员");
            this.tableData = {};
            this.pageConfig.total = 0
          }
        })
        .catch(error => {
          console.log(error);
        });
      //请求渠道可选
      this.getQudaoList()  
      //请求平台可选
      this.getPingTaiList() 
    },
    //修改来源
    EditLaiYuan(){
      this.disButton()
      if (this.EditData.laiYuanId == "") {
          this.$message.error("请选择来源");
          return false
      }
      this.$axios
        .get(`/source/updateHzTypeById?userid=${this.currentUser.currentid}&bool=${this.EditData.bool}&id=${this.EditData.laiYuanId}`)
        .then(res => {
        //   console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({  message: '修改成功',type: 'success'});
            this.centerDialogVisible = false
          }else{
            this.$message.error(res.data.messageModel.messageText);
            this.centerDialogVisible = false
          }
        })
        .catch(error => {
          console.log(error);
          this.centerDialogVisible = false
        });
    },
    //获取选中来源并返回数组
    changeLaiYuan (val) {
      this.LaiYuanIdList = []
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.LaiYuanIdList.push(val[i].id); 
      }
      // console.log(this.LaiYuanIdList);
    },
    //批量开启自营
    OpenZiGong(){
      if (this.LaiYuanIdList.length == 0) {
        this.$message.error("请至少选择一个来源");
      }else{
        this.$axios
          .get(`/source/updateHzTypeById?hasValue=${this.hasValue}&id=${this.LaiYuanIdList}&bool=true&userId=${this.currentUser.currentid}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
          .then(res => {
            // console.log(res);
            if (res.data.messageModel.code == 0) {
              this.$message.success("成功修改状态");
              this.$axios
              .get(`/source/getSourceList?hasValue=${this.hasValue}&pinTaiId=${this.formLabelAlign.pingTaiId}&hzType=${this.formLabelAlign.hzType}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.tableData = res.data.dataModel.list;
                  this.pageConfig.total = res.data.dataModel.total
                }else{
                  this.$message.error(res.data.messageModel.messageText);
                  this.tableData = {};
                  this.pageConfig.total = 0
                }
              })
              .catch(error => {
                console.log(error);
              });
          }else{
              this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    //批量关闭自营
    CloseZiGong(){
      if (this.LaiYuanIdList.length == 0) {
        this.$message.error("请至少选择一个来源");
      }else{
        this.$axios
          .get(`/source/updateHzTypeById?hasValue=${this.hasValue}&id=${this.LaiYuanIdList}&bool=false&userId=${this.currentUser.currentid}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
          .then(res => {
            // console.log(res);
            if (res.data.messageModel.code == 0) {
              this.$message.success("成功修改状态");
              this.$axios
              .get(`/source/getSourceList?hasValue=${this.hasValue}&pinTaiId=${this.formLabelAlign.pingTaiId}&hzType=${this.formLabelAlign.hzType}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
              .then(res => {
                // console.log(res);
                if (res.data.messageModel.code == 0) {
                  this.tableData = res.data.dataModel.list;
                  this.pageConfig.total = res.data.dataModel.total
                }else{
                  this.$message.error(res.data.messageModel.messageText);
                  this.tableData = {};
                  this.pageConfig.total = 0
                }
              })
              .catch(error => {
                console.log(error);
              });
          }else{
              this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    panduanKG(value){
      if (value == false) {
        return "已关闭"
      }else if (value == true) {
        return "已开启"
      }else{
        return ""
      }
    }
  },
  created () {
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentid = window.sessionStorage.getItem("id")
    //获取来源
    this.$axios
      .get(`/source/getSourceList?hasValue=${this.hasValue}&pageSize=${this.pageConfig.pageSize}&currentPage=${this.pageConfig.currentPage}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.messageText == "成功!") {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
        }else{
            this.$message.error("获取数据失败，请联系管理员");
            this.tableData = {};
            this.pageConfig.total = 0
        }
      })
      .catch(error => {
        console.log(error);
      });
    //请求渠道可选
    this.getQudaoList()  
    //请求平台可选
    this.getPingTaiList()  
  },
};
</script>

<style  lang='scss'>
.SupplyConfiguration-container {
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
  .el-table {
    font-size: 12px;
    th {
      padding: 0;
    }
    .warning-row {
      background: #fff;
    }
     .success-row {
      background: #D3D3D3;
    }
    td {//取消单元格的上下padding
      padding: 0;
      border-bottom: 1px solid rgb(211, 205, 205);
    }
     .el-button--mini{
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255,255,255,0.01);
    }
    .toEdit:hover {
      color:skyblue;
    }
  }
// 时间分类框样式
.SupplyConfiguration-container input.el-input__inner#kinds,
.SupplyConfiguration-container input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
  .xinZeng {
      padding-left: 10px;
      color: #49AEEF;
  }
}
.check {
  margin-left: 27px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
  .el-pagination input.el-input__inner {
    width: 120px;
  }
  //弹窗
  .el-dialog__body{
    .tanchuang{
      width: 100%;
      .el-input{
        width: 82%;
      }
      .el-form-item__label{
        width: 18%;
      }
    }
    .el-switch{
      margin-left: 20px;
    }
  }
}

</style>