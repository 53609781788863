<template>
  <div class="myCusEdit-container" v-loading="loading">
    <el-row class="filterCondition" v-if="showCol"> </el-row>
    <!-- 人事信息 -->
    <el-row class="filterCondition">
      <el-form
        label-width="90px"
        label-position="right"
        :model="formLabelAlign"
      >
        <!-- 展示区域 只展示 不可编辑-->
        <el-col :span="3"
          ><div class="grid-content miaoshu col-first row-first">
            姓名：
          </div></el-col
        >
        <!--        <el-col :span="5"><div class="grid-content  content row-first">{{formLabelAlign.name}}</div></el-col>-->
        <el-col :span="5"
          ><el-input
            class="grid-content content row-first"
            v-model="formLabelAlign.name"
            >{{ formLabelAlign.name }}</el-input
          ></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu row-first">性别：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content row-first">
            {{ formLabelAlign.sex }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu row-first">年龄：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content col-last row-first">
            {{ formLabelAlign.age | panDuanAge }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu col-first">手机号：</div></el-col
        >
        <el-col :span="5" v-if="showPhone">
          <div style="display: flex; align-items: center">
            <div class="grid-content content">
              {{ formLabelAlign.phone }}
            </div>
            <i
              @click="tel()"
              class="el-icon-phone"
              style="
                font-size: 20px;
                color: #3390ff;
                cursor: pointer;
                padding-bottom: 15px;
              "
            ></i>
          </div>
        </el-col>
        <!--        <el-col :span="5" v-if="!showPhone"><div class="grid-content  content ">{{formLabelAlign.phone | phoneFeiKong}}</div></el-col>-->
        <!--<el-col :span="5" v-if="!showPhone"><div class="grid-content  content "><a :href ="'http://192.168.3.250/cc/dial/8001/'+formLabelAlign.phone" target="_blank">{{formLabelAlign.phone | phoneFeiKong}}</a>&nbsp;
          <el-button type="text" class="bohaoBth" @click="bohao('formLabelAlign')">拨号</el-button>&nbsp;
          <el-button type="text" class="bohaoBth" @click="guaji('formLabelAlign')">挂机</el-button></div></el-col>-->
        <el-col :span="5" v-if="!showPhone">
          <div style="display: flex; align-items: center">
            <div class="grid-content content">
              {{ formLabelAlign.phone | phoneFeiKong }}&nbsp;

              <!--<el-button v-if="showBtn" type="text" class="bohaoBth" @click="bohao('formLabelAlign')" icon="el-icon-phone">拨号</el-button>&nbsp;
          <el-button v-if="!showBtn" type="text" class="bohaoBth"  icon="el-icon-phone-outline"><font style="color: red">拨号中...</font></el-button>&nbsp;
          <el-button v-if="showTh" type="text" class="bohaoBth"  icon="el-icon-phone-outline"><font style="color: #79FF79">通话中...</font></el-button>&nbsp;
          <el-button type="text" class="bohaoBth" @click="guaji('formLabelAlign')">挂机</el-button>-->
              <!-- <el-button
                  type="text"
                  class="bohaoBth"
                  @click="bohao('formLabelAlign')"
                  icon="el-icon-phone"
          >拨号</el-button>
          <el-button type="text" icon="el-icon-my-guaji" @click="guaji('formLabelAlign')">挂机</el-button>-->
            </div>
            <i
              @click="tel()"
              class="el-icon-phone"
              style="
                font-size: 20px;
                color: #3390ff;
                cursor: pointer;
                padding-bottom: 15px;
              "
            ></i>
          </div>
        </el-col>
        <el-col :span="3"
          ><div class="grid-content miaoshu">申请额度：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.linesCount }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu">城市：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content col-last">
            {{ formLabelAlign.city | panDuanCity }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu col-first">车：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.car | JudgeBoolean }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu">公积金：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.accumulation | JudgeBoolean }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu">代发：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content col-last">
            {{ formLabelAlign.isbankpay | JudgeBoolean }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu col-first">社保：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.socialSecurity | JudgeBoolean }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu">保单：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.lifeSecurity | JudgeBoolean }}
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content miaoshu">房产：</div></el-col
        >
        <el-col :span="5"
          ><div class="grid-content content">
            {{ formLabelAlign.room | JudgeBoolean }}
          </div></el-col
        >
        <!--        <el-col :span="5"><div class="grid-content  content  col-last "></div></el-col>-->
        <el-col :span="3"
          ><div class="grid-content miaoshu col-first row-last">
            待确认资质：
          </div></el-col
        >
        <el-col :span="19"
          ><div class="grid-content content row-last">
            {{ formLabelAlign.qualification }}
          </div></el-col
        >
        <!-- <el-col :span="3"><div class="grid-content  miaoshu row-last"></div></el-col>
        <el-col :span="5"><div class="grid-content  content row-last"></div></el-col>
        <el-col :span="3"><div class="grid-content  miaoshu row-last"></div></el-col>
        <el-col :span="5"><div class="grid-content  content row-last col-last "></div></el-col> -->
      </el-form>
    </el-row>
    <!-- 资质与状态 -->
    <el-row class="filterCondition">
      <el-form
        label-width="102px"
        label-position="right"
        :model="formLabelAlign"
      >
        <el-form-item v-if="!showPhone" class="dangan" label="当前客户：">
          <el-col style="color: red"
            ><div>
              {{ formLabelAlign.name }}&emsp;&emsp;<button
                class="upBtn"
                @click="getUpCustomer"
              >
                上一个</button
              >&nbsp;<button class="upBtn" @click="getNextCustomer">
                下一个
              </button>
            </div></el-col
          > </el-form-item
        ><br />
        <el-form-item class="dangan" label="状态">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <span style="font-size: 16px; font-weight: 700"
                >状态是客户申请所处的环节：</span
              ><br />
              1.<span style="color: red">已受理</span
              >-说明：新申请数据，还未联系客户；<br />
              2.<span style="color: red">待跟进</span
              >-说明：已联系，但未了解客户信息；<br />
              3.<span style="color: red">未接通</span
              >-说明：已致电，但未接通电话；<br />
              4.<span style="color: red">贷款资质不符</span
              >-说明：该客户或身边人士无可贷点；<br />
              5.<span style="color: red">捣乱申请</span
              >-说明：同行或者申请人恶意提交的不实申请；<br />
              6.<span style="color: red">待签约</span
              >-说明：联系后，并判定该客户或身边人士有可贷点；<br />
              7.<span style="color: red">已上门</span
              >-说明：已上门，但未签约；<br />
              8.<span style="color: red">B上门</span>-说明：已上门签约；<br />
              9.<span style="color: red">审核中</span
              >-说明：客户已经完成进件，银行审核中，请备注进件银行、申请金额、审核进度；<br />
              10.<span style="color: red">银行已放款</span
              >-说明：银行审批结束，批放款成功，请备注放款银行、到账金额；<br />
              银行已拒绝-说明：银行审批结束，贷款被拒（包含初审），请备注被拒的原因；
            </div>
            <el-select
              v-model="formLabelAlign.state"
              @change="ShowXingJi"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="dangan" label="星级">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <span style="font-size: 16px; font-weight: 700"
                >星级是对客户贷款资质的客观评价：</span
              ><br />
              <span style="color: red">0星：</span
              >系统默认的未了解过信息的客户；<br />
              <span style="color: red">1星：</span
              >本人或身边朋友无可贷点的客户；<br />
              <span style="color: red">2星：</span
              >本人或身边朋友有可贷点但暂时进不了件的客户；<br />
              <span style="color: red">2星+：</span
              >本人或身边朋友有可贷点，但只能进小贷的客户；<br />
              <span style="color: red">3星：</span
              >本人或身边朋友有可贷点，并可以进银行及主流金融机构的客户；<br />
              <span style="color: red">4星：</span
              >本人或身边有可贷点，且马上需要或条件优质的客户（放款客户默认4星）。
            </div>
            <el-select v-model="formLabelAlign.level" placeholder="请选择星级">
              <el-option
                label="0星"
                value="0"
                v-show="isShowStar.lingXing"
              ></el-option>
              <el-option
                label="1星"
                value="1"
                v-show="isShowStar.YiXing"
              ></el-option>
              <el-option
                label="2星"
                value="2"
                v-show="isShowStar.ErXing"
              ></el-option>
              <el-option
                label="2星+"
                value="3"
                v-show="isShowStar.ErXingJia"
              ></el-option>
              <el-option
                label="3星"
                value="4"
                v-show="isShowStar.SanXing"
              ></el-option>
              <el-option
                label="4星"
                value="5"
                v-show="isShowStar.SiXing"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          v-if="currentUser.currentLevel > 4"
          class="dangan"
          label="客户分组"
        >
          <span v-if="isSet">
            <el-select
              v-model="formLabelAlign.groupId"
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in userGroup"
                :key="item.id"
                :label="item.groupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span v-else style="color: red">{{ formLabelAlign.groupName }}</span>
          <el-button
            type="primary"
            style="margin-left: 90px"
            class="addbeizhu"
            @click="pwdChange(true)"
            size="small"
          >
            {{ isSet ? "保存" : "修改" }}
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 90px"
            class="addbeizhu"
            v-if="isSet"
            @click="pwdChange(false)"
            size="small"
          >
            取消
          </el-button>
        </el-form-item>
        <br />
        <el-form-item class="dangan" label="资质" id="zizhi">
          <el-input
            id="nianxian"
            type="textarea"
            :rows="3"
            placeholder="请输入资质内容"
            v-model="formLabelAlign.remarks"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 档案信息 -->
    <el-row class="filterCondition downOne">
      <el-form
        label-width="82px"
        label-position="right"
        :model="formLabelAlign"
      >
        <el-form-item class="dangan" label="备忘">
          <div class="block" id="dateOfBirth">
            <el-date-picker
              v-model="memoTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="dangan momecontent" label="">
          <el-input v-model="memoTxt"></el-input>
        </el-form-item>
        <el-button type="warning" id="addnote" @click="add">添加备忘</el-button
        ><br />
        <el-form-item class="dangan" label="备注" id="zizhi">
          <el-input
            id="nianxian"
            type="textarea"
            :rows="3"
            placeholder="请输入备注内容"
            v-model="noteContent"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          style="margin-left: 90px"
          class="addbeizhu"
          @click="addBeizhu"
          :disabled="disTBN"
          >提交编辑内容</el-button
        >
        <br />
        <el-form-item class="dangan" label="跟进记录">
          <div id="noteList">
            <ul>
              <li
                v-for="(item, index) in formLabelAlign.noteList"
                :key="index"
                v-bind:style="{
                  color: item.otherUser == 1 ? 'red' : '#606266',
                }"
              >
                <span
                  v-bind:style="{
                    color: item.otherUser == 1 ? '#898989' : '#898989',
                  }"
                  >{{ ++index }}--{{ item.createDate | formatTime }}</span
                >{{ item.userName }}--{{ item.content }}
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-button
          type="danger"
          style="margin-left: 90px"
          class="addbeizhu"
          v-show="showaddMyCus"
          @click="addMyCusList"
          :disabled="disTBN"
          >加入我的客户</el-button
        >
        <el-button
          type="success"
          style="margin-left: 90px"
          class="addbeizhu"
          v-show="showaddMyCus"
          @click="addGGC"
          :disabled="disTBN"
          >加入公共池</el-button
        >
        <br />
        <el-form-item class="dangan" label="流转记录">
          <div id="liuZhuanNote">
            <ul>
              <li v-for="(item, index) in this.liuZhuanNote" :key="index">
                <p style="margin: 0" v-if="item.type == 0">
                  {{ ++index }}--{{ item.createDate | formatTime }}--{{
                    item.type | fenPeiWay
                  }}<span style="color: red">{{ item.userName }}</span>
                  {{ item.operationName | cuoZuoRen }}
                </p>
                <p style="margin: 0" v-else-if="item.type == 1">
                  {{ ++index }}--{{ item.createDate | formatTime }}--{{
                    item.type | fenPeiWay
                  }}<span style="color: red">{{ item.userName }}</span>
                  {{ item.operationName | cuoZuoRen }}
                </p>
                <p style="margin: 0" v-else-if="item.type == 2">
                  {{ ++index }}--{{ item.createDate | formatTime }}--{{
                    item.type | fenPeiWay
                  }}<span style="color: red">{{ item.departmentName }}</span>
                  {{ item.operationName | cuoZuoRen }}
                </p>
                <p style="margin: 0" v-else-if="item.type == 3">
                  {{ ++index }}--{{ item.createDate | formatTime }}--<span
                    style="color: #7e2686"
                    >{{ item.type | fenPeiWay }}</span
                  >
                </p>
                <p style="margin: 0" v-else>
                  {{ ++index }}--{{ item.createDate | formatTime }}--{{
                    item.type | fenPeiWay
                  }}<span style="color: red">{{ item.userName }}</span>
                  {{ item.operationName | cuoZuoRen }}
                </p>
              </li>
            </ul>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { setInterval } from "timers";
import moment from "moment";
import { mapMutations, mapActions, mapState } from "vuex";
import $ from "jquery";

export default {
  name: "myCusEdit",

  // props:['tableData'],
  // watch: {
  //   tableData: function (newVal,oldVal) {
  //     this.cdata = newVal
  //     this.getUpCustomer();
  //   }
  // },

  // 数据
  data() {
    return {
      currentUser: {
        currentName: "",
        currentId: "",
        currentLevel: "",
        currentPhone: "",
      },
      // cdata: [],
      loading: false,
      showaddMyCus: false,
      showPhone: false,
      showBtn: true,
      showCol: false,
      showTh: false,
      //备忘
      memoTxt: "",
      memoTime: "",
      //备注
      noteContent: "",
      //进度动态生成内容
      statusList: [
        { id: "已受理", name: "已受理" },
        // { id: "未接通", name: "未接通" },
        { id: "待跟进", name: "待跟进" },
        { id: "资质不符", name: "资质不符" },
        { id: "待签约", name: "待签约" },
        { id: "已上门", name: "已上门" },
        { id: "B上门", name: "B上门" },
        { id: "审核中", name: "审核中" },
        { id: "已放款", name: "已放款" },
        { id: "已拒批", name: "已拒批" },
        { id: "捣乱申请", name: "捣乱申请" },
        { id: "重复申请", name: "重复申请" },
        { id: "外地申请", name: "外地申请" },
      ],
      isShowStar: {
        lingXing: false,
        YiXing: false,
        ErXing: false,
        ErXingJia: false,
        SanXing: false,
        SiXing: false,
      },
      //备注
      // noteList:"This is beizhujilu!!!",
      liuZhuanNote: [{ content: "该客户没有流转记录" }],
      //图片
      imageUrl: "",
      //客户id
      localCusId: "",
      keywords: "",
      level: "",
      state: "",
      content: "",
      contentCount: "",
      weiLianXiDays: "",
      timeType: "",
      dayType: "",
      beginTime: "",
      endTime: "",
      oldGroupId: "",

      //禁用按钮的状态值
      disTBN: false,
      //客户筛选区域数据
      formLabelAlign: {
        id: "",
        name: "",
        phone: "",
        sex: "",
        state: "",
        level: "", //星级
        age: "",
        isUse: 0,
        userId: null,
        userName: null,
        zaiFenPei: false,
        poolData: false,
        room: "", //房产
        car: "",
        socialSecurity: false,
        lifeSecurity: false,
        accumulation: false,
        isbankpay: false,
        linesCount: "",
        city: "",
        sourceCustomer: null,
        sourceId: 8,
        noteList: [{ content: "还没有给该客户添加任何备注" }],
        source: null,
        stateName: "",
        remarks: "",
        noteContent: "",
        newData: true,
        groupId: "",
        groupName: "",
      },
      dianPing: "",
      userGroup: [],
      isSet: false,
    };
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    JudgeBoolean(value) {
      if (value == false) {
        return "无";
      } else if (value == true) {
        return "有";
      } else {
        return "未填写";
      }
    },
    panDuanCity(val) {
      if (val == "其他") {
        return this.formLabelAlign.originalCity;
      } else {
        return val;
      }
    },
    phoneNum(val) {
      if (val != null) {
        return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
      } else {
        return "";
      }
    },
    phoneFeiKong(val) {
      if (val != null) {
        return val;
      } else {
        return "";
      }
    },
    fenPeiWay(val) {
      if (val == 0) {
        return "该客户被系统分配给";
      } else if (val == 1) {
        return "该客户被手动分配给";
      } else if (val == 2) {
        return "该客户被加入";
      } else if (val == 3) {
        return "该客户被系统抓取至公共池";
      } else {
        return "";
      }
    },
    cuoZuoRen(val) {
      if (val != null) {
        return "操作人" + val;
      } else {
        return "";
      }
    },
    panDuanAge(val) {
      if (val > 0) {
        return val + "岁";
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState({
      // 通话状态
      callStatus: (state) => state.webSocket.callStatus,
      wsError: (state) => state.webSocket.wsError,
      iccidList: (state) => state.webSocket.iccidList,
      changeState: (state) => state.webSocket.changeState,
      isPhone: (state) => state.webSocket.isPhone,
    }),
  },
  methods: {
    ...mapActions(["dial", "sendMsg", "queryChangeSim"]),
    tel() {
      let obj = {
        phone: this.formLabelAlign.phone,
      };
      this.$socket().send(JSON.stringify(obj));
    },
    // 添加备忘
    add() {
      // `/customer/updateCustomer?memoTxt=${this.memoTxt}&memoTime=${this.memoTime}&id=${this.localCusId}`
      this.$axios
        .post(`/customer/updateCustomerMemo`, {
          memoTxt: this.memoTxt,
          memoTime: this.memoTime,
          id: this.localCusId,
        })
        .then(({ data }) => {
          console.log(data);
          if (data.messageModel.code == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(data.messageModel.messageText);
          }
        });
    },
    //获取客户信息
    getCusInfo(cusId) {
      this.$axios
        .get(
          `/customer/getCustomerById?id=${cusId}&userId=${this.currentUser.currentid}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success("提交成功，已为您更新该用户的信息(#^.^#)");
            this.formLabelAlign = res.data.dataModel;
            this.memoTxt = res.data.dataModel.memoTxt;
            this.memoTime = res.data.dataModel.memoTime;
            //传值取值都为数字  但有时会出现select与value对应不上的情况
            // let level = res.data.dataModel.level
            this.formLabelAlign.remarks = res.data.dataModel.remarks;
            if (res.data.dataModel.level == 0) {
              this.formLabelAlign.level = "0星";
            } else if (res.data.dataModel.level == 1) {
              this.formLabelAlign.level = "1星";
            } else if (res.data.dataModel.level == 2) {
              this.formLabelAlign.level = "2星";
            } else if (res.data.dataModel.level == 3) {
              this.formLabelAlign.level = "2星+";
            } else if (res.data.dataModel.level == 4) {
              this.formLabelAlign.level = "3星";
            } else if (res.data.dataModel.level == 5) {
              this.formLabelAlign.level = "4星";
            } else {
              this.formLabelAlign.level = "还未设置星级";
            }
            if (this.formLabelAlign.remarks == "null") {
              this.formLabelAlign.remarks = "";
            }
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //获取上一个客户资料
    getUpCustomer() {
      //this.guaji();
      this.loading = true;
      let fstUrl = "/?#/myCusEdit?CusId=";
      // let fstUrl = "http://bjs0.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs1.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs2.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs3.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://localhost:8080/?#/myCusEdit?CusId=";
      this.currentUser.currentid = window.sessionStorage.getItem("id");
      if (window.sessionStorage.getItem("addatbn") == "true") {
        this.$axios
          .get(
            `/customer/getAllCustomerList?userId=${this.currentUser.currentid}&searchType=true&keywords=${this.keywords}&level=${this.level}
            &state=${this.state}&content=${this.content}&contentCount=${this.contentCount}&weiLianXiDays=${this.weiLianXiDays}&timeType=${this.timeType}&dayType=${this.dayType}
            &beginTime=${this.beginTime}&endTime=${this.endTime}&currentPage=&pageSize=`
          )
          .then((res) => {
            let customerList = res.data.dataModel.list;
            for (let index in customerList) {
              //获取当前的客户的下标
              let customerPhone = customerList[index].phone;
              let listLength = customerList.length;
              if (customerPhone == this.formLabelAlign.phone) {
                if (index <= listLength - 1 && index > 0) {
                  let upIndex = parseInt(index) - 1;
                  let upCusId = customerList[upIndex].id;
                  /*this.getCusInfo(upCusId);
                this.loading = false*/
                  //方法二
                  let upUrl =
                    fstUrl +
                    upCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = upUrl;
                  window.location.reload();
                } else {
                  // this.$alert('<strong>这是第一位客户</strong>', '提示信息', { dangerouslyUseHTMLString: true });
                  let upIndex = listLength - 1;
                  let upCusId = customerList[upIndex].id;
                  /*this.getCusInfo(upCusId);
                this.loading = false*/
                  //方法二
                  let upUrl =
                    fstUrl +
                    upCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = upUrl;
                  window.location.reload();
                }
              }
            }
          })
          .catch();
      } else {
        this.$axios
          .get(
            `/customer/getAllCustomerList?userId=${this.currentUser.currentid}&keywords=${this.keywords}&level=${this.level}
            &state=${this.state}&content=${this.content}&contentCount=${this.contentCount}&weiLianXiDays=${this.weiLianXiDays}&timeType=${this.timeType}&dayType=${this.dayType}
            &beginTime=${this.beginTime}&endTime=${this.endTime}&currentPage=&pageSize=`
          )
          .then((res) => {
            let customerList = res.data.dataModel.list;
            let upUrl = "";
            let upCusId = "";
            for (let index in customerList) {
              //获取当前的客户的下标
              let customerPhone = customerList[index].phone;
              let listLength = customerList.length;
              if (customerPhone == this.formLabelAlign.phone) {
                if (index <= listLength - 1 && index > 0) {
                  let upIndex = parseInt(index) - 1;
                  upCusId = customerList[upIndex].id;
                  /*this.getCusInfo(upCusId);
                this.loading = false*/
                  //方法二
                  upUrl =
                    fstUrl +
                    upCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = upUrl;
                  window.location.reload();
                } else {
                  /*this.$alert('<strong> 这是第一位客户</strong>', '提示信息', { dangerouslyUseHTMLString: true });*/
                  let upIndex = listLength - 1;
                  upCusId = customerList[upIndex].id;
                  /*this.getCusInfo(upCusId);
                this.loading = false*/
                  //方法二
                  upUrl =
                    fstUrl +
                    upCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = upUrl;
                  window.location.reload();
                }
              }
            }

            /*if (upUrl != fstUrl+upCusId) {
            let currentUrl = fstUrl+this.localCusId+"&addatbn=true";
            window.location.href = currentUrl
            window.location.reload();
          }*/
          })
          .catch();
      }
    },
    //获取下一个客户资料
    getNextCustomer() {
      console.info(121);
      //this.guaji();
      // this.loading = true
      let fstUrl = "/?#/myCusEdit?CusId=";
      // let fstUrl = "http://bjs0.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs1.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs2.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://bjs3.crm.qiyiqianbao.com/?#/myCusEdit?CusId=";
      //let fstUrl = "http://localhost:8080/?#/myCusEdit?CusId=";
      this.currentUser.currentid = window.sessionStorage.getItem("id");
      if (window.sessionStorage.getItem("addatbn") == "true") {
        this.$axios
          .get(
            `/customer/getAllCustomerList?userId=${this.currentUser.currentid}&searchType=true&keywords=${this.keywords}&level=${this.level}
            &state=${this.state}&content=${this.content}&contentCount=${this.contentCount}&weiLianXiDays=${this.weiLianXiDays}&timeType=${this.timeType}&dayType=${this.dayType}
            &beginTime=${this.beginTime}&endTime=${this.endTime}&currentPage=&pageSize=`
          )
          .then((res) => {
            // alert(this.level)
            let customerList = res.data.dataModel.list;
            for (let index in customerList) {
              //获取当前的客户的下标
              let customerPhone = customerList[index].phone;
              let listLength = customerList.length;
              if (customerPhone == this.formLabelAlign.phone) {
                if (index < listLength - 1) {
                  let nextIndex = parseInt(index) + 1;
                  let nextCusId = customerList[nextIndex].id;
                  /*this.getCusInfo(nextCusId);
                this.loading = false*/
                  // 方法二，跳转链接
                  let nextUrl =
                    fstUrl +
                    nextCusId +
                    "&addatbn=true&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = nextUrl;
                  window.location.reload();
                } else {
                  // this.$alert('<strong> 这是最后一位客户</strong>', '提示信息', { dangerouslyUseHTMLString: true });
                  //当是最后一个客户点下一个时回到第一个
                  let nextIndex = 0;
                  let nextCusId = customerList[nextIndex].id;
                  /*this.getCusInfo(nextCusId);
                this.loading = false*/
                  // 方法二，跳转链接
                  let nextUrl =
                    fstUrl +
                    nextCusId +
                    "&addatbn=true&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = nextUrl;
                  window.location.reload();
                }
              }
            }
          })
          .catch();
      } else {
        this.$axios
          .get(
            `/customer/getAllCustomerList?userId=${this.currentUser.currentid}&keywords=${this.keywords}&level=${this.level}
            &state=${this.state}&content=${this.content}&contentCount=${this.contentCount}&weiLianXiDays=${this.weiLianXiDays}&timeType=${this.timeType}&dayType=${this.dayType}
            &beginTime=${this.beginTime}&endTime=${this.endTime}&currentPage=&pageSize=`
          )
          .then((res) => {
            let customerList = res.data.dataModel.list;
            let nextUrl = "";
            let nextCusId = "";
            for (let index in customerList) {
              //获取当前的客户的下标
              let customerPhone = customerList[index].phone;
              let listLength = customerList.length;
              if (customerPhone == this.formLabelAlign.phone) {
                if (index < listLength - 1) {
                  let nextIndex = parseInt(index) + 1;
                  nextCusId = customerList[nextIndex].id;
                  /*this.getCusInfo(nextCusId);
                this.loading = false*/
                  // 方法二，跳转链接
                  nextUrl =
                    fstUrl +
                    nextCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = nextUrl;
                  window.location.reload();
                } else {
                  //this.$alert('<strong> 这是最后一位客户</strong>', '提示信息', { dangerouslyUseHTMLString: true });
                  //当是最后一个客户点下一个时回到第一个
                  let nextIndex = 0;
                  nextCusId = customerList[nextIndex].id;
                  /*this.getCusInfo(nextCusId);
                this.loading = false*/
                  // 方法二，跳转链接
                  nextUrl =
                    fstUrl +
                    nextCusId +
                    "&addatbn=false&keywords=&level=&state=&content=&contentCount=&weiLianXiDays=&timeType=&dayType=&beginTime=&endTime=";
                  window.location.href = nextUrl;
                  window.location.reload();
                }
              }
            }
            /*if (nextUrl != fstUrl+nextCusId) {
            let currentUrl = fstUrl+this.localCusId+"&addatbn=true";
            window.location.href = currentUrl
            window.location.reload();
          }*/
          })
          .catch();
      }
    },
    //拨号
    bohao() {
      let phone = this.formLabelAlign.phone;
      /*let fenjiNo = this.currentUser.currentid;
      if(phone != null){
        // window.location.href='http://192.168.3.250/cc/dial/801/'+phone;
        // window.open('http://192.168.3.250/cc/dial/'+fenjiNo+'/'+phone);
        $.get('http://chinacall.ydw123.com/cc/dial/'+fenjiNo+'/'+phone);

      /!*  this.$axios.get(`/call/getType`).then(res => {

          let typeStr = JSON.stringify(res.data)
          let typeData = typeStr.replace(/\\u0000/g, "")
          // let typeData = typeStr.disableHtmlEscaping().create();
          // alert(typeData)
        }).catch()*!/
        this.showBtn = false;
      }*/
      if (phone != null) {
        this.dial({ phone: phone });
      }
    },
    //挂机
    guaji() {
      /*let fenjiNo = this.currentUser.currentid;
      $.get('http://chinacall.ydw123.com/cc/hangup/'+fenjiNo);
      this.showBtn = true;*/
      this.sendMsg("ATH");
    },

    //获取客户信息的方法
    getEditCus() {
      this.$axios
        .get(
          `/customer/getCustomerById?id=${this.localCusId}&userId=${this.currentUser.currentid}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success("提交成功，已为您更新该用户的信息(#^.^#)");
            this.formLabelAlign = res.data.dataModel;
            this.memoTxt = res.data.dataModel.memoTxt;
            this.memoTime = res.data.dataModel.memoTime;
            //传值取值都为数字  但有时会出现select与value对应不上的情况
            // let level = res.data.dataModel.level
            this.formLabelAlign.remarks = res.data.dataModel.remarks;
            if (res.data.dataModel.level == 0) {
              this.formLabelAlign.level = "0星";
            } else if (res.data.dataModel.level == 1) {
              this.formLabelAlign.level = "1星";
            } else if (res.data.dataModel.level == 2) {
              this.formLabelAlign.level = "2星";
            } else if (res.data.dataModel.level == 3) {
              this.formLabelAlign.level = "2星+";
            } else if (res.data.dataModel.level == 4) {
              this.formLabelAlign.level = "3星";
            } else if (res.data.dataModel.level == 5) {
              this.formLabelAlign.level = "4星";
            } else {
              this.formLabelAlign.level = "还未设置星级";
            }
            if (this.formLabelAlign.remarks == "null") {
              this.formLabelAlign.remarks = "";
            }
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取未处理的数据数量
    getUnread() {
      this.disButton();
      this.$axios
        .get(`/customer/getCusCount?userId=${this.currentUser.currentid}`)
        .then((res) => {
          if (res.data.messageModel.code != 0) {
            this.$message.error(res.data.messageModel.messageText);
          } else {
            this.$store.commit("getUnread", res.data.dataModel);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取流转记录
    getLiuZhuanNote() {
      this.$axios
        .get(`/customer/getDisList?customerId=${this.localCusId}`)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.liuZhuanNote = res.data.dataModel;
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //提交备注内容
    addBeizhu() {
      this.disButton();
      if (this.formLabelAlign.state == "") {
        this.$message.error("您还没选择客户状态");
        return false;
      } else if (
        this.formLabelAlign.level != 0 &&
        this.formLabelAlign.level != "0星" &&
        this.formLabelAlign.remarks == ""
      ) {
        this.$message.error("非0星状态下，资质内容不得为空");
        return false;
      } else if (
        this.formLabelAlign.level != 0 &&
        this.formLabelAlign.level != "0星" &&
        this.formLabelAlign.remarks.length <= 10
      ) {
        this.$message.error("非0星状态下，资质内容不得少于十个字符");
        return false;
      }
      if (this.formLabelAlign.level == "") {
        this.$message.error("星级不可为空");
        return false;
      }
      //翻译后再传给后端
      // let level = this.formLabelAlign.level
      if (
        typeof this.formLabelAlign.level === "number" &&
        !isNaN(this.formLabelAlign.level)
      ) {
        this.formLabelAlign.level = this.formLabelAlign.level;
      } else {
        if (
          this.formLabelAlign.level == "0星" ||
          this.formLabelAlign.level == 0
        ) {
          this.formLabelAlign.level = 0;
        } else if (this.formLabelAlign.level == "1星") {
          this.formLabelAlign.level = 1;
        } else if (this.formLabelAlign.level == "2星") {
          this.formLabelAlign.level = 2;
        } else if (this.formLabelAlign.level == "2星+") {
          this.formLabelAlign.level = 3;
        } else if (this.formLabelAlign.level == "3星") {
          this.formLabelAlign.level = 4;
        } else if (this.formLabelAlign.level == "4星") {
          this.formLabelAlign.level = 5;
        } else {
          this.formLabelAlign.level = this.formLabelAlign.level;
        }
      }
      //解决+号被解析为空格的问题
      // eslint-disable-next-line no-useless-escape
      let reg = /^[a-zA-Z0-9\-\+] + $/i;
      if (reg.test(this.noteContent) != -1) {
        this.noteContent = encodeURI(this.noteContent).replace(/\+/g, "%2B");
      }
      if (reg.test(this.formLabelAlign.remarks) != -1) {
        this.formLabelAlign.remarks = encodeURI(
          this.formLabelAlign.remarks
        ).replace(/\+/g, "%2B");
      }
      this.$axios
        .post(
          `/customer/updateCustomer?id=${this.localCusId}&userId=${this.currentUser.currentid}&name=${this.formLabelAlign.name}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&remarks=${this.formLabelAlign.remarks}&noteContent=${this.noteContent}&type=${this.dianPing}`
        )
        .then((res) => {
          if (res.data.messageModel.code == 0) {
            // alert(JSON.stringify(res))
            this.$message.success("成功提交新的备注信息(#^.^#)");
            this.getUnread();
            this.getEditCus();
            this.noteContent = "";
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.noteContent = "";
            this.getUnread();
            this.getEditCus();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //将按钮禁用两秒
    disButton() {
      this.disTBN = true;
      setTimeout(() => {
        this.disTBN = false;
      }, 2000);
    },
    //加入我的客户
    addMyCusList() {
      this.loading = true;
      this.disButton();
      this.$axios
        .post(`/customer/addToMyCustomer?id=${this.localCusId}`)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success("您已成功添加该客户到您的客户(#^.^#)");
            this.loading = false;
            // window.location.reload();
          } else {
            this.$message.error("提交失败，请稍后重试┭┮﹏┭┮");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //加入公共池
    addGGC() {
      this.disButton();
      this.$axios
        .post(
          `/customer/addToPool?operationId=${this.currentUser.currentid}&customerId=${this.localCusId}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.messageText == "修改成功") {
            this.$message({
              message: "已将选中的客户成功添加到公共池",
              type: "success",
            });
          } else {
            this.$message.error("添加失败/(ㄒoㄒ)/~~");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取进度 控制隐藏和现实
    ShowXingJi(vId) {
      this.formLabelAlign.level = "";
      let obj = {};
      obj = this.statusList.find((item) => {
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (
        obj.name == "已受理" ||
        obj.name == "未接通" ||
        obj.name == "待跟进"
      ) {
        for (let key in this.isShowStar) {
          this.isShowStar[key] = false;
        }
        this.isShowStar.lingXing = true;
      } else if (obj.name == "资质不符" || obj.name == "捣乱申请") {
        for (let key in this.isShowStar) {
          this.isShowStar[key] = false;
        }
        this.isShowStar.YiXing = true;
      } else if (
        obj.name == "待签约" ||
        obj.name == "B上门" ||
        obj.name == "审核中" ||
        obj.name == "已放款" ||
        obj.name == "已拒批"
      ) {
        for (let key in this.isShowStar) {
          this.isShowStar[key] = true;
        }
        this.isShowStar.lingXing = false;
        this.isShowStar.YiXing = false;
      } else if (
        obj.name == "已上门" ||
        obj.name == "重复申请" ||
        obj.name == "外地申请"
      ) {
        for (let key in this.isShowStar) {
          this.isShowStar[key] = true;
        }
        this.isShowStar.lingXing = false;
      } else {
        for (let key in this.isShowStar) {
          this.isShowStar[key] = false;
        }
      }
    },
    //修改
    pwdChange(cg) {
      console.log(this.formLabelAlign.groupName);
      //是否是取消操作
      if (!cg) {
        return (this.isSet = !this.isSet);
      }
      //提交数据
      if (this.isSet) {
        //项目是模拟请求操作  自己修改下
        // let data = JSON.parse(JSON.stringify(this.userGroup.sel));
        //
        // console.log(data);
        // for (let k in data) row[k] = data[k];
        // console.log(row);
        if (this.formLabelAlign.groupId == null) {
          return this.$message.error("请选择分组后，在保存，谢谢");
        }
        this.$axios
          .post(`/group/updateGroupCustomer`, {
            oldGroupId: this.oldGroupId,
            groupId: this.formLabelAlign.groupId,
            customerId: this.localCusId,
          })
          .then((res) => {
            // console.log(res);
            let code = res.data.messageModel.code;
            if (code == 0) {
              this.$message.success("保存成功");
              this.oldGroupId = this.formLabelAlign.groupId;
              this.userGroup.map((i) => {
                if (i.id == this.oldGroupId) {
                  this.formLabelAlign.groupName = i.groupName;
                }
                return i;
              });
            } else {
              this.loading = false;
              this.$message.error(res.data.messageModel.messageText);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.isSet = false;
      } else {
        this.$axios
          .post(`/group/getGroupList?userId=${this.currentUser.currentid}`)
          .then((res) => {
            // console.log(res);
            let code = res.data.messageModel.code;
            if (code == 0) {
              this.loading = false;
              if (res.data.dataModel.length == 0) {
                this.$message("暂无分组");
                this.userGroup = [];
                return false;
              }
              this.userGroup = res.data.dataModel;
            } else {
              this.loading = false;
              this.$message.error(res.data.messageModel.messageText);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // this.userGroup.sel = JSON.parse(JSON.stringify(row));
        this.isSet = true;
      }
    },
  },

  created() {
    this.loading = true;
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel");
    if (this.$route.query.CusId) {
      this.localCusId = this.$route.query.CusId;
    } else {
      this.localCusId = window.sessionStorage.getItem("CusId");
    }
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords;
    } else {
      this.keywords = window.sessionStorage.getItem("keywords");
    }
    if (this.$route.query.level) {
      this.level = this.$route.query.level;
    } else {
      this.level = window.sessionStorage.getItem("level");
    }
    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    } else {
      this.state = window.sessionStorage.getItem("state");
    }
    if (this.$route.query.content) {
      this.content = this.$route.query.content;
    } else {
      this.content = window.sessionStorage.getItem("content");
    }
    if (this.$route.query.contentCount) {
      this.contentCount = this.$route.query.contentCount;
    } else {
      this.contentCount = window.sessionStorage.getItem("contentCount");
    }
    if (this.$route.query.weiLianXiDays) {
      this.weiLianXiDays = this.$route.query.weiLianXiDays;
    } else {
      this.weiLianXiDays = window.sessionStorage.getItem("weiLianXiDays");
    }
    if (this.$route.query.timeType) {
      this.contentCount = this.$route.query.contentCount;
    } else {
      this.contentCount = window.sessionStorage.getItem("contentCount");
    }
    if (this.$route.query.contentCount) {
      this.timeType = this.$route.query.timeType;
    } else {
      this.timeType = window.sessionStorage.getItem("timeType");
    }
    if (this.$route.query.dayType) {
      this.dayType = this.$route.query.dayType;
    } else {
      this.dayType = window.sessionStorage.getItem("dayType");
    }
    if (this.$route.query.beginTime) {
      this.beginTime = this.$route.query.beginTime;
    } else {
      this.beginTime = window.sessionStorage.getItem("beginTime");
    }
    if (this.$route.query.endTime) {
      this.endTime = this.$route.query.endTime;
    } else {
      this.endTime = window.sessionStorage.getItem("endTime");
    }
    // console.log(this.$route.query.addatbn);
    if (this.$route.query.addatbn == "true") {
      // console.log("已识别这是再分配客户的编辑页面");
      this.showaddMyCus = true;
    } else {
      this.showaddMyCus = false;
    }
    // console.log(this.localCusId);
    if (this.localCusId == null) {
      this.$message.error("网络异常，获取用户数据失败，请稍后尝试/(ㄒoㄒ)/~~");
    } else {
      /*let currentCusId =""
      this.$axios.get(`/customer/getByPhone?phone=${this.formLabelAlign.phone}`)
          .then(res => {
            currentCusId = res.data.id
          })
          .catch()*/
      //获取客户信息
      this.$axios
        .get(
          `/customer/getCustomerById?id=${this.localCusId}&userId=${this.currentUser.currentid}`
        ) //正常请求
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message.success(
              "已为您显示客户信息，请开始编辑该用户(#^.^#)"
            );
            this.formLabelAlign = res.data.dataModel;
            this.memoTxt = res.data.dataModel.memoTxt;
            this.memoTime = res.data.dataModel.memoTime;
            //后端给的布尔值和星级数据无法直接渲染需要翻译
            // let level = res.data.dataModel.level
            if (res.data.dataModel.level == 0) {
              this.formLabelAlign.level = "0星";
            } else if (res.data.dataModel.level == 1) {
              this.formLabelAlign.level = "1星";
            } else if (res.data.dataModel.level == 2) {
              this.formLabelAlign.level = "2星";
            } else if (res.data.dataModel.level == 3) {
              this.formLabelAlign.level = "2星+";
            } else if (res.data.dataModel.level == 4) {
              this.formLabelAlign.level = "3星";
            } else if (res.data.dataModel.level == 5) {
              this.formLabelAlign.level = "4星";
            } else {
              this.formLabelAlign.level = "还未设置星级";
            }
            if (this.formLabelAlign.remarks == "null") {
              this.formLabelAlign.remarks = "";
            }
            if (this.formLabelAlign.userId != this.currentUser.currentid) {
              this.showPhone = true;
              this.showCol = false;
              this.dianPing = "dianPing";
            } else {
              this.showPhone = false;
              this.showCol = true;
              this.dianPing = "";
            }
            this.oldGroupId = this.formLabelAlign.groupId;
            this.loading = false;
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    console.log(this.formLabelAlign);
    this.getLiuZhuanNote();
  },
  activated() {
    this.loading = true;
    //切回组件的时候执行 只刷新数据，不改变整体的缓存
    // this.fetchDate();
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.localCusId = this.$route.query.CusId;
    // console.log(this.$route.query.CusId);
    if (this.$route.query.CusId) {
      this.localCusId = this.$route.query.CusId;
    } else {
      this.localCusId = window.sessionStorage.getItem("CusId");
      this.$message.success("请继续编辑");
    }
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords;
    } else {
      this.keywords = window.sessionStorage.getItem("keywords");
    }
    if (this.$route.query.level) {
      this.level = this.$route.query.level;
    } else {
      this.level = window.sessionStorage.getItem("level");
    }
    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    } else {
      this.state = window.sessionStorage.getItem("state");
    }
    if (this.$route.query.content) {
      this.content = this.$route.query.content;
    } else {
      this.content = window.sessionStorage.getItem("content");
    }
    if (this.$route.query.contentCount) {
      this.contentCount = this.$route.query.contentCount;
    } else {
      this.contentCount = window.sessionStorage.getItem("contentCount");
    }
    if (this.$route.query.weiLianXiDays) {
      this.weiLianXiDays = this.$route.query.weiLianXiDays;
    } else {
      this.weiLianXiDays = window.sessionStorage.getItem("weiLianXiDays");
    }
    if (this.$route.query.timeType) {
      this.contentCount = this.$route.query.contentCount;
    } else {
      this.contentCount = window.sessionStorage.getItem("contentCount");
    }
    if (this.$route.query.contentCount) {
      this.timeType = this.$route.query.timeType;
    } else {
      this.timeType = window.sessionStorage.getItem("timeType");
    }
    if (this.$route.query.dayType) {
      this.dayType = this.$route.query.dayType;
    } else {
      this.dayType = window.sessionStorage.getItem("dayType");
    }
    if (this.$route.query.beginTime) {
      this.beginTime = this.$route.query.beginTime;
    } else {
      this.beginTime = window.sessionStorage.getItem("beginTime");
    }
    if (this.$route.query.endTime) {
      this.endTime = this.$route.query.endTime;
    } else {
      this.endTime = window.sessionStorage.getItem("endTime");
    }
    // console.log(this.$route.query.addatbn);
    if (this.$route.query.addatbn == "true") {
      // console.log("已识别这是再分配客户的编辑页面");
      this.showaddMyCus = true;
    } else {
      this.showaddMyCus = false;
    }
    let currentCusId = "";
    // this.$axios.get(`/customer/getByPhone?phone=${this.formLabelAlign.phone}`)
    //         .then(res => {
    //           currentCusId = res.data.id
    //         })
    //         .catch()
    //获取客户信息
    this.$axios
      .get(
        `/customer/getCustomerById?id=${this.localCusId}&userId=${this.currentUser.currentid}`
      ) //正常请求
      .then((res) => {
        console.log(res);
        if (res.data.messageModel.code == 0) {
          this.$message.success("已为您显示客户信息，请开始编辑该用户(#^.^#)");
          this.formLabelAlign = res.data.dataModel;
          this.memoTxt = res.data.dataModel.memoTxt;
          this.memoTime = res.data.dataModel.memoTime;
          if (this.formLabelAlign.userId != this.currentUser.currentid) {
            this.showPhone = true;
            this.showCol = false;
            this.dianPing = "dianPing";
          } else {
            this.showPhone = false;
            this.showCol = true;
            this.dianPing = "";
          }
          if (this.formLabelAlign.remarks == "null") {
            this.formLabelAlign.remarks = "";
          }
          //后端给的布尔值和星级数据无法直接渲染需要翻译
          let level = res.data.dataModel.level;
          if (level == 0) {
            this.formLabelAlign.level = "0星";
          } else if (level == 1) {
            this.formLabelAlign.level = "1星";
          } else if (level == 2) {
            this.formLabelAlign.level = "2星";
          } else if (level == 3) {
            this.formLabelAlign.level = "2星+";
          } else if (level == 4) {
            this.formLabelAlign.level = "3星";
          } else if (level == 5) {
            this.formLabelAlign.level = "4星";
          } else {
            this.formLabelAlign.level = "0星";
          }
          this.oldGroupId = this.formLabelAlign.groupId;
          this.loading = false;
        } else {
          this.$message.error(res.data.messageModel.messageText);
          this.loading = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(this.formLabelAlign);
    console.log(this.formLabelAlign.phone);
    console.log(this.formLabelAlign.phoneNum);

    this.getLiuZhuanNote();
    this.isSet = false;
  },
};
</script>

<style lang="scss">
.myCusEdit-container {
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      line-height: 20px;
      width: 100%;
      color: #708090;
    }
  }
  // 信息展示部分样式
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    line-height: 36px;
  }
  .bg-purple-dark {
    background: #bcc4ce;
  }
  .bg-purple {
    background: #eff1f3;
  }
  .miaoshu {
    text-align: center;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .content {
    padding-left: 20px;
  }
  .grid-content {
    min-height: 50px;
    /*border-bottom: 1px solid grey;*/
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .col-first {
    margin-left: 20px;
  }
  .col-last {
    margin-right: 20px;
  }
  .row-last {
    margin-bottom: 30px;
  }
  .row-first {
    margin-top: 30px;
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
  }
  // 时间分类框样式
  .teamCusEdit-container input.el-input__inner#againkinds,
  .teamCusEdit-container input.el-input__inner#againtime {
    width: 85px;
    padding-left: 7px;
  }

  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
    .el-date-editor .el-range-separator {
      width: 5%;
    }
  }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 10px;
    #addnote {
      line-height: 0.5;
      margin-left: 10px;
    }
    #noteList {
      width: 712px;
      // height: 160px;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      border: 1px solid #dcdfe6;
      background-color: #f5f7fa;
    }
    #liuZhuanNote {
      width: 712px;
      // height: 160px;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px;
      border: 1px solid #dcdfe6;
      background-color: #f5f7fa;
    }
    /deep/.addbeizhu {
      margin-left: 20px !important;
    }
  }
  .filterCondition.downOne {
    padding-bottom: 30px;
  }
  .zaifenCheck {
    margin-left: 20px;
  }
  .zaifenBth.el-button {
    padding: 10px 20px;
  }
  /deep/ #dateOfBirth .el-input__inner {
    width: 172px !important;
    padding-left: 30px;
  }
  .redistribution-container input.el-input__inner {
    width: 182px;
    height: 32px;
    padding-left: 10px;
  }
  #name .el-form-item__label {
    width: 43px;
  }
  .benhangye {
    display: inline-block;
    vertical-align: top;
    /deep/ .el-form-item__content {
      margin-left: 10px !important;
    }
    #hangyejingyan {
      display: flex;
      margin: 0;

      .el-form-item__label {
        width: 115px;
      }
    }
  }
  .momecontent {
    /deep/ .el-form-item__content {
      margin-left: 20px !important;
      .el-input__inner {
        width: 500px;
      }
    }
  }
  //跟进记录
  #genjinjilu,
  #zizhicontent {
    width: 712px;
    height: 100px;
  }
  // 从业年限
  .nianxian {
    margin-left: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 182px;
  }
  //文本域
  .el-textarea {
    width: 712px;
  }
}
.bohaoBth {
  /*width: 232px;*/
  font-size: 5px;
  margin-bottom: 20px;
  max-height: 0px;
}
.el-icon-my-guaji {
  background: url(../../assets/guaji.png) center no-repeat;
  /*background-size: cover;*/
}
.el-icon-my-guaji:before {
  content: "挂机";
  font-size: 16px;
  visibility: hidden;
}

.upBtn {
  height: 20px;
  width: 70px;
  background-color: #409eff;
  /*margin-top: 2px;
  vertical-align: top;
  margin-left: 10px;
  border: 1px solid grey;*/
  /*border-radius: 3px;*/
  color: #f7f7f7;
}
</style>
