var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"UnhandleM-container"},[_c('el-row',{staticClass:"filterCondition"},[(this.formLabelAlign.MySearchLevel == 'level0')?_c('p',{staticStyle:{"background-color":"#C7DEE8","display":"inline-block","line-height":"1.5","opacity":"0.5","margin":"10px 20px","padding":"0 20px"}},[_vm._v(" 0星客户超过6h未联系 ")]):(this.formLabelAlign.MySearchLevel == 'level2')?_c('p',{staticStyle:{"background-color":"#C7DEE8","display":"inline-block","line-height":"1.5","opacity":"0.5","margin":"10px 20px","padding":"0 20px"}},[_vm._v(" 2星及2星+客户超过6天未联系 ")]):(this.formLabelAlign.MySearchLevel == 'level3')?_c('p',{staticStyle:{"background-color":"#C7DEE8","display":"inline-block","line-height":"1.5","opacity":"0.5","margin":"10px 20px","padding":"0 20px"}},[_vm._v(" 3星及4星客户超过4天未联系 ")]):_c('p')]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","width":"70","align":"center"}}),_c('el-table-column',{attrs:{"label":"姓名","width":"70","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{staticClass:"toEdit",attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"状态","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.state == '资质不符')?_c('span',{staticStyle:{"color":"#C73E3A"}},[_vm._v(_vm._s(scope.row.state))]):(
              scope.row.state == 'B上门' ||
                scope.row.state == '已上门' ||
                scope.row.state == '待签约' ||
                scope.row.state == '已放款' ||
                scope.row.state == '审核中'
            )?_c('span',{staticStyle:{"color":"#5BBD2B"}},[_vm._v(_vm._s(scope.row.state))]):(
              scope.row.state == '已受理' ||
                scope.row.state == '未接通' ||
                scope.row.state == '待跟进'
            )?_c('span',{staticStyle:{"color":"#3390ff"}},[_vm._v(_vm._s(scope.row.state))]):(
              scope.row.state == '捣乱申请' ||
                scope.row.state == '已拒批' ||
                scope.row.state == '外地申请' ||
                scope.row.state == '重复申请'
            )?_c('span',{staticStyle:{"color":"#D7962F"}},[_vm._v(_vm._s(scope.row.state))]):_c('span',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(scope.row.state))])]}}])}),_c('el-table-column',{attrs:{"label":"星级","width":"50","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm._f("levelKind")(scope.row.level)))]}}])}),_c('el-table-column',{attrs:{"label":"备注","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.noteList),function(item,index){return _c('div',{key:index},[(index < 3)?_c('p',{staticClass:"ell",style:({ color: item.otherUser == 1 ? 'red' : '#606266' })},[_vm._v("  "),_c('span',{style:({
									color: item.otherUser == 1 ? '#898989' : '#898989',
								})},[_vm._v(_vm._s(++index)+"--"+_vm._s(_vm._f("formatTime")(item.createDate))+"--"+_vm._s(item.userName))]),_vm._v("--"+_vm._s(item.content)+" ")]):_vm._e()])})}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"资质","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("remarksPanKong")(scope.row.remarks)))])]}}])}),_c('el-table-column',{attrs:{"label":"分配时间","width":"150","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm._f("formatTime")(scope.row.distributionTime)))]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageConfig.currentPage,"page-sizes":[10, 20, 50, 100],"page-size":_vm.pageConfig.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pageConfig.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }