<template>
  <div class="PromotionAnalysis-container" >
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
        <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
            <el-form-item prop="city" label="城市" >
            <el-select v-model="formLabelAlign.city" placeholder="请选择城市" >
                <el-option label="所有" value = ""></el-option>
                <el-option
                    v-for="item in cityselectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="pingtaiID" label="平台">
            <el-select v-model="formLabelAlign.pingtaiID" @change="PingTaiZH" placeholder="请选平台">
                <el-option label="全部" value = ""  ></el-option>
                <el-option
                    v-for="item in PingTaiList"
                    :key="item.id"
                    :label="item.sourceName2"
                    :value="item.id"
                ></el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="BTN">
            <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check" :disabled="disTBN">查询</el-button>
            <!-- <el-button style="background-color:	#00CDCD;color:#fff" @click="resetForm('formLabelAlign')"   class="check">重置筛选条件</el-button> -->
            </el-form-item>
        </el-form>
    </el-row>
    <!-- Ecahrts -->
    <el-row class="filterCondition" v-loading="loading">
        <!-- <h3>推广数据统计分部图</h3> -->
        <div id="main" ref="chart" style="width: 100%;height: 400px;"></div>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table  :data="tableData" v-loading="loading"  style="width: 100%" >
        <el-table-column label="申请量与3日平均申请差额（差额 = 今日 - 三日平均）">
            <el-table-column prop="city" label="城市" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.city | PanKong }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="PingTai" label="平台" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.PingTai | PanKong }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ZeroToEight" label="0点-8点" >
              <template slot-scope="scope">
                <span v-if="scope.row.ZeroToEight < 0 " style="color:red">{{ scope.row.ZeroToEight }}</span>
                <span v-else-if="scope.row.ZeroToEight > 0 " style="color:green">{{ scope.row.ZeroToEight }}</span>
                <span v-else>{{ scope.row.ZeroToEight }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="NineToFourteen" label="9点-14点" >
              <template slot-scope="scope">
                <span v-if="scope.row.NineToFourteen < 0 " style="color:red">{{ scope.row.NineToFourteen }}</span>
                <span v-else-if="scope.row.NineToFourteen > 0 " style="color:green">{{ scope.row.NineToFourteen }}</span>
                <span v-else>{{ scope.row.NineToFourteen }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="FiveteenToEnd" label="15-23点" >
              <template slot-scope="scope">
                <span v-if="scope.row.FiveteenToEnd < 0 " style="color:red">{{ scope.row.FiveteenToEnd }}</span>
                <span v-else-if="scope.row.FiveteenToEnd > 0 " style="color:green">{{ scope.row.FiveteenToEnd }}</span>
                <span v-else>{{ scope.row.FiveteenToEnd }}</span>
              </template>
            </el-table-column>
        </el-table-column>
    </el-table>
    <!-- 分页 -->
    <!-- <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="10"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination> -->
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
import echarts from 'echarts'
export default {
  name: "PromotionAnalysis",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
        // eslint-disable-next-line no-dupe-keys,vue/no-dupe-keys
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      loading:true,
      showTimeKind:true,
      //禁用按钮的状态值
      disTBN:false,
      //预存选择的平台中午呢名称
      PingTaiZh:"",
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      cityselectList: [{ id: 0, name: "请先选择城市" }],
      PingTaiList: [],
      //客户筛选区域数据
      formLabelAlign: {
        pingtaiID : "", 	//	平台Id
        city : "" 	//	城市
      },
      // 表格依赖于数据没有数据  会一行都没有
      tableData:[
        {city : "",
        PingTai : "",
        ZeroToEight : 0,
        NineToFourteen : 0,
        FiveteenToEnd : 0}
        ],
      //echarts
      EchartsData:{
        ShowLines:['今日上限','今日数值','近三天平均值','对比数值'], //显示的线条
        timeData:['1时','2时','3时','4时','5时','6时','7时','8时','9时','10时','11时','12时','13时','14时','15时','16时','17时','18时','19时','20时','21时','22时','23时','24时'],
        TodayCeiling:[], //今日上限
        TodayData:[], //今日数据 (计算用)
        yesterdayData:[],  //昨日数据(计算用)
        DuiBiData:[], //对比数据(计算用)
        ThreeDaySumData:[], //近三日总和(计算用)
        SanRiJunData:[], //三日均值(计算用)
        ShowTodayData:[], //今日数据 (显示用)
        ShowSanRiJunData:[], //三日均 (显示用)
        ShowDuiBiData:[], //对比数据 (显示用)
      }
    };
  },
  methods: {
    //获取折线图数据的方法
    getZheXianData(){
      this.loading = true
      // console.log(this.PingTaiZh);
      // if (this.formLabelAlign.pingtaiID == "") {
      //   this.EchartsData.ShowLines = ['今日上限','今日数值']
      // }else{
      //   this.EchartsData.ShowLines = ['今日数值','近三天平均值','对比数值']
      // }
      //给表格的城市和平台赋值
      this.tableData[0].city = this.formLabelAlign.city;
      this.tableData[0].PingTai = this.PingTaiZh;
      //清空统计数据重新计算
      this.tableData[0].ZeroToEight = 0;
      this.tableData[0].NineToFourteen = 0;
      this.tableData[0].FiveteenToEnd = 0;
      this.$axios
      .get(`/customer/getDataHours24?city=${this.formLabelAlign.city}&pingtaiID=${this.formLabelAlign.pingtaiID}&dayType=today`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0 ) {
          //计算今日数据 (计算用)
          let TodayData = []
          for (let i = 0; i < res.data.dataModel.list.length; i++) {
            TodayData[i] = res.data.dataModel.list[i].count;
          }
          this.EchartsData.TodayData = TodayData;
          //计算今日数据 (显示用)
          //a.清空原值
          let ShowTodayData = []
          this.EchartsData.ShowTodayData = []
          for (let l = 0; l < this.EchartsData.TodayData.length; l++) {
            if (l == 0) {
              ShowTodayData[l] = this.EchartsData.TodayData[l]
            }else{
              ShowTodayData[l] = this.EchartsData.TodayData[l] + ShowTodayData[l-1];
            }
          }
          this.EchartsData.ShowTodayData = ShowTodayData
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.pageConfig.total = 0;
          this.loading = false;
        }
        })
      .catch(error => {
        console.log(error);
      });
      this.$axios
      .get(`/customer/getDataHours24?city=${this.formLabelAlign.city}&pingtaiID=${this.formLabelAlign.pingtaiID}&dayType=threeDay`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0 ) {
          let ThreeDaySumData = []
          for (let i = 0; i < res.data.dataModel.list.length; i++) {
            ThreeDaySumData[i] = res.data.dataModel.list[i].count;
          }
          this.EchartsData.ThreeDaySumData = ThreeDaySumData
          //计算对比数值
          //1.先算出近三天每个时间段的平均数
          for (let j = 0; j < this.EchartsData.ThreeDaySumData.length; j++) {
            this.EchartsData.SanRiJunData[j] = Math.ceil(this.EchartsData.ThreeDaySumData[j]/3);
          }
          //1.1计算三日均值 (显示用)
          let ShowSanRiJunData = []
          this.EchartsData.ShowSanRiJunData = []
          for (let h = 0; h < this.EchartsData.SanRiJunData.length; h++) {
            if (h == 0) {
              ShowSanRiJunData[h] = this.EchartsData.SanRiJunData[h]
            }else{
              ShowSanRiJunData[h] = this.EchartsData.SanRiJunData[h] + ShowSanRiJunData[h-1];
            }
          }
          this.EchartsData.ShowSanRiJunData = ShowSanRiJunData
          //2.计算今日与近三日均值的差
          for (let K = 0; K < this.EchartsData.SanRiJunData.length; K++) {
            this.EchartsData.DuiBiData[K] = this.EchartsData.TodayData[K] - this.EchartsData.SanRiJunData[K];
          }
          //2.2计算对比值值 (显示用)
          let ShowDuiBiData = []
          this.EchartsData.ShowDuiBiData = []
          for (let h = 0; h < this.EchartsData.DuiBiData.length; h++) {
            if (h == 0) {
              ShowDuiBiData[h] = this.EchartsData.DuiBiData[h]
            }else{
              ShowDuiBiData[h] = this.EchartsData.DuiBiData[h] + ShowDuiBiData[h-1];
            }
          }
          this.EchartsData.ShowDuiBiData = ShowDuiBiData
          //3.将差额按时间段统计
          for (let w = 0; w < this.EchartsData.DuiBiData.length; w++) {
           if (w < 9) {
             this.tableData[0].ZeroToEight += this.EchartsData.DuiBiData[w]
           }else if (9 <= w && w < 15) {
             this.tableData[0].NineToFourteen += this.EchartsData.DuiBiData[w]
           }else if (w > 15) {
             this.tableData[0].FiveteenToEnd += this.EchartsData.DuiBiData[w]
           }
          }
          //给今日上限赋值
          for (let p = 0; p < 24; p++) {
            this.EchartsData.TodayCeiling[p] = res.data.dataModel.disCount;
          }
        }else{
          this.$message.error(res.data.messageModel.messageText);
          // this.tableData = [];
          this.pageConfig.total = 0;
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
      });
      //拿到数据后需要计算会出现延时 所以设置延时渲染
      setTimeout(() => {
        this.drawPie('main')
        this.loading = false
      }, 1000);
    },
    //拿到所选平台的中文名
    PingTaiZH(vId){
      if (this.formLabelAlign.pingtaiID != "") {
        let obj = {};
        obj = this.PingTaiList.find(item => {
          return item.id === vId; //筛选出匹配数据
        });
        //暂存平台中文名
        this.PingTaiZh = obj.sourceName2
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //分页的方法
    handleSizeChange(val) {
      this.loading = true
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getZheXianData()
      }else{
        this.pageConfig.pageSize = val;
        this.getZheXianData();
      }
    },
    handleCurrentChange(val) {
      this.loading = true
      this.pageConfig.currentPage = val;
      this.getZheXianData()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //查询
    checkCus(){
      this.disButton()
      this.loading = true
      this.getZheXianData()
    },
    //Echats部分
    drawPie(id){
        this.charts = echarts.init(document.getElementById(id))
        this.charts.clear()
        this.charts.setOption({
        title: {
          text: '推广数据统计分部图'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
          data:this.EchartsData.ShowLines
      },
      toolbox: {
          feature: {
              saveAsImage: {}
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis : [
          {
              type : 'category',
              boundaryGap : false,
              data : this.EchartsData.timeData
          }
      ],
      yAxis : [
          {
              type : 'value'
          }
      ],
      series : [
          {
              name:'今日上限',
              type:'line',
              stack: '总量1',
              areaStyle: {
                  normal: {
                  color:'#ECECEC',
                      lineStyle:{
                          color:'#8B0016'
                      }
                  }
              },
              data:this.EchartsData.TodayCeiling
          },
          {
              name:'今日数值',
              type:'line',
              stack: '总量2',
              areaStyle: {
                  normal: {
                  color:'#83C75D',
                      lineStyle:{
                          color:'#83C75D'
                      }
                  }
              },
              data:this.EchartsData.ShowTodayData
          },
          {
              name:'近三天平均值',
              type:'line',
              stack: '总量3',
              areaStyle: {normal: {
                  color:'#F9CC76',
                  lineStyle:{
                      color:'#F9CC76'
                  }
              }},
              data:this.EchartsData.ShowSanRiJunData
          },
          {
              name:'对比数值',
              type:'line',
              stack: '总量4',
              label: {
                  normal: {
                      // show: true,
                      // position: 'top'
                  }
              },
              areaStyle: {normal: {}},
              data:this.EchartsData.ShowDuiBiData
          }
      ]
        })
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YY-MM-DD");
    },
    PanKong(val){
      if (val == "") {
        return "全部"
      }else{
        return val
      }
    }
  },
  created () {
    this.loading = true
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
      this.currentUser.currentName = window.sessionStorage.getItem("name")
      this.currentUser.currentid = window.sessionStorage.getItem("id")
      this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
      this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
      // if (this.currentUser.currentLevel == 0 && this.currentUser.currentcity == "null") {
      //   this.formLabelAlign.city = "all"
      //   //获取所有城市
          this.$axios
            .get(`/department/getAll`)
            .then(res => {
              // console.log(res);
              this.cityselectList = res.data;
              // this.loading = false
            })
            .catch(error => {
              console.log(error);
              // this.loading = false
            });
      // }else{
      //   this.formLabelAlign.city = this.currentUser.currentcity
      //   this.getZheXianData()
      // }
      //拿平台
      this.$axios
      .get(`/source/getSourceList?pageSize=10000&currentPage=${this.pageConfig.currentPage}&hasValue="ABC"`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
            // this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total
            this.PingTaiList = res.data.dataModel.list
            // this.loading = false
        }else{
            this.$message.error(res.data.messageModel.messageText);
            // this.tableData = "";
            this.pageConfig.total = 0
            // this.loading = false
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.getZheXianData()
  },
  mounted(){
    this.$nextTick(function() {
        this.drawPie('main')
    })
  },
  //抽取到vuex中试一试
  computed:{},
};
</script>

<style  lang='scss'>
.PromotionAnalysis-container {
    //echarts的样式
  * {
        // margin: 0;
        // padding: 0;
        list-style: none;
    }

  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}
//突出隔行的颜色差异
.el-table {
  font-size: 12px;
  .cell {
    padding-left: 1px;
    padding-right: 1px;
    text-align: center;
  }
  th {
    padding: 0;
    // border: .5px solid #000;
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important;
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
