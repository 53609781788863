<template>
  <div class="teamCus-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form
        label-width="82px"
        label-position="right"
        ref="formLabelAlign"
        :model="formLabelAlign"
      >
        <el-form-item prop="keywords" label="客户" id="cust">
          <el-input
            v-model="formLabelAlign.keywords"
            placeholder="姓名/ID/手机号均可"
          ></el-input>
        </el-form-item>
        <el-form-item prop="level" label="星级">
          <el-select v-model="formLabelAlign.level" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="0星" value="0"></el-option>
            <el-option label="1星" value="1"></el-option>
            <el-option label="2星" value="2"></el-option>
            <el-option label="2星+" value="3"></el-option>
            <el-option label="3星" value="4"></el-option>
            <el-option label="4星" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="state" label="状态">
          <el-select v-model="formLabelAlign.state" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="已受理" value="已受理"></el-option>
            <el-option label="未接通" value="未接通"></el-option>
            <el-option label="待跟进" value="待跟进"></el-option>
            <el-option label="资质不符" value="资质不符"></el-option>
            <el-option label="待签约" value="待签约"></el-option>
            <el-option label="已上门" value="已上门"></el-option>
            <el-option label="B上门" value="B上门"></el-option>
            <el-option label="审核中" value="审核中"></el-option>
            <el-option label="已放款" value="已放款"></el-option>
            <el-option label="已拒批" value="已拒批"></el-option>
            <el-option label="捣乱申请" value="捣乱申请"></el-option>
            <el-option label="重复申请" value="重复申请"></el-option>
            <el-option label="外地申请" value="外地申请"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="content" label="备注">
          <el-input v-model="formLabelAlign.content"></el-input>
        </el-form-item>
        <el-form-item prop="contentCount" label="备注条数">
          <el-input
            type="number"
            v-model="formLabelAlign.contentCount"
          ></el-input>
        </el-form-item>
        <el-form-item prop="weiLianXiDays" label="未联系天数">
          <el-input
            v-model="formLabelAlign.weiLianXiDays"
            type="number"
            placeholder="仅支持阿拉伯数字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="scopeOf" label="包含再分配">
          <el-select v-model="formLabelAlign.zaiFenPei" placeholder>
            <el-option label="否" value="true"></el-option>
            <el-option label="是" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="scopeOf" label="查询范围">
          <el-select v-model="formLabelAlign.scopeOf" placeholder>
            <el-option label="所有" value="all"></el-option>
            <el-option label="客户池" value="kehu"></el-option>
            <el-option label="公共池" value="pool"></el-option>
            <el-option label="其他" value="other" v-if="ShowOther"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="otherScopeOf" label="其他条件">
          <el-select
            v-model="formLabelAlign.otherScopeOf"
            id="otherThing"
            placeholder
          >
            <el-option label="所有" value=""></el-option>
            <el-option
              label="城市"
              value="city"
              v-if="showCityOption"
            ></el-option>
            <el-option label="分公司" value="fenGS"></el-option>
            <el-option label="区域" value="quYu"></el-option>
            <el-option label="部门" value="buMen"></el-option>
            <el-option label="顾问" value="user"></el-option>
          </el-select>
          <el-input
            v-model="formLabelAlign.otherName"
            class="yaosile"
            id="addThing"
          ></el-input>
        </el-form-item>

        <el-form-item label="平台" v-show="pingTaiShow">
          <el-select v-model="formLabelAlign.sourceName2" placeholder="平台">
            <el-option label="所有" value=""></el-option>
            <el-option
              v-for="item in pingTaiList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="timeType" label="时间">
          <el-select
            v-model="formLabelAlign.timeType"
            @change="hiddenTimeKind"
            placeholder="分类"
          >
            <el-option
              v-for="(item, index) in kindtimeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="formLabelAlign.dayType"
            id="time"
            @change="holderQuJian"
            placeholder="时间"
            :disabled="this.showTimeKind"
          >
            <el-option
              v-for="(item, index) in timeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-button
          style="background-color: #c73e3a; color: #fff; width: 130px"
          class="cusBth"
          @click="checkCus"
          :disabled="disTBN"
          >查询</el-button
        >
        <el-button
          style="background-color: #008c5e; color: #fff"
          @click="resetForm('formLabelAlign')"
          class="cusBth"
          >重置筛选条件</el-button
        >
        <el-button
          style="background-color: #3a8ac7; color: #fff"
          @click="dilogTask"
          class="cusBth"
          :disabled="disTBN"
          >设置分配任务
        </el-button>
        <!-- <el-button style="background-color:#25c58b;color:#fff" class="cusBth" >加入公共池</el-button> -->
      </el-form>
      <div id="unread">
        <span class="unread"
          >待处理客户提醒：您的团队有<span
            class="unreadNuM"
            @click="enterDetail('level0')"
            >{{ this.daiGenJinNum.level0Count }}</span
          >位0星客户超过6h未联系；<span
            class="unreadNuM"
            @click="enterDetail('level2')"
            >{{ this.daiGenJinNum.level2count }}</span
          >位2星及2星+客户超过6天未联系；<span
            class="unreadNuM"
            @click="enterDetail('level3')"
            >{{ this.daiGenJinNum.level3count }}</span
          >位3星及4星客户超过4天未联系
          <!--          ,若不及时处理将被加入公共池-->
        </span>
      </div>
      <div id="bigBox" style="display: flex">
        <span id="give">分配给:</span>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div class="duoxuankuang" style="display: inline">
          <el-checkbox-group
            v-model="checkedGuWens"
            @change="handleCheckedGuWenChange"
          >
            <el-checkbox
              v-for="(dange, index) in guwenList"
              :label="dange"
              :key="index"
              >{{ dange.name }}</el-checkbox
            >
            <!--            v-for="(item,index) in scope.row.noteList" :key='index' v-bind:style="{'color': item.otherUser == 1 ? 'red':'#606266'}">&nbsp;<span v-bind:style="{'color': item.otherUser == 1 ? '#898989':'#898989'}</span>-->
          </el-checkbox-group>
        </div>
        <el-button
          style="background-color: #008c5e; color: #fff"
          class="fenPei"
          :disabled="disTBN"
          @click="beforeFenPei"
          >分 &nbsp; 配</el-button
        >
        <el-button
          style="
            background-color: #dfa018;
            color: #fff;
            white-space: normal;
            width: 10rem;
          "
          class="AddGGC"
          @click="addGGC"
          :disabled="disTBN"
          >加入公共池</el-button
        >
      </div>
    </el-row>
    <!-- table表格部分 -->
    <!-- <el-table :data="tableData" @selection-change="changeCus" :row-class-name="tableRowClassName" style="width: 100%" stripe> -->
    <el-table
      :data="tableData"
      @selection-change="changeCus"
      style="width: 100%"
      v-loading="loading"
      stripe
    >
      <el-table-column type="selection" width="35"></el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column label="姓名" width="70" align="center">
        <template slot-scope="scope"
          ><a size="mini" @click="handleEdit(scope.$index, scope.row)"
              v-bind:class="{ 'newData':scope.row.noteList.length <= 0  }">{{
            scope.row.name
          }}</a></template
        >
      </el-table-column>
      <!-- <el-table-column prop="phone" label="电话" width="50" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row.phone }}</div>
            <span>显示</span>
          </el-tooltip>
        </template>
      </el-table-column> -->
      <el-table-column prop="phone" label="电话" width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
          <i
            @click="tel(scope.row)"
            class="el-icon-phone"
            style="font-size: 18px; color: #3390ff; cursor: pointer"
          ></i>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="70" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '资质不符'" style="color: #c73e3a">{{
            scope.row.state
          }}</span>
          <span
            v-else-if="
              scope.row.state == 'B上门' ||
              scope.row.state == '已上门' ||
              scope.row.state == '待签约' ||
              scope.row.state == '已放款' ||
              scope.row.state == '审核中'
            "
            style="color: #5bbd2b"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '已受理' ||
              scope.row.state == '未接通' ||
              scope.row.state == '待跟进'
            "
            style="color: #3390ff"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '捣乱申请' ||
              scope.row.state == '外地申请' ||
              scope.row.state == '已拒批' ||
              scope.row.state == '重复申请'
            "
            style="color: #d7962f"
            >{{ scope.row.state }}</span
          >
          <span v-else style="color: grey">{{ scope.row.state }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="星级" width="50" align="center">
        <template slot-scope="scope">{{
          scope.row.level | levelKind
        }}</template>
      </el-table-column>
      <el-table-column label="备注" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.noteList" :key="index">
            <p
              class="ell"
              v-if="index < 3"
              v-bind:style="{ color: item.otherUser == 1 ? 'red' : '#606266' }"
            >
              <span
                v-bind:style="{
                  color: item.otherUser == 1 ? '#898989' : '#898989',
                }"
                >{{ ++index }}--{{ item.createDate | formatTime }}--{{
                  item.userName
                }}</span
              >--{{ item.content }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="资质" width="200" align="center">
        <template slot-scope="scope"
          ><span>{{ scope.row.remarks | remarksPanKong }}</span></template
        >
      </el-table-column>
      <el-table-column
        prop="userName"
        label="所属顾问"
        width="68"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="unfollowDays"
        label="未跟进天数"
        width="80"
        align="center"
      >
        <template slot-scope="{ row }">{{
          row.unfollowDays === null ? "未曾跟进" : row.unfollowDays
        }}</template>
      </el-table-column>
      <el-table-column
        prop="sourceName3"
        label="来源"
        width="68"
        align="center"
      ></el-table-column>
      <el-table-column label="城市详情" width="120" v-if="ShowOther">
        <template slot-scope="scope">
          <p class="ell">客填：{{ scope.row.originalCity }}</p>
          <p class="ell">手机：{{ scope.row.addressPhone }}</p>
          <p class="ell">IP所在：{{ scope.row.addressByIp }}</p>
          <p class="ell">IP：{{ scope.row.addressIp }}</p>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="140" align="center" sortable>
        <template slot-scope="scope">{{
          scope.row.distributionTime | formatTime
        }}</template>
      </el-table-column>
      <el-table-column prop="level" label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="handleEdits(scope.$index, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Details
      :dialogVisible="dialogVisible"
      @handleClose="dialogVisible = false"
    />
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageConfig.currentPage"
      :page-sizes="[10, 20, 50, 100, 200]"
      :page-size="pageConfig.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageConfig.total"
    >
    </el-pagination>
    <!-- 分配弹出框 -->
    <el-dialog
      title="确认分配"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      width="30%"
      center
    >
      <span
        >确认将选中的{{ this.customerId.length }}名客户分配给{{
          this.userNameList
        }}？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="fenPei">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="分配任务设置"
      :visible.sync="dialogTaskVisible"
      :before-close="handleDialogTaskClose"
      append-to-body
      width="90%"
      center
    >
      <template>
        <div class="teamCus-container">
          <!-- 栅格 输入框 和按钮 -->
          <el-row class="filterCondition">
            <el-form
              label-width="82px"
              label-position="right"
              ref="formLabelAlign"
              :model="formLabelAlign"
            >
              <el-form-item prop="num" label="客户数量" id="cust">
                <el-input
                  v-model="formLabelAlign.num"
                  placeholder="客户数量"
                ></el-input>
              </el-form-item>
              <el-form-item prop="level" label="星级">
                <el-select v-model="formLabelAlign.level" placeholder>
                  <el-option label="所有" value=""></el-option>
                  <el-option label="0星" value="0"></el-option>
                  <el-option label="1星" value="1"></el-option>
                  <el-option label="2星" value="2"></el-option>
                  <el-option label="2星+" value="3"></el-option>
                  <el-option label="3星" value="4"></el-option>
                  <el-option label="4星" value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="state" label="状态">
                <el-select v-model="formLabelAlign.state" placeholder>
                  <el-option label="所有" value=""></el-option>
                  <el-option label="已受理" value="已受理"></el-option>
                  <el-option label="未接通" value="未接通"></el-option>
                  <el-option label="待跟进" value="待跟进"></el-option>
                  <el-option label="资质不符" value="资质不符"></el-option>
                  <el-option label="待签约" value="待签约"></el-option>
                  <el-option label="已上门" value="已上门"></el-option>
                  <el-option label="B上门" value="B上门"></el-option>
                  <el-option label="审核中" value="审核中"></el-option>
                  <el-option label="已放款" value="已放款"></el-option>
                  <el-option label="已拒批" value="已拒批"></el-option>
                  <el-option label="捣乱申请" value="捣乱申请"></el-option>
                  <el-option label="重复申请" value="重复申请"></el-option>
                  <el-option label="外地申请" value="外地申请"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="content" label="备注">
                <el-input
                  v-model="formLabelAlign.content"
                  size="mini"
                ></el-input>
              </el-form-item>
              <el-form-item prop="contentCount" label="备注条数">
                <el-input
                  type="number"
                  v-model="formLabelAlign.contentCount"
                ></el-input>
              </el-form-item>
              <el-form-item prop="weiLianXiDays" label="未联系天数">
                <el-input
                  v-model="formLabelAlign.weiLianXiDays"
                  type="number"
                  placeholder="仅支持阿拉伯数字"
                ></el-input>
              </el-form-item>
              <el-form-item prop="scopeOf" label="包含再分配">
                <el-select v-model="formLabelAlign.zaiFenPei" placeholder>
                  <el-option label="否" value="true"></el-option>
                  <el-option label="是" value=""></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="scopeOf" label="查询范围">
                <el-select v-model="formLabelAlign.scopeOf" placeholder>
                  <el-option label="所有" value="all"></el-option>
                  <el-option label="客户池" value="kehu"></el-option>
                  <el-option label="公共池" value="pool"></el-option>
                  <el-option
                    label="其他"
                    value="other"
                    v-if="ShowOther"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="otherScopeOf" label="其他条件">
                <el-select
                  v-model="formLabelAlign.otherScopeOf"
                  id="otherThing"
                  placeholder
                >
                  <el-option label="所有" value=""></el-option>
                  <el-option
                    label="城市"
                    value="city"
                    v-if="showCityOption"
                  ></el-option>
                  <el-option label="分公司" value="fenGS"></el-option>
                  <el-option label="区域" value="quYu"></el-option>
                  <el-option label="部门" value="buMen"></el-option>
                  <el-option label="顾问" value="user"></el-option>
                </el-select>
                <el-input
                  v-model="formLabelAlign.otherName"
                  class="yaosile"
                  id="addThing"
                ></el-input>
              </el-form-item>
              <el-form-item prop="timeType" label="时间">
                <el-select
                  v-model="formLabelAlign.timeType"
                  @change="hiddenTimeKind"
                  placeholder="分类"
                >
                  <el-option
                    v-for="(item, index) in kindtimeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="formLabelAlign.dayType"
                  id="time"
                  @change="holderQuJian"
                  placeholder="时间"
                  :disabled="this.showTimeKind"
                >
                  <el-option
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="value1" label="时间区间" v-show="isShow">
                <div class="block">
                  <el-date-picker
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </div>
              </el-form-item>
              <el-button
                type="warning"
                class="cusBth"
                @click="changBigBoxIsShow"
                >选择顾问</el-button
              >
              <el-button
                style="background-color: #008c5e; color: #fff"
                class="cusBth"
                :disabled="disTBN"
                @click="beforeAddTask"
                >添加分配任务</el-button
              >
            </el-form>
            <!-- <span id="give">分配给:</span> -->
            <div id="bigBox" style="display: flex" v-show="bigBoxIsShow">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <div class="duoxuankuang" style="display: inline">
                <el-checkbox-group
                  v-model="checkedGuWens"
                  @change="handleCheckedGuWenChange"
                  size="mini"
                >
                  <el-checkbox-button
                    v-for="(dange, index) in guwenList"
                    :label="dange"
                    :key="index"
                    >{{ dange.name }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-row>
          <!-- table表格部分 -->
          <!-- <el-table :data="tableData" @selection-change="changeCus" style="width: 100%" v-loading="loading" stripe> -->
          <el-table :data="taskData" style="width: 100%">
            <!-- <el-table-column type="selection" width="35"></el-table-column> -->
            <el-table-column
              prop="id"
              label="ID"
              width="80"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="status"
              label="任务状态"
              width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0" style="color: #c73e3a"
                  >未开始</span
                >
                <span v-else-if="scope.row.status == 1" style="color: #5bbd2b"
                  >执行中</span
                >
                <span v-else-if="scope.row.status == 2" style="color: #3390ff"
                  >执行完毕</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="level"
              label="星级"
              width="50"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.level == '0'">0星</span>
                <span v-if="scope.row.level == '1'">1星</span>
                <span v-if="scope.row.level == '2'">2星</span>
                <span v-if="scope.row.level == '3'">2星+</span>
                <span v-if="scope.row.level == '4'">3星</span>
                <span v-if="scope.row.level == '5'">4星</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="scopeOf"
              label="查询范围"
              width="50"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.scopeOf == 'all'">所有</span>
                <span v-if="scope.row.scopeOf == 'kehu'">客户池</span>
                <span v-if="scope.row.scopeOf == 'pool'">公共池</span>
                <span v-if="scope.row.scopeOf == 'other'">其他</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="otherScopeOf"
              label="其他条件"
              width="50"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.otherScopeOf == ''">所有</span>
                <span v-if="scope.row.otherScopeOf == 'city'">城市</span>
                <span v-if="scope.row.otherScopeOf == 'fenGS'">分公司</span>
                <span v-if="scope.row.otherScopeOf == 'quYu'">其他</span>
                <span v-if="scope.row.otherScopeOf == 'buMen'">部门</span>
                <span v-if="scope.row.otherScopeOf == 'user'">顾问</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="otherName"
              label="其他条件名称"
              width="70"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="timeType"
              label="时间类别"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.timeType == 'beizhuTime'">备注时间</span>
                <span v-if="scope.row.timeType == 'fenpeiTime'">分配时间</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="dayType"
              label="天数类别"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.dayType == 'today'">今日</span>
                <span v-if="scope.row.dayType == 'yestoday'">昨日</span>
                <span v-if="scope.row.dayType == 'sevenDay'">最近七天</span>
                <span v-if="scope.row.dayType == 'tomonth'">本月</span>
                <span v-if="scope.row.dayType == 'yesmonth'">上月</span>
                <span v-if="scope.row.dayType == 'customize'">自定义</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="beginTime"
              label="开始时间"
              width="70"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="endTime"
              label="结束时间"
              width="70"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="keywords"
              label="指定用户关键字"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="zaiFenPei"
              label="是否包含再分配"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="weiLianXiDays"
              label="未联系天数"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="targetUser"
              label="分配目标顾问"
              width="133"
              align="center"
            ></el-table-column>
            <el-table-column label="任务创建时间" width="90" align="center">
              <template slot-scope="scope">{{
                scope.row.taskCreateTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column label="任务执行时间" width="90" align="center">
              <template slot-scope="scope">{{
                scope.row.taskStartTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column label="任务结束时间" width="90" align="center">
              <template slot-scope="scope">{{
                scope.row.taskEndTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100%"
              fixed="right"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 0 || scope.row.status == 2"
                  @click="dialogDelTask(scope.row.id)"
                  type="text"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <!-- 分配弹出框 -->
          <el-dialog
            title="确认添加任务"
            :visible.sync="centerTaskVisible"
            append-to-body
            width="30%"
            center
          >
            <span>确认将已勾选的条件作为分配任务？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="centerTaskVisible = false">取 消</el-button>
              <el-button type="primary" @click="addTask">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 删除任务 -->
          <el-dialog
            title="确认删除任务"
            :visible.sync="centerDelTaskVisible"
            append-to-body
            width="30%"
            center
          >
            <span>确认删除该任务？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="centerDelTaskVisible = false">取 消</el-button>
              <el-button type="primary" @click="deleteTask">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Details from "./details.vue";
import { mapMutations } from "vuex";
export default {
  name: "teamCus",
  components: {
    Details,
  },
  // 数据
  data() {
    return {
      other: "",
      ShowOther: false,
      checkAll: false,
      isIndeterminate: false,
      centerDialogVisible: false,
      dialogTaskVisible: false,
      centerTaskVisible: false,
      centerDelTaskVisible: false,
      currentUser: {
        currentName: "",
        currentId: "",
        currentLevel: "",
        currentPhone: "",
      },
      showCityOption: false,
      disTBN: false,
      loading: true,
      checkedGuWens: [],
      userId: [], //被选中顾问id的数组
      userNameList: [], //被选中顾问姓名的数组
      customerId: [], //被选中客户id的数组
      guwenList: [], //顾问列表
      pingTaiList: [], //平台列表
      showTimeKind: true,
      //时间选择生成内容
      kindtimeList: [
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
      ],
      timeList: [
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      daiGenJinNum: {
        level0Count: 0,
        level2count: 0,
        level3count: 0,
      },
      //时间选择框数据
      value1: "",
      //时间区间隐藏状态
      isShow: false,
      //平台条件隐藏状态
      pingTaiShow: false,
      //页码参数
      pageConfig: {
        currentPage: 1, //当前页
        pageSize: 10, //当前单页数据条数
        total: 0, //请求到的数据总条数
      },
      //客户筛选区域数据
      formLabelAlign: {
        keywords: "",
        level: "",
        userName: "",
        state: "",
        noteList: [],
        scopeOf: "kehu", //范围
        otherScopeOf: "", //范围
        otherName: "", //范围值
        content: "",
        contentCount: "",
        zaiFenPei: "",
        timeType: "",
        kinds: "",
        weiLianXiDays: "",
        distributionTime: "",
        dayType: "",
        beginTime: "",
        endTime: "",
        num: "",
        sourceName2: "",
      },
      tableData: [],
      taskData: [],
      targetUsers: "",
      taskId: "",
      bigBoxIsShow: false,
      dialogVisible: false,
    };
  },
  methods: {
    tel(row) {
      let obj = {
        phone: row.phone,
      };
      this.$socket().send(JSON.stringify(obj));
    },
    //表格各行变色的方法
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    handleCheckAllChange(val) {
      this.checkedGuWens = val ? this.guwenList : [];
      if (this.checkedGuWens != val) {
        this.guwenList.forEach((item) => {
          this.userId.push(item.id);
          this.userNameList.push(item.name);
        });
      } else {
        this.checkAll = false;
        this.userId = [];
      }
      this.isIndeterminate = false;
    },
    //获取待处理客户提醒
    getDaiChuLiNum() {
      this.$axios
        .get(`/customer/getDCL?userId=${this.currentUser.currentid}&type=team`)
        .then((res) => {
          this.daiGenJinNum = res.data.dataModel;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取选中顾问
    handleCheckedGuWenChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.guwenList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.guwenList.length;
      this.userId = [];
      for (let i = 0; i < value.length; i++) {
        this.userId.push(value[i].id);
      }
      this.userNameList = [];
      for (let i = 0; i < value.length; i++) {
        this.userNameList.push(value[i].name);
      }
    },
    //获取平台的下拉列表
    getPingTaiList() {
      this.$axios
        .get(`/source/getSourceByType?type=2`)
        .then((res) => {
          // console.log(res);
          this.pingTaiList = res.data.dataModel;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取客户 List的方法
    getCusList() {
      this.loading = true;
      this.$axios
        .post(
          `/customer/getCustomerTeamList?userId=${this.currentUser.currentid}&other=${this.other}&zaiFenPei=${this.formLabelAlign.zaiFenPei}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&keywords=${this.formLabelAlign.keywords}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&scopeOf=${this.formLabelAlign.scopeOf}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&sourceName2=${this.formLabelAlign.sourceName2}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.loading = false;
            if (res.data.dataModel.total == 0) {
              this.$message("抱歉，没有找到符合搜索条件的客户");
              this.tableData = res.data.dataModel.list;
              this.pageConfig.total = res.data.dataModel.total;
              return false;
            }
            this.$message.success("已为您找到以下客户(#^.^#)");
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
          } else {
            this.loading = false;
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //重置查询条件
    resetForm(formName) {
      for (let key in this.formLabelAlign) {
        this.formLabelAlign[key] = "";
      }
      this.formLabelAlign.scopeOf = "kehu";
    },
    //分页功能
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getCusList();
      } else {
        this.pageConfig.pageSize = val;
        this.getCusList();
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getCusList();
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId) {
      let obj = {};
      obj = this.kindtimeList.find((item) => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true;
      } else {
        this.showTimeKind = false;
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId) {
      let obj = {};
      obj = this.timeList.find((item) => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.name == "自定义") {
        this.isShow = true;
        this.formLabelAlign.dayType = "customize";
      } else if (obj.name == "昨日") {
        this.isShow = false;
        this.formLabelAlign.dayType = "yestoday";
      } else if (obj.name == "最近七天") {
        this.isShow = false;
        this.formLabelAlign.dayType = "sevenDay";
      } else if (obj.name == "本月") {
        this.isShow = false;
        this.formLabelAlign.dayType = "tomonth";
      } else if (obj.name == "上月") {
        this.isShow = false;
        this.formLabelAlign.dayType = "yesmonth";
      } else if (obj.name == "今日") {
        this.isShow = false;
        this.formLabelAlign.dayType = "today";
      } else {
        this.isShow = false;
      }
    },
    //将按钮禁用两秒
    disButton() {
      this.disTBN = true;
      setTimeout(() => {
        this.disTBN = false;
      }, 2000);
    },
    //按首字母排序的方法
    sortGuWenList(arr, sortStr) {
      // 排序函数（用以返回次序关系）
      var bySort = function () {
        return function (o, p) {
          // p 是 o 的下一项
          var a = o[sortStr],
            b = p[sortStr];
          if (isNaN(a)) {
            // 非数字排序
            return a.localeCompare(b); // 用本地特定顺序来比较(支持中文)
          } else {
            if (a === b) {
              return 0;
            } else {
              return a > b ? 1 : -1;
            }
          }
        };
      };
      for (var i = 0; i < arr.length; i++) {
        //console.log(arr[i][sortStr])
        arr.sort(bySort(arr[i][sortStr]));
      }
    },
    //拿到所有可支配的顾问列表
    getGuWenList() {
      this.$axios
        .get(`/user/getUserTeam?userId=${this.currentUser.currentid}`)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            if (sessionStorage.getItem("companyPosition") == "部长") {
              this.guwenList = [
                {
                  name: sessionStorage.getItem("name"),
                  id: sessionStorage.getItem("id"),
                },
                ...res.data.dataModel,
              ];
            } else {
              this.guwenList = res.data.dataModel;
            }
            this.sortGuWenList(this.guwenList, "name");
          } else {
            this.$message.error(
              "没能成功获取您麾下的顾问，请稍后再试/(ㄒoㄒ)/~~"
            );
            // this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //查询
    checkCus() {
      this.disButton();
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = moment(this.value1[0]).format(
          "YYYY-MM-DD"
        );
        this.formLabelAlign.endTime = moment(this.value1[1]).format(
          "YYYY-MM-DD"
        );
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime =
            this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime =
            this.formLabelAlign.endTime + " " + "23:59:59";
        }
      } else {
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      if (this.formLabelAlign.scopeOf == "other") {
        this.other = "true";
      } else {
        this.other = "";
      }
      this.pageConfig.currentPage = 1;
      // this.pageConfig.pageSize = 10
      this.getCusList();
    },
    //获取选中客户并返回数组
    changeCus(val) {
      this.customerId = [];
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.customerId.push(val[i].id);
      }
    },
    // 获取未处理的数据数量
    getUnread() {
      this.$axios
        .get(`/customer/getCusCount?userId=${this.currentUser.currentid}`)
        .then((res) => {
          if (res.data.messageModel.code != 0) {
            this.$message.error("获取未处理数据失败/(ㄒoㄒ)/~~");
          } else {
            this.$store.commit("getUnread", res.data.dataModel);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    //分配前
    beforeFenPei() {
      if (this.userId.length == 0) {
        this.$message.error("请至少选择一个顾问进行分配");
        return false;
      } else if (this.customerId.length == 0) {
        this.$message.error("您还没有选择被分配的客户");
        return false;
      } else {
        this.centerDialogVisible = true;
      }
    },
    //控制顾问多选框是否显示
    changBigBoxIsShow() {
      if (this.bigBoxIsShow) {
        this.bigBoxIsShow = false;
      } else {
        this.bigBoxIsShow = true;
      }
    },
    //添加任务前检查
    beforeAddTask() {
      if (this.userId.length == 0) {
        this.$message.error("请至少选择一个顾问进行分配");
        return false;
      } else if (
        this.formLabelAlign.num == "" ||
        this.formLabelAlign.num == null ||
        this.formLabelAlign.num == 0
      ) {
        this.$message.error("客户数量不能0，请填选需要分配的客户数量");
        return false;
      } else {
        this.centerTaskVisible = true;
      }
    },

    //弹出任务框之前 清空表单重新选择，并查询当前用户下的所有任务
    dilogTask() {
      //清空checkedGuWens[] 和 formLabelAlign
      this.checkedGuWens = [];
      this.checkAll = false;
      this.resetForm("formLabelAlign");
      this.userId = [];
      this.dialogTaskVisible = true;
      //拿到当前用户创建的所有任务列表
      this.$axios
        .get(
          `/distributionTask/selectList?userId=${this.currentUser.currentid}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 200) {
            this.loading = false;
            this.$message.success("已为您找到以下任务(#^.^#)");
            this.taskData = res.data.dataModel;
          } else {
            this.$message.error("当前暂无任务/(ㄒoㄒ)/~~");
            this.loading = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    handleDialogTaskClose() {
      //清空checkedGuWens[] 和 formLabelAlign
      this.checkAll = false;
      this.dialogTaskVisible = false;
      this.checkedGuWens = [];
      this.userId = [];
      this.resetForm("formLabelAlign");
      this.bigBoxIsShow = false;
    },
    //添加分配任务
    addTask() {
      this.centerTaskVisible = false;
      this.loading = true;
      this.targetUsers = this.userId.join(",");
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = moment(this.value1[0]).format(
          "YYYY-MM-DD"
        );
        this.formLabelAlign.endTime = moment(this.value1[1]).format(
          "YYYY-MM-DD"
        );
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime =
            this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime =
            this.formLabelAlign.endTime + " " + "23:59:59";
        }
      } else {
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      if (this.formLabelAlign.scopeOf == "other") {
        this.other = "true";
      } else {
        this.other = "";
      }
      this.$axios
        .get(
          `/distributionTask/insert?userId=${this.currentUser.currentid}&targetUser= ${this.targetUsers}&scopeOf=${this.formLabelAlign.scopeOf}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&num=${this.formLabelAlign.num}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&other=${this.other}&zaiFenPei=${this.formLabelAlign.zaiFenPei}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 200) {
            this.$message({ message: "添加成功", type: "success" });
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.centerTaskVisible = false;
          }

          //执行成功 刷新表格
          this.$axios
            .get(
              `/distributionTask/selectList?userId=${this.currentUser.currentid}`
            )
            .then((res) => {
              if (res.data.messageModel.code == 200) {
                this.loading = false;
                this.$message.success("已为您找到以下任务(#^.^#)");
                this.taskData = res.data.dataModel;
              } else {
                this.$message.error("当前暂无任务/(ㄒoㄒ)/~~");
                this.taskData = [];
                this.loading = false;
              }
            })
            .catch((error) => {
              // console.log(error);
            });
          this.centerTaskVisible = false;
          // this.disTBN = false;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    dialogDelTask(val) {
      this.taskId = val;
      this.centerDelTaskVisible = true;
    },
    //删除分配任务val
    deleteTask() {
      this.centerDelTaskVisible = false;
      this.loading = true;
      this.$axios
        .get(`/distributionTask/deleteById?id=${this.taskId}`)
        .then((res) => {
          if (res.data.messageModel.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
          //执行成功 刷新表格
          this.$axios
            .get(
              `/distributionTask/selectList?userId=${this.currentUser.currentid}`
            )
            .then((res) => {
              if (res.data.messageModel.code == 200) {
                this.loading = false;
                this.$message.success("已为您找到以下任务(#^.^#)");
                this.taskData = res.data.dataModel;
              } else {
                this.$message.error("当前暂无任务/(ㄒoㄒ)/~~");
                this.taskData = [];
                this.loading = false;
              }
            })
            .catch((error) => {
              // console.log(error);
            });

          // this.disTBN = false;
        })
        .catch((error) => {
          // console.log(error);
        });
      this.centerDelTaskVisible = false;
    },
    //分配
    fenPei() {
      // this.disButton()
      //！！！！！！！！！注意！！！！！！！！！！后端规定该请求userId为数组而非当前登陆用户的Id
      // console.log(this.userId);
      // this.disTBN = true;
      this.centerDialogVisible = false;
      this.loading = true;
      this.$axios
        .get(
          `/customer/hangFenPei?operationId=${this.currentUser.currentid}&userId=${this.userId}&customerId=${this.customerId}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({ message: "分配成功", type: "success" });
            // this.$router.go(0)
            // this.loading = false;
            // window.location.reload();
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.centerDialogVisible = false;
          }
          this.$axios
            .post(
              `/customer/getCustomerTeamList?userId=${this.currentUser.currentid}&other=${this.other}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&keywords=${this.formLabelAlign.keywords}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&scopeOf=${this.formLabelAlign.scopeOf}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}`
            )
            .then((res) => {
              // console.log(res);
              if (res.data.messageModel.code == 0) {
                this.loading = false;
                if (res.data.dataModel.total == 0) {
                  this.$message("给予当前搜索条件的客户，已全部被您分配");
                  this.tableData = res.data.dataModel.list;
                  this.pageConfig.total = res.data.dataModel.total;
                  return false;
                }
                // this.$message.success("已为您找到以下客户(#^.^#)");
                this.tableData = res.data.dataModel.list;
                this.pageConfig.total = res.data.dataModel.total;
              } else {
                this.loading = false;
                this.$message.error(res.data.messageModel.messageText);
              }
            })
            .catch((error) => {
              // console.log(error);
            });
          this.getUnread();
          this.centerDialogVisible = false;
          // this.disTBN = false;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    //加入公共池
    addGGC() {
      if (this.customerId.length == 0) {
        this.$message.error("您还没有选择需要加入公共池的客户");
        return false;
      }
      this.$axios
        .post(
          `/customer/addToPool?operationId=${this.currentUser.currentid}&customerId=${this.customerId}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.$message({
              message: "已将选中的客户成功添加到公共池",
              type: "success",
            });
          } else {
            this.$message.error(
              res.data.messageModel.messageText + "/(ㄒoㄒ)/~~"
            );
          }
          this.$axios
            .post(
              `/customer/getCustomerTeamList?userId=${this.currentUser.currentid}&other=${this.other}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&keywords=${this.formLabelAlign.keywords}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&scopeOf=${this.formLabelAlign.scopeOf}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}`
            )
            .then((res) => {
              // console.log(res);
              if (res.data.messageModel.code == 0) {
                this.loading = false;
                if (res.data.dataModel.total == 0) {
                  this.$message("给予当前搜索条件的客户，已全部被您分配");
                  this.tableData = res.data.dataModel.list;
                  this.pageConfig.total = res.data.dataModel.total;
                  return false;
                }
                // this.$message.success("已为您找到以下客户(#^.^#)");
                this.tableData = res.data.dataModel.list;
                this.pageConfig.total = res.data.dataModel.total;
              } else {
                this.loading = false;
                this.$message.error(res.data.messageModel.messageText);
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    //编辑
    handleEdit(index, row) {
      // console.log(index, row);
      let CusId = row.id;
      // console.log(CusId);
      // 编程式导航
      this.$router.push({ path: "/myCusEdit", query: { CusId: CusId } });
      window.sessionStorage.setItem("CusId", CusId);
    },
    handleEdits(index, row) {
      // console.log(index, row);
      let CusId = row.id;
      // console.log(CusId);
      // 编程式导航
      window.sessionStorage.setItem("CusId", CusId);
      this.dialogVisible = true;
    },
    //进入未处理详情页
    enterDetail(val) {
      // 编程式导航
      this.$router.push({
        path: "/UnhandleT",
        query: { teamSearchLevel: val },
      });
      window.sessionStorage.setItem("teamSearchLevel", val);
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return "";
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
      // return moment(value).format("YYYY-MM-DD");
    },
    remarksPanKong(value) {
      if (value == "null") {
        return "";
      }
      return value;
    },
    //判断客户星级
    levelKind(val) {
      if (val == 0) {
        return "0星";
      } else if (val == "1") {
        return "1星";
      } else if (val == "2") {
        return "2星";
      } else if (val == "3 ") {
        return "2星+";
      } else if (val == "4") {
        return "3星";
      } else if (val == "5") {
        return "4星";
      } else {
        return "未设置星级";
      }
    },
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel");
    console.info(this.currentUser.currentName, this.currentUser.currentLevel);
    if (this.currentUser.currentLevel == 0) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 1) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 2) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 3) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 4) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else {
      this.ShowOther = false;
      this.showCityOption = false;
      this.pingTaiShow = false;
    }
    //应该先拿当前用户的ID后 再发请求
    this.$axios
      .get(
        `/customer/getCustomerTeamList?userId=${this.currentUser.currentid}&pageSize=10&currentPage=1`
      )
      .then((res) => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.loading = false;
          if (res.data.dataModel.total == 0) {
            this.$message("抱歉，没有找到符合搜索条件的客户");
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
            return false;
          }
          this.$message.success("已为您找到以下客户(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        } else {
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
          this.loading = false;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    //请求平台可选
    this.getPingTaiList();
    //拿到所有可支配的顾问列表
    this.getGuWenList();

    // this.$axios
    //   .get(`/user/getUserTeam?userId=${this.currentUser.currentid}`)
    //   .then((res) => {
    //     // console.log(res);
    //     if (res.data.messageModel.code == 0) {
    //       this.guwenList = res.data.dataModel;
    //     } else {
    //       this.$message.error(
    //         "没能成功获取您麾下的顾问，请稍后再试/(ㄒoㄒ)/~~"
    //       );
    //       // this.$message.error(res.data.messageModel.messageText);
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
    this.getDaiChuLiNum();
    //注册键盘事件
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key === 13) {
        this.methods.checkCus();
      }
    };
  },
  activated() {
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel");
    console.info(this.currentUser.currentName, this.currentUser.currentLevel);
    if (this.currentUser.currentLevel == 0) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 1) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 2) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 3) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else if (this.currentUser.currentLevel == 4) {
      this.ShowOther = true;
      this.showCityOption = true;
      this.pingTaiShow = true;
    } else {
      this.ShowOther = false;
      this.showCityOption = false;
      this.pingTaiShow = false;
    }
    this.getCusList();
    this.getGuWenList();
    this.getDaiChuLiNum();
  },
};
</script>

<style lang="scss">
.teamCus-container {
  //解决闪屏的问题
  margin: 0 auto;
  position: relative;
  .el-table {
    font-size: 12px;
    .cell {
      padding-left: 1px;
      padding-right: 1px;
    }
    td {
      //取消单元格的上下padding
      padding: 2px 0;
      border-bottom: 1px solid rgb(211, 205, 205);
      border-right: 1px dashed #d3cdcd;
    }
    th {
      padding: 0;
    }
    th.is-sortable {
      .cell {
        text-align: center;
      }
    }
    .el-checkbox__inner {
      margin-left: 10px;
    }
    .warning-row {
      background: #fff;
    }
    .success-row {
      background: #d3d3d3;
    }
    .cell {
      line-height: 16px;
      .ell {
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        word-break: normal;
        text-indent: -2em;
        margin: 0 0 0 2em;
        line-height: 1.2;
      }

      .el-button--mini {
        border: 0;
        padding: 5px 15px;
        margin: 5px 0;
        background: rgba(255, 255, 255, 0.01);
      }
      .toEdit:hover {
        color: skyblue;
      }
    }
    .el-button--mini {
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255, 255, 255, 0.01);
    }
    a:hover {
      color: aqua;
    }
    .newData {
      color: red;
      :hover {
        color: skyblue;
      }
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
  input.el-input__inner#kinds,
  input.el-input__inner#addThing,
  input.el-input__inner#otherThing,
  /deep/input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
    height: 32px !important;
  }
  .yaosile {
    width: 85px;
  }
  //tit宽度
  .el-form-item__label {
    width: 70px;
  }
  .el-form-item {
    display: inline-block;
    margin-left: 50px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .cusBth {
    margin-left: 30px;
    padding: 8px 20px;
  }
  .fenPei {
    padding: 2px 25px;
  }
  .AddGGC {
    padding: 1px;
    font-size: 12px;
  }
  // 播报文字颜色
  // 播报文字颜色
  #unread {
    margin-top: 10px;
    margin-bottom: 15px;
    .unread {
      font-size: 14px;
      color: #000000;
      margin-left: 22px;
    }
    .unreadNuM {
      font-size: 18px;
      color: #c73e3a;
    }
  }
  //时间框的样式
  .block {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 240px;
    }
  }
  //多选框
  #bigBox {
    margin-bottom: 20px;
    #give {
      width: 100px;
      font-size: 14px;
      margin-left: 20px;
    }
    .el-checkbox {
      margin-right: 8px;
    }
    .duoxuankuang {
      // padding: 10px;
      margin-right: 10px;
      // border: 1px solid #eeeeee;
      border-bottom: 20px;
    }
  }
  //分页框样式设置（避免页面被覆盖）
  .el-pagination .el-input__inner {
    width: 100px;
  }
}
</style>
