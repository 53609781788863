<template>
  <div class="redistribution-container">
    <!--    <my-cus-edit :tableData="tableData" v-show="false"></my-cus-edit>-->
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form
        label-width="82px"
        label-position="right"
        :model="formLabelAlign"
      >
        <el-form-item label=" 客户">
          <el-input
            v-model="formLabelAlign.keywords"
            placeholder="姓名/ID/手机号均可"
          ></el-input>
        </el-form-item>
        <el-form-item label="星级">
          <el-select v-model="formLabelAlign.level" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="0星" value="0"></el-option>
            <el-option label="1星" value="1"></el-option>
            <el-option label="2星" value="2"></el-option>
            <el-option label="2星+" value="3"></el-option>
            <el-option label="3星" value="4"></el-option>
            <el-option label="4星" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formLabelAlign.state" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option label="已受理" value="已受理"></el-option>
            <el-option label="未接通" value="未接通"></el-option>
            <el-option label="待跟进" value="待跟进"></el-option>
            <el-option label="资质不符" value="资质不符"></el-option>
            <el-option label="待签约" value="待签约"></el-option>
            <el-option label="已上门" value="已上门"></el-option>
            <el-option label="B上门" value="B上门"></el-option>
            <el-option label="已拒批" value="已拒批"></el-option>
            <el-option label="审核中" value="审核中"></el-option>
            <el-option label="已放款" value="已放款"></el-option>
            <el-option label="捣乱申请" value="捣乱申请"></el-option>
            <el-option label="重复申请" value="重复申请"></el-option>
            <el-option label="外地申请" value="外地申请"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="formLabelAlign.content"></el-input>
        </el-form-item>
        <el-form-item label="备注条数">
          <el-input
            v-model="formLabelAlign.contentCount"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item prop="weiLianXiDays" label="未联系天数">
          <el-input
            v-model="formLabelAlign.weiLianXiDays"
            type="number"
            placeholder="仅支持阿拉伯数字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="timeType" label="时间">
          <el-select
            v-model="formLabelAlign.timeType"
            @change="hiddenTimeKind"
            placeholder="分类"
          >
            <el-option label="所有" value=""></el-option>
            <el-option
              v-for="item in kindtimeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="formLabelAlign.dayType"
            id="time"
            @change="holderQuJian"
            placeholder="时间"
            :disabled="this.showTimeKind"
          >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间区间" v-show="isShow">
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="group" label="分组">
          <el-select v-model="formLabelAlign.group" placeholder>
            <el-option label="所有" value=""></el-option>
            <el-option
              v-for="item in userGroup.data"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
            <!--                        <el-option label="无" value=""></el-option>-->
          </el-select>
        </el-form-item>
        <el-button
          style="background-color: #c73e3a; color: #fff"
          :disabled="disTBN"
          @click="checkCus"
          class="check"
          >查询</el-button
        >
        <el-button
          style="background-color: #008c5e; color: #fff"
          :disabled="disTBN"
          @click="addGGC"
          class="check"
          >加入公共池</el-button
        >
        <el-button
          style="background-color: #985f0d; color: #fff"
          @click="dialogGroupVisible = true"
          class="check"
          :disabled="disTBN"
          >分组设置</el-button
        >
      </el-form>
      <div id="unread">
        <span class="unread"
          >待处理客户提醒：您有<span
            class="unreadNuM"
            @click="enterDetail('level0')"
            >{{ this.daiGenJinNum.level0Count }}</span
          >位0星客户超过6h未联系；<span
            class="unreadNuM"
            @click="enterDetail('level2')"
            >{{ this.daiGenJinNum.level2count }}</span
          >位2星及2星+客户超过6天未联系；<span
            class="unreadNuM"
            @click="enterDetail('level3')"
            >{{ this.daiGenJinNum.level3count }}</span
          >位3星及4星客户超过4天未联系啦,若不及时处理将被加入公共池</span
        >
      </div>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table
      @selection-change="changeCus"
      :data="tableData"
      v-loading="loading"
      style="width: 100%"
      stripe
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column label="姓名" width="80" align="center">
        <template slot-scope="scope"
          ><a size="mini" @click="handleEdit(scope.$index, scope.row)"
              v-bind:class="{ 'newData':scope.row.noteList.length <= 0  }">{{
            scope.row.name
          }}</a></template
        >
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
          <i
            @click="tel(scope.row)"
            class="el-icon-phone"
            style="font-size: 18px; color: #3390ff; cursor: pointer"
          ></i>
        </template>
      </el-table-column>
      <!--<el-table-column prop="phone" label="拨号" width="60" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{scope.row.phone}}</div>
            <el-button
                    type="text"
                    class="bohaoBth"
                    @click="bohao(scope.row)"
                    icon="el-icon-phone"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="挂机" width="60" align="center">
        <template slot-scope="scope">
          <el-button
                  type="text"
                  class="bohaoBth"
                  @click="guaji(scope.row)"
                  icon="el-icon-my-guaji"
          ></el-button>
        </template>
      </el-table-column>-->
      <el-table-column label="状态" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '资质不符'" style="color: #c73e3a">{{
            scope.row.state
          }}</span>
          <span
            v-else-if="
              scope.row.state == 'B上门' ||
              scope.row.state == '已上门' ||
              scope.row.state == '待签约' ||
              scope.row.state == '已放款' ||
              scope.row.state == '审核中'
            "
            style="color: #5bbd2b"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '已受理' ||
              scope.row.state == '未接通' ||
              scope.row.state == '待跟进'
            "
            style="color: #3390ff"
            >{{ scope.row.state }}</span
          >
          <span
            v-else-if="
              scope.row.state == '捣乱申请' ||
              scope.row.state == '外地申请' ||
              scope.row.state == '已拒批' ||
              scope.row.state == '重复申请'
            "
            style="color: #d7962f"
            >{{ scope.row.state }}</span
          >
          <span v-else style="color: grey">{{ scope.row.state }}</span>
        </template>
      </el-table-column>
      <el-table-column label="星级" width="50" align="center">
        <template slot-scope="scope">{{
          scope.row.level | levelKind
        }}</template>
      </el-table-column>
      <el-table-column label="备注" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.noteList" :key="index">
            <p
              class="ell"
              v-if="index < 1"
              v-bind:style="{ color: item.otherUser == 1 ? 'red' : '#606266' }"
            >
              <span
                v-bind:style="{
                  color: item.otherUser == 1 ? '#898989' : '#898989',
                }"
                >{{ ++index }}--{{ item.createDate | formatTime }}--{{
                  item.userName
                }}</span
              >--{{ item.content }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="资质" width="200" align="center">
        <template slot-scope="scope"
          ><span>{{ scope.row.remarks | remarksPanKong }}</span></template
        >
      </el-table-column>
      <el-table-column label="来源" width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sourceType == 1">******</span>
          <span v-else>{{ scope.row.sourceName3 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分配时间" width="160" align="center" sortable
        >1
        <template slot-scope="scope">
          <p style="text-align: left">
            <span> 分配：{{ scope.row.distributionTime | formatTime }}</span>
            <br />
            <span>再分配：{{ scope.row.zfpTime | formatTime }}</span>
          </p>
          <!-- <p class="ell">再分配：{{ scope.row.zfpTime | formatTime }}</p> -->
        </template>
      </el-table-column>
      <el-table-column prop="level" label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="handleEdits(scope.$index, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Details
      :addatbn="true"
      :dialogVisible="dialogVisible"
      @handleClose="dialogVisible = false"
    />
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageConfig.currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageConfig.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageConfig.total"
    >
    </el-pagination>
    <!-- 分组设置 -->
    <el-dialog title="分组设置" :visible.sync="dialogGroupVisible">
      <el-table :data="userGroup.data" style="width: 100%" stripe v-cloak
        ><!---->
        <el-table-column
          v-for="v in userGroup.columns"
          :prop="v.field"
          :label="v.title"
          :key="v.field"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="v.field == 'groupName' && scope.row.isSet">
              <el-input
                size="mini"
                placeholder="请输入内容"
                v-model="userGroup.sel[v.field]"
              >
              </el-input>
            </span>
            <span v-else>{{ scope.row[v.field] }}</span>
          </template>
        </el-table-column>
        <!--<el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="groupName" label="分组名" align="center">
            <template slot-scope="scope">
                <span v-if="scope.row.isSet">
                    <el-input size="small" placeholder="请输入内容"></el-input>
                </span>
                <span v-else>{{scope.row.groupName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="customNum" label="分组客户数量" align="center"></el-table-column>-->
        <el-table-column label="操作" width="100%" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              @click="pwdChange(scope.row, scope.$index, true)"
              type="text"
              size="small"
            >
              {{ scope.row.isSet ? "保存" : "修改" }}
            </el-button>
            <el-button
              v-if="!scope.row.isSet"
              @click="deleteGroup(scope.row.id)"
              type="text"
              size="small"
            >
              删除
            </el-button>
            <el-button
              v-else
              @click="pwdChange(scope.row, scope.$index, false)"
              type="text"
              size="small"
            >
              取消
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        class="el-table-add-row"
        style="width: 99.2%"
        @click="addMasterGroup()"
      >
        <span>+ 添加</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 导入时间戳
import moment from "moment";
import { setInterval } from "timers";
import { mapMutations, mapActions, mapState } from "vuex";
import MyCusEdit from "./myCusEdit";
import Details from "./details.vue";
export default {
  name: "redistribution",
  components: { Details },
  // 数据
  data() {
    return {
      dialogVisible: false,
      currentUser: {
        currentName: "",
        currentId: "",
        currentPhone: "",
      },
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow: false,
      loading: true,
      //页码参数
      pageConfig: {
        currentPage: 1, //当前页
        pageSize: 10, //当前单页数据条数
        total: 0, //请求到的数据总条数
      },
      showTimeKind: true,
      //分组设置弹窗是否显示
      dialogGroupVisible: false,
      kindtimeList: [
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
      ],
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      //客户筛选区域数据
      formLabelAlign: {
        keywords: "", //关键字
        level: "", //	星级
        state: "", //	状态
        content: "", //备注内容
        contentCount: "", //备注条数
        timeType: "", //时间类型
        dayType: "",
        weiLianXiDays: "", //未联系天数
        beginTime: "", //开始时间
        endTime: "", //	结束时间
        group: "", //分组ID
        pageSize: "", //分页大小
        currentPage: "", //	当前页码
      },
      disTBN: false,
      //待处理的客户数量
      daiGenJinNum: {
        level0Count: 0,
        level3count: 0,
        level2count: 0,
      },
      //加入公共池的客户id数组
      customerId: [],
      tableData: [],
      userGroup: {
        sel: null, //选中行
        columns: [
          { field: "id", title: "ID" },
          { field: "groupName", title: "分组名" },
          { field: "customNum", title: "分组客户数" },
        ],
        data: [],
      },
    };
  },
  computed: {
    ...mapState({
      // 通话状态
      callStatus: (state) => state.webSocket.callStatus,
      wsError: (state) => state.webSocket.wsError,
      iccidList: (state) => state.webSocket.iccidList,
      changeState: (state) => state.webSocket.changeState,
      isPhone: (state) => state.webSocket.isPhone,
    }),
  },
  methods: {
    ...mapActions(["dial", "sendMsg", "queryChangeSim"]),
    tel(row) {
      let obj = {
        phone: row.phone,
      };
      this.$socket().send(JSON.stringify(obj));
    },
    //拨号
    bohao(obj) {
      let phone = obj.phone;
      if (phone != null) {
        this.dial({ phone: phone });
      }
    },

    //挂机
    guaji() {
      this.sendMsg("ATH");
    },

    //表格各行变色的方法
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    //获取客户 List的方法
    getCusList() {
      this.loading = true;
      this.$axios
        .get(
          `/customer/getAllCustomerList?groupId=${this.formLabelAlign.group}&userId=${this.currentUser.currentid}&searchType=true&keywords=${this.formLabelAlign.keywords}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code == 0) {
            this.loading = false;
            if (res.data.dataModel.total == 0) {
              this.$message("抱歉，没有找到符合搜索条件的客户");
              this.tableData = res.data.dataModel.list;
              this.pageConfig.total = res.data.dataModel.total;
              //标签数字
              if (res.data.dataModel.total > 0) {
                this.$store.state.common.zfpCount = res.data.dataModel.total;
              } else {
                this.$store.state.common.zfpCount = "";
              }
              this.clearAllCheck();
              return false;
            }
            this.$message.success("已为您找到以下客户(#^.^#)");
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
            //标签数字
            if (res.data.dataModel.total > 0) {
              this.$store.state.common.zfpCount = res.data.dataModel.total;
            } else {
              this.$store.state.common.zfpCount = "";
            }
            // this.clearAllCheck();
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.clearAllCheck();
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeCus(val) {
      this.customerId = [];
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.customerId.push(val[i].id);
      }
    },
    //加入公共池
    addGGC() {
      this.disButton();
      this.$axios
        .post(
          `/customer/addToPool?operationId=${this.currentUser.currentid}&customerId=${this.customerId}`
        )
        .then((res) => {
          // console.log(res);
          let messageText = res.data.messageModel.messageText;
          if (messageText == "修改成功") {
            this.$message({
              message: "已将选中的客户成功添加到公共池",
              type: "success",
            });
            this.$axios
              .get(
                `/customer/getAllCustomerList?searchType=true&pageSize=10&currentPage=1&userId=${this.currentUser.currentid}`
              )
              .then((res) => {
                // console.log(res);
                let dataModel = res.data.dataModel;
                if (dataModel == null) {
                  this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
                } else {
                  this.tableData = res.data.dataModel.list;
                  this.pageConfig.total = res.data.dataModel.total;
                  //标签数字
                  if (res.data.dataModel.total > 0) {
                    this.$store.state.common.zfpCount =
                      res.data.dataModel.total;
                  } else {
                    this.$store.state.common.zfpCount = "";
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$message.error("添加失败/(ㄒoㄒ)/~~");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //将按钮禁用两秒
    disButton() {
      this.disTBN = true;
      setTimeout(() => {
        this.disTBN = false;
      }, 1000);
    },
    //清空所有筛选条件的方法
    clearAllCheck() {
      for (let i = 0; i < this.formLabelAlign.length; i++) {
        this.formLabelAlign[i] = "";
      }
      this.value1 = ""; //未分割的完整版时间区间
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId) {
      let obj = {};
      obj = this.kindtimeList.find((item) => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true;
      } else {
        this.showTimeKind = false;
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId) {
      let obj = {};
      obj = this.timeList.find((item) => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.value1 = "";
      }
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getCusList();
      } else {
        this.pageConfig.pageSize = val;
        this.getCusList();
      }
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageConfig.currentPage = val;
      this.getCusList();
      // }
    },
    //编辑
    handleEdit(index, row) {
      // console.log( row);
      let CusId = row.id;
      // 编程式导航
      this.$router.push({
        path: "/myCusEdit",
        query: {
          CusId: CusId,
          addatbn: "true",
          keywords: this.formLabelAlign.keywords,
          level: this.formLabelAlign.level,
          state: this.formLabelAlign.state,
          content: this.formLabelAlign.content,
          contentCount: this.formLabelAlign.contentCount,
          weiLianXiDays: this.formLabelAlign.weiLianXiDays,
          timeType: this.formLabelAlign.timeType,
          dayType: this.formLabelAlign.dayType,
          beginTime: this.formLabelAlign.beginTime,
          endTime: this.formLabelAlign.endTime,
        },
      });
      window.sessionStorage.setItem("CusId", CusId);
      window.sessionStorage.setItem("addatbn", "true");
      window.sessionStorage.setItem("keywords", this.formLabelAlign.keywords);
      window.sessionStorage.setItem("level", this.formLabelAlign.level);
      window.sessionStorage.setItem("state", this.formLabelAlign.state);
      window.sessionStorage.setItem("content", this.formLabelAlign.content);
      window.sessionStorage.setItem(
        "contentCount",
        this.formLabelAlign.contentCount
      );
      window.sessionStorage.setItem(
        "weiLianXiDays",
        this.formLabelAlign.weiLianXiDays
      );
      window.sessionStorage.setItem("timeType", this.formLabelAlign.timeType);
      window.sessionStorage.setItem("dayType", this.formLabelAlign.dayType);
      window.sessionStorage.setItem("beginTime", this.formLabelAlign.beginTime);
      window.sessionStorage.setItem("endTime", this.formLabelAlign.endTime);
    },
    //编辑
    handleEdits(index, row) {
      // console.log( row);
      let CusId = row.id;
      // 编程式导航
      window.sessionStorage.setItem("CusId", CusId);
      window.sessionStorage.setItem("addatbn", "true");
      window.sessionStorage.setItem("keywords", this.formLabelAlign.keywords);
      window.sessionStorage.setItem("level", this.formLabelAlign.level);
      window.sessionStorage.setItem("state", this.formLabelAlign.state);
      window.sessionStorage.setItem("content", this.formLabelAlign.content);
      window.sessionStorage.setItem(
        "contentCount",
        this.formLabelAlign.contentCount
      );
      window.sessionStorage.setItem(
        "weiLianXiDays",
        this.formLabelAlign.weiLianXiDays
      );
      window.sessionStorage.setItem("timeType", this.formLabelAlign.timeType);
      window.sessionStorage.setItem("dayType", this.formLabelAlign.dayType);
      window.sessionStorage.setItem("beginTime", this.formLabelAlign.beginTime);
      window.sessionStorage.setItem("endTime", this.formLabelAlign.endTime);
      this.dialogVisible = true;
    },
    //查询
    checkCus() {
      this.disButton();
      this.pageConfig.currentPage = 1;
      this.pageConfig.pageSize = 10;
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = this.value1[0];
        this.formLabelAlign.endTime = this.value1[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime =
            this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime =
            this.formLabelAlign.endTime + " " + "23:59:59";
        }
      } else {
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      this.getCusList();
    },
    // 获取未处理的数据数量
    getUnread() {
      this.disButton();
      this.$axios
        .get(`/customer/getCusCount?userId=${this.currentUser.currentid}`)
        .then((res) => {
          if (res.data.messageModel.code != 0) {
            this.$message.error(res.data.messageModel.messageText);
          } else {
            this.$store.commit("getUnread", res.data.dataModel);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //进入未处理详情页
    enterDetail(val) {
      // 编程式导航
      this.$router.push({ path: "/UnhandleR", query: { zfpSearchLevel: val } });
      window.sessionStorage.setItem("zfpSearchLevel", val);
    },
    //获取待处理客户提醒  type的值为my即为我的用户 zfp为再分配客户 team为团队客户
    getDaiChuLiNum() {
      this.$axios
        .get(`/customer/getDCL?userId=${this.currentUser.currentid}&type=zfp`)
        .then((res) => {
          this.daiGenJinNum = res.data.dataModel;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //修改
    pwdChange(row, index, cg) {
      //点击修改 判断是否已经保存所有操作
      for (let i of this.userGroup.data) {
        if (i.isSet && i.id != row.id) {
          this.$message.warning("请先保存当前编辑项");
          return false;
        }
      }
      //是否是取消操作
      if (!cg) {
        if (!this.userGroup.sel.id) this.userGroup.data.splice(index, 1);
        return (row.isSet = !row.isSet);
      }
      //提交数据
      if (row.isSet) {
        //项目是模拟请求操作  自己修改下
        let data = JSON.parse(JSON.stringify(this.userGroup.sel));
        if (data.groupName == "" || data.groupName == null) {
          this.$message.warning("请输入分组名");
          return false;
        }
        this.updateGroup(data);
        //然后这边重新读取表格数据
        // app.readMasterUser();
        row.isSet = false;
      } else {
        this.userGroup.sel = JSON.parse(JSON.stringify(row));
        row.isSet = true;
      }
    },
    //添加分组
    addMasterGroup() {
      for (let i of this.userGroup.data) {
        if (i.isSet) return this.$message.warning("请先保存当前编辑项");
      }
      let j = {
        id: "",
        userId: this.currentUser.currentid,
        groupName: "",
        customNum: "",
        isSet: true,
        _temporary: true,
      };
      this.userGroup.data.push(j);
      this.userGroup.sel = JSON.parse(JSON.stringify(j));
    },
    //获取分组List
    getGroupList() {
      //打开遮罩层
      this.loading = true;
      this.$axios
        .post(`/group/getGroupList?userId=${this.currentUser.currentid}`)
        .then((res) => {
          // console.log(res);
          let code = res.data.messageModel.code;
          if (code == 0) {
            this.loading = false;
            if (res.data.dataModel.length == 0) {
              this.$message("暂无分组");
              this.userGroup.data = [];
              return false;
            }
            this.$message.success("已为您找到以下分组");
            res.data.dataModel.map((i) => {
              i.isSet = false; //给后台返回数据添加`isSet`标识
              return i;
            });
            this.userGroup.data = res.data.dataModel;
            // this.userGroup.data = [{id: 1, groupName: '有车', customNum: 100, isSet: false},
            //     {id: 2, groupName: '有房', customNum: 100, isSet: false},
            //     {id: 3, groupName: '有老婆', customNum: 100, isSet: false},
            //     {id: 4, groupName: '有遗产', customNum: 100, isSet: false}];
          } else {
            this.loading = false;
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //更新分组
    updateGroup(data) {
      //打开遮罩层
      this.loading = true;
      this.$axios
        .post(`/group/addAndUpdateGroup`, data)
        .then((res) => {
          // console.log(res);
          let code = res.data.messageModel.code;
          if (code == 0) {
            this.$message.success("保存成功");
            this.getGroupList();
          } else {
            this.loading = false;
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //删除分组
    deleteGroup(id) {
      //打开遮罩层
      this.loading = true;
      this.$axios
        .post(`/group/delGroup/` + id)
        .then((res) => {
          // console.log(res);
          let code = res.data.messageModel.code;
          if (code == 0) {
            this.$message.success("删除成功");
            this.getGroupList();
          } else {
            this.loading = false;
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return "";
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    remarksPanKong(value) {
      if (value == "null") {
        return "";
      }
      return value;
    },
    //判断星级状态
    levelKind(val) {
      // console.log(val);
      if (val == "0") {
        return "0星";
      } else if (val == "1") {
        return "1星";
      } else if (val == "2") {
        return "2星";
      } else if (val == "3") {
        return "2星+";
      } else if (val == "4") {
        return "3星";
      } else if (val == "5") {
        return "4星";
      } else {
        return "";
      }
    },
  },
  created() {
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.$axios
      .get(
        `/customer/getAllCustomerList?searchType=true&pageSize=10&currentPage=1&userId=${this.currentUser.currentid}`
      )
      .then((res) => {
        if (res.data.messageModel.code == 0) {
          this.loading = false;
          if (res.data.dataModel.total == 0) {
            this.$message("抱歉，没有找到符合搜索条件的客户");
            this.clearAllCheck();
            this.tableData = [];
            this.pageConfig.total = 0;
            this.$store.state.common.zfpCount = "";
            return false;
          }
          this.$message.success("已为您找到以下客户(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          //标签数字
          if (res.data.dataModel.total > 0) {
            this.$store.state.common.zfpCount = res.data.dataModel.total;
          } else {
            this.$store.state.common.zfpCount = "";
          }
          // this.clearAllCheck();
        } else {
          this.loading = false;
          this.$message.error(res.data.messageModel.messageText);
          this.clearAllCheck();
          this.tableData = [];
          this.pageConfig.total = 0;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }else{
    //   this.$message.warning("请先进行登录操作！");
    //   this.$router.push("login");
    // } ;
    this.getUnread();
    let component = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key === 13) {
        component.checkCus();
      }
    };
    this.getDaiChuLiNum(), this.getGroupList();
  },
  activated() {
    this.getCusList();
    this.getUnread();
    this.getDaiChuLiNum();
    this.getGroupList();
  },
};
</script>

<style lang="scss">
.bohaoBth {
  /*width: 232px;*/
  font-size: 16px;
  margin-bottom: 20px;
  max-height: 0px;
}

.el-icon-my-guaji {
  background: url(../../assets/guaji.png) center no-repeat;
  /*background-size: cover;*/
  line-height: 16px;
}
.el-icon-my-guaji:before {
  line-height: 16px;
  content: "挂机";
  font-size: 16px;
  visibility: hidden;
}

.redistribution-container {
  // 播报文字颜色
  #unread {
    margin-top: 10px;
    margin-bottom: 15px;
    .unread {
      font-size: 14px;
      color: #000000;
      margin-left: 22px;
    }
    .unreadNuM {
      font-size: 18px;
      color: #c73e3a;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }
  //突出隔行的颜色差异
  .el-table {
    font-size: 12px;
    .cell {
      padding: 0 1px;
      line-height: 16px;
    }
    th {
      padding: 0;
    }
    th.is-sortable {
      .cell {
        text-align: center;
        line-height: 16px;
      }
    }
    .warning-row {
      background: #fff;
    }
    .success-row {
      background: #d3d3d3;
    }
    td {
      //取消单元格的上下padding
      padding: 2px 0;
      border-bottom: 1px solid rgb(211, 205, 205);
      border-right: 1px dashed #d3cdcd;
    }
    .el-button--mini {
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255, 255, 255, 0.01);
    }
    a:hover {
      color: aqua;
    }
    .newData {
      color: red;
      :hover {
        color: aqua;
      }
    }
  }
  // 时间分类框样式
  input.el-input__inner#kinds,
  /deep/input.el-input__inner#time {
    width: 85px;
    padding-left: 7px;
    height: 32px !important;
  }
  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
    .el-date-editor .el-range-separator {
      width: 5%;
    }
    /deep/.block,
    #block {
      border: none !important;
      /deep/.el-date-editor--daterange {
        width: 240px !important;
      }
    }
  }
  .el-form-item {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .check {
    margin-left: 27px;
    padding: 10px 20px;
  }
  .ell {
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    word-break: normal;
    text-indent: -2em;
    margin: 0 0 0 2em;
    line-height: 1.2;
  }
  //分页框样式设置（避免页面被覆盖）
  .el-pagination .el-input__inner {
    width: 100px;
  }
  .el-table-add-row {
    margin-top: 10px;
    width: 100%;
    height: 34px;
    border: 1px dashed #c1c1cd;
    border-radius: 3px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    line-height: 34px;
  }
}
</style>
