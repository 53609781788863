import { Message } from 'element-ui';
import { $url } from "../../main";
import axios from 'axios';
const webSocket = {
    state: {
        connect: 0, // 连接次数
        ws: null,
        wsSuccess: null, // 连接成功
        wsError: '连接失败,请检查是否安装驱动或联系管理员', // 连接失败
        serverUrl: 'ws://127.0.0.1:8800/', // 连接地址
        // serverUrl: 'ws://192.168.0.85:8800/',
        iccidList: [],
        simVlaue: 1, // value：1/2/3/4/5：1/2/3/4/5 卡 默认0 为未切卡
        getIccidNumb: 0, // 获取iccid次数
        simNumb: '', // 默认那张卡
        callStatus: 0, // 通话状态 0 挂机，1 来电，2 呼叫，3 接通
        changeState: true, // 切卡是否成功
        isPhone: false, // 是否插入话机
        flage: true, // 第一次获取iccid
        simStr: null,
        isSimUseCount: true
    },
    mutations: {},
    actions: {
        wxClose({ dispatch, commit, state }) {
            state.simVlaue = 0;
            state.getIccidNumb = 0;
            state.ws.close();
        },
        wsInit({ dispatch, commit, state }, val) {
            //重连先关闭
            if (val === 'reConn') state.ws.close();
            if ("WebSocket" in window) {
                // 打开一个 web socket，全局共用一个
                state.ws = new WebSocket(state.serverUrl);
                console.log("已连接：" + state.serverUrl);
                state.ws.onopen = () => {
                    setTimeout(() => {
                        dispatch('sendMsg', 'link');
                        // dispatch('setCallrecord');
                    }, 100);
                    // 连接成功
                    dispatch('showResult', 'ok')
                };
                // 连接成功返回的报文
                state.ws.onmessage = (evt) => {
                    var data = JSON.parse(evt.data);
                    if (data.cmd !== 'SignalIntensity') console.log(`连接成功返回的报文:${JSON.stringify(data)}`);
                    switch (data['cmd']) {
                        case 'SignalIntensity':
                            if (data.CSQ <= 0) {
                                state.wsError = '请检查话机信号'
                                state.wsSuccess = ''
                            } else {
                                state.wsError = ''
                                state.wsSuccess = '连接成功'
                            }
                            break;
                        case 'GETCallStat':
                            // callStatus: 0, // 通话状态 0 挂机，1 来电，2 呼叫，3 接通
                            // CallStat 0 来电时 1 打电话对方未振铃时 2 对方振铃 3 通话接通通话中 5 挂机
                            switch (data.CallStat) {
                                case 5:
                                    state.callStatus = 0;
                                    break;
                                case 0:
                                    state.callStatus = 1;
                                    break;
                                case 3:
                                    state.callStatus = 3;
                                    break;
                                case 1:
                                case 2:
                                    state.callStatus = 2;
                                    break;
                            }

                            break;
                        // 服务端ping客户端
                        case 'USB':
                            state.isPhone = true;
                            if (!data.connected) {
                                // Message.error('请插入话机');
                                state.callStatus = 0;
                                state.isPhone = false;
                            }
                            // 查询话机通话状态
                            if (data.connected && state.iccidList.length <= 0) {
                                state.simVlaue = 0;
                                state.getIccidNumb = 0;
                                // dispatch('changeSim');
                                dispatch('sendMsg', 'getIccid');
                            }
                            dispatch('sendMsg', 'GETCallStat');
                            break;
                        case 'CEND':
                            // 挂断
                            state.callStatus = 0;
                            // dispatch('setCallrecord');
                            break;
                        case 'setCallrecord':
                            // 通话结束且生成通话记录自动切卡
                            dispatch('queryChangeSim', state.iccidList);
                            break;
                        case 'CALLING':
                            // 呼叫
                            state.callStatus = 1;
                            break;
                        case 'CORG':
                            // 拨号中
                            state.callStatus = 2;
                            break;
                        case 'ATA':
                            // 通话中
                            state.callStatus = 3;
                            break;
                        case 'stoprecord':
                            dispatch('setCallrecord');
                            // 通话结束生成通话记录地址 fileName
                            break;
                        case 'CBEGIN':
                            // 话机来电或去电接通返回
                            state.callStatus = 3;
                            break;
                        case 'ALERT':
                            state.ws.send('{"cmd":"ALERT","success":"true","message":"成功"}');
                            break;
                        case 'ATD':
                            if (!data.success) {
                                Message.error('拨打失败');
                            } else {
                                state.callStatus = 1;
                                dispatch('saveCallOutLog', data)
                            }
                            break;
                        case 'begChgSim':
                            // 切卡中
                            state.changeState = false;
                            // 当前使用卡槽卡拔出
                            if (data.status == 1) {
                                state.simVlaue = 0;
                                state.getIccidNumb = 0;
                                state.iccidList.forEach((item, index) => {
                                    if (item.code == data.simIndex) {
                                        state.iccidList.splice(index, 1)
                                    }
                                })
                                dispatch('queryChangeSim', state.iccidList);
                            }
                            break;
                        case 'endChgSim':
                            // 手动切卡成功
                            state.changeState = true;
                            state.simNumb = data.simIndex;
                            if (state.iccidList.length <= 0) dispatch('sendMsg', 'getIccid');
                            break;
                        case 'getIccid':
                            var simList = data.sim.split(',');
                            var simStr = data.sim.substring(0,5);
                            var leng = simList.length;
                            state.changeState = false;
                            state.simNumb = Number(simList[leng - 2]);
                            // 当之前的simStr 与当前的simStr不同时说明卡槽卡有变化重新赋值
                            if (simStr !== state.simStr) {
                                state.simStr = simStr;
                                state.iccidList = [];
                                simList.forEach((item, index) => {
                                    if (index < 5 && item == 1) {
                                        state.iccidList.push({
                                            code: index + 1,
                                            iccid: null,
                                            number: null,
                                            valueName: null
                                        })
                                    }
                                })
                            }
                            // 0:注册成功 1：搜索中 2：注网失败 3：没有卡 4：紧急呼叫
                            state.iccidList.forEach(item => {
                                if (item.iccid) return;
                                if (item.code == state.simNumb){
                                    if (!item.iccid && data.iccid) {
                                        item.iccid = data.iccid;
                                        item.number = data.number;
                                        item.valueName = `${item.code}号卡：${data.iccid}`;
                                    } else if (!data.iccid){
                                        dispatch('sendMsg', 'getIccid');
                                    }
                                } else if (!item.iccid){
                                    console.log(item.iccid)
                                    dispatch('changeSim', item.code);
                                }
                            })
                            state.changeState = true;
                            state.flage = state.iccidList.every(item => !!item.iccid);
                            if (state.flage && state.isSimUseCount && state.iccidList.length > 1) dispatch('queryChangeSim', state.iccidList);
                            break;
                        case 'changeSim':
                            // 切卡成功获取iccid
                            state.changeState = false;
                            dispatch('sendMsg', 'getIccid');
                            break;
                    }

                };
                //出现错误
                state.ws.onerror = (evt) => {
                    console.error(evt);
                }
                //连接断开
                state.ws.onclose = (evt) => {
                    console.error(evt)
                    dispatch('showResult', 'error')
                }
            } else {
                // 浏览器不支持 WebSocket
                // alert("您的浏览器不支持 WebSocket!");
                Message.error("您的浏览器不支持 WebSocket!");
            }
        },
        changeSim({ dispatch, commit, state }, simVlaue) {
            // 获取 获取 ICCID
            // 默认为第一张
            let val = `{"cmd": "changeSim","value": ${simVlaue},"status":0}`;
            state.ws.send(val);
        },
        sendMsg({ commit, state }, val) {
            // Web Socket 已连接上，使用 send() 方法发送数据
            var socketState = state.ws.readyState;
            console.log("readyState:" + socketState);
            if (socketState != 1) {
                // 连接失败 失败自动重连3次
                state.connect++;
                if (state.connect <= 3) {
                    state.connect = 0;
                    this.wsInit('reConn');
                }
                return;
            } else {
                state.wsError = null;
            }

            if (val == "link") {
                val = '{"cmd":"LINK"}';
            }
            if (val == 'getIccid') {
                val = '{"cmd":"getIccid"}';
            }
            // 挂断
            if (val == "ATH") {
                val = '{"cmd":"ATH"}';
            }
            if (val == "ATA") {
                val = '{"cmd":"ATA"}';
            }
            if (val == "READIMEI") {
                val = '{"cmd":"READIMEI"}';
            }
            if (val == "READVER") {
                val = '{"cmd":"READVER"}';
            }
            if (val == "READSTATUS") {
                val = '{"cmd":"READSTATUS"}';
            }
            if (val == "stoprecord") {
                val = '{"cmd":"stoprecord"}';
            }

            if (val == 'CRCL') {
                val = '{"cmd":"CRCL","type":"1", "begindex":"1","endindex":"10"}'
            }
            if (val == 'GETCallStat') {
                val = '{"cmd":"GETCallStat"}'
            }
            state.ws.send(val);
        },
        showResult({ dispatch, commit, state }, val) {
            if (val == 'ok') {
                state.wsSuccess = '连接成功';
                state.wsError = null;
            } else {
                state.wsError = '连接失败,请检查是否安装驱动或联系管理员';
                state.wsSuccess = null;
            }
        },
        // 查询当天最少使用的卡并切换
        queryChangeSim({ dispatch, commit, state }, obj) {
            return new Promise((resolve, reject) => {
                axios.post('/talk/getSimUseCount', obj).then(({ data }) => {
                    if (data.messageModel.code == 200) {
                        state.simNumb = data.dataModel.code;
                        dispatch('selectChangeSim', state.simNumb);
                        state.isSimUseCount = false;
                    } else {
                        Message.error(data.messageModel.messageText)
                    }
                    resolve()
                }).catch(error => {
                    reject(error)
                });
            })
        },
        // 保存iccid于通话记录关系
        saveCallOutLog({ dispatch, commit, state }, obj) {
            return new Promise((resolve, reject) => {
                let iccid = null;
                let simNumber = null;
                state.iccidList.forEach(item => {
                    if (item.code == state.simNumb) {
                        iccid = item.iccid;
                        simNumber = item.number;
                    }
                })
                let id = window.sessionStorage.getItem("id");
                let parm = {
                    iccid: iccid,
                    callId: obj.callId,
                    simNumber: simNumber,
                    userId: id
                }
                axios.post('/talk/saveCallOutLog', parm).then(({ data }) => {
                    if (data.messageModel.code != 200) {
                        Message.error(data.messageModel.messageText)
                    }
                    resolve()
                }).catch(error => {
                    reject(error)
                });
            })
        },
        // 切卡
        selectChangeSim({ dispatch, commit, state }, code) {
            // 获取 获取 ICCID
            // 默认为第一张
            let val = `{"cmd": "changeSim","value": ${code},"status":0}`;
            state.ws.send(val);
        },
        // 拨号
        dial({ commit, state }, obj) {
            // let phone = "10010"
            let val = `{"cmd":"ATD","number":"${obj.phone}"}`
            // let val = `{"cmd":"ATD","number":"${phone}"}`
            state.ws.send(val);
        },
        setCallrecord({ commit, state }, obj) {
            let callrecordUrl = `${$url}/talk/save`;
            let recordFileUrl = `${$url}/talk/saveRecordFile`;
            let departmentId = window.sessionStorage.getItem("departmentId");
            let id = window.sessionStorage.getItem("id");
            console.log("departmentId", departmentId)
            console.log("id", id)
            let val = `{"cmd": "setCallrecord","pid": "${departmentId}","userWid":"${id}",
                "callrecordUrl":"${callrecordUrl}",
                "recordFileUrl":"${recordFileUrl}"
            }`
            state.ws.send(val);
        }
    }
}

export default webSocket;
