<template>
    <div class="CallRecords-container">
        <!-- 栅格 输入框 和按钮 -->
        <el-row class="filterCondition">
            <el-form  label-width="82px"  label-position="right"  ref="formLabelAlign"  :model="formLabelAlign">
                <el-form-item label="顾问" v-show="guwenshow">
                    <el-input v-model="formLabelAlign.userWords" placeholder="姓名/手机号均可"></el-input>
                </el-form-item>
                <!--<el-form-item label="客户">
                    <el-input v-model="formLabelAlign.customerWords" placeholder="手机号"></el-input>
                </el-form-item>-->
                <el-form-item label="呼叫类型">
                    <el-select v-model="formLabelAlign.type" id="time"  placeholder="请选择呼叫类型">
                        <!-- <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
                        <el-option label="不限" value=""></el-option>
                        <!--                        <el-option label="呼入" value=1></el-option>-->
                        <el-option label="接通" value="ANSWERED"></el-option>
                        <el-option label="未接通" value="NO ANSWER"></el-option>
                        <el-option label="占线" value="BUSY"></el-option>
                        <el-option label="失败" value="FAILED"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
                    <el-select  v-model="formLabelAlign.dayType"  id="time"  @change="holderQuJian"  placeholder="时间">
                        <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="value1" label="时间区间" v-show="isShow">
                    <div class="block">
                        <el-date-picker
                                v-model="value1"
                                type="daterange"
                                format="yyyy/MM/dd"
                                value-format="yyyy/MM/dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item class="BTN">
                    <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" :disabled="disTBN" class="check">查询</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="通话记录" name="1" >
                <!-- <p class="titleWithLine"><i class="el-icon-arrow-down"></i> 通话记录</p> -->
                <el-table  :data="shujuData"  style="width: 100%;margin-top:10px;"  v-loading="loading"   border>
                    <el-table-column prop="userName" label="顾问"  ></el-table-column>
                    <el-table-column label="部门">
                        <template slot-scope="scope">
                            <p class="ell">{{ scope.row.parentName2 }}{{scope.row.parentName3 | panKong}}{{scope.row.parentName4 |panKong}}</p>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column prop="customerName" label="客户姓名"  align="center"></el-table-column>-->
                    <el-table-column label="客户手机号"  align="center">
                        <template slot-scope="scope">{{(scope.row.callee || scope.row.caller) | phoneNum}}</template>
                    </el-table-column>
                    <el-table-column label="呼叫类型"  align="center"><template slot-scope="scope">{{ scope.row.callDirection | TongHuaType }}</template></el-table-column>
                    <el-table-column label="是否接听"  align="center"><template slot-scope="scope">{{ scope.row.callFlag == 0 ? '未接听' : '已接听' }}</template></el-table-column>
                    <el-table-column label="通话时长"  align="center"><template slot-scope="scope">{{ scope.row.duration | GuoLvShiChang }}</template></el-table-column>
                    <el-table-column label="通话时间"  align="center"><template slot-scope="scope">{{ scope.row.callTime | formatTime }}</template></el-table-column>
                    <el-table-column  label="通话录音"  align="center">
                        <!-- <template slot-scope="scope"><a  size="mini" @click="handleEdit(scope.$index, scope.row)"><i class="el-icon-video-play">播放</i></a></template> -->
                        <template slot-scope="scope"><!--{{scope.row.recordingFile | luyinFile}}}-->
                            <!-- <el-button @click="handleClick(scope.$index, scope.row)" type="text" size="small" disabled><i class="el-icon-video-play">暂不支持AMR格式</i></el-button> -->
                            <template v-if="scope.row.callFlag == 1">
                                <el-button @click="handleClick(scope.$index, scope.row)" type="text" size="small" ><i class="el-icon-video-play">播放</i></el-button>
                                <el-button @click="download(scope.$index, scope.row)" type="text" size="small" ><i class="el-icon-download">下载</i></el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <p>正在播放: {{this.currentplayer.customerName}}--{{this.currentplayer.callTime | formatTime }}</p>
                <!-- <audio ref='audio' style="width:100%" autoplay controls crossorigin playsinline><source :src="audio_url" type="audio/ogg" /> 当前浏览器不支持播放音频，请使用chrome试试嘛 </audio> -->
                <!-- <audio style="width:100%" autoplay loop controls><source :src="audio_url" type='audio/mp3' /></audio> -->
                <audio style="width:100%" ref='audio' controls autoplay name="media" ><source :src="audio_url" type="audio/mpeg/ogg"></audio>
                <!-- 分页 -->
                <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[20,50,100,200]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total"></el-pagination>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    //导入转码插件 amr
    // import BenzAMRRecorder from 'benz-amr-recorder'
    // var amr = new BenzAMRRecorder();
    // 导入时间戳
    import moment from "moment";
    import { log } from "util";
    export default {
        name: "PhoneRecords",
        // 数据
        data() {
            return {
                currentUser: {
                    currentName: "",
                    currentId: "",
                    currentLevel: "",
                    currentPhone: ""
                },
                currentplayer:{
                    customerName:"未选客户",
                    type:"",
                    callTime:"未知时间",
                },
                activeNames: ['1'],
                guwenshow: true,
                showTimeKind: true,
                value1: "",  //未分割的完整版时间区间
                audio_url: "",  //音频路径12.14下午
                //时间区间隐藏状态
                isShow: false,
                loading:false,
                pageConfig: {//表一页码参数
                    currentPage: 1, //当前页
                    pageSize: 20, //当前单页数据条数
                    total: 0 //请求到的数据总条数
                },
                kindtimeList: [
                    { id: "beizhuTime", name: "备注时间" },
                    { id: "fenpeiTime", name: "分配时间" }
                ],
                //时间选择生成内容
                timeList: [
                    { id: "today", name: "今日" },
                    { id: "yestoday", name: "昨日" },
                    { id: "sevenDay", name: "最近七天" },
                    { id: "tomonth", name: "本月" },
                    { id: "yesmonth", name: "上月" },
                    { id: "customize", name: "自定义" }
                ],
                //禁用按钮的状态值
                disTBN:false,
                //客户筛选区域数据
                formLabelAlign: {
                    userWords : "", //	顾问姓名
                    // customerWords: "", //	客户姓名
                    time: "", //	消耗时间
                    timeType: "", //时间类型
                    type: "", //呼叫类型
                    dayType: "today",  //默认今天
                    beginTime: "", //开始时间
                    endTime: "", //	结束时间
                    pageSize: "", //分页大小
                    currentPage: "" //	当前页码
                },
                formLabelWidth: "180px",
                // 表格依赖于数据没有数据  会一行都没有
                shujuData: []
            };
        },
        methods: {
            //获取通话记录的方法
            getLuYinList() {
                this.loading = true
                this.disButton()
                this.$axios
                    .post(
                        `/talk/findRecordAll?userWords=${this.formLabelAlign.userWords}&userId=${this.currentUser.currentid}&type=${this.formLabelAlign.type}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`
                    )
                    .then(res => {
                        if (res.data.messageModel.code == 0) {
                            this.loading = false
                            //通话记录
                            this.shujuData = res.data.dataModel.list;
                            this.pageConfig.total = res.data.dataModel.total;
                        } else {
                            this.$message.error(res.data.messageModel.messageText);
                            //通话记录
                            this.shujuData = [];
                            this.pageConfig.total = 0;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleChange(val) {//折叠表格方法
                // console.log(val);
            },
            //播放
            handleClick(index, row){
                // let date = new Date();
                // let downloadDate = moment(row.callTime).format("YYYY/MM/DD");
                // let luyinUrl = row.recordingFile;
                // let luyinName = luyinUrl.split("/")[luyinUrl.split('/').length - 1];
                // this.$refs.audio.src = "http://chinacall.ydw123.com/wav/"+downloadDate+'/'+luyinName; //动态渲染
                this.$refs.audio.src = row.recordFileUrl;
            },
            //下载
            download(index, row){
                // let date = new Date();
                // let downloadDate = moment(date).format("YYYY/MM/DD");
                // let luyinUrl = row.recordingFile;
                // let luyinName = luyinUrl.split("/")[luyinUrl.split('/').length - 1];
                // window.open("http://chinacall.ydw123.com/wav/"+downloadDate+"/"+luyinName,"_blank")
                window.open(row.recordFileUrl, "_blank")
            },
            //清空所有筛选条件的方法
            clearAllCheck() {
                for (let i = 0; i < this.formLabelAlign.length; i++) {
                    this.formLabelAlign.formLabelAlign[i] = "";
                }
                this.value1 = ""; //未分割的完整版时间区间
            },
            //将按钮禁用两秒
            disButton(){
                this.disTBN = true
                setTimeout(() => {
                    this.disTBN = false
                }, 2000);
            },
            //判断时间种类是否禁用
            hiddenTimeKind(vId) {
                let obj = {};
                obj = this.kindtimeList.find(item => {
                    //这里的fenselectList就是上面遍历的数据源
                    return item.id === vId; //筛选出匹配数据
                });
                // console.log(obj);
                if (obj.name == "") {
                    this.showTimeKind = true;
                } else {
                    this.showTimeKind = false;
                }
            },
            //判断时间区间是否隐藏
            holderQuJian(vId) {
                let obj = {};
                obj = this.timeList.find(item => {
                    return item.id === vId; //筛选出匹配数据
                });
                if (obj.id == "customize") {
                    this.isShow = true;
                    this.formLabelAlign.dayType = "customize";
                }  else {
                    this.isShow = false;
                    this.value1 = ""
                }
            },
            //分页的方法
            handleSizeChange(val) {
                // console.log(val);
                if (this.pageConfig.currentPage != 1) {
                    this.pageConfig.currentPage = 1;
                    this.pageConfig.pageSize = val;
                    this.getLuYinList();
                } else {
                    this.pageConfig.pageSize = val;
                    this.getLuYinList();
                }
            },
            handleCurrentChange(val) {
                this.pageConfig.currentPage = val;
                this.getLuYinList();
                // }
            },
            //查询
            checkCus() {
                this.loading = true
                // if (this.cityId.length == 0) {  this.$message.error("请选择城市进行查询");  return false}
                if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
                    this.formLabelAlign.beginTime = this.value1[0];
                    this.formLabelAlign.endTime = this.value1[1];
                    if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
                        this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
                        this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
                    }
                } else {
                    this.formLabelAlign.beginTime = "";
                    this.formLabelAlign.endTime = "";
                }
                this.getLuYinList()
            },
        },
        // 本地过滤器
        filters: {
            formatTime(value) {
                if (value == null) {
                    return "";
                }
                return moment(value).format("YYYY-MM-DD HH:mm:ss");
                //   return moment(value).format("YYYY-MM-DD");
            },
            phoneNum(val){  //页面对号码简单加密
                if (val != null ) {
                    return val.replace(/(\d{3})(\d{4})(\d{4})/,"$1****$3")
                }else{
                    return ""
                }
            },
            quZheng(val){
                return Math.ceil(val)
            },
            // //处理录音文件名
            // luyinFile(val) {
            //
            // },
            GuoLvShiChang(val){
                return Number(parseInt(val/60)) + "分" + Number(val%60) + "秒"
            },
            panKong(val) {
                if (val != null) {
                    return "--" + val
                }else{
                    return ""
                }
            },
            TongHuaType(val){
                let arr = ['来电', '去电']
                return arr[val]
                // if (val == "FAILED") {
                //     return "失败"
                // }else if (val == "ANSWERED") {
                //     return "接通"
                // }else if (val == "NO ANSWER") {
                //     return "未接通"
                // }else{
                //     return "占线"
                // }
            }
        },
        created() {
            this.currentUser.currentName = window.sessionStorage.getItem("name")
            this.currentUser.currentid = window.sessionStorage.getItem("id")
            this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
            // this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
            this.currentUser.currentPhone = window.sessionStorage.getItem("phone")
            //如果是顾问不显示顾问查询框
            if (this.currentUser.currentLevel ==5) {
                this.guwenshow = false
            }
            this.$axios
                .post(
                    `/talk/findRecordAll?userId=${this.currentUser.currentid}&currentPage=1&pageSize=20`
                )
                .then(res => {
                    if (res.data.messageModel.code == 0) {
                        //通话记录
                        this.shujuData = res.data.dataModel.list;
                        // let shujuList = this.shujuData;
                        // for (let index in shujuList) {
                        //     if(shujuList[index].type != 'ANSWERED'){
                        //         shujuList[index].duration = 0
                        //     }
                        // }
                        this.pageConfig.total = res.data.dataModel.total;
                    } else {
                        this.$message.error(res.data.messageModel.messageText);
                        //通话记录
                        this.shujuData = [];
                        this.pageConfig.total = 0;
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    };
</script>

<style  lang='scss'>
    .CallRecords-container {
        padding: 20px;
        // margin: 0;
        //折叠图标
        .el-collapse-item__arrow {
            margin-left: 5px;
            font-weight: 900;
        }
        .el-collapse-item {
            th {
                background-color: #D7D7D7;
            }
            .el-collapse-item__header{
                background-color:#a0c5e8;
                height: 30px;
                border-radius: 3px;
                margin-bottom: 10px;
            }
        }
        .titleWithLine{
            border-bottom: 1.2px solid rgb(189, 189, 189);
        }
        .el-dialog {
            // width: 35%;
            #dateOfBirth {
                /deep/.el-input__inner {
                    padding-left: 30px !important;
                }
            }
            .el-form-item {
                margin-bottom: 5px;
                /deep/.el-form-item__label {
                    width: 150px !important;
                }
                /deep/.el-form-item__content {
                    margin-left: 150px !important;
                }
            }
        }
        .el-form-item.BTN {
            /deep/.el-form-item__content {
                margin-left: 20px !important;
            }
        }
        input.el-input__inner {
            width: 170px;
            height: 32px;
            padding-left: 10px;
        }

        //突出隔行的颜色差异
        .el-table {
            font-size: 12px;
            border: 1px solid grey;
            position: relative;
            .cell {
                padding:0 2px;
            }
            .el-table_9_column_83 {
                position: relative;
            }
            .el-table_9_column_84 {
                margin-left: 10px;
            }
            td {
                //取消单元格的上下padding
                padding: 0;
                border-right: 1px solid grey;
                border-bottom: 1px solid grey;
            }
            th {
                //取消单元格的上下padding
                padding: 0;
                border-right: 1px solid grey;
                border-bottom: 1px solid grey;
                .cell {
                    text-align: center;
                }
            }

            .el-button--mini {
                border: 0;
                padding: 5px 15px;
                margin: 5px 0;
                background: rgba(255, 255, 255, 0.01);
            }
            .toEdit:hover {
                color: skyblue;
            }
        }
        // 时间分类框样式
        input.el-input__inner#kinds,
        /deep/input.el-input__inner#time {
            // width: 85px;
            padding-left: 7px;
            height: 32px !important;
        }
        // 日期选择器
        daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
            width: 240px;
            height: 36px;
            vertical-align: middle;
            .el-date-editor .el-range__icon {
                line-height: 27px;
            }
            .el-date-editor .el-range-separator {
                width: 5%;
            }
            /deep/.block,
            #block {
                border: none !important;
                /deep/.el-date-editor--daterange {
                    width: 240px !important;
                }
            }
        }
        .el-form-item {
            display: inline-block;
            margin: 3px 0 3px 10px;
        }
        .el-form-item:nth-child(n + 6) {
            margin-top: 0;
        }
        .el-select.time {
            margin-left: 20px;
        }
        .el-input--suffix .el-input__inner.fanwei {
            padding-right: 30px;
            width: 30px;
        }
        .filterCondition {
            background-color: #fff;
            margin-bottom: 20px;
        }
        .check {
            margin-left: 22px;
            padding: 8px 20px;
        }
        .ell {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            // line-height: 0.9;
        }
        //分页框样式设置（避免页面被覆盖）
        .el-pagination .el-input__inner {
            width: 100px;
        }
        //多选框
        #bigBox {
            margin-top: 20px;
            #give {
                width: 41px;
                margin-left: 51px;
                font-size: 14px;
            }
            .el-checkbox {
                margin-right: 8px;
            }
            .duoxuankuang {
                margin-right: 10px;
            }
        }
    }
</style>
