<template>
  <div class="rechargeDeplete-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
        <el-form-item prop="timeType" label="时间">
          <!-- <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" :disabled="this.showTimeKind"> -->
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="BTN">
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check">查询</el-button>
        <el-button style="background-color:	#00CDCD;color:#fff" @click="dialogFormVisible = true"   class="check">新增消耗</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <el-table  :data="tableData" style="width: 100%" >
      <el-table-column prop="sourceName1" label="渠道" width="90" ></el-table-column>
      <el-table-column prop="sourceName2" label="平台" width="100" ></el-table-column>
      <el-table-column prop="sumUpMoney" label="累计充值" width="100" ></el-table-column>
      <el-table-column prop="beginMoney" label="初期余额" width="100" ></el-table-column>
      <!-- <el-table-column prop="sumUpMoney" label="期间消耗" width="100"></el-table-column> -->
      <el-table-column prop="rechargeList" label="期间消耗" width="120">
        <template slot-scope="scope">
          <p class="ell" v-for="(item,index) in scope.row.rechargeList" :key="index" >{{item.city  }}&nbsp;：{{item.downMoney}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="rechargeList" label="消耗总计" width="100">
        <template slot-scope="scope">{{scope.row.rechargeList | ZongJi}}</template>
      </el-table-column>
      <el-table-column prop="endMoney" label="期末余额" width="100"></el-table-column>
      <el-table-column label="次日预估余额" >
        <template slot-scope="scope">{{scope.row.tomorrow | quZheng}}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <!-- 弹出框 -->
    <el-dialog title="新增消耗" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-position="right">
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-select v-model="form.qudao" placeholder="请选择渠道">
            <el-option
                  v-for="item in laiYuanList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台" :label-width="formLabelWidth">
          <el-select v-model="form.sourceId" placeholder="请选择平台">
            <el-option
                  v-for="item in pingTaiList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消耗金额" :label-width="formLabelWidth">
          <el-input v-model="form.downMoney" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="城市" :label-width="formLabelWidth">
          <el-select v-model="form.city" placeholder="请选择城市">
            <el-option
                  v-for="item in cityselectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消耗日期" :label-width="formLabelWidth" >
          <div class="block" id="dateOfBirth">
                <el-date-picker
                  v-model="form.downTime"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addXiaoHao">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "rechargeDeplete",
  // 数据
  data() {
    return {
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() ;
          }
        }, 
      ShowCity:false,
      radio1:'上海',
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      showTimeKind:true,
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      laiYuanList: [],
      pingTaiList: [],
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
        ],
      //客户筛选区域数据
      formLabelAlign: {
        level : "", 	//	星级
        city : "", 	//	状态
        time : "", 	//	消耗时间
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      dialogFormVisible: false,
      form: {
          qudao:"",	//平台Id
          sourceId:"",	//平台Id
          downMoney:"",	//消耗金额
          city:"",	//	城市、中文
          downTime:"" //   消耗时间
        },
      cityselectList: [],
      formLabelWidth: '180px',
      // 表格依赖于数据没有数据  会一行都没有
      tableData: []
    };
  },
  methods: {
    //获取客户 List的方法
    getCusList(){
      this.$axios
      // .get(`/customer/getAllCustomerList?userId=${this.currentUser.currentid}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .get(`/consumptionLog/getConsumptionList?dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.messageText == "成功!") {
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.$message.error("获取用户失败/(ㄒoㄒ)/~~");
          this.tableData = [];
          this.pageConfig.total = 0;
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";  
        }
        this.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      }  else {
        this.isShow = false;
        this.value1 = "";
      }
    },
      //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getCusList()
      }else{
        this.pageConfig.pageSize = val;
         this.getCusList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
         this.getCusList()
      // }
    },
    //查询
    checkCus(){
      if (this.value1 != "" && this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        }
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }    
      this.$axios
      .post(`/consumptionLog/getConsumptionList?dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&pageSize=10&currentPage=1`)
      .then(res => {
        console.log(res);
        let code = res.data.messageModel.code;
        if (code == 0) {
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合搜索条件的数据');
             this.clearAllCheck();
             this.tableData = []
             return false;
          }
          this.$message.success("根据您的搜索条件，已为您找到以下数据(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          // this.clearAllCheck();
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.clearAllCheck();
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //添加充值
    addXiaoHao(){
      if (this.form.qudao == "") {
        this.$message.error("请选择渠道");
        return false
      }else if (this.form.sourceId == "") {
        this.$message.error("请选择平台");
        return false
      }else if (this.form.downMoney == "") {
        this.$message.error("请输入消耗金额");
        return false
      }else if (this.form.city == "") {
        this.$message.error("请选择城市");
        return false
      }else if (this.form.downTime == "") {
        this.$message.error("请选择消耗日期");
        return false
      }
      this.$axios
      .get(`/source/addConsumptionLog?qudao=${this.form.qudao}&sourceId=${this.form.sourceId}&downMoney=${this.form.downMoney}&city=${this.form.city}&downTime=${this.form.downTime}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.$message({  message: res.data.messageModel.messageText,type: 'success'});
          this.dialogFormVisible = false
          this.getCusList()
        }else{
          this.$message.error(res.data.messageModel.messageText);
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.dialogFormVisible = false
    },
    //获取渠道的下拉列表
    getQudaoList(){
        this.$axios
      .get(`/source/getSourceByType?type=1`)
      .then(res => {
        this.laiYuanList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      }); 
    },
    //获取平台的下拉列表
    getPingTaiList(){
        this.$axios
      .get(`/source/getSourceByType?type=2`)
      .then(res => {
        // console.log(res);
        this.pingTaiList = res.data.dataModel;
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YYYY-MM-DD");
    },
    ZongJi(value){
      if (value.length != 0) {
        let sum = 0
        for (let index = 0; index < value.length; index++) {
          sum += value[index].downMoney;
        }
        return sum
      }else{
        return 0
      }
    },
    quZheng(value){
      if (value != 0) {
        return Math.ceil(value)
      }else{
        return 0
      }
    },
  },
  created () {
    // console.log(Date.now());
    this.getCusList()
    //请求渠道可选
    this.getQudaoList() 
    //平台
    this.getPingTaiList() 
    //请求城市可选
    this.$axios
      .get(`/department/getAll`)
      .then(res => {
        // console.log(res);
        this.cityselectList = res.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang='scss'>
.rechargeDeplete-container {
  .el-dialog {
    // width: 35%;
    #dateOfBirth {
        /deep/.el-input__inner {
            padding-left: 30px!important;
        }
    }
    .el-form-item {
      // margin-top: 0;
      /deep/.el-form-item__label {
        width: 150px!important;
      }
      /deep/.el-form-item__content {
        margin-left: 150px!important;
      }
    }
    
  }
  //突出隔行的颜色差异
  .el-table {
    font-size: 12px;
    th {
      padding: 0;
    }
    .warning-row {
      background: #fff;
    }
     .success-row {
      background: #D3D3D3;
    }
    td {
      padding: 0;
      border-bottom: 1px solid rgb(211, 205, 205);
    }
     .el-button--mini{
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255,255,255,0.01);
    }
    .toEdit:hover {
      color:skyblue;
    }
  } 
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}

  
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important; 
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>