<template>
    <div class="CallRecords-container">
        <!-- 栅格 输入框 和按钮 -->
        <el-row class="filterCondition">
            <el-form  label-width="82px"  label-position="right"  ref="formLabelAlign"  :model="formLabelAlign">
                <el-form-item label="顾问" v-show="guwenshow">
                    <el-input v-model="formLabelAlign.userWords" placeholder="姓名/手机号均可"></el-input>
                </el-form-item>
                <!-- <el-form-item label="客户">
                     <el-input v-model="formLabelAlign.customerWords" placeholder="姓名/客户id均可"></el-input>
                 </el-form-item>
                 <el-form-item label="呼叫类型">
                     <el-select v-model="formLabelAlign.type" id="time"  placeholder="请选择呼叫类型">
                         &lt;!&ndash; <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option> &ndash;&gt;
                         <el-option label="不限" value=""></el-option>
 &lt;!&ndash;                        <el-option label="呼入" value=1></el-option>&ndash;&gt;
                         <el-option label="呼出" value=2></el-option>
                         <el-option label="未接通" value=3></el-option>
                     </el-select>
                 </el-form-item>-->
                <!--<el-form-item prop="scopeOf" label="层级">
                    <el-select v-model="formLabelAlign.scopeOf" placeholder="请选择层级">
                        <el-option label="忽略层级" value = ""  ></el-option>
                        <el-option label="城市" value="city" v-if="ShowCitys" ></el-option>
                        <el-option label="分公司" value="fenGS" v-if="ShowfenGS" ></el-option>
                        <el-option label="大区" value="quyu" v-if="Showquyu" ></el-option>
                        <el-option label="部门" value="bumen" v-if="Showbumen" ></el-option>
                        <el-option label="顾问" value="guwen" v-if="Showguwen" ></el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="时间">
                    <el-select  v-model="formLabelAlign.dayType"  id="time"  @change="holderQuJian"  placeholder="时间">
                        <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="value1" label="时间区间" v-show="isShow">
                    <div class="block">
                        <el-date-picker
                                v-model="value1"
                                type="daterange"
                                format="yyyy/MM/dd"
                                value-format="yyyy/MM/dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item class="BTN">
                    <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" :disabled="disTBN" class="check">查询</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="通话数据" name="1" >
                <!-- <p class="titleWithLine"><i class="el-icon-arrow-down"></i> 通话记录</p> -->
                <el-table  :data="JiLuData"  style="width: 100%;margin-top:10px;"  v-loading="loading"   border>
                    <el-table-column prop="userName" label="顾问"  ></el-table-column>
                    <el-table-column label="部门">
                        <template slot-scope="scope">
                            <p class="ell">{{ scope.row.parentName2 }}{{scope.row.parentName3 | panKong}}{{scope.row.parentName4 |panKong}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="通话时长"  align="center"><template slot-scope="scope">{{ scope.row.duration | GuoLvShiChang }}</template></el-table-column>
                    <el-table-column label="通话次数"  align="center"><template slot-scope="scope">{{ scope.row.callNum }}</template></el-table-column>
                    <el-table-column label="拨打次数"  align="center"><template slot-scope="scope">{{ scope.row.bodaNum }}</template></el-table-column>
                    <el-table-column label="接通率"  align="center">
                        <template slot-scope="scope">{{scope.row.jietongRate}}%</template>
                    </el-table-column>
                    <el-table-column label="平均通话时长"  align="center"><template slot-scope="scope">{{ scope.row.avgCallNum | GuoLvShiChang }}</template></el-table-column>
                    <el-table-column label="联系人数"  align="center"><template slot-scope="scope">{{ scope.row.lianxiNum}}</template></el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[20,50,100,200]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total"></el-pagination>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    //导入转码插件 amr
    // import BenzAMRRecorder from 'benz-amr-recorder'
    // var amr = new BenzAMRRecorder();
    // 导入时间戳
    import moment from "moment";
    import { log } from "util";
    export default {
        name: "PhoneConcats",
        // 数据
        data() {
            return {
                currentUser: {
                    currentName: "",
                    currentId: "",
                    currentLevel: "",
                    currentPhone: ""
                },
                activeNames: ['1'],
                guwenshow:true,
                showTimeKind: true,
                //未分割的完整版时间区间
                value1: "",
                //时间区间隐藏状态
                isShow: false,
                loading:false,
                pageConfig: {//表一页码参数
                    currentPage: 1, //当前页
                    pageSize: 20, //当前单页数据条数
                    total: 0 //请求到的数据总条数
                },
                kindtimeList: [
                    { id: "beizhuTime", name: "备注时间" },
                    { id: "fenpeiTime", name: "分配时间" }
                ],
                //时间选择生成内容
                timeList: [
                    { id: "today", name: "今日" },
                    { id: "yestoday", name: "昨日" },
                    { id: "sevenDay", name: "最近七天" },
                    { id: "tomonth", name: "本月" },
                    { id: "yesmonth", name: "上月" },
                    { id: "customize", name: "自定义" }
                ],
                //禁用按钮的状态值
                disTBN:false,
                //客户筛选区域数据
                formLabelAlign: {
                    userWords : "", //	顾问姓名
                    // customerWords: "", // 客户姓名
                    time: "", //	消耗时间
                    timeType: "", //时间类型
                    // type: "", //呼叫类型
                    dayType: "today",  //默认今日
                    beginTime: "", //开始时间
                    endTime: "", //	结束时间
                    pageSize: "", //分页大小
                    currentPage: "" //	当前页码
                },
                formLabelWidth: "180px",
                // 表格依赖于数据没有数据  会一行都没有
                JiLuData: [  ]
            };
        },
        methods: {
            //获取通话记录的方法
            getLuYinList() {
                this.loading = true
                this.disButton()
                this.$axios
                    .post(
                        `/talk/statistics?userWords=${this.formLabelAlign.userWords}&userId=${this.currentUser.currentid}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`
                    )
                    .then(res => {
                        // console.log(res);
                        //   let dataModel = res.data.dataModel;
                        if (res.data.messageModel.code == 0) {
                            this.loading = false
                            //通话记录
                            this.JiLuData = res.data.dataModel.list;
                            this.pageConfig.total = res.data.dataModel.total;
                            this.loading = false
                        } else {
                            this.$message.error(res.data.messageModel.messageText);
                            //通话记录
                            this.JiLuData = [];
                            this.pageConfig.total = 0;

                            this.loading = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleChange(val) {//折叠表格方法
                // console.log(val);
            },

            //清空所有筛选条件的方法
            clearAllCheck() {
                for (let i = 0; i < this.formLabelAlign.length; i++) {
                    this.formLabelAlign.formLabelAlign[i] = "";
                }
                this.value1 = ""; //未分割的完整版时间区间
            },
            //将按钮禁用两秒
            disButton(){
                this.disTBN = true
                setTimeout(() => {
                    this.disTBN = false
                }, 2000);
            },
            //判断时间种类是否禁用
            hiddenTimeKind(vId) {
                let obj = {};
                obj = this.kindtimeList.find(item => {
                    //这里的fenselectList就是上面遍历的数据源
                    return item.id === vId; //筛选出匹配数据
                });
                // console.log(obj);
                if (obj.name == "") {
                    this.showTimeKind = true;
                } else {
                    this.showTimeKind = false;
                }
            },
            //判断时间区间是否隐藏
            holderQuJian(vId) {
                let obj = {};
                obj = this.timeList.find(item => {
                    return item.id === vId; //筛选出匹配数据
                });
                if (obj.id == "customize") {
                    this.isShow = true;
                    this.formLabelAlign.dayType = "customize";
                }  else {
                    this.isShow = false;
                    this.value1 = ""
                }
            },
            //分页的方法
            handleSizeChange(val) {
                // console.log(val);
                if (this.pageConfig.currentPage != 1) {
                    this.pageConfig.currentPage = 1;
                    this.pageConfig.pageSize = val;
                    this.getLuYinList();
                } else {
                    this.pageConfig.pageSize = val;
                    this.getLuYinList();
                }
            },
            handleCurrentChange(val) {
                this.pageConfig.currentPage = val;
                this.getLuYinList();
                // }
            },

            //查询
            checkCus() {
                if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
                    this.formLabelAlign.beginTime = this.value1[0];
                    this.formLabelAlign.endTime = this.value1[1];
                    if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
                        this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
                        this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
                    }
                } else {
                    this.formLabelAlign.beginTime = "";
                    this.formLabelAlign.endTime = "";
                }
                this.getLuYinList()
            },
        },
        // 本地过滤器
        filters: {
            /* formatTime(value) {
                 if (value == null) {
                     return "";
                 }
                 return moment(value).format("YYYY-MM-DD HH:mm:ss");
                 //   return moment(value).format("YYYY-MM-DD");
             },
 */
            /*quZheng(val){
                return Math.ceil(val)
            },*/
            GuoLvShiChang(val){
                return Number(parseInt(val/60)) + "分" + Number(val%60) + "秒"
            },
            panKong(val) {
                if (val != null) {
                    return "--" + val
                }else{
                    return ""
                }
            },

        },
        created() {
            this.currentUser.currentName = window.sessionStorage.getItem("name")
            this.currentUser.currentid = window.sessionStorage.getItem("id")
            this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
            // this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
            this.currentUser.currentPhone = window.sessionStorage.getItem("phone")
            // this.$message('请先输入顾问名称进行查询');
            //如果是顾问不显示顾问查询框
            if (this.currentUser.currentLevel ==5) {
                this.guwenshow = false
            }
            //通话明细记录
            this.getLuYinList();
        }
    };
</script>

<style  lang='scss'>
    .CallRecords-container {
        padding: 20px;
        // margin: 0;
        //折叠图标
        .el-collapse-item__arrow {
            margin-left: 5px;
            font-weight: 900;
        }
        .el-collapse-item {
            th {
                background-color: #D7D7D7;
            }
            .el-collapse-item__header{
                background-color:#a0c5e8;
                height: 30px;
                border-radius: 3px;
                margin-bottom: 10px;
            }
        }
        .titleWithLine{
            border-bottom: 1.2px solid rgb(189, 189, 189);
        }
        .el-dialog {
            // width: 35%;
            #dateOfBirth {
                /deep/.el-input__inner {
                    padding-left: 30px !important;
                }
            }
            .el-form-item {
                margin-bottom: 5px;
                /deep/.el-form-item__label {
                    width: 150px !important;
                }
                /deep/.el-form-item__content {
                    margin-left: 150px !important;
                }
            }
        }
        .el-form-item.BTN {
            /deep/.el-form-item__content {
                margin-left: 20px !important;
            }
        }
        input.el-input__inner {
            width: 170px;
            height: 32px;
            padding-left: 10px;
        }

        //突出隔行的颜色差异
        .el-table {
            font-size: 12px;
            border: 1px solid grey;
            position: relative;
            .cell {
                padding:0 2px;
            }
            .el-table_9_column_83 {
                position: relative;
            }
            .el-table_9_column_84 {
                margin-left: 10px;
            }
            td {
                //取消单元格的上下padding
                padding: 0;
                border-right: 1px solid grey;
                border-bottom: 1px solid grey;
            }
            th {
                //取消单元格的上下padding
                padding: 0;
                border-right: 1px solid grey;
                border-bottom: 1px solid grey;
                .cell {
                    text-align: center;
                }
            }

            .el-button--mini {
                border: 0;
                padding: 5px 15px;
                margin: 5px 0;
                background: rgba(255, 255, 255, 0.01);
            }
            .toEdit:hover {
                color: skyblue;
            }
        }
        // 时间分类框样式
        input.el-input__inner#kinds,
        /deep/input.el-input__inner#time {
            // width: 85px;
            padding-left: 7px;
            height: 32px !important;
        }
        // 日期选择器
        daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
            width: 240px;
            height: 36px;
            vertical-align: middle;
            .el-date-editor .el-range__icon {
                line-height: 27px;
            }
            .el-date-editor .el-range-separator {
                width: 5%;
            }
            /deep/.block,
            #block {
                border: none !important;
                /deep/.el-date-editor--daterange {
                    width: 240px !important;
                }
            }
        }
        .el-form-item {
            display: inline-block;
            margin: 3px 0 3px 10px;
        }
        .el-form-item:nth-child(n + 6) {
            margin-top: 0;
        }
        .el-select.time {
            margin-left: 20px;
        }
        .el-input--suffix .el-input__inner.fanwei {
            padding-right: 30px;
            width: 30px;
        }
        .filterCondition {
            background-color: #fff;
            margin-bottom: 20px;
        }
        .check {
            margin-left: 22px;
            padding: 8px 20px;
        }
        .ell {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            // line-height: 0.9;
        }
        //分页框样式设置（避免页面被覆盖）
        .el-pagination .el-input__inner {
            width: 100px;
        }
        //多选框
        #bigBox {
            margin-top: 20px;
            #give {
                width: 41px;
                margin-left: 51px;
                font-size: 14px;
            }
            .el-checkbox {
                margin-right: 8px;
            }
            .duoxuankuang {
                margin-right: 10px;
            }
        }
    }
</style>
