<template>
  <el-container class="index-container">
    <el-header class="index-header" style="height: 80px">
      <!-- 头部部分 -->
      <el-row>
        <el-col :span="3">
          <div
            class="grid-content bg-purple"
            style="padding: 0px 10px 10px 10px"
          >
            <img
              v-if="this.currentUser.currentDepartmentName2 == '极易网'"
              class="logo"
              src="../assets/jiYiWang.png"
              alt
            />
            <img
              v-else-if="this.currentUser.currentDepartmentName2 == '鑫源网'"
              class="logo"
              src="../assets/xinYuanWang.png"
              alt
            />
            <!--            <img v-else-if="this.currentUser.currentDepartmentName2.indexOf('速') != -1" class="logo" src="../assets/white.jpg" alt>-->
            <!--            <img v-else-if="this.currentUser.currentDepartmentName2.indexOf('上海三分') != -1" class="logo" src="../assets/NewSuSan.png" alt>-->
            <!--            <img v-else src="../assets/logo-xmyd.png" style="width: 150px;margin-bottom: 15px" alt>-->
          </div>
        </el-col>
        <el-col :span="18">
          <div
            v-if="list.length"
            class="grid-content bg-purple-light title"
            style="display: flex; align-items: center; margin-top: 10px"
          >
            <!-- <span><img src="../assets/horn.png" alt=""> &nbsp;恭喜，赵大河本周的业绩取得第一名，特此公告。</span> -->
            <!-- <span
              >
              &nbsp;恭喜，XXX本周的业绩取得第一名，特此公告。</span
            > -->
            <img
              src="../assets/horn.png"
              style="width: 15px; height: 15px; margin-right: 10px"
              alt=""
            />
            <el-carousel
              height="50px"
              direction="vertical"
              :autoplay="true"
              style="width: 400px; line-height: 50px"
            >
              <el-carousel-item v-for="(item, index) in list" :key="index">
                <span class="medium" @click="goDetail(item)">{{
                  item.memoTxt
                }}</span>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            v-else
            class="grid-content bg-purple-light title"
            style="display: flex; align-items: center"
          >
            <img
              src="../assets/horn.png"
              style="width: 15px; height: 15px; margin-right: 10px"
              alt=""
            />

            <div class="el-carousel">暂无通知</div>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content bg-purple" id="bg-purple">
            <!--            <span><a class='download' :href='downloadUrl' download=""  title="下载软电话">软电话</a></span>-->
            <!-- <el-button type="danger" icon="el-icon-delete" circle></el-button> -->
            <el-button
              size="small"
              style="background-color: #c73e3a; color: #fff"
              class="xinXiKG"
              @click="closeJieShou"
              v-show="this.ShowxinXiKG"
              >已开启信息接收</el-button
            >
            <el-button
              size="small"
              style="background-color: #008c5e; color: #fff"
              class="xinXiKG"
              @click="OpenJieShou"
              v-show="!this.ShowxinXiKG"
              >已关闭信息接收</el-button
            >
            <el-button
              size="small"
              style="background-color: #c73e3a; color: #fff"
              @click="signOut"
              >退出登录</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-header>
    <!-- 主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="185px" class="index-aside">
        <!-- 用户信息显示区域 -->
        <div class="userinfo">
          <p>
            {{ this.currentName }}-{{ this.currentUser.currentid }}，欢迎您！
          </p>
          <div class="switch">
            <span class="qiehuan">切换</span>
            <el-select v-model="changeUser" id="guwen" placeholder="" disabled>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <button class="huanyuanbth" @click="getUnread">还原</button>
          </div>
        </div>
        <div id="extcon"></div>
        <!-- 无分层导航栏 -->
        <el-menu
          router
          :default-active="activeRouter"
          class="el-menu-vertical-demo"
          active-text-color="#ca4a47"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu index="1" v-if="showList.cusManagement">
            <template slot="title">
              <i class="el-icon-s-custom"></i>客户管理
            </template>
            <el-menu-item-group>
              <el-menu-item index="/myCustomer" v-if="showList.showCusForGuWen"
                ><i class="el-icon-user"></i>我的客户{{
                  yslCount
                }}</el-menu-item
              >
              <el-menu-item
                index="/customerList"
                v-if="showList.showCustomerList"
                ><i class="el-icon-user"></i>客户列表</el-menu-item
              >
              <el-menu-item
                index="/redistribution"
                v-if="showList.showCusForGuWen"
                ><i class="el-icon-refresh-right"></i>再分配客户{{
                  zfpCount
                }}</el-menu-item
              >
              <el-menu-item index="/teamCus" v-if="showList.showTeamCus"
                ><i class="el-icon-user-solid"></i>团队客户</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/FinExamine" v-if="showList.showFinExamine"
            ><i class="el-icon-document-copy"></i>业绩管理</el-menu-item
          >
          <el-menu-item index="/Leaderboard" v-if="showList.showLeaderboard"
            ><i class="el-icon-s-data"></i>业绩排行榜</el-menu-item
          >

          <!--            <el-submenu index="5"  v-if="showList.showTongHua"><template slot="title"><i class="el-icon-phone-outline"></i>通话管理</template>
              <el-menu-item-group>
                <el-menu-item index="CallRecords"><i class="el-icon-edit-outline"></i>通话记录</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="CallConcats"><i class="el-icon-edit-outline"></i>通话数据</el-menu-item>
              </el-menu-item-group>
            </el-submenu>-->
          <el-menu-item
            index="/FinRechargeRecord"
            v-if="showList.showFinExamine"
            ><i class="el-icon-coin"></i>加盟充值记录</el-menu-item
          >
          <el-submenu index="2" v-if="showList.showTongji"
            ><template slot="title">
              <i class="el-icon-data-line"></i>统计分析</template
            >
            <el-menu-item-group>
              <el-menu-item index="/dataStatistics"
                ><i class="el-icon-data-analysis"></i>数据统计</el-menu-item
              >
              <!-- <el-menu-item index="ResEfficiency" ><i class="el-icon-watch"></i>数据处理效率</el-menu-item>
                <el-menu-item index="BankAnalysis" ><i class="el-icon-pie-chart"></i>进件银行分析</el-menu-item>
                <el-menu-item index="PerformanceAnalysis" ><i class="el-icon-s-finance"></i>业绩数据分析</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" v-if="showList.showCaiWu">
            <template slot="title">
              <i class="el-icon-notebook-1"></i>财务管理</template
            >
            <el-menu-item-group>
              <el-menu-item index="/examine"
                ><i class="el-icon-edit-outline"></i>在审件管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4" v-if="showList.showYunYing"
            ><template slot="title">
              <i class="el-icon-shopping-bag-2"></i>运营管理
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="/SourceStatistics"
                v-if="showList.showLaiYuan"
                ><i class="el-icon-receiving"></i>来源管理</el-menu-item
              >
              <el-menu-item
                index="/SupplyConfiguration"
                v-if="showList.showGongLiang"
                ><i class="el-icon-s-operation"></i>供量配置</el-menu-item
              >
              <el-menu-item index="/rechargeRecord"
                ><i class="el-icon-edit"></i>充值记录</el-menu-item
              >
              <el-menu-item index="/rechargeDeplete"
                ><i class="el-icon-toilet-paper"></i>充值消耗</el-menu-item
              >
              <el-menu-item index="/PromotionAnalysis"
                ><i class="el-icon-s-promotion"></i>申请跑量分析</el-menu-item
              >
              <el-menu-item index="/toBReport"
                ><i class="el-icon-s-order"></i>对公数据报表</el-menu-item
              >
              <!-- <el-menu-item index="popularizeAnalysis"><i class="el-icon-s-order"></i>推广数据分析</el-menu-item> -->
              <!-- <el-menu-item index="dataAnalysis"><i class="el-icon-pie-chart"></i>数据分析</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item
            index="/department"
            v-if="showList.showDepartmnet"
            style="bumenshow"
            ><i class="el-icon-s-shop"></i>部门管理</el-menu-item
          >
          <el-submenu index="5" v-if="showList.showLog"
            ><template slot="title">
              <i class="el-icon-s-order"></i>日志管理
            </template>
            <el-menu-item-group>
              <el-menu-item index="/MyLog" v-if="showList.showLog"
                ><i class="el-icon-tickets"></i>我的日志</el-menu-item
              >
              <el-menu-item index="/TeamLog" v-if="showList.showTeamLog"
                ><i class="el-icon-document-copy"></i>团队日志</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <keep-alive>
        <el-main class="index-main" id="index-main">
          <!-- tabs -->
          <my-tabs />
          <!-- 增加router-view -->
          <keep-alive
            exclude="Leaderboard,dataStatistics,rechargeRecord,rechargeDeplete,SupplyConfiguration,MyTabs,BankAnalysis,PromotionAnalysis,PromotionAnalysis"
          >
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </keep-alive>
    </el-container>
  </el-container>
</template>

<script>
import { log } from "util";
import { mapMutations } from "vuex";
import myTabs from "./MyTabs";
export default {
  name: "index",
  data() {
    return {
      list: [],
      // downloadUrl: "./assets/eyeBeam.rar",
      currentUser: {
        currentName: "",
        currentId: "",
        currentLevel: "",
        currentPhone: "",
        currentIsNeedData: "",
        currentDepartmentId: "",
        currentDepartmentName2: "",
      },
      currentName: "", //当前登录用户的姓名
      ShowxinXiKG: "", //信息开关按钮显隐
      //tabs存值判重
      title: "",
      name: "",
      //动态改变默认router
      activeRouter: "/myCustomer",
      options: [
        { value: "选项1", label: "小明" },
        { value: "选项5", label: "小冉" },
      ],
      changeUser: "",
      bumenshow: "",
      cusManagement: true, //客户管理一级tab
      showList: {
        showCustomerList: true,
        showYunYing: true,
        showCusForGuWen: true,
        showDepartmnet: true,
        showLeaderboard: true,
        showTongji: true,
        showCaiWu: true,
        cusManagement: true,
        showFinExamine: false,
        showTeamCus: true,
        showLog: true,
        showTeamLog: true,
        showLaiYuan: true,
        showGongLiang: true,
        // showTongHua:true //通话记录
      },
      time: null,
      ws: null,
    };
  },
  methods: {
    ...mapMutations(["Add_Tab"]),
    setWeb() {
      console.log(this.$socket());
      // 连接建立事件
      this.$socket().onopen = () => {
        this.$socket().send("HEARTBEAT");
        this.ws = setInterval(() => {
          console.log("心跳");
          this.$socket().send("HEARTBEAT");
        }, 10000);
      };
      // 消息接收
      this.$socket().onmessage = (message) => {
        console.log("Received message:", message.data);
      };
    },
    // 获取备忘列表
    getBw() {
      this.$axios
        .post(
          `/customer/getCustomerMemoList?userId=${sessionStorage.getItem("id")}`
        )
        .then(({ data }) => {
          this.list = data.dataModel;
        });
    },
    goDetail(row) {
      this.$axios
        .post(`/customer/updateCustomerMemoRead?id=${row.id}`)
        .then(({ data }) => {
          this.getBw();
        });
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    //关闭数据开关
    closeJieShou() {
      this.$axios
        .get(`/user/openData?userId=${this.currentUser.currentid}&bool=false`)
        .then((res) => {
          if (res.data.messageModel.code == 0) {
            this.$message.success("数据开关已关闭，当前状态不接收新数据");
            this.ShowxinXiKG = false;
            window.sessionStorage.setItem("isNeedData", false);
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //打开数据开关
    OpenJieShou() {
      this.$axios
        .get(`/user/openData?userId=${this.currentUser.currentid}&bool=true`)
        .then((res) => {
          if (res.data.messageModel.code == 0) {
            this.$message.success(
              "数据开关已开启，请及时查看并处理新数据(#^.^#)"
            );
            this.ShowxinXiKG = true;
            window.sessionStorage.setItem("isNeedData", true);
          } else {
            this.$message.error(res.data.messageModel.messageText);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //退出登录
    signOut() {
      this.$confirm("此操作将退出登录状态, 是否继续?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({ type: "success", message: "您成功已退出登录" });
          window.sessionStorage.clear();
          this.$store.commit("clearEditableTabs");
          this.$router.push("login");
          this.$socket().close();
          this.$socket("close");
          clearInterval(this.ws);
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消退出登录" });
        });
    },
    addTab(name, title) {
      let newTabName = ++this.tabIndex + "";
      this.editableTabs.push({
        title: title,
        name: name,
      });
      // this.editableTabsValue = newTabName;
      this.editableTabsValue = name;
      this.$router.push(`${this.editableTabsValue}`);
      //
      this.handleClick();
    },
    //tabs
    removeTab(targetName) {
      // console.log(targetName);
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;

      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      // 判断是否全部移除完，标签页全部移除则返回首页
      if (this.editableTabs.length !== 0) {
        this.$router.push({ path: `${this.editableTabsValue}` });
      } else {
        this.$router.push({ path: "/" });
      }
    },
    // 切换便签页
    handleClick(tab, event) {
      // console.log(this.editableTabsValue);
      this.$router.push(`${this.editableTabsValue}`);
    },
    // 添加tabs的方法
    changeActive(val, value) {
      localStorage.setItem("activeRouter", val);
      this.activeRouter = localStorage.getItem("activeRouter");
    },
    // 获取未处理的数据数量
    getUnread() {
      this.$axios
        .get(`/customer/getCusCount?userId=${this.currentUser.currentid}`)
        .then((res) => {
          // console.log(res);
          if (res.data.messageModel.code != 0) {
            this.$message.error(res.data.messageModel.messageText);
          } else {
            this.$store.commit("getUnread", res.data.dataModel);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    //呼入定时器
    /* extTry() {
      var  urlext="http://www.daqid.com/cc/extsjsonHtml/?ss=";  // here new url ...
      urlext=  urlext +  Math.random()
      $.getScript( urlext );
      // alert(urlext)
      // window.setTimeout("extTry()",3000);
    },*/
  },
  /*mounted(){
    $.getScript("http://192.168.5.124/cc/pop2/801",function(){

    })
    $.getScript("http://192.168.5.124/cc/extsjson/",function(){
      updateExtjson( extsJson );
    })
    function updateExtjson(res){
      $("#extcon").html(res.exts);
    }
    setInterval(this.extTry, 3000);
  },*/
  mounted() {
    if (sessionStorage.getItem("id")) {
      this.setWeb();
    }
    this.activeRouter = String(this.$route.path);
    console.log(this.activeRouter);
    this.getBw();
    if (this.time) {
      clearInterval(this.time);
      this.time = null;
    }
    this.time = setInterval(() => {
      this.getBw();
    }, 20000);
  },
  created() {
    this.currentUser.currentName = window.sessionStorage.getItem("name");
    this.currentUser.currentid = window.sessionStorage.getItem("id");
    this.currentUser.currentDepartmentId =
      window.sessionStorage.getItem("departmentId");
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel");
    this.currentUser.currentIsNeedData =
      window.sessionStorage.getItem("isNeedData");
    this.currentUser.currentDepartmentName2 =
      window.sessionStorage.getItem("departmentName2");
    this.currentUser.currentPhone = window.sessionStorage.getItem("phone");
    if (this.currentUser.currentIsNeedData == "true") {
      this.ShowxinXiKG = true;
    } else {
      this.ShowxinXiKG = false;
    }
    if (this.currentUser.currentLevel == 10) {
      //运营
      this.showList.showCusForGuWen = false;
      this.showList.showTeamCus = false;
      this.showList.showLeaderboard = false;
      this.showList.showLog = false;
      this.showList.showTeamLog = false;
      this.showList.showDepartmnet = true;
      // this.showList.showTongHua = false;
      this.currentUser.isOperate = window.sessionStorage.getItem("isOperate");
      if (this.currentUser.isOperate == 1) {
        this.showList.showDepartmnet = false;
        this.showList.showCaiWu = false;
        this.showList.showLaiYuan = false;
        this.showList.showGongLiang = false;
      }
      // if (this.currentUser.currentPhone == 13268210642 || this.currentUser.currentPhone == 13544215057) {
      //   this.showList.showDepartmnet = true;
      // }else{
      //   this.showList.showDepartmnet = false;
      // }
      this.$router.push("customerList");
      // this.$router.go(0)
    }
    if (
      this.currentUser.currentLevel != 0 &&
      this.currentUser.currentLevel < 5
    ) {
      //管理层
      // this.showList.showCustomerList = false;
      this.showList.showYunYing = false;
      this.showList.showLog = true; //暂时仅限管理员可见
      this.showList.showTeamLog = true; //暂时仅限管理员可见
      // this.showList.showTongHua = true;//通话记录  临时禁止！！！
    }
    if (this.currentUser.currentLevel == 5) {
      //顾问
      this.showList.showCustomerList = false;
      this.showList.showDepartmnet = false;
      this.showList.showLeaderboard = false;
      this.showList.showYunYing = false;
      this.showList.showTongji = false;
      this.showList.showTeamCus = false;
      this.showList.showLog = true; //暂时仅限管理员可见
      this.showList.showTeamLog = false; //暂时仅限管理员、业务端领导可见
      // this.showList.showTongHua = false;//通话记录  临时禁止！！！
    } else if (this.currentUser.currentLevel == 12) {
      //人事
      alert("当前登陆用户所属部门为HR，请联系管理员获取相关权限");
      this.$router.push("login");
    }
    if (this.currentUser.currentLevel == 11) {
      //财务
      for (let key in this.showList) {
        this.showList[key] = false;
      }
      this.showList.showFinExamine = true;
      this.showList.showLeaderboard = true;
      this.showList.showLog = false;
      this.$router.push("FinExamine");
    } else {
      this.showList.showFinExamine = false;
    }
    if (window.sessionStorage.getItem("prohibitLogin") == "true") {
      this.currentName = window.sessionStorage.getItem("name");
      //获取未处理数据数量
      this.getUnread();
    } else if (window.sessionStorage.getItem("prohibitLogin") == "false") {
      this.$message.warning("您已被禁止登陆");
      this.$router.push("login");
    } else {
      this.$message.warning("请先进行登录操作！");
      this.$router.push("login");
    }
    this.Add_Tab({
      name: this.$route.meta.name,
      path: this.$route.path,
    });
    // console.log(this.$store.state.common.zfpCount);

    //用户进入系统流量数超过一定数量给与提示
    this.$axios
      .get(`customer/getTotalDisCount?userId=${this.currentUser.currentid}`)
      .then((res) => {
        let level = this.currentUser.currentLevel;
        let count = res.data.total;
        if (level == 4 && count >= 900 && count < 1000) {
          this.$alert(
            "您的客户数量已达" + count + "条，" + "即将达到1000条,请及时处理"
          );
        }
        if (level == 4 && count >= 1000) {
          this.$alert("您的客户数量已达" + count + "条，" + "将不会再进数据");
        }
        if (level == 5 && count >= 480 && count < 500) {
          this.$alert(
            "您的客户数量已达" + count + "条，" + "即将达到300条,请及时处理"
          );
        }
        if (level == 5 && count >= 500) {
          this.$alert("您的客户数量已达" + count + "条，" + "将不会再进数据");
        }
      })
      .catch();
  },
  components: {
    myTabs,
  },
  computed: {
    zfpCount() {
      return this.$store.state.common.zfpCount;
    },
    yslCount() {
      return this.$store.state.common.yslCount;
    },
  },
  activated() {},
};
</script>

<style lang="scss">
.el-input__icon {
  line-height: 20px;
}
.el-menu {
  border-right: 0;
}
.el-menu-item,
.el-submenu__title {
  border-bottom: 1px solid #ececec;
}
.index-container {
  height: 100%;
  .el-menu-vertical-demo {
    .el-menu-item-group__title {
      padding: 0;
    }
    ul {
      overflow: hidden;
      li ul li {
        font-size: 12px;
        background-color: rgba(246, 246, 246, 1);
      }
    }
  }
  .index-header {
    line-height: 80px;
    #bg-purple {
      width: 500px;
      height: 80px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;
    }
    .logo {
      margin-top: 16px;
      // margin-left: 34px;
      margin-left: 12px;
    }
    .title {
      color: #5990f7;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .index-aside {
    border-top: 1px solid #ef4553;
    .userinfo {
      height: 110px;
      padding-left: 15px;
      border-bottom: 1px solid #ececec;
      p {
        margin-top: 40px;
      }
      .switch {
        // /deep/.el-input__prefix, .el-input__suffix {
        //   top: 10px!important;
        // }
        .qiehuan {
          margin-right: 10px;
        }
        .huanyuanbth {
          height: 20px;
          width: 45px;
          background-color: #c73e3a;
          margin-top: 2px;
          vertical-align: top;
          margin-left: 10px;
          border: 1px solid grey;
          border-radius: 3px;
          color: #f7f7f7;
        }
      }
      .el-button {
        width: 50px;
        height: 20px;
        margin-top: 2px;
        margin-left: 10px;
        vertical-align: top;
      }
    }
    #guwen {
      width: 60px;
      height: 20px;
      padding-left: 0;
    }
  }
  .index-main {
    background-color: #f7f7f7;
    padding: 10px;
    overflow: hidden;
    //全局去除table的hover效果
    tr:hover > td {
      background-color: initial !important;
    }
    tr.current-row > td {
      background-color: initial !important;
    }
    .el-tabs {
      background-color: #fff;
      border-bottom: 1px solid rgb(58, 138, 199);
      width: 100%;
      .el-tabs__item {
        // border: 1px solid rgb(58, 138, 199);
        // border-bottom: none;
        background-color: rgb(194, 217, 228);
      }
      .el-tabs__header {
        margin: 0;
      }
    }
  }
}
.medium {
  cursor: pointer;
}
</style>
