<template>
  <!-- 根节点 -->
  <router-view></router-view>
</template>

<script>

export default {
  name: "app"
};
</script>

<style >

</style>
