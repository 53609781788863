<template>
  <div class="toBReport-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <div id="bigBox" style="display:flex">
        <span id="give">城市:</span>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >全选</el-checkbox>
        <div class="duoxuankuang" style="display:inline">
          <el-checkbox-group v-model="checkedCity" @change="handleCheckedCityChange">
            <el-checkbox
              v-for="(dange,index) in cityList"
              :label="dange"
              :key="index"
            >{{dange.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <el-form
        label-width="82px"
        label-position="right"
        ref="formLabelAlign"
        :model="formLabelAlign"
      >
        <el-form-item prop="timeType" label="时间">
          <el-select
            v-model="formLabelAlign.dayType"
            id="time"
            @change="holderQuJian"
            placeholder="时间"
          >
            <el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="BTN">
          <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" :disabled="disTBN" class="check">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table
      :data="tableData"
      style="width: 100%"
      :row-style="headerColor"
      :span-method="objectSpanMethod"
      v-loading="loading"
      border
    >
      <el-table-column prop="parentName" label="渠道" width="95" ></el-table-column>
      <el-table-column prop="name" label="平台" width="80"></el-table-column>
      <el-table-column prop="downMoney" label="消费" width="60" align="center"></el-table-column>
      <el-table-column prop="reallyDownMoney" label="真实消费" width="60" align="center"></el-table-column>
      <el-table-column prop="sqCount" label="申请" width="45" align="center"></el-table-column>
      <el-table-column prop="avgDownMoney" label="均价" width="30" align="center"></el-table-column>
      <el-table-column prop="keDaiCount" label="可贷数" width="50" align="center"></el-table-column>
      <el-table-column label="可贷率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.keDaiRate }}%</template>
      </el-table-column>
      <el-table-column prop="keDaiAvgMoney" label="可贷均价" width="40" align="center"></el-table-column>
      <el-table-column prop="youzhiCount" label="优质数" width="40" align="center"></el-table-column>
      <el-table-column label="优质率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.youzhiRate }}%</template>
      </el-table-column>
      <el-table-column prop="youzhiAvgMoney" label="优质均价" width="40" align="center"></el-table-column>
      <el-table-column label="待跟进率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.dgjRate }}%</template>
      </el-table-column>
      <el-table-column label="重复数" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.repeatCount }}</template>
      </el-table-column>
      <el-table-column label="重复率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.repeatRate }}%</template>
      </el-table-column>
      <el-table-column label="捣乱率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.daoLuanRate }}%</template>
      </el-table-column>
      <el-table-column label="异地率" width="40" align="center">
        <template slot-scope="scope">{{ scope.row.yidiRate }}%</template>
      </el-table-column>
      <el-table-column prop="chuangShou" label="创收" width="60" align="center"></el-table-column>
      <el-table-column label="单条数据利润" width="30" align="center">
        <template slot-scope="scope">{{ scope.row.danTiaoLiRun | quZheng}}</template>
      </el-table-column>
      <el-table-column label="转化率" width="30" align="center">
        <template slot-scope="scope">{{ scope.row.zhuangHuaRate }}%</template>
      </el-table-column>
      <el-table-column label="放款件均" width="50" align="center">
        <template slot-scope="scope">{{ scope.row.kuangKuanAvg | quZheng}}</template>
      </el-table-column>
      <el-table-column prop="roi" label="ROI" width="35" align="center"></el-table-column>
      <el-table-column prop="dqFangKuangCount" label="当前放款笔数" width="30" align="center"></el-table-column>
      <el-table-column label="当前放款率">
        <template slot-scope="scope">{{ scope.row.dqFangKuangRate}}%</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageConfig.currentPage"
      :page-sizes="[100, 200]"
      :page-size="pageConfig.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageConfig.total"
    ></el-pagination>
  </div>
</template>

<script>
// 导入时间戳
import moment from "moment";
import { log } from "util";
export default {
  name: "toBReport",
  // 数据
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checkedCity: [],
      cityList: [], //城市列表
      cityId: [], //城市ID
      ShowCity: false,
      currentUser: {
        currentName: "",
        currentId: "",
        currentLevel: "",
        currentPhone: ""
      },
      showTimeKind: true,
      //未分割的完整版时间区间
      value1: "",
      //时间区间隐藏状态
      isShow: false,
      loading:true,
      //页码参数
      pageConfig: {
        currentPage: 1, //当前页
        pageSize: 100, //当前单页数据条数
        total: 0 //请求到的数据总条数
      },
      kindtimeList: [
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" }
      ],
      //时间选择生成内容
      timeList: [
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" }
      ],
      //禁用按钮的状态值
      disTBN:false,
      //客户筛选区域数据
      formLabelAlign: {
        level: "", //	星级
        city: "", //	状态
        time: "", //	消耗时间
        timeType: "", //时间类型
        dayType: "",
        beginTime: "", //开始时间
        endTime: "", //	结束时间
        pageSize: "", //分页大小
        currentPage: "" //	当前页码
      },
      dialogFormVisible: false,
      formLabelWidth: "180px",
      // 表格依赖于数据没有数据  会一行都没有
      tableData: [],
      xinXiLiu:[], //信息流系 的index
      souSuoXi:[], //搜索系的index
      heZuoQD:[], //合作渠道的index
      QiTa:[] //合作渠道的index
    };
  },
  methods: {
    //设置总计行样式并分别遍历筛选出同类的index集合
    headerColor({ row, rowIndex }) {
      if (row.parentName == "合计") {
        return { 'background-color':'#82bfea','color':'#C73E3A'};
      }
      if (row.parentName == "信息流系/合计") {
        return { 'background-color':'#e3dbba','color':'#000'};
      }
      if (row.parentName == "渠道合作/合计") {
        return { 'background-color':'#b2ce95','color':'#000'};
      }
      if (row.parentName == "搜索系/合计") {
        return { 'background-color':'#d1dcb6','color':'#000'};
      }
      if (row.parentName == "其它/合计") {
        return { 'background-color':'#C57CAC','color':'#000'};
      }
      if (row.name == null) {
        return { 'background-color':'#55C7B0','color':'#C73E3A'};
      }
      if (row.parentName == "搜索系") {
        if (this.souSuoXi.indexOf(rowIndex) == -1) {
          this.souSuoXi.push(rowIndex)
        }
        return { 'background-color':'#d1dcb6','color':'#000'};
      }

      if (row.parentName == "信息流系") {
        if (this.xinXiLiu.indexOf(rowIndex) == -1) {
          this.xinXiLiu.push(rowIndex)
        }
    //
        return { 'background-color':'#e3dbba','color':'#000'};
      }
      if (row.parentName == "渠道合作") {
        if (this.heZuoQD.indexOf(rowIndex) == -1) {
          this.heZuoQD.push(rowIndex)
        }
        return { 'background-color':'#b2ce95','color':'#000'};
      }
      if (row.parentName == "其它") {
        if (this.QiTa.indexOf(rowIndex) == -1) {
          this.QiTa.push(rowIndex)
        }
        return { 'background-color':'#C57CAC','color':'#000'};
      }
    },
    handleCheckAllChange(val) {

      this.checkedCity = val ? this.cityList : [];
      if (this.checkedCity != val) {
        this.cityList.forEach(item => {
          this.cityId.push(item.name);
        });
      } else {
        this.checkAll = false;
        this.cityId = [];
      }
      this.isIndeterminated = false;
    },
    //获取选中城市
    handleCheckedCityChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cityList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cityList.length;
      this.cityId = [];
      for (let i = 0; i < value.length; i++) {
        this.cityId.push(value[i].name);
      }
    },
    //获取报表的方法
    getReportList() {
      this.loading = true
      this.disButton()
      if (this.cityId == []) {
        this.$message.error("请选择城市进行查询");
        return false;
      }
      this.$axios
        .post(
          `/consumptionLog/getPublicDataReport?city=${this.cityId}&userId=${this.currentUser.currentId}&userLevel=${this.currentUser.currentLevel}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`
        )
        .then(res => {
          // console.log(res);
          let dataModel = res.data.dataModel;
          if (res.data.messageModel.code == 0) {
            this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
            this.loading = false
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.tableData = [];
            this.pageConfig.total = 0;
            this.loading = false
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //清空所有筛选条件的方法
    clearAllCheck() {
      for (let i = 0; i < this.formLabelAlign.length; i++) {
        this.formLabelAlign.formLabelAlign[i] = "";
      }
      this.value1 = ""; //未分割的完整版时间区间
    },
    //重排tableData的方法
    Rearrange(array){
      let NewArray = []
      NewArray = array;
/*      let ziyin = []
      let hezuo = []
      //插入第一行总计
    NewArray.push(array[0])
      for (let i = 0; i < array.length; i++) {
        if(array[i].parentName.indexOf("自营") != -1 ){
          ziyin.push(array[i])
        }else if (array[i].parentName.indexOf("合作渠道") != -1 || array[i].parentName.indexOf("合作渠道/合计") != -1){
          hezuo.push(array[i])
        }
      }
      if (ziyin.length > 0) {
        for (let j = 0; j < ziyin.length; j++) {
          NewArray.push(ziyin[j])
        }
      }
      if (hezuo.length > 0) {
        for (let j = 0; j < hezuo.length; j++) {
          NewArray.push(hezuo[j])
        }
      }*/
      // console.log(NewArray);
      this.tableData = NewArray;
      this.loading = false
    },
    //同类数据行合并
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {

        // if (rowIndex == this.xinXiLiu[0]) {  return {  rowspan: this.xinXiLiu.length,  colspan: 1  };  }
        // else if ( this.xinXiLiu[0] < rowIndex && rowIndex < this.xinXiLiu[0] + this.xinXiLiu.length) {  return [0, 0];}
        // else if (rowIndex == this.souSuoXi[0]) {  return {  rowspan: this.souSuoXi.length,  colspan: 1  };  }
        // else if ( this.souSuoXi[0] < rowIndex && rowIndex < this.souSuoXi[0] + this.souSuoXi.length) {  return [0, 0];}
        // else if (rowIndex == this.heZuoQD[0]) {  return {  rowspan: this.heZuoQD.length,  colspan: 1  };  }
        // else if ( this.heZuoQD[0] < rowIndex && rowIndex < this.heZuoQD[0] + this.heZuoQD.length) {  return [0, 0];}
        // else if (rowIndex == this.QiTa[0]) {  return {  rowspan: this.QiTa.length,  colspan: 1  };  }
        // else if ( this.QiTa[0] < rowIndex && rowIndex < this.QiTa[0] + this.QiTa.length) {  return [0, 0];}
        // else {  return {  rowspan: 1,  colspan: 1  };  }
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //判断时间种类是否禁用
    hiddenTimeKind(vId) {
      let obj = {};
      obj = this.kindtimeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj);
      if (obj.name == "") {
        this.showTimeKind = true;
      } else {
        this.showTimeKind = false;
      }
    },
    //判断时间区间是否隐藏
    holderQuJian(vId) {
      let obj = {};
      obj = this.timeList.find(item => {
        return item.id === vId; //筛选出匹配数据
      });
      if (obj.id == "customize") {
        this.isShow = true;
        this.formLabelAlign.dayType = "customize";
      }  else {
        this.isShow = false;
        this.value1 = ""
      }
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getReportList();
      } else {
        this.pageConfig.pageSize = val;
        this.getReportList();
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.getReportList();
      // }
    },
    //查询
    checkCus() {
      this.loading = true
      // if (this.cityId.length == 0) {  this.$message.error("请选择城市进行查询");  return false}
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = this.value1[0];
        this.formLabelAlign.endTime = this.value1[1];
        if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
          this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
          this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        }
      } else {
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }
      this.$axios
        .post(
          `/consumptionLog/getPublicDataReport?city=${this.cityId}&userId=${this.currentUser.currentId}&userLevel=${this.currentUser.currentLevel}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&pageSize=100&currentPage=1`
        )
        .then(res => {
          // console.log(res);
          let array = []
          if (res.data.messageModel.code == 0) {
            if (res.data.dataModel.total == 0) {
              this.$message("抱歉，没有找到符合搜索条件的客户");
              this.clearAllCheck();
              this.tableData = [];
              this.loading = false
              return false;
            }
            this.$message.success(  "根据您的搜索条件，已为您找到以下客户(#^.^#)" );
            // this.tableData = res.data.dataModel.list;
            array = res.data.dataModel.list;
            this.Rearrange(array)
            this.pageConfig.total = res.data.dataModel.total;
            this.loading = false
          } else {
            this.$message.error(res.data.messageModel.messageText);
            this.clearAllCheck();
            this.loading = false
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //添加充值
    addChongZhi() {
      this.dialogFormVisible = false;
      alert("添加新的充值咯");
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return "";
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YYYY-MM-DD");
    },
    quZheng(val){
      return Math.ceil(val)
    }
  },

  created() {
    this.loading = true
    this.formLabelAlign.dayType = "customize"
    this.isShow = true;
      let datetime = new Date();
     let year = datetime.getFullYear();
     let month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
     let date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
     this.formLabelAlign.beginTime = (year+'/'+ month +'/'+ date + " " + "00:00:00").toString();
     this.formLabelAlign.endTime = (year+'/'+ month +'/'+ date + " " + "23:59:59").toString();
    // alert(this.formLabelAlign.beginTime)
    this.currentUser.currentName = window.sessionStorage.getItem("name")
    this.currentUser.currentId = window.sessionStorage.getItem("id")
    this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
    //拿到所有城市列表
    this.$axios
      .get(`/department/getAll`)
      .then(res => {
        // console.log(res);
        this.cityList = res.data;
      })
      .catch(error => {
        console.log(error);
      });
    //获取所有报表
    this.$axios
      .get(
        // `/consumptionLog/getPublicDataReport?city=${this.cityId}&userId=${this.currentUser.currentId}&userLevel=${this.currentUser.currentLevel}&dayType=${this.formLabelAlign.dayType}&pageSize=100&currentPage=1`
              `/consumptionLog/getPublicDataReport?city=${this.cityId}&userId=${this.currentUser.currentId}&userLevel=${this.currentUser.currentLevel}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&pageSize=100&currentPage=1`
      )
      .then(res => {
        let array = []
        if (res.data.messageModel.code == 0) {
          // this.tableData = res.data.dataModel.list;
          array = res.data.dataModel.list;
          this.Rearrange(array)
          this.pageConfig.total = res.data.dataModel.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.messageModel.messageText);
          this.tableData = [];
          this.pageConfig.total = res.data.dataModel.total;
          this.loading = false
        }
        this.value1[0]=this.formLabelAlign.beginTime;
        this.value1[1]=this.formLabelAlign.endTime;
      })
      .catch(error => {
        console.log(error);
      });

  }
};
</script>

<style  lang='scss'>
.toBReport-container {
  .el-dialog {
    // width: 35%;
    #dateOfBirth {
      /deep/.el-input__inner {
        padding-left: 30px !important;
      }
    }
    .el-form-item {
      margin-bottom: 5px;
      /deep/.el-form-item__label {
        width: 150px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 150px !important;
      }
    }
  }
  .el-form-item.BTN {
    /deep/.el-form-item__content {
      margin-left: 20px !important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
  }

  //突出隔行的颜色差异
  .el-table {
    font-size: 12px;
    border: 1px solid grey;
    position: relative;
    .cell {
      padding:0 2px;
    }
    .el-table_9_column_83 {
      position: relative;
    }
    .el-table_9_column_84 {
      margin-left: 10px;
    }
    td {
      //取消单元格的上下padding
      padding: 0;
      border-right: 1px solid grey;
      border-bottom: 1px solid grey;
    }
    th {
      //取消单元格的上下padding
      padding: 0;
      border-right: 1px solid grey;
      border-bottom: 1px solid grey;
      .cell {
      text-align: center;
    }
    }

    .el-button--mini {
      border: 0;
      padding: 5px 15px;
      margin: 5px 0;
      background: rgba(255, 255, 255, 0.01);
    }
    .toEdit:hover {
      color: skyblue;
    }
  }
  // 时间分类框样式
  input.el-input__inner#kinds,
  /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px !important;
  }
  // 日期选择器
  daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
    .el-date-editor .el-range-separator {
      width: 5%;
    }
    /deep/.block,
    #block {
      border: none !important;
      /deep/.el-date-editor--daterange {
        width: 240px !important;
      }
    }
  }
  .el-form-item {
    display: inline-block;
    margin: 3px 0 3px 10px;
  }
  .el-form-item:nth-child(n + 6) {
    margin-top: 0;
  }
  .el-select.time {
    margin-left: 20px;
  }
  .el-input--suffix .el-input__inner.fanwei {
    padding-right: 30px;
    width: 30px;
  }
  .filterCondition {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .check {
    margin-left: 22px;
    padding: 8px 20px;
  }
  .ell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 0.9;
  }
  //分页框样式设置（避免页面被覆盖）
  .el-pagination .el-input__inner {
    width: 100px;
  }
  //多选框
  #bigBox {
    margin-top: 20px;
    #give {
      width: 41px;
      margin-left: 51px;
      font-size: 14px;
    }
    .el-checkbox {
      margin-right: 8px;
    }
    .duoxuankuang {
      margin-right: 10px;
    }
  }
}
</style>
