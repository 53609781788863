<template>
  <div class="dataStatistics-container">
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
      <el-form label-width="82px" label-position="right" ref="formLabelAlign"  :model="formLabelAlign">
        <el-form-item prop="city" label="城市" v-if="ShowCity">
          <el-select v-model="formLabelAlign.city" placeholder="请选择城市" >
            <el-option label="所有" value="all"></el-option>
              <el-option
                v-for="item in cityselectList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item prop="scopeOf" label="层级">
          <el-select v-model="formLabelAlign.scopeOf" placeholder="请选择层级">
            <el-option label="忽略层级" value = ""  ></el-option>
            <el-option label="城市" value="city" v-if="ShowCitys" ></el-option>
            <el-option label="分公司" value="fenGS" v-if="ShowfenGS" ></el-option>
            <el-option label="大区" value="quyu" v-if="Showquyu" ></el-option>
            <el-option label="部门" value="bumen" v-if="Showbumen" ></el-option>
            <el-option label="顾问" value="guwen" v-if="Showguwen" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="timeType" label="时间">
          <el-select v-model="formLabelAlign.dayType" id="time" @change="holderQuJian" placeholder="时间" >
            <el-option
              v-for="item in timeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="value1" label="时间区间" v-show="isShow">
          <div class="block" >
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="BTN">
        <el-button style="background-color:#c73e3a;color:#fff" @click="checkCus" class="check" :disabled="disTBN">查询</el-button>
        <!-- <el-button style="background-color:	#00CDCD;color:#fff" @click="resetForm('formLabelAlign')"   class="check">重置筛选条件</el-button> -->
        </el-form-item>
      </el-form>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table  :data="tableData" style="width: 100%" v-loading="loading">
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column prop="time" label="日期" width="80" v-if="tableDepartmentShows.dateShow"></el-table-column>
      <el-table-column prop="departmentName" label="城市" width="60" v-if="tableDepartmentShows.cityShow"></el-table-column>
      <el-table-column prop="departmentName" label="分公司" width="70" v-if="tableDepartmentShows.fenShow"></el-table-column>
      <el-table-column prop="departmentName" label="大区" width="70" v-if="tableDepartmentShows.quyuShow"></el-table-column>
      <el-table-column prop="departmentName" label="部门" width="125" v-if="tableDepartmentShows.bumenShow"></el-table-column>
      <el-table-column prop="userName" label="顾问" width="60" v-if="tableDepartmentShows.guWenShow"></el-table-column>
      <el-table-column prop="sqCount" label="申请量" width="60"></el-table-column>
      <el-table-column prop="slCount" label="已受理" width="60"></el-table-column>
      <el-table-column prop="dgjCount" label="待跟进" width="60"></el-table-column>
      <el-table-column  label="待跟进率" width="60">
        <template slot-scope="scope">{{ scope.row.dgjRate }}%</template>
      </el-table-column>
      <el-table-column prop="level1Count" label="1星" width="60"></el-table-column>
      <el-table-column prop="level2Count" label="2星" width="60"></el-table-column>
      <el-table-column prop="level3Count" label="2星+" width="60"></el-table-column>
      <el-table-column label="可贷率" width="60">
        <template slot-scope="scope">{{ scope.row.keddRate }}%</template>
      </el-table-column>
      <el-table-column prop="level4Count" label="3星" width="60"></el-table-column>
      <el-table-column prop="level5Count" label="4星" width="60"></el-table-column>
      <el-table-column  label="优质率" width="60">
        <template slot-scope="scope">{{ scope.row.youzRate }}%</template>
      </el-table-column>
      <el-table-column prop="daoluanCount" label="捣乱申请" width="60"></el-table-column>
      <el-table-column label="捣乱率" width="60">
        <template slot-scope="scope">{{ scope.row.daoluanRate }}%</template>
      </el-table-column>
      <el-table-column label="异地率" width="60">
        <template slot-scope="scope">{{ scope.row.yidiRate }}%</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
export default {
  name: "dataStatistics",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:"",
        currentcity:""
      },
      tableDepartmentShows:{
        dateShow:true,
        cityShow:false,
        fenShow:false,
        quyuShow:false,
        bumenShow:false,
        guWenShow:false
      },
      ShowCitys:false,
      ShowfenGS:false,
      Showquyu:false,
      Showbumen:false,
      Showguwen:false,
      showTimeKind:true,
      //未分割的完整版时间区间
      value1: "",
      loading:true,
      //时间区间隐藏状态
      isShow:false,
      //禁用按钮的状态值
      disTBN:false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      cityselectList: [{ id: 0, name: "请先选择城市" }],
       //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
        ],
      //客户筛选区域数据
      formLabelAlign: {
        scopeOf : "", 	//	层级
        city : "", 	//	状态
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "" //	当前页码
      },
      // 表格依赖于数据没有数据  会一行都没有
      tableData: []
    };
  },
  methods: {
    hiddenCengJi(){
      for(let key  in this.tableDepartmentShows){
        this.tableDepartmentShows[key] = false
      }
    },
    //获取统计数据List的方法
    getTongJiList(){
      this.$axios
      .get(`/customer/getDataStatistics?userId=${this.currentUser.currentid}&city=${this.formLabelAlign.city}&scopeOf=${this.formLabelAlign.scopeOf}&dayType=${this.formLabelAlign.dayType}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`)
      .then(res => {
        if (res.data.messageModel.code == 0) {
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
          this.loading = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.tableData = [];
          this.pageConfig.total = 0;
          this.loading = false
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //清空所有筛选条件的方法
    clearAllCheck(){
        for (let i = 0; i < this.formLabelAlign.length; i++) {
          this.formLabelAlign.formLabelAlign[i] = "";  
        }
        this.value1 = "" 	//未分割的完整版时间区间
    },
    //判断时间区间是否隐藏
    holderQuJian(vId){
      let obj = {};
      obj = this.timeList.find(item => {
        //这里的fenselectList就是上面遍历的数据源
        return item.id === vId; //筛选出匹配数据
      });
      // console.log(obj.name);
      if (obj.id == "customize") {
        this.isShow = true;
      }
      else {
        this.isShow = false;
        this.value1 = "";
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //分页的方法
    handleSizeChange(val) {
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
         this.getTongJiList()
      }else{
        this.pageConfig.pageSize = val;
         this.getTongJiList()
      }
    },
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
        this.getTongJiList()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //查询
    checkCus(){
      this.disButton()
      this.loading = true
      if (this.currentUser.currentcity == null) {
        if (this.ShowCity == true && this.formLabelAlign.city == "") {
        this.$message.error("请选择城市");
        return false
        }
      }else if(this.formLabelAlign.scopeOf == "city"){
        this.hiddenCengJi()
        this.tableDepartmentShows.cityShow = true
      }else if(this.formLabelAlign.scopeOf == "fenGS"){
        this.hiddenCengJi()
        this.tableDepartmentShows.fenShow = true
      }else if(this.formLabelAlign.scopeOf == "quyu"){
        this.hiddenCengJi()
        this.tableDepartmentShows.quyuShow = true
      }else if(this.formLabelAlign.scopeOf == "bumen"){
        this.hiddenCengJi()
        this.tableDepartmentShows.bumenShow = true
      }else if(this.formLabelAlign.scopeOf == "guwen"){
        this.hiddenCengJi()
        this.tableDepartmentShows.bumenShow = true
        this.tableDepartmentShows.guWenShow = true
      }else if(this.formLabelAlign.scopeOf == ""){
        this.hiddenCengJi()
        this.tableDepartmentShows.dateShow = true
      }
      
      if (this.value1 != "" || this.formLabelAlign.dayType == "customize") {
        this.formLabelAlign.beginTime = (this.value1)[0];
        this.formLabelAlign.endTime = (this.value1)[1];
        // if (this.formLabelAlign.beginTime == this.formLabelAlign.endTime) {
        this.formLabelAlign.beginTime = this.formLabelAlign.beginTime + " " + "00:00:00";
        this.formLabelAlign.endTime = this.formLabelAlign.endTime + " " + "23:59:59";
        // }
      }else{
        this.formLabelAlign.beginTime = "";
        this.formLabelAlign.endTime = "";
      }    
      this.getTongJiList()
    }
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      // return moment(value).format("YYYY-MM-DD HH:mm:ss");
      return moment(value).format("YY-MM-DD");
    },
  },
  created () {
    // console.log(this.$store.state.admin);
    // if (window.sessionStorage.getItem("prohibitLogin") == "true") {
      this.currentUser.currentName = window.sessionStorage.getItem("name")
      this.currentUser.currentid = window.sessionStorage.getItem("id")
      this.currentUser.currentLevel = window.sessionStorage.getItem("userLevel")
      this.currentUser.currentcity = window.sessionStorage.getItem("departmentName1")
      if (this.currentUser.currentLevel == 0 && this.currentUser.currentcity == "null" || this.currentUser.currentLevel == 10 && this.currentUser.currentcity == "null") {
        this.formLabelAlign.city = "all"
        this.ShowCity = true
        // this.formLabelAlign.city = "深圳"
        this.getTongJiList()
        //获取所有城市
          this.$axios
            .get(`/department/getAll`)
            .then(res => {
              this.cityselectList = res.data;
            })
            .catch(error => {
              console.log(error);
            });
      }else{
        this.formLabelAlign.city = this.currentUser.currentcity
        this.getTongJiList()
      }
      if( this.currentUser.currentLevel <= 4){
        this.Showguwen = true;
        this.Showbumen = true;
      }
    if( this.currentUser.currentLevel <= 3){
      this.Showquyu = true;
    }
    if( this.currentUser.currentLevel <= 2){
      this.ShowfenGS = true;
    }
    if( this.currentUser.currentLevel <= 1){
      this.ShowCitys = true;
    }
    if( this.currentUser.currentLevel == 10){
      this.ShowCitys = true;
      this.Showguwen = true;
      this.Showbumen = true;
      this.Showquyu = true;
      this.ShowfenGS = true;
    }
  }
};
</script>

<style  lang='scss'>
.dataStatistics-container {
  .el-form-item.BTN {
    /deep/.el-form-item__content{
          margin-left: 20px!important;
    }
  }
  input.el-input__inner {
    width: 170px;
    height: 32px;
    padding-left: 10px;
}
//突出隔行的颜色差异
.el-table {
  font-size: 12px;
  .cell {
    padding-left: 1px;
    padding-right: 1px;
    text-align: center;
  }
  th {
    padding: 0;
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 0;
    border-bottom: 1px solid rgb(211, 205, 205);
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
} 
// 时间分类框样式
 input.el-input__inner#kinds,
 /deep/input.el-input__inner#time {
    // width: 85px;
    padding-left: 7px;
    height: 32px!important;
}
// 日期选择器
daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 240px;
    height: 36px;
    vertical-align: middle;
    .el-date-editor .el-range__icon {
    line-height: 27px;
    }
    .el-date-editor .el-range-separator {
    width: 5%;
}
/deep/.block,
#block {
  border: none !important;
  /deep/.el-date-editor--daterange {
    width: 240px !important; 
  }
}
}
.el-form-item {
  display: inline-block;
  margin: 3px 0 3px 10px;
}
.el-form-item:nth-child(n + 6) {
  margin-top: 0;
}
.el-select.time {
  margin-left: 20px;
}
.el-input--suffix .el-input__inner.fanwei {
  padding-right: 30px;
  width: 30px;
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
}
.check {
  margin-left: 22px;
  padding: 8px 20px;
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: .9;
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>