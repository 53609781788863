<template>
  <div class="UnhandleT-container" v-cloak>
    <!-- 栅格 输入框 和按钮 -->
    <el-row class="filterCondition">
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-if="this.formLabelAlign.teamSearchLevel == 'level0'">0星客户超过6h未联系</p>
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-else-if="this.formLabelAlign.teamSearchLevel == 'level2'">2星及2星+客户超过6天未联系</p>
        <p style="background-color:#C7DEE8;display: inline-block ;line-height:1.5;opacity:0.5; margin:10px 20px;padding:0 20px" v-else-if="this.formLabelAlign.teamSearchLevel == 'level3'">3星及4星客户超过4天未联系</p>
        <p v-else></p>
        <div id="bigBox" style="display:flex">
        <span id="give">分配给:</span>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div class="duoxuankuang" style="display:inline">
          <el-checkbox-group v-model="checkedGuWens" @change="handleCheckedGuWenChange">
            <el-checkbox v-for="(dange,index) in guwenList" :label="dange" :key="index">{{dange.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <el-button style="background-color:#008C5E;color:#fff" class="fenPei" :disabled="disTBN" @click="beforeFenPei">分 &nbsp; 配</el-button>
        <el-button style="background-color:#DFA018;color:#fff;white-space: normal;width: 10rem;" class="AddGGC" @click="addGGC" :disabled="disTBN" >加入公共池</el-button>
      </div>
    </el-row>
    <!-- table -->
    <!-- <el-table  @selection-change="changeCus" :row-class-name="tableRowClassName" :data="tableData" style="width: 100%" stripe> -->
    <el-table :data="tableData" @selection-change="changeCus" style="width: 100%" v-loading="loading" v-cloak>
      <el-table-column type="selection" width="35"></el-table-column>
      <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
      <el-table-column  label="姓名" width="70" align="center">
        <template slot-scope="scope"><a class="toEdit" size="mini" @click="handleEdit(scope.$index, scope.row)">{{ scope.row.name}}</a></template>
      </el-table-column>
      <el-table-column  label="状态" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '资质不符' " style='color:#C73E3A'>{{ scope.row.state}}</span>
          <span v-else-if=" scope.row.state == 'B上门'||scope.row.state == '已上门'  || scope.row.state == '待签约'|| scope.row.state == '已放款'|| scope.row.state == '审核中'" style='color:#5BBD2B'>{{ scope.row.state}}</span>
          <span v-else-if="scope.row.state == '已受理' || scope.row.state == '未接通' || scope.row.state == '待跟进'" style='color:#3390ff'>{{ scope.row.state}}</span>
          <span v-else-if="scope.row.state == '捣乱申请' || scope.row.state == '已拒批' || scope.row.state == '外地申请'||  scope.row.state == '重复申请'" style='color:#D7962F'>{{ scope.row.state}}</span>
          <span v-else style='color:grey'>{{ scope.row.state}}</span>
          </template>
      </el-table-column>
      <el-table-column  label="星级" width="50" align="center">
        <template slot-scope="scope">{{ scope.row.level| levelKind }}</template>
      </el-table-column>
      <el-table-column label="备注" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.noteList" :key="index">
            <p class="ell" v-if="index < 3"
               v-bind:style="{ color: item.otherUser == 1 ? 'red' : '#606266' }">
              &nbsp;<span v-bind:style="{
									color: item.otherUser == 1 ? '#898989' : '#898989',
								}"
            >{{ ++index }}--{{ item.createDate | formatTime }}--{{
                item.userName
              }}</span
            >--{{ item.content }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="资质" width="200" align="center">
        <template slot-scope="scope"><span>{{ scope.row.remarks | remarksPanKong}}</span></template>
      </el-table-column>
      <el-table-column prop="userName" label="所属顾问" width="68" align="center"></el-table-column>
      <el-table-column  label="分配时间" width="150" align="center" sortable>
        <!-- <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template> -->
        <template slot-scope="scope">{{ scope.row.distributionTime | formatTime }}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange"  :current-page="pageConfig.currentPage"  :page-sizes="[10, 20 ,50, 100]"  :page-size="pageConfig.pageSize"  layout="total, sizes, prev, pager, next, jumper"  :total="pageConfig.total">
    </el-pagination>
    <!-- 分配弹出框 -->
    <el-dialog  title="确认分配"  :visible.sync="centerDialogVisible"  :modal-append-to-body='false'   width="30%"  center>
      <span>确认将选中的{{this.customerId.length}}名客户分配给{{this.userNameList}}？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="fenPei" >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 导入时间戳
import moment from 'moment'
import { mapMutations } from 'vuex';
export default {
  name: "UnhandleT",
  // 数据
  data() {
    return {
      currentUser:{
        currentName:"",
        currentId:"",
        currentLevel:"",
        currentPhone:""
      },
      loading:true,
      checkAll: false,
      showTimeKind:true,
      isIndeterminate: false,
      checkedGuWens:[],
      centerDialogVisible: false,
      userNameList:[],//被选中顾问姓名的数组
      guwenList:[{name:'顾问一',id:1}],//顾问列表
      //提交表格的对象
      form:"",
      //时间区间隐藏状态
      isShow:false,
      disTBN : false,
      //页码参数
      pageConfig:{
        currentPage:1,//当前页
        pageSize:10,//当前单页数据条数
        total:0,//请求到的数据总条数
      },
      //进度动态生成内容
      statusList: [
        { id: "所有", name: "" },
        { id: "已受理", name: "已受理" },
        { id: "未接通", name: "未接通" },
        { id: "待跟进", name: "待跟进" },
        { id: "资质不符", name: "资质不符" },
        { id: "待签约", name: "待签约" },
        { id: "已上门", name: "已上门" },
        { id: "B上门", name: "B上门" },
        { id: "审核中", name: "审核中" },
        { id: "已放款", name: "已放款" },
        { id: "已拒批", name: "已拒批" },
        { id: "捣乱申请", name: "捣乱申请" },
        { id: "重复申请", name: "重复申请" },
        { id: "外地申请", name: "外地申请" },
      ],
      daiGenJinNum:{
        dgjCount:0,
        level3count:0,
        level2count:0
      },
      //加入公共池的客户id数组
      customerId:[],
      kindtimeList:[
        { id: "beizhuTime", name: "备注时间" },
        { id: "fenpeiTime", name: "分配时间" },
      ],
      //时间选择生成内容
      timeList: [
        { id: "", name: "不限" },
        { id: "today", name: "今日" },
        { id: "yestoday", name: "昨日" },
        { id: "sevenDay", name: "最近七天" },
        { id: "tomonth", name: "本月" },
        { id: "yesmonth", name: "上月" },
        { id: "customize", name: "自定义" },
      ],
      //客户筛选区域数据
      formLabelAlign: {
        timeType: "", 	//时间类型
        dayType:"",
        beginTime : "", 	//开始时间
        endTime : "",  //	结束时间
        pageSize : "", 	//分页大小
        currentPage : "", //	当前页码
        keywords: "",
        level: "",
        userName: "",
        state: "",
        noteList: [],
        scopeOf: "kehu",
        otherScopeOf: "",
        otherName: "",
        content:"",
        contentCount:"",
        zaiFenPei:"",
        kinds: "",
        weiLianXiDays: "",
        distributionTime: "",
        teamSearchLevel : "" //	星级条件
      },
      // 表格依赖于数据没有数据  会一行都没有
      tableData: [],
      //暂存当前页面的星级条件 判断是否需要变更currentPage
      currentSearchLevel:""
    };
  },
  methods: {
    //表格各行变色的方法
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
        return 'warning-row';
      }else{
        return 'success-row';
      }
    },
    //将按钮禁用两秒
    disButton(){
      this.disTBN = true
      setTimeout(() => {
        this.disTBN = false
      }, 2000);
    },
    //获取选中客户并返回数组
    changeCus (val) {
      this.customerId = []
      // console.log(val);// 返回的是选中的列的数组集合
      for (let i = 0; i < val.length; i++) {
        this.customerId.push(val[i].id);
      }
    },
    handleCheckAllChange(val) {
        this.checkedGuWens = val ? this.guwenList : [];
        if (this.checkedGuWens != val) {
        this.guwenList.forEach(item => {
          this.userId.push(item.id)
          this.userNameList.push(item.name)
        })
      } else {
        this.checkAll = false
        this.userId = []
      }
      this.isIndeterminate = false;
    },
    //获取选中顾问
    handleCheckedGuWenChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.guwenList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.guwenList.length;
      this.userId = []
        for (let i = 0; i < value.length; i++) {
          this.userId.push(value[i].id);
        }
      this.userNameList = []
        for (let i = 0; i < value.length; i++) {
          this.userNameList.push(value[i].name);
        }
      // console.log(this.userNameList);
    },
    //按首字母排序的方法
    sortGuWenList(arr, sortStr) {
      // 排序函数（用以返回次序关系）
      var bySort = function() {
        return function(o, p) {  // p 是 o 的下一项
          var a = o[sortStr],
            b = p[sortStr];
          if (isNaN(a)) {  // 非数字排序
            return a.localeCompare(b);  // 用本地特定顺序来比较(支持中文)
          } else {
            if (a === b) {
              return 0;
            } else {
              return a > b ? 1 : -1;
            }
          }
        }
      };
      for (var i = 0; i < arr.length; i++) {
        //console.log(arr[i][sortStr])
        arr.sort(bySort(arr[i][sortStr]));
      }
    },
    //分配前
    beforeFenPei(){
      if (this.userId.length == 0) {
        this.$message.error("请至少选择一个顾问进行分配");
        return false
      }else if(this.customerId.length == 0){
        this.$message.error("您还没有选择被分配的客户");
        return false
      }else{
        this.centerDialogVisible = true
      }
    },
    //分配
    fenPei(){
      this.disButton()
      //！！！！！！！！！注意！！！！！！！！！！后端规定该请求userId为数组而非当前登陆用户的Id
        // console.log(this.userId);
      this.$axios.get(`/customer/hangFenPei?operationId=${this.currentUser.currentid}&userId=${this.userId}&customerId=${this.customerId}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.centerDialogVisible = false
          this.$message({  message: '分配成功',type: 'success'});
          // this.$router.go(0)
          this.loading = true
          this.getCusList()
          // this.$axios
          // .post(`/customer/getCustomerTeamList?userId=${this.currentUser.currentid}&other=${this.other}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}&otherScopeOf=${this.formLabelAlign.otherScopeOf}&otherName=${this.formLabelAlign.otherName}&keywords=${this.formLabelAlign.keywords}&level=${this.formLabelAlign.level}&state=${this.formLabelAlign.state}&content=${this.formLabelAlign.content}&contentCount=${this.formLabelAlign.contentCount}&scopeOf=${this.formLabelAlign.scopeOf}&timeType=${this.formLabelAlign.timeType}&dayType=${this.formLabelAlign.dayType}&weiLianXiDays=${this.formLabelAlign.weiLianXiDays}&beginTime=${this.formLabelAlign.beginTime}&endTime=${this.formLabelAlign.endTime}`)
          // .then(res => {
          //   // console.log(res);
          //   if (res.data.messageModel.code == 0) {
          //     this.loading = false
          //     if (res.data.dataModel.total == 0) {
          //       this.$message('给予当前搜索条件的客户，已全部被您分配');
          //       this.tableData = res.data.dataModel.list;
          //       this.pageConfig.total = res.data.dataModel.total;
          //       return false;
          //     }
          //     // this.$message.success("已为您找到以下客户(#^.^#)");
          //     this.tableData = res.data.dataModel.list;
          //     this.pageConfig.total = res.data.dataModel.total;
          //   }else{
          //     this.loading = false
          //     this.$message.error(res.data.messageModel.messageText);
          //   }
          //   })
          // .catch(error => {
          //   console.log(error);
          // });
          this.getUnread()
          this.centerDialogVisible = false
        }else{
          this.$message.error(res.data.messageModel.messageText);
          this.centerDialogVisible = false
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //加入公共池
    addGGC(){
      if(this.customerId.length == 0){
        this.$message.error("您还没有选择需要加入公共池的客户");
        return false
      }
      this.$axios
      .post(`/customer/addToPool?operationId=${this.currentUser.currentid}&customerId=${this.customerId}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.$message({  message: '已将选中的客户成功添加到公共池',type: 'success'});
          this.getCusList()
        }else{
          this.$message.error(res.data.messageModel.messageText + "/(ㄒoㄒ)/~~");
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //拿到所有可支配的顾问列表
    getGuWenList(){
      this.$axios.get(`/user/getUserTeam?userId=${this.currentUser.currentid}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.guwenList = res.data.dataModel
          this.sortGuWenList(this.guwenList, 'name')
        }else{
          this.$message.error("没能成功获取您麾下的顾问，请稍后再试/(ㄒoㄒ)/~~");
          // this.$message.error(res.data.messageModel.messageText);
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //获取客户 List的方法
    getCusList(){
      //打开遮罩层
      this.loading = true
      this.$axios.post(`/customer/getDclCustomerList?userId=${this.currentUser.currentid}&type=team&searchLevel=${this.formLabelAlign.teamSearchLevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`).then(res => {
        // console.log(res);
        let code = res.data.messageModel.code;
        if (code == 0) {
          this.loading = false
          if (res.data.dataModel.total == 0) {
             this.$message('抱歉，没有找到符合条件的客户');
             this.tableData = []
             this.pageConfig.total = res.data.dataModel.total;
             return false;
          }
          this.$message.success("已为您找到以下客户(#^.^#)");
          this.tableData = res.data.dataModel.list;
          this.pageConfig.total = res.data.dataModel.total;
        }else{
          this.loading = false
          this.$message.error(res.data.messageModel.messageText);
        }
        })
      .catch(error => {
        console.log(error);
      });
    },
    //分页的方法
    handleSizeChange(val) {
      // console.log(val);
      if (this.pageConfig.currentPage != 1) {
        this.pageConfig.currentPage = 1;
        this.pageConfig.pageSize = val;
        this.getCusList()
      }else{
        this.pageConfig.pageSize = val;
        this.getCusList()
      }
    },
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getCusList()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //编辑
    handleEdit(index, row) {
      let CusId = row.id;
      // 编程式导航
      this.$router.push({ path: '/myCusEdit', query: { "CusId": CusId } })
      window.sessionStorage.setItem("CusId", CusId);
    },
    //查询
    checkCus(){
      this.pageConfig.currentPage = 1
      this.getCusList()
    },
  },
  // 本地过滤器
  filters: {
    formatTime(value) {
      if (value == null) {
        return ""
      }
      return moment(value).format("YY-MM-DD HH:mm:ss");
    },
    remarksPanKong(value) {
      if (value == "null") {
        return ""
      }
      return value;
    },
    //判断星级状态
    levelKind(val) {
      if (val == "0") {  return  "0星" }
      else if(val == "1"){  return  "1星" }
      else if(val == "2"){  return  "2星" }
      else if(val == "3"){  return "2星+" }
      else if(val == "4"){  return  "3星" }
      else if(val == "5"){  return "4星" }
      else{  return ""
      }
    }
  },
  created () {
    if (window.sessionStorage.getItem("prohibitLogin") == "true") {
        if (this.$route.query.teamSearchLevel) {
            this.formLabelAlign.teamSearchLevel = this.$route.query.teamSearchLevel
            this.currentSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
        }else{
            this.formLabelAlign.teamSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
            this.currentSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
        }
      //创建存放表格的对象
      this.form = new FormData()
      this.currentUser.currentName = window.sessionStorage.getItem("name")
      this.currentUser.currentid = window.sessionStorage.getItem("id")
      this.$axios.post(`/customer/getDclCustomerList?userId=${this.currentUser.currentid}&type=team&searchLevel=${this.formLabelAlign.teamSearchLevel}&currentPage=${this.pageConfig.currentPage}&pageSize=${this.pageConfig.pageSize}`).then(res => {
        if (res.data.messageModel.code == 0) {
          this.loading = false;
          if (res.data.dataModel == null) {
            this.$message.error("抱歉，没有找到符合搜索条件的客户");
            this.tableData = {};
          }else{  this.tableData = res.data.dataModel.list;
            this.pageConfig.total = res.data.dataModel.total;
          }
        }else{
          this.loading = false;
          this.$message.error(res.data.messageModel.messageText);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      this.$message.warning("请先进行登录操作！");
      this.$router.push("login");
    }
    let component = this
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key === 13) {
        component.checkCus()
      }
    }
    //拿到所有可支配的顾问列表
    this.$axios.get(`/user/getUserTeam?userId=${this.currentUser.currentid}`)
      .then(res => {
        // console.log(res);
        if (res.data.messageModel.code == 0) {
          this.guwenList = res.data.dataModel
          this.getGuWenList()
        }else{
          this.$message.error("没能成功获取您麾下的顾问，请稍后再试/(ㄒoㄒ)/~~");
          // this.$message.error(res.data.messageModel.messageText);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  activated () {
    if (this.$route.query.teamSearchLevel) {
        this.formLabelAlign.teamSearchLevel = this.$route.query.teamSearchLevel
        if (this.currentSearchLevel != window.sessionStorage.getItem("teamSearchLevel")) {
          this.currentSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
          this.pageConfig.currentPage = 1
        }
    }else{
        this.formLabelAlign.teamSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
        if (this.currentSearchLevel != window.sessionStorage.getItem("teamSearchLevel")) {
          this.currentSearchLevel = window.sessionStorage.getItem("teamSearchLevel")
          this.pageConfig.currentPage = 1
        }
    }
    this.getCusList()
    this.getGuWenList()
  }
};
</script>

<style  lang='scss'>
// 在填充数据之前隐藏
[v-cloak] {
    display: none;
}
.UnhandleT-container {
//突出隔行的颜色差异
.el-table {
  font-size: 12px;
  .cell {
      padding: 2px 2px;
      line-height: 16px;
    }
  th {
    padding: 0;
  }
  th.is-sortable {
    .cell {
      text-align: center;
    }
  }
  .warning-row {
    background: #fff;
  }
   .success-row {
    background: #D3D3D3;
  }
  td {//取消单元格的上下padding
    padding: 2px 0;
    border-bottom: 1px solid rgb(211, 205, 205);
    border-right: 1px dashed #d3cdcd;
  }
   .el-button--mini{
    border: 0;
    padding: 5px 15px;
    margin: 5px 0;
    background: rgba(255,255,255,0.01);
  }
  .toEdit:hover {
    color:skyblue;
  }
}
.filterCondition {
  background-color: #fff;
  margin-bottom: 20px;
  .el-form-item#btnList {
    /deep/.el-form-item__content{
      margin-left: 20px!important;
    }
  }
}
.check {
  margin-left: 22px;
  padding: 10px 20px;
}
.ell {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  word-break:normal;
  text-indent: -2em;
  margin: 0 0 0 2em;
  line-height: 1.2;
}
//多选框
#bigBox {
  margin-bottom: 20px;
  #give {
    width: 100px;
    font-size: 14px;
    margin-left: 20px;
  }
  .el-checkbox {
    margin-right: 8px;
  }
  .duoxuankuang {
    // padding: 10px;
    margin-right: 10px;
    // border: 1px solid #eeeeee;
    border-bottom: 20px
  }
}
//分页框样式设置（避免页面被覆盖）
    .el-pagination .el-input__inner {
      width: 100px;
    }
}
</style>
